import React, { useContext, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Dropdown from "react-bootstrap/Dropdown";
import Toast from "react-bootstrap/Toast";
import Form from "react-bootstrap/Form";
import {
  AccordionContext,
  ToastContainer,
  useAccordionButton,
} from "react-bootstrap";

import i18n from "../../utils/i18n";

import Crown from "../icons/Crown";
import GreaterThan from "../icons/GreaterThan";
import People from "../icons/People";
import MutedPeople from "../icons/MutedPeople";
import BannedPeople from "../icons/BannedPeople";
import Frozen from "../icons/Frozen";
import ThreeVerticalDots from "../icons/ThreeVerticalDots";

const ActionsToggle = React.forwardRef(({ onClick }, ref) => (
  <button
    className={"sendbird-action-dropdown-button-container"}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <div className="sendbird-user-list-item--small__action">
      <div className="sendbird-context-menu">
        <button
          className="sendbird-user-message__more__menu sendbird-iconbutton "
          type="button"
        >
          <span className="sendbird-iconbutton__inner">
            <div
              className=" sendbird-icon sendbird-icon-more sendbird-icon-color--content-inverse"
              role="button"
              tabIndex="0"
            >
              <ThreeVerticalDots />
            </div>
          </span>
        </button>
      </div>
    </div>
  </button>
));

function RowToggle({
  eventKey,
  callback,
  id,
  accordionClassnames,
  accordionIconClassnames,
  RowIcon,
  rowTitle,
  badgeCount,
}) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback?.());

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      className={`sendbird-accordion__panel-header ${accordionClassnames}`}
      id={id}
      role={"switch"}
      aria-checked={"false"}
      tabIndex={0}
      onClick={decoratedOnClick}
      onKeyUp={decoratedOnClick}
    >
      <div
        className={`sendbird-channel-settings__accordion-icon sendbird-icon sendbird-icon-color--primary ${accordionIconClassnames}`}
        role="button"
        tabIndex="0"
      >
        <RowIcon />
      </div>
      <span className="sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
        {rowTitle}
      </span>
      {badgeCount && (
        <div className=" sendbird-badge">
          <div className="sendbird-badge__text">
            <span className="sendbird-label sendbird-label--caption-2 sendbird-label--color-oncontent-1">
              {badgeCount}
            </span>
          </div>
        </div>
      )}
      <div
        className={`sendbird-accordion__panel-icon-right sendbird-accordion__panel-icon--chevron  sendbird-icon sendbird-icon-chevron-right ${
          isCurrentEventKey ? "sendbird-accordion__panel-icon--open" : ""
        }`}
        role="button"
        tabIndex="0"
      >
        <GreaterThan />
      </div>
    </div>
  );
}

function MemberList({
  sb,
  channel,
  members,
  eventKey,
  emptyText,
  addText,
  menuItems,
}) {
  const curUId = sb.currentUser.userId;
  members.sort(function (x, y) {
    return x.userId === curUId ? -1 : y.userId === curUId ? 1 : 0;
  });

  return (
    <Accordion.Collapse eventKey={eventKey}>
      <div className={"sendbird-accordion__list"}>
        <div
          className={`sendbird-channel-settings-member-list sendbird-accordion ${
            members.length === 0 ? "empty-list" : ""
          }`}
        >
          {members.map((member) => (
            <div className="sendbird-user-list-item--small">
              <div className="sendbird-context-menu">
                <div
                  className="sendbird-user-list-item--small__avatar sendbird-avatar"
                  role="button"
                  tabIndex="0"
                >
                  <div className="sendbird-avatar-img sendbird-image-renderer">
                    background-image: ;
                    <div
                      className="sendbird-image-renderer__image"
                      style={{
                        backgroundImage: `url("${member.plainProfileUrl}")`,
                      }}
                    />
                    <img
                      className="sendbird-image-renderer__hidden-image-loader"
                      src={`${member.plainProfileUrl}`}
                      alt=""
                    />
                  </div>
                </div>
                {(member.isMuted || member.role === "operator") && (
                  <div className="sendbird-muted-avatar">
                    <div className="sendbird-muted-avatar__icon">
                      <div className="sendbird-muted-avatar__bg" />
                      <div
                        className=" sendbird-icon sendbird-icon-mute sendbird-icon-color--white"
                        role="button"
                        tabIndex="0"
                      >
                        {member.isMuted && <MutedPeople />}
                        {member.role === "operator" && <Crown />}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <span className="sendbird-user-list-item--small__title sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
                {member.nickname + (curUId === member.userId ? " (You)" : "")}
              </span>
              {menuItems &&
                curUId !== member.userId &&
                member.userId !== channel.creator.userId && (
                  <Dropdown className={"sendbird-action-dropdown"}>
                    <Dropdown.Toggle as={ActionsToggle} />
                    <Dropdown.Menu>
                      {menuItems
                        .filter(
                          (item) => !item.showShow || item.showShow(member),
                        )
                        .map(({ title, onClick }, index) => (
                          <Dropdown.Item
                            eventKey={`${index}`}
                            onClick={() => onClick(member)}
                          >
                            {title}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
            </div>
          ))}
          {members.length === 0 && (
            <span className="sendbird-channel-settings-empty-list">
              {emptyText}
            </span>
          )}
          {addText && (
            <div className="sendbird-channel-settings-accordion__footer">
              <button
                className="sendbird-button sendbird-button--secondary sendbird-button--small"
                type="button"
              >
                <span className="sendbird-button__text sendbird-label sendbird-label--button-1 sendbird-label--color-oncontent-1">
                  {addText}
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </Accordion.Collapse>
  );
}

export default function ChannelSettingsModerationPanel({ sb, channel }) {
  const [members, setMembers] = useState(channel.members);
  const [operators, setOperators] = useState(
    channel.members.filter((member) => member.role === "operator"),
  );
  const [muted, setMuted] = useState(
    channel.members.filter((member) => member.isMuted),
  );
  const [bannedUsers, setBannedUsers] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  function displayToast(message, isSuccess) {
    setToastMessage(message);
    setIsSuccess(isSuccess);
    setShowToast(true);
  }

  function handleSendbirdErrorResponse(e, message) {
    //TODO: log in posthog/sentry
    displayToast(
      message || i18n.t("client.community.something_went_wrong"),
      false,
    );
  }

  const getBannedUsers = function () {
    const query = channel.createBannedUserListQuery();
    query
      .next()
      .then((users) => {
        setBannedUsers(users);
      })
      .catch(handleSendbirdErrorResponse);
  };

  function registerUserAsOperator(member) {
    const userIds = [member.userId];
    channel
      .addOperators(userIds)
      .then(() => {
        displayToast(
          i18n.t("client.community.added_operator", { name: member.nickname }),
          true,
        );
        setOperators(
          channel.members.filter((member) => member.role === "operator"),
        );
      })
      .catch(handleSendbirdErrorResponse);
  }

  function unregisterUserAsOperator(member) {
    const userIds = [member.userId];
    channel
      .removeOperators(userIds)
      .then(() => {
        displayToast(
          i18n.t("client.community.removed_operator", {
            name: member.nickname,
          }),
          true,
        );
        setOperators(
          channel.members.filter((member) => member.role === "operator"),
        );
      })
      .catch(handleSendbirdErrorResponse);
  }

  function banUser(member) {
    channel
      .banUser(member, 60000000, "Banned by operator")
      .then(() => {
        displayToast(
          i18n.t("client.community.banned_member", { name: member.nickname }),
          true,
        );
        setMembers(channel.members);
      })
      .catch(handleSendbirdErrorResponse);
  }

  function unbanUser(member) {
    channel
      .unbanUser(member)
      .then(() => {
        const userIds = [member.userId];
        channel
          .inviteWithUserIds(userIds)
          .then(() => {
            displayToast(
              i18n.t("client.community.unbanned_member", {
                name: member.nickname,
              }),
              true,
            );
            setMembers(channel.members);
            getBannedUsers();
          })
          .catch(() =>
            handleSendbirdErrorResponse(
              i18n.t("client.community.something_went_wrong_support"),
            ),
          );
      })
      .catch(handleSendbirdErrorResponse);
  }

  function muteUser(member) {
    channel
      .muteUser(member, 60000000, "Muted by operator")
      .then(() => {
        displayToast(
          i18n.t("client.community.muted_member", { name: member.nickname }),
          true,
        );
        setMuted(channel.members.filter((member) => member.isMuted));
        setMembers(channel.members);
      })
      .catch(handleSendbirdErrorResponse);
  }

  function unmuteUser(member) {
    channel
      .unmuteUser(member)
      .then(() => {
        displayToast(
          i18n.t("client.community.unmuted_member", { name: member.nickname }),
          true,
        );
        setMuted(channel.members.filter((member) => member.isMuted));
        setMembers(channel.members);
      })
      .catch(handleSendbirdErrorResponse);
  }

  function updateChannelFreeze(e) {
    if (e.target.checked) {
      channel
        .freeze()
        .then(() => {
          displayToast(i18n.t("client.community.channel_frozen"), true);
        })
        .catch(handleSendbirdErrorResponse);
    } else {
      channel
        .unfreeze()
        .then(() => {
          displayToast(i18n.t("client.community.channel_unfrozen"), true);
        })
        .catch(handleSendbirdErrorResponse);
    }
  }

  return (
    <>
      <ToastContainer className="toast-container position-fixed top-0 end-0 p-3 pt-6">
        <Toast
          className="toast"
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={2000}
          autohide
        >
          <Toast.Body>
            <div className="d-flex">
              <div className="d-flex gap-2 w-100 align-items-center">
                <i className="bi-info-circle-fill fs-3 text-success" />
                <span className="align-super px-1 mb-0 text-black toast-message-container">
                  {toastMessage}
                </span>
              </div>
              <div className="d-flex gap-2 align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                />
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Accordion className={"sendbird-channel-settings__operator"}>
        <RowToggle
          eventKey={"0"}
          id={"operators"}
          accordionClassnames={"sendbird-channel-settings__operators-list"}
          accordionIconClassnames={"sendbird-icon-operator"}
          RowIcon={Crown}
          rowTitle={i18n.t("client.community.operators_title")}
        />
        <MemberList
          sb={sb}
          channel={channel}
          members={operators}
          eventKey={"0"}
          menuItems={[
            {
              title: i18n.t("client.community.unregister_operator"),
              onClick: (member) => unregisterUserAsOperator(member),
            },
          ]}
        />
        <RowToggle
          eventKey={"1"}
          id={"members"}
          accordionClassnames={"sendbird-channel-settings__members-list"}
          accordionIconClassnames={"sendbird-icon-members"}
          RowIcon={People}
          rowTitle={i18n.t("client.community.members_title")}
          badgeCount={channel.members.length}
        />
        <MemberList
          sb={sb}
          channel={channel}
          members={members}
          eventKey={"1"}
          menuItems={[
            {
              title: i18n.t("client.community.register_operator"),
              onClick: (member) => registerUserAsOperator(member),
              showShow: (member) =>
                member.role !== "operator" && !member.isMuted,
            },
            {
              title: i18n.t("client.community.unregister_operator"),
              onClick: (member) => unregisterUserAsOperator(member),
              showShow: (member) => member.role === "operator",
            },
            {
              title: i18n.t("client.community.mute"),
              onClick: (member) => muteUser(member),
              showShow: (member) =>
                !member.isMuted && member.role !== "operator",
            },
            {
              title: i18n.t("client.community.unmute"),
              onClick: (member) => unmuteUser(member),
              showShow: (member) => member.isMuted,
            },
            {
              title: i18n.t("client.community.ban"),
              onClick: (member) => banUser(member),
              showShow: (member) => member.role !== "operator",
            },
          ]}
        />
        <RowToggle
          eventKey={"2"}
          id={"mutedMembers"}
          accordionClassnames={"sendbird-channel-settings__muted-members-list"}
          accordionIconClassnames={"sendbird-icon-mute"}
          RowIcon={MutedPeople}
          rowTitle={i18n.t("client.community.muted_title")}
        />
        <MemberList
          sb={sb}
          channel={channel}
          members={muted}
          eventKey={"2"}
          emptyText={i18n.t("client.community.no_muted_members")}
          menuItems={[
            {
              title: i18n.t("client.community.unmute"),
              onClick: (member) => unmuteUser(member),
            },
          ]}
        />
        <RowToggle
          eventKey={"3"}
          id={"bannedUsers"}
          accordionClassnames={
            "ssendbird-channel-settings__banned-members-list"
          }
          accordionIconClassnames={"sendbird-icon-ban"}
          RowIcon={BannedPeople}
          rowTitle={i18n.t("client.community.banned_title")}
          callback={getBannedUsers}
        />
        <MemberList
          sb={sb}
          channel={channel}
          members={bannedUsers}
          eventKey={"3"}
          emptyText={i18n.t("client.community.no_banned_members")}
          menuItems={[
            { title: "Unban", onClick: (member) => unbanUser(member) },
          ]}
        />
        <div className="sendbird-channel-settings__freeze">
          <div
            className="sendbird-channel-settings__accordion-icon sendbird-icon sendbird-icon-freeze sendbird-icon-color--primary"
            role="button"
            tabIndex="0"
          >
            <Frozen />
          </div>
          <span className="sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
            {i18n.t("client.community.freeze_channel")}
          </span>
          <div className="sendbird-ui-toggle sendbird-channel-settings__frozen-icon">
            <Form>
              <Form.Check
                type="switch"
                id="frozen-switch"
                defaultChecked={channel.isFrozen}
                value={"frozen"}
                onChange={(e) => updateChannelFreeze(e)}
              />
            </Form>
          </div>
        </div>
      </Accordion>
    </>
  );
}
