// Entry point for the build script in your package.json
import { Turbo } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";

import { StatsigClient } from "@statsig/js-client";
import { runStatsigSessionReplay } from "@statsig/session-replay";
import { runStatsigAutoCapture } from "@statsig/web-analytics";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";
import { getEnv } from "./utils/get-env";
import { Toaster } from "./utilities";

import "./controllers";
import "./utils/events";
import * as bootstrap from "bootstrap";

window.bootstrap = bootstrap;

import "trix";
import "./trix_extensions";

import "@rails/actiontext";
import { getStatsigCookiesParams } from "./utilities";

ActiveStorage.start();

if (document.querySelector("meta[name=webspot_disable_turbo_drive]")) {
  Turbo.session.drive = false;
}

// this allows wiring up bootstrap components (modal, offcanvas)
// when they come in by a turbo-frame. i'm wondering if there's maybe a less
// manual way for this to happen, similar to how bootstrap knows to "show"
// these things on page load if they have the show class, but i could not figure
// that out so here we are.
//
// https://turbo.hotwired.dev/reference/events
document.addEventListener("turbo:frame-render", (ev) => {
  // https://getbootstrap.com/docs/5.0/components/offcanvas
  for (let el of ev.target.querySelectorAll(".offcanvas")) {
    let offcanvas = bootstrap.Offcanvas.getOrCreateInstance(el);
    if (el.classList.contains("show")) {
      offcanvas.show();
    }
  }

  // https://getbootstrap.com/docs/5.2/components/modal
  for (let el of ev.target.querySelectorAll(".modal")) {
    let modal = bootstrap.Modal.getOrCreateInstance(el);
    if (el.classList.contains("show")) {
      modal.show();
    }
  }

  const fadeableElement = document.querySelector("#offcanvas-frame .fade");

  if (fadeableElement) {
    // fixes a adding a "show" class to modals breaks them
    // e.g. issue where the avatar cropping modal would show up empty
    if (fadeableElement.classList.contains("modal")) {
      return;
    }
    setTimeout(() => {
      fadeableElement.classList.add("show");
    }, 150);
  }
});

document.addEventListener("turbo:before-frame-render", (ev) => {
  // https://getbootstrap.com/docs/5.0/components/offcanvas
  for (let el of ev.target.querySelectorAll(".offcanvas")) {
    let offcanvas = bootstrap.Offcanvas.getOrCreateInstance(el);
    if (el.classList.contains("show")) {
      offcanvas.hide();
    }
  }

  // https://getbootstrap.com/docs/5.2/components/modal
  for (let el of ev.target.querySelectorAll(".modal")) {
    let modal = bootstrap.Modal.getOrCreateInstance(el);
    if (el.classList.contains("show")) {
      modal.hide();
    }
  }
});

// rudimentary error handling for when turbo frame requests get an error response
document.addEventListener("turbo:before-fetch-response", (ev) => {
  // https://github.com/hotwired/turbo/blob/main/src/http/fetch_response.ts
  if (ev.detail.fetchResponse.succeeded) return;
  if (ev.detail.fetchResponse.statusCode === 422) return;

  Toaster.error("An error occurred, please try again later", {
    delay: 3000,
  });
  ev.preventDefault();

  const loadingShimmer = document.querySelector(".loading-shimmer");

  if (loadingShimmer) {
    loadingShimmer.remove();
  }
});

// rudimentary error handling when network errors occur
document.addEventListener("turbo:fetch-request-error", (ev) => {
  Toaster.error("An error occurred, please try again later", {
    delay: 3000,
  });
  ev.preventDefault();

  const loadingShimmer = document.querySelector(".loading-shimmer");

  if (loadingShimmer) {
    loadingShimmer.remove();
  }
});

// initalize all tooltips on the page
const tooltipTriggerList = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]',
);
const tooltipList = [...tooltipTriggerList].map(
  (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl),
);

let isModalVisible = false;

document.addEventListener("DOMContentLoaded", async () => {
  document.addEventListener("openLoginModal", () => {
    if (!isModalVisible) {
      const loginModal = document.querySelector("#login-modal");
      if (loginModal) {
        // Add modal backdrop
        const backdrop = document.createElement("div");
        backdrop.classList.add("modal-backdrop", "fade", "show");
        document.body.appendChild(backdrop);

        // Show modal
        loginModal.classList.add("show");
        loginModal.style.display = "block";
        loginModal.setAttribute("aria-hidden", "false");

        // Override canvas focusin event handler
        loginModal.addEventListener("focusin", function (event) {
          event.stopPropagation();
        });

        const closeButton = loginModal.querySelector(".btn-close");
        if (closeButton) {
          closeButton.addEventListener("click", () => {
            // Hide modal
            loginModal.classList.remove("show");
            loginModal.style.display = "none";
            loginModal.setAttribute("aria-hidden", "true");

            // Remove modal backdrop
            const backdrop = document.querySelector(".modal-backdrop");
            if (backdrop) {
              backdrop.remove();
            }

            isModalVisible = false;
          });
        }

        // Set focus to an input element
        const inputElement = loginModal.querySelector("input");
        if (inputElement) {
          inputElement.focus();
        }

        isModalVisible = true;
      }
    }
  });

  // Loading skeleton on turbo link click
  const isProfileTabSkeletonEnabled = getEnv("PAGES_LOADING_SHIMMER");

  if (isProfileTabSkeletonEnabled) {
    document.addEventListener("turbo:click", function (event) {
      if (
        event.target.classList.contains("with-loading-shimmer") &&
        event.target.dataset.turboFrame !== "offcanvas-frame"
      ) {
        const containerElement = document.querySelector(
          ".loading-shimmer-container",
        );
        if (containerElement) {
          const loadingShimmer = document.createElement("div");
          loadingShimmer.classList.add("loading-shimmer");

          if (
            containerElement.classList.contains(
              "loading-shimmer-container-full-height",
            )
          ) {
            loadingShimmer.classList.add("full-hegiht");
          }

          containerElement.insertBefore(
            loadingShimmer,
            containerElement.firstChild,
          );
        }
      }
    });

    document.addEventListener("turbo:load", function (event) {
      const loadingShimmer = document.querySelector(".loading-shimmer");

      if (loadingShimmer) {
        loadingShimmer.remove();
      }
    });
  }

  // Statsig
  const STATSIG_CLIENT_KEY = getEnv("STATSIG_CLIENT_KEY");
  const STATSIG_ENVIRONMENT = getEnv("STATSIG_ENVIRONMENT");
  const STATSIG_USER_ID = getEnv("STATSIG_USER_ID");
  const STATSIG_USER_EMAIL = getEnv("STATSIG_USER_EMAIL");

  // If STATSIG_CLIENT_KEY is undefined it means the statsig is turned off
  if (STATSIG_CLIENT_KEY) {
    // Custom stableID
    const cookiesInstance = new Cookies();
    let wsStatsigStableID = cookiesInstance.get("wsStatsigStableID");
    let wsStatsigSessionID = cookiesInstance.get("wsStatsigSessionID");

    // In case server-side cookies were not set
    if (!wsStatsigStableID) {
      let expires = new Date();
      expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);

      const newWsStatsigStableID = uuidv4() + "_" + new Date().getTime();
      wsStatsigStableID = newWsStatsigStableID;
      cookiesInstance.set("wsStatsigStableID", wsStatsigStableID, {
        expires,
        ...getStatsigCookiesParams(window.location.href),
      });
    }

    if (!wsStatsigSessionID) {
      let expires = new Date();
      // Statsig sessions expire in 4 hours
      expires.setTime(expires.getTime() + 4 * 60 * 60 * 1000);

      const newWsStatsigSessionID = uuidv4() + "_" + new Date().getTime();
      wsStatsigSessionID = newWsStatsigSessionID;
      cookiesInstance.set("wsStatsigSessionID", wsStatsigSessionID, {
        expires,
        ...getStatsigCookiesParams(window.location.href),
      });
    }

    const client = new StatsigClient(
      STATSIG_CLIENT_KEY,
      {
        userID: STATSIG_USER_ID,
        email: STATSIG_USER_EMAIL,
        customIDs: {
          stableID: wsStatsigStableID,
        },
      },
      {
        initialSessionID: wsStatsigSessionID,
        environment: {
          tier: STATSIG_ENVIRONMENT,
        },
      },
    );
    runStatsigSessionReplay(client);
    runStatsigAutoCapture(client);

    const loggingEnabled = STATSIG_ENVIRONMENT !== "production";

    if (loggingEnabled) {
      const onAnyClientEvent = (event) => {
        console.log("Statsig events: ", event);
      };
      client.on("*", onAnyClientEvent);
    }

    // Update statsig session id on `session_expired` event
    const onSessionExpired = async () => {
      const clientContext = await client?.getAsyncContext();

      const newSessionID = clientContext?.session.data.sessionID;

      if (loggingEnabled) {
        console.log("Statsig session expired, setting new sessionID in cookie");
        console.log(`newSessionID = ${newSessionID}`);
      }

      // Renew sessionID in cookie
      const newExpires = new Date();
      newExpires.setTime(newExpires.getTime() + 4 * 60 * 60 * 1000); // 4 hours from now

      cookiesInstance.set("wsStatsigSessionID", newSessionID, {
        ...getStatsigCookiesParams(window.location.href),
      });
    };

    client?.on("session_expired", onSessionExpired);

    await client.initializeAsync();

    window.Statsig = client;
  }
});
