import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tip"
export default class extends Controller {
  initialize() {}

  setBlockId(event: Event) {
    // Button that triggered the modal
    const button = event.target as HTMLButtonElement;
    // Extract info from data-bs-* attributes
    const blockId = button.getAttribute("data-bs-block-id");
    // If necessary, you could initiate an AJAX request here
    // and then do the updating in a callback.
    //
    // Update the modal's content.
    const tipBlockInput = document.querySelector(
      "#tip-modal input.tip_block_id_input",
    ) as HTMLInputElement;
    tipBlockInput.value = blockId || "";
  }

  clearTipAmount() {
    const tipBlockIdInput = document.querySelector(
      "#tip-modal input.tip_block_id_input",
    ) as HTMLInputElement;
    const tipAmountInput = document.querySelector(
      "#tip-modal input[name='user_donation[amount]']",
    ) as HTMLInputElement;
    tipAmountInput.value = "";
    tipBlockIdInput.value = "";
  }

  focusInput() {
    const tipAmountInput = document.querySelector(
      "#tip-modal input[name='user_donation[amount]']",
    ) as HTMLInputElement;
    tipAmountInput.focus();
  }
}
