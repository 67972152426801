import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fit-text"
export default class extends Controller {
  connect() {
    const item = this.element as HTMLElement;
    const options = {
      sizes: {
        min: item.dataset.fittextSizeMin || 10,
        max: item.dataset.fittextSizeMax || 40,
      },
    };

    const originalWordBreak = item.style.wordBreak;
    item.style.fontSize = `${options.sizes.max}px`;
    item.style.wordBreak = "break-all";

    let longestIndex = 0;

    item.innerHTML = item.innerHTML
      .split(" ")
      .map((word, index, words) => {
        if (index > 0 && word.length > words[longestIndex].length) {
          longestIndex = index;
        }

        return `<div>${word}</div>`;
      })
      .join(" ");

    const controlWord = item.querySelectorAll("div")[longestIndex];

    this.reduceFont(controlWord, item, options, function () {
      item.innerHTML = item.textContent || item.innerText;
      item.style.wordBreak = originalWordBreak;
    });
  }

  reduceFont(
    control: HTMLElement,
    element: HTMLElement,
    options: { sizes: { min: string | number; max: string | number } },
    cb: () => void,
  ) {
    const fontSize = parseInt(element.style.fontSize);
    const decrement = Math.ceil(fontSize / 20);
    if (
      fontSize > parseInt(options.sizes.min as string) &&
      control.getBoundingClientRect().height > 2 * fontSize
    ) {
      element.style.fontSize = `${fontSize - decrement}px`;
      this.reduceFont(control, element, options, cb);
    } else {
      cb();
    }
  }
}
