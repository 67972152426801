import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = ["scrollableContainer", "prevBtn", "nextBtn"];
  static debounces = ["handleScroll", "handleResize"];
  static values = {
    visibilityClass: { type: String, default: "invisible" },
    floatingCtaInvisibleClass: {
      type: String,
      default: "floating-cta-invisible",
    },
  };

  declare readonly scrollableContainerTarget: HTMLUListElement;
  declare readonly prevBtnTarget: HTMLButtonElement;
  declare readonly nextBtnTarget: HTMLButtonElement;
  declare readonly hasScrollableContainerTarget: boolean;
  declare readonly hasPrevBtnTarget: boolean;
  declare readonly hasNextBtnTarget: boolean;
  declare visibilityClassValue: string;
  declare floatingCtaInvisibleClassValue: string;

  private scrollDistance: number = 0;

  connect() {
    if (!this.hasScrollableContainerTarget) return;

    this.scrollDistance = this.scrollableContainerTarget.scrollWidth / 4;

    useDebounce(this, { wait: 50 });
    this.scrollableContainerTarget.addEventListener(
      "scroll",
      this.handleScroll,
    );
    window.addEventListener("resize", this.handleResize);
    this.updateButtonVisibility();
    this.handleFloatingCtaInvisible();
  }

  disconnect() {
    if (this.hasScrollableContainerTarget) {
      this.scrollableContainerTarget.removeEventListener(
        "scroll",
        this.handleScroll,
      );
    }
    window.removeEventListener("resize", this.handleResize);
  }

  scrollContainerLeft() {
    if (!this.hasScrollableContainerTarget) return;

    this.scrollableContainerTarget.scrollBy({
      left: -this.scrollDistance,
      behavior: "smooth",
    });
  }

  scrollContainerRight() {
    if (!this.hasScrollableContainerTarget) return;

    this.scrollableContainerTarget.scrollBy({
      left: this.scrollDistance,
      behavior: "smooth",
    });
  }

  private handleScroll = this.updateButtonVisibility.bind(this);
  private handleResize = this.updateButtonVisibility.bind(this);

  private updateButtonVisibility() {
    const threshold = 5;
    const { scrollWidth, clientWidth, scrollLeft } =
      this.scrollableContainerTarget;
    const scrollWidthDifference = scrollWidth - clientWidth;
    const isScrollable = scrollWidthDifference > 0;
    const isAtStart = scrollLeft <= threshold;
    const isAtEnd = scrollLeft >= scrollWidthDifference - threshold;

    if (!isScrollable) {
      this.hidePrevBtnTarget();
      this.hideNextBtnTarget();
    } else if (isAtStart) {
      this.hidePrevBtnTarget();
      this.showNextBtnTarget();
    } else if (isAtEnd) {
      this.showPrevBtnTarget();
      this.hideNextBtnTarget();
    } else {
      this.showPrevBtnTarget();
      this.showNextBtnTarget();
    }
  }

  private hidePrevBtnTarget() {
    if (this.hasPrevBtnTarget)
      this.prevBtnTarget.classList.add(this.visibilityClassValue);
  }

  private hideNextBtnTarget() {
    if (this.hasNextBtnTarget)
      this.nextBtnTarget.classList.add(this.visibilityClassValue);
  }

  private showPrevBtnTarget() {
    if (this.hasPrevBtnTarget)
      this.prevBtnTarget.classList.remove(this.visibilityClassValue);
  }

  private showNextBtnTarget() {
    if (this.hasNextBtnTarget)
      this.nextBtnTarget.classList.remove(this.visibilityClassValue);
  }

  private handleFloatingCtaInvisible() {
    if (
      !document.getElementById("floating-cta-button") &&
      this.hasNextBtnTarget
    ) {
      this.nextBtnTarget.classList.add(this.floatingCtaInvisibleClassValue);
    }
  }
}
