import { Controller } from "@hotwired/stimulus";
import { Offcanvas } from "bootstrap";
import { productDetailsLoadingTemplate } from "../templates/loading";

const EXTRA_WIDE_CLASS = "extra-wide";

// Connects to data-controller="extra-wide-offcanvas"
export default class extends Controller {
  disconnect() {
    this.removeOffcanvasEventListener();
  }

  /**
   * shows a loading state in the offcanvas
   */
  showOffcanvas(e) {
    const bsOffcanvas = Offcanvas.getOrCreateInstance("#right-tray");
    const offcanvasElement = bsOffcanvas?._element;

    if (offcanvasElement) {
      offcanvasElement.classList.add(EXTRA_WIDE_CLASS);

      const turboFrame = document.getElementById("offcanvas-frame");
      turboFrame.innerHTML = productDetailsLoadingTemplate;

      bsOffcanvas.show();

      offcanvasElement.addEventListener("hidden.bs.offcanvas", () => {
        offcanvasElement.classList.remove(EXTRA_WIDE_CLASS);
      });
    }
  }

  removeOffcanvasEventListener() {
    const bsOffcanvas = Offcanvas.getInstance("#right-tray");
    const offcanvasElement = bsOffcanvas?._element;

    if (offcanvasElement) {
      offcanvasElement.removeEventListener("hidden.bs.offcanvas", () => {
        offcanvasElement.classList.remove(EXTRA_WIDE_CLASS);
      });
    }
  }
}
