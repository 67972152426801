import { Controller } from "@hotwired/stimulus";
import { addLoadingSpinnerToFormButton } from "../utilities";
import lottieWeb from "lottie-web";

const replaceStateWhitelist = [
  "/forgot_password",
  "/reset_password",
  "/password_reset",
  "/signup",
];

let timer;

// Connects to data-controller="login"
export default class extends Controller {
  static values = {
    loginUrl: String,
    starsPath: String,
  };

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("login_context")) {
      setTimeout(() => {
        document.getElementById(urlParams.get("login_context"))?.click();
      }, 500);
    }

    lottieWeb.loadAnimation({
      container: document.getElementById("stars-animation-container"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: this.starsPathValue,
      name: "Traveling through space",
    });
  }

  onClickSubmit(e) {
    addLoadingSpinnerToFormButton(e);
  }
}
