import React, { useEffect, useState } from "react";
import { FormInputProps } from "@frigade/react";
import { Reorder, useMotionValue } from "framer-motion";
import {
  socialLinkItemStyle,
  socialLinkSelectBoxStyle,
  socialLinksStyle,
} from "./OnboardingFormStyles";
import { availableLinks, SocialLinkItem } from "./SocialLinkContent";
import Select from "react-select";
import { IconChevronDown } from "@tabler/icons-react";

const initialLinks = ["TikTok", "Instagram", "YouTube", "X"]
  .map((name) => {
    return availableLinks.find((item) => item.name === name);
  })
  .map((item) => ({ ...item, key: item?.name })) as typeof availableLinks;

function SocialLinkPicker({
  selectedItem,
  setItems,
  items,
}: {
  selectedItem: SocialLinkItem;
  setItems: (items: SocialLinkItem[]) => void;
  items: SocialLinkItem[];
}) {
  return (
    <Select
      components={{
        Option: CustomOption,
        SingleValue: (props, context) => {
          return (
            <>
              <div style={socialLinkItemStyle}>{selectedItem.icon}</div>
              <IconChevronDown size={16} style={{ marginLeft: "8px" }} />
            </>
          );
        },
        IndicatorsContainer: () => null,
        IndicatorSeparator: () => null,
        MenuList: (props) => {
          return <div style={socialLinkSelectBoxStyle}>{props.children}</div>;
        },
      }}
      unstyled
      options={availableLinks.map((item) => ({
        value: item.name,
        label: item.name,
      }))}
      classNamePrefix={"react-select"}
      onChange={(option) => {
        const index = items.findIndex((item) => item.key === selectedItem.key);
        const itemCopy = items[index];
        const newItem = availableLinks.find(
          (item) => item.name === option?.value,
        ) as SocialLinkItem;
        itemCopy.name = newItem.name;
        itemCopy.icon = newItem.icon;
        itemCopy.platformId = newItem.platformId;
        items[index] = itemCopy;
        setItems([...items]);
      }}
      value={{ value: selectedItem.name, label: selectedItem.name }}
      backspaceRemovesValue={false}
      isSearchable={false}
    />
  );
}

const CustomOption = (props: any) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div className="react-select__option">
      {availableLinks.find((item) => item.name === data.label)?.icon}
      <div ref={innerRef} {...innerProps}>
        {data.label}
      </div>
    </div>
  );
};

function SocialLink({
  item,
  onChangeUrl,
  setItems,
  items,
}: {
  item: SocialLinkItem;
  onChangeUrl: (url: string) => void;
  setItems: (items: SocialLinkItem[]) => void;
  items: SocialLinkItem[];
}) {
  const y = useMotionValue(0);

  return (
    <Reorder.Item
      initial={false}
      style={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        width: "100%",
        padding: "12px",
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: "16px",
        border: "1px solid #E0E0E0",
        justifyContent: "space-between",
        gap: "8px",
        y,
      }}
      as={"div"}
      value={item}
      id={item.key}
      key={item.key}
    >
      <div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 15V21M12 21L15 18M12 21L9 18"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 9V3M12 3L15 6M12 3L9 6"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <SocialLinkPicker selectedItem={item} items={items} setItems={setItems} />
      <input
        type="url"
        required
        style={{
          borderRadius: "16px",
          width: "100%",
          border: "1px solid #E0E0E0",
          padding: "8px",
          flex: 1,
        }}
        value={item.url}
        onChange={(e) => {
          onChangeUrl(e.target.value);
        }}
        placeholder={`Add ${item.name} URL`}
      />
    </Reorder.Item>
  );
}

export function SocialLinks({
  formInput,
  customFormTypeProps,
  onSaveInputData,
  inputData,
  setFormValidationErrors,
}: FormInputProps) {
  const [items, setItems] = useState<SocialLinkItem[]>(initialLinks);

  useEffect(() => {
    onSaveInputData({
      links: items.map(
        // Remove icon/key
        ({ icon, key, ...rest }) => rest,
      ),
    });
  }, [items]);

  return (
    <Reorder.Group
      style={socialLinksStyle}
      axis="y"
      onReorder={setItems}
      values={items}
      initial={false}
      as={"div"}
    >
      {items.map((item) => {
        return (
          <SocialLink
            item={item}
            key={item.key}
            onChangeUrl={(url) => {
              item.url = url;
              // Update state
              setItems([...items]);
            }}
            setItems={setItems}
            items={items}
          />
        );
      })}
    </Reorder.Group>
  );
}
