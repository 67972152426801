import { Controller } from "@hotwired/stimulus";
import { GridStack } from "gridstack";
import { getCsrfToken, post } from "../fetch";

// Connects to data-controller="multi-image-selector"
export default class extends Controller {
  static values = {
    blockId: String,
    url: String,
    subdomain: String,
  };

  connect() {
    this.initGrid();
    this.initChangeListener();
  }

  triggerFileUpload() {
    document.getElementById("image_file_input").click();
  }

  uploadedFileChanged(e) {
    const files = e.target.files;
    const grid = this.getGridInstance();

    for (let i = 0; i < files.length; i++) {
      const newFile = files[i];
      const imageId = `${Math.random()}-image`;

      grid.load([
        {
          content: getImageHtml(imageId),
          noMove: true,
        },
      ]);

      this.uploadImage(newFile, imageId);
    }
  }

  uploadImage(file, tempImageId) {
    const blockId = this.blockIdValue || this.context?.blockId;
    const gridImageElement = document.getElementById(tempImageId);

    let formData = new FormData();
    formData.append("image", file);
    formData.append("blockId", blockId);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    // kick off server upload
    fetch(this.urlValue, {
      method: "post",
      body: formData,
      headers: {
        "X-CSRF-Token": getCsrfToken(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          const gridStackItem = gridImageElement.closest(".grid-stack-item");

          gridImageElement.querySelector(".loading-indicator").remove();
          gridStackItem.dataset.blockImageId = res.block_image_id;

          this.getGridInstance().update(gridStackItem, { noMove: false });
        } else {
          console.info("error uploading image");
        }
      })
      .catch((err) => {
        console.info("err!", err);
      });

    // once image is loaded, create an img element to display it
    reader.onloadend = function () {
      const image = reader.result;

      const imgEl = document.createElement("img");
      imgEl.src = image;

      // determine how to style the image preview based on current radio selection
      const imgIsFit = document.getElementById(
        "image_gallery_fill_false",
      )?.checked;
      if (imgIsFit) {
        imgEl.classList.add("gallery-image", "fit");
      } else {
        imgEl.classList.add("gallery-image", "fill");
      }

      gridImageElement.appendChild(imgEl);
    };
  }

  deleteImage(e) {
    const gridstackItem = e.target.closest(".grid-stack-item");
    const blockImageId = gridstackItem.dataset.blockImageId;
    const subdomain = this.subdomainValue;

    post(`/hub/${subdomain}/edit/delete_image`, { id: blockImageId })
      .then((res) => {
        this.getGridInstance().removeWidget(gridstackItem);
      })
      .catch((err) => {
        console.info("error deleting image", err);
      });
  }

  openDeleteConfirmation(e) {
    const deleteConfirmationDialogTarget = e.target
      .closest(".grid-stack-item-content")
      .querySelector(".delete-confirmation-dialog");

    const imageTarget = e.target
      .closest(".grid-stack-item-content")
      .querySelector("img");

    deleteConfirmationDialogTarget.classList.remove("d-none");

    imageTarget.classList.add("opacity-50");
    imageTarget.classList.add("pe-none");
  }

  closeDeleteConfirmation(e) {
    const deleteConfirmationDialogTarget = e.target
      .closest(".grid-stack-item-content")
      .querySelector(".delete-confirmation-dialog");

    const imageTarget = e.target
      .closest(".grid-stack-item-content")
      .querySelector("img");

    deleteConfirmationDialogTarget.classList.add("d-none");

    imageTarget.classList.remove("opacity-50");
    imageTarget.classList.remove("pe-none");
  }

  initGrid() {
    const grid = GridStack.init(
      {
        column: 1,
        cellHeight: 416,
      },
      "#multi-image-gridstack",
    );
  }

  initChangeListener() {
    const grid = this.getGridInstance();

    grid.on("change", (event, items) => {
      const itemsToUpdate = items.map((item) => {
        const blockImageId = item.el.dataset.blockImageId;

        return {
          blockImageId,
          position: item.y,
        };
      });

      const subdomain = this.subdomainValue;
      const blockId = this.blockIdValue;
      const payload = { blockId, blockImages: itemsToUpdate };

      post(`/hub/${subdomain}/edit/update_image_order`, payload).catch(
        (err) => {
          console.info("error updating image order", err);
        },
      );
    });
  }

  getGridInstance() {
    return document.querySelector("#multi-image-gridstack").gridstack;
  }
}

const getImageHtml = (imageId) => {
  return `
    <div class="multi-image-selector-preview uploaded-image">
      <div class="grid-stack-item-content" id="${imageId}">
        <button
          type="button"
          class="btn trash-button-rounded absolute-right-10"
          data-action="click->multi-image-selector#openDeleteConfirmation">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6H20" stroke="#F9463B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 10V16" stroke="#F9463B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 10V16" stroke="#F9463B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 6L6 18C6 18.5304 6.21071 19.0391 6.58579 19.4142C6.96086 19.7893 7.46957 20 8 20H16C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18L19 6" stroke="#F9463B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 6V3C9 2.73478 9.10536 2.48043 9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289C14.8946 2.48043 15 2.73478 15 3V6" stroke="#F9463B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <div
          class="text-center position-absolute w-100 h-100 d-flex flex-column align-items-center justify-content-center bg-light loading-indicator"
          style="--bs-bg-opacity: .5;">
          <div class="spinner-border" role="status"></div>
        </div>
        <div
          class="delete-confirmation-dialog delete-image-confirmation-dialog  d-none">
          <p>Are you sure you want to delete this image?</p>
            <div class="row">
              <div class='col-6'>
                <button
                  class="btn btn-black d-inline-block btn-lg w-100"
                  data-action="click->multi-image-selector#closeDeleteConfirmation"
                  type="button">
                  Keep it
                </button>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-delete d-inline-block btn-lg w-100"
                  data-action="click->multi-image-selector#deleteImage"
                  type="button">
                  Delete
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
  `;
};
