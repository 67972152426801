import React from "react";

export default function MutedPeople() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8576 8.3161L20.8827 8.36222C21.6193 9.78279 22.0026 11.3599 22.0002 12.9601C22.0002 14.5823 21.614 16.1138 20.9263 17.4695C20.5468 18.4917 20.4631 19.2871 20.675 19.8556C20.9412 20.5692 21.2743 21.1643 21.6732 21.6398C22.2507 22.2346 22.0629 22.6509 21.1085 22.8886C20.3248 23.084 18.965 22.8785 17.0289 22.2713L16.8459 22.2132L16.2481 22.0156C14.9191 22.6397 13.4684 22.9623 12.0001 22.9602C10.3907 22.9626 8.80462 22.5748 7.37787 21.83L7.35575 21.8187L8.85575 20.3187C9.84951 20.7436 10.9193 20.9618 12.0001 20.96C13.1225 20.96 14.2089 20.7297 15.2109 20.2902L15.3976 20.2055L16.1195 19.8661L17.4744 20.3146L17.735 20.3967C18.008 20.4811 18.2652 20.5553 18.5052 20.6198L18.6823 20.6667L18.8641 20.7121L18.8015 20.5546L18.7265 20.3318C18.4212 19.325 18.545 18.2015 18.9939 16.9325L19.0516 16.7731L19.0914 16.6666L19.2894 16.2601C19.7599 15.2237 20.0022 14.0983 20 12.9601C20.0018 11.8793 19.7836 10.8095 19.3587 9.81572L20.8576 8.3161ZM21.7074 3.25285C21.8794 3.42511 21.9828 3.65421 21.998 3.8972C22.0133 4.1402 21.9394 4.38042 21.7902 4.57285L21.7074 4.66735L3.70737 22.6673C3.52705 22.8452 3.28599 22.948 3.03281 22.9551C2.77964 22.9622 2.5332 22.873 2.34321 22.7055C2.15322 22.538 2.03382 22.3047 2.0091 22.0527C1.98438 21.8006 2.05617 21.5486 2.21 21.3473L2.29287 21.2528L4.25712 19.289C2.83247 17.5507 2.03753 15.3817 2.0015 13.1345L2 12.9601C2 7.4371 6.4775 2.95997 12.0001 2.95997C14.3076 2.95634 16.5447 3.75431 18.329 5.21747L20.2929 3.25285C20.4805 3.06535 20.7349 2.96002 21.0001 2.96002C21.2654 2.96002 21.5198 3.06535 21.7074 3.25285ZM12.0001 4.96022C7.58187 4.96022 4.00025 8.54147 4.00025 12.9601C4.00025 14.7736 4.6055 16.4855 5.68025 17.8666L16.9062 6.63985C15.504 5.54845 13.7771 4.9575 12.0001 4.96022Z"
        fill="currentcolor"
      />
    </svg>
  );
}
