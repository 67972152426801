import React from "react";
import ChatAvatar from "./ChatAvatar";

export default function ChannelListHeader({ profileTitle, profileImage }) {
  return (
    <div className="chat-header d-flex align-items-center">
      <ChatAvatar
        className="chat-header__avatar"
        width={32}
        height={32}
        src={profileImage}
        alt="Profile Image"
      />
      <span className="text-white ms-2 me-auto line-clamp-1">
        {profileTitle}
      </span>
    </div>
  );
}
