import { Controller } from "@hotwired/stimulus";
import { post } from "../fetch";
import { Toaster } from "../utilities";

// Connects to data-controller="confirm-follow"
export default class extends Controller {
  static values = {
    followUrl: String,
    email: String,
  };

  declare followUrlValue: string;
  declare emailValue: String;

  connect() {}

  followAndReload() {
    post(this.followUrlValue, { email: this.emailValue, context: "fwgt" })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          window.location.reload();
        } else {
          Toaster.error(response.message, { delay: 3000 });
        }
      });
  }
}
