import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="download"
export default class extends Controller {
  static values = {
    url: String,
  };
  declare urlValue: string;

  connect() {
    window.location.href = this.urlValue;
  }
}
