import { Controller } from "@hotwired/stimulus";
import { post } from "../fetch";
import { createDownloadLink, loadingSpinner } from "../utilities";
import { Tooltip } from "bootstrap";

// Connects to data-controller="order-item"
export default class extends Controller {
  static values = {
    downloadPath: String,
    orderItemId: String,
  };

  connect() {
    this.initTooltips();
  }

  download(e) {
    const orderItemId = this.orderItemIdValue;
    const downloadPath = this.downloadPathValue;

    const button =
      e.target.closest("button") || e.target.querySelector("button");
    const previousButtonText = button.innerHTML;

    const buttonWrapper =
      e.target.closest(".download-button-wrapper") ||
      e.target.querySelector(".download-button-wrapper");

    const tooltip = Tooltip.getInstance(buttonWrapper);

    // tooltip won't close unless it's pushed onto the event loop
    setTimeout(() => {
      tooltip.hide();
    });

    button.innerHTML = loadingSpinner("");

    post(downloadPath, { orderItemId })
      .then((res) => res.json())
      .then((json) => {
        createDownloadLink(json.url);

        buttonWrapper.dataset.bsOriginalTitle = json.downloadsRemainingMessage;

        setTimeout(() => {
          button.innerHTML = previousButtonText;

          if (json.downloadsRemaining === 0) {
            button.classList.add("disabled");
            button.innerText = "Downloaded";
          }
        }, 250);
      })
      .catch((err) => {
        const errorElement = document.getElementById(`${orderItemId}-error`);
        errorElement.innerHTML = err.message;

        errorElement.classList.add("d-inline-block");
        errorElement.classList.remove("d-none");
        errorElement.classList.add("show");
      });
  }

  access(_) {
    const orderItemId = this.orderItemIdValue;
    const downloadPath = this.downloadPathValue;

    post(downloadPath, { orderItemId })
      .then((res) => res.json())
      .then((json) => {
        // Note: Don't open a new window as Safari will block it.
        createDownloadLink(json.url, false);
      })
      .catch((err) => {
        const errorElement = document.getElementById(`${orderItemId}-error`);
        errorElement.innerHTML = err.message;

        errorElement.classList.add("d-inline-block");
        errorElement.classList.remove("d-none");
        errorElement.classList.add("show");
      });
  }

  initTooltips() {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]',
    );
    [...tooltipTriggerList].map(
      (tooltipTriggerEl) =>
        new Tooltip(tooltipTriggerEl, {
          trigger: "hover",
        }),
    );
  }
}
