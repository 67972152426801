import { Controller } from "@hotwired/stimulus";
import { Toaster, loadingSpinner } from "../utilities";

// Connects to data-controller="rewards-user-info"
export default class extends Controller {
  declare submitting: Boolean;

  connect() {
    const form = document.getElementById("user-fields-form") as HTMLFormElement;
    const inputs = form.querySelectorAll("input");

    inputs.forEach((input) => {
      input.addEventListener("blur", this.validateForm);
    });
  }

  disconnect() {
    const form = document.getElementById("user-fields-form") as HTMLFormElement;
    const inputs = form.querySelectorAll("input");

    inputs.forEach((input) => {
      input.removeEventListener("blur", this.validateForm);
    });
  }

  validateForm = (event: Event) => {
    const input = event.target as HTMLInputElement;
    input.reportValidity();
  };

  submitUserInfo = (event: Event) => {
    event.preventDefault();

    if (this.submitting) {
      return;
    }

    this.submitting = true;
    let formElement = event.target as HTMLFormElement;
    let data = new FormData(formElement);

    const submitElement = document.querySelector(
      "button[form='user-fields-form']",
    ) as HTMLButtonElement;
    const submitText = submitElement.innerHTML;

    submitElement.disabled = true;
    submitElement.innerHTML = loadingSpinner();

    const inputs = formElement.querySelectorAll("input");
    inputs.forEach((input) => {
      input.classList.remove("form-input-error");
    });

    fetch(formElement.action, {
      method: formElement.method,
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => {
        response.json().then((data) => {
          this.submitting = false;

          if (data.redirect_to) {
            window.location.href = data.redirect_to;
          } else {
            // An error occurred during save
            submitElement.disabled = false;
            submitElement.innerHTML = submitText;

            if (data.fields) {
              data.fields.forEach((field: any) => {
                let input = formElement.querySelector(
                  `input[name="user[${field}]"]`,
                );

                if (input) {
                  input.classList.add("form-input-error");
                }
              });
            }

            Toaster.error(data.error, { delay: 3000 });
          }
        });
      })
      .catch((error) => {
        this.submitting = false;

        if (error.redirect_to) {
          window.location.href = error.redirect_to;
        }

        submitElement.disabled = false;
        submitElement.innerHTML = submitText;
      });
  };
}
