const FLOW_IDS = {
  FRIGADE_FLOW_ID_CREATOR_ONBOARDING_FORM: "flow_7lS5wiuwHRXGkU4H",
  FRIGADE_FLOW_ID_CREATOR_ANNOUNCEMENT: "flow_3pckyzDDmWkUYc95",
  FRIGADE_FLOW_ID_CREATOR_TOUR_DESKTOP: "flow_wNaIVVym",
  FRIGADE_FLOW_ID_CREATOR_TOUR_MOBILE: "flow_qQRIAUkM",
  FRIGADE_FLOW_ID_CREATOR_CHECKLIST: "flow_OThaX1shKGuYXXWB",
};

export function getConfig(): Record<string, string> {
  return FLOW_IDS;
}
