import { ApplicationController, useDebounce } from "stimulus-use";
import invariant from "tiny-invariant";

// Connects to data-controller="audio-form"
export default class extends ApplicationController {
  static debounces = ["renderPreview", "formValidateUrl"];

  static values = {
    previewUrl: String,
    imageUrl: String,
    profileSubdomain: String,
  };

  /** the path the audio preview, parameters like title, url etc are needed */
  declare previewUrlValue: string;
  declare imageUrlValue: string | undefined;

  static targets = [
    "serviceInput",
    "serviceIdInput",
    "serviceTypeInput",
    "imageUrlInput",
    "spotifyEmbed",
    "soundcloudEmbed",
    "audioUrlInput",
    "imageFileInput",
    "removeImageInput",
    "audioImagePreviewFrame",
    "audioTitleInput",
    "saveButton",
  ];

  declare readonly serviceInputTarget: HTMLInputElement;
  declare readonly serviceIdInputTarget: HTMLInputElement;
  declare readonly serviceTypeInputTarget: HTMLInputElement;
  declare readonly imageUrlInputTarget: HTMLInputElement;
  declare readonly audioUrlInputTarget: HTMLInputElement;
  declare readonly imageFileInputTarget: HTMLInputElement;
  declare readonly removeImageInputTarget: HTMLInputElement;
  declare readonly audioImagePreviewFrameTarget: HTMLElement;
  declare readonly audioTitleInputTarget: HTMLInputElement;
  declare readonly saveButtonTarget: HTMLButtonElement;
  declare readonly hasSaveButtonTarget: boolean;
  declare readonly profileSubdomainValue: string;

  connect() {
    useDebounce(this);
    const initialUrlValue = this.audioUrlInputTarget.value;

    if (initialUrlValue) {
      this.validateUrl(initialUrlValue);
    }
  }

  formValidateUrl(e: any) {
    const value = e.target.value;

    if (this.validateUrl(value)) {
      this.fetchTitle(value);
    }
  }

  validateUrl(value: string) {
    const [spotifyId, spotifyType] = parseSpotifyUrl(value);

    if (spotifyId) {
      this.setValidUrl();
      this.serviceInputTarget.value = "spotify";
      this.serviceTypeInputTarget.value = spotifyType;
      this.serviceIdInputTarget.value = spotifyId;
      return true;
    }

    if (value.includes("soundcloud.com")) {
      this.setValidUrl();
      this.serviceInputTarget.value = "soundcloud";
      return true;
    }

    this.serviceInputTarget.value = "";
    this.serviceIdInputTarget.value = "";
    this.setInvalidUrl();

    return false;
  }

  setValidUrl() {
    this.audioUrlInputTarget.classList.remove("is-invalid");

    if (this.hasSaveButtonTarget) this.saveButtonTarget.disabled = false;

    document.getElementById("invalid-url-message")!.classList.add("d-none");
    document.getElementById("invalid-url-message")!.classList.remove("d-block");
  }

  setInvalidUrl() {
    this.audioUrlInputTarget.classList.add("is-invalid");

    if (this.hasSaveButtonTarget) this.saveButtonTarget.disabled = true;

    document.getElementById("invalid-url-message")!.classList.remove("d-none");
    document.getElementById("invalid-url-message")!.classList.add("d-block");
  }

  fetchTitle(url: string) {
    fetch(`/hub/${this.profileSubdomainValue}/forms/url_details?url=${url}`)
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          throw new Error(`response body indicates error: ${response.error}`);
        } else {
          this.audioUrlInputTarget.value = response.url;

          this.audioTitleInputTarget.value = response.title
            .split(" | ")[0]
            .replace("song and lyrics by ", "");

          this.imageUrlInputTarget.value = response.image;
        }
      })
      .catch((err) => {
        console.warn("error fetching audio embed title", err);
      });
  }

  setImage() {
    let files = this.imageFileInputTarget.files;
    if (files == null || files.length !== 1) {
      throw new Error("expected one file to be selected");
    }
    let file = files[0];

    this.imageUrlValue = URL.createObjectURL(file);
    this.removeImageInputTarget.value = "";
    document
      .getElementById("audio-fit-fill-container")
      ?.classList?.remove("d-none");

    this.renderPreview();
  }

  form() {
    // could use any input here
    return this.imageFileInputTarget.form;
  }

  renderPreview() {
    let form = this.form();
    invariant(form, "expected a form");
    let formData = new FormData(form);

    let previewUrl = new URL(this.previewUrlValue);
    previewUrl.searchParams.append("url", formData.get("audio[url]") as string);
    previewUrl.searchParams.append(
      "title",
      formData.get("audio[title]") as string,
    );
    previewUrl.searchParams.append(
      "service_id",
      formData.get("audio[service_id]") as string,
    );
    previewUrl.searchParams.append(
      "service_type",
      formData.get("audio[service_type]") as string,
    );

    if (this.imageUrlValue) {
      previewUrl.searchParams.append("image_preview_url", this.imageUrlValue);
    }
    (this.audioImagePreviewFrameTarget as any).src = previewUrl.toString();
  }

  removeImage() {
    this.imageFileInputTarget.value = "";
    this.removeImageInputTarget.value = "1";
    this.imageUrlValue = undefined;

    this.renderPreview();
  }
}

function parseSpotifyUrl(url: string): [false, false] | [string, string] {
  const match = url.match(
    /^.*(spotify\.com\/)(playlist|album|track|episode|artist|show)\/?([\w\d]+)/,
  );

  if (match && match[3].length > 0) {
    return [match[3], match[2]];
  } else {
    return [false, false];
  }
}
