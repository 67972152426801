import { Controller } from "@hotwired/stimulus";
import { GridStack } from "gridstack";
import invariant from "tiny-invariant";

const MAX_PAGES = 30;

export default class PagesController extends Controller {
  static targets = ["pageGridStackItem"];
  declare readonly pageGridStackItemTargets: HTMLElement[];

  connect() {
    this.initGrid();
    this.initChangeListener();

    this.element.querySelector(".add-link")?.addEventListener("click", (e) => {
      // TODO: Connect this element
      this.handleAddLinkClick(this.element.querySelector("#add-page-link"), e);
    });
  }

  initGrid() {
    const grid = GridStack.init(
      {
        disableResize: true,
        column: 1,
        cellHeight: 100,
        handle: ".drag-handle",
      },
      "#pages",
    );
  }

  initChangeListener() {
    const grid = this.getGridInstance();
    grid.on("change", (_event, items) => {
      if (items) {
        invariant(Array.isArray(items), "expected an array of items");
        items.forEach((item) => {
          invariant(item, "expected item");
          // TODO: Gridstack is zero indexed. The position data model uses 1
          // indexing
          //
          // Set the form as "dirty" when position changes
          (
            item.el!.querySelector("#position-input") as HTMLInputElement
          ).value = (item.y! + 1).toString();
        });
      }
    });
  }

  getGridInstance(): GridStack {
    // gridstack is not a property on element. When this is changed to be a
    // connected element the type can be set to an extended HTMLElement that
    // includes the property
    // @ts-ignore
    return this.element.querySelector("#pages")?.gridstack;
  }

  handleAddLinkClick(link: HTMLElement | null, e: Event) {
    e.preventDefault();
    if (!(link && e)) return;

    const grid = this.getGridInstance();

    // Initial position
    // Gridstack is zero indexed. The position data model uses 1 indexing
    const newPosition =
      grid
        .getGridItems()
        .filter((el: HTMLElement) => !el.classList.contains("d-none")).length +
      1;

    if (newPosition > MAX_PAGES) return;

    // Uniq id for form
    let time = new Date().getTime();
    let linkId = link.dataset.id;
    let regexp = linkId ? new RegExp(linkId, "g") : null;

    // This functionality is common to multiple controllers. When it is moved to
    // a utilities module the typing can be addressed
    // @ts-ignore
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;

    // @ts-expect-error todo
    const widget = this.getGridInstance().addWidget(newFields);

    // @ts-expect-error todo
    widget.querySelector("#position-input").value = newPosition;

    if (newPosition === MAX_PAGES) {
      this.element.querySelector("#add-page-link")?.classList?.add("disabled");
    }
  }

  handleRemoveClick(e: Event) {
    e.preventDefault();

    // This functionality is common to multiple controllers. When it is moved to
    // a utilities module the typing can be addressed
    // @ts-ignore
    let fieldContainer = e.currentTarget.closest(".grid-stack-item");

    this.removePageGridStackItem(fieldContainer);
  }

  removePageGridStackItem(
    fieldContainer: HTMLElement,
    removeCompletely: boolean = false,
  ) {
    let deleteField = fieldContainer
      ? (fieldContainer.querySelector("#destroy-input") as HTMLInputElement)
      : null;

    if (deleteField) {
      deleteField.value = "1";
    }

    fieldContainer.classList.add("d-none");

    const grid = this.getGridInstance();
    grid.removeWidget(fieldContainer, removeCompletely);

    // Gridstack is zero indexed. The position data model uses 1 indexing
    const items = grid
      .getGridItems()
      .filter((el: HTMLElement) => !el.classList.contains("d-none")).length;

    if (items < MAX_PAGES) {
      this.element
        .querySelector("#add-page-link")
        ?.classList?.remove("disabled");
    }
  }

  /**
   * waits for a turbo frame response, and decides which grid stack item to remove
   * based on form submission url
   */
  handleRemovePage(ev: any) {
    let formSubmissionUrl = ev.detail.formSubmission.location as URL;

    let pageGridStackItem = this.pageGridStackItemTargets.find((el) => {
      return el.dataset.pagePath === formSubmissionUrl.pathname;
    });

    invariant(
      pageGridStackItem,
      "expected to find gridstack item with matching path",
    );
    this.removePageGridStackItem(pageGridStackItem, true);
  }
}
