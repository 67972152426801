import { Controller } from "@hotwired/stimulus";
import i18n from "../utils/i18n";

const PRODUCT_ID = "productId";
const PRODUCT_TYPE = "productType";
const PRICE_IN_POINTS = "priceInPoints";
const PRICE_IN_CURRENCY = "priceInCurrency";
const POINTS_AFTER_PURCHASE = "pointsAfterPurchase";
const POINTS_ONLY_PURCHASE = "pointsOnlyPurchase";
const D_NONE_CLASS = "d-none";

// Connects to data-controller="buy-now"
export default class extends Controller {
  setData(event: Event) {
    // Button that triggered the modal
    const target = event.currentTarget as HTMLElement;
    const productIdValue = target.getAttribute(`data-${PRODUCT_ID}`);
    const productTypeValue = target.getAttribute(`data-${PRODUCT_TYPE}`);
    const priceInPointsValue = target.getAttribute(`data-${PRICE_IN_POINTS}`);
    const priceInCurrencyValue = target.getAttribute(
      `data-${PRICE_IN_CURRENCY}`,
    );
    const pointsAfterPurchaseValue = target.getAttribute(
      `data-${POINTS_AFTER_PURCHASE}`,
    );
    const pointsOnlyPurchaseValue = target.getAttribute(
      `data-${POINTS_ONLY_PURCHASE}`,
    );

    // Update the modal's content
    const productIdInput = document.querySelector(
      `#buy-now-modal input[name=${PRODUCT_ID}]`,
    ) as HTMLInputElement;
    const productTypeInput = document.querySelector(
      `#buy-now-modal input[name=${PRODUCT_TYPE}]`,
    ) as HTMLInputElement;
    const usePointsElem = document.getElementById(
      "buy-now-modal-use-points",
    ) as HTMLElement;
    const priceInCurrencyElem = document.getElementById(
      "buy-now-modal-price-in-currency",
    ) as HTMLElement;
    const pointsAfterPurchaseElem = document.getElementById(
      "buy-now-modal-points-after-purchase",
    ) as HTMLElement;
    const pointsNeededElem = document.getElementById(
      "buy-now-modal-points-needed",
    ) as HTMLElement;
    const header = document.getElementById(
      "buy-now-modal-header",
    ) as HTMLElement;
    const form = document.getElementById("buy-now-modal-form") as HTMLElement;
    const earnPointsElem = document.getElementById(
      "buy-now-modal-earn-points-button",
    ) as HTMLElement;
    const separatorElem = document.getElementById(
      "buy-now-modal-pay-separator",
    ) as HTMLElement;
    const payCardElem = document.getElementById(
      "buy-now-modal-pay-card-btn",
    ) as HTMLElement;

    this.resetElements(
      header,
      form,
      earnPointsElem,
      separatorElem,
      payCardElem,
      pointsNeededElem,
    );

    productIdInput.value = productIdValue || "";
    productTypeInput.value = productTypeValue || "";
    pointsAfterPurchaseElem.innerHTML =
      Number(pointsAfterPurchaseValue).toLocaleString() || "";
    usePointsElem.innerHTML =
      i18n.t("client.purchase.use_points", {
        count: Number(priceInPointsValue),
        amount: Number(priceInPointsValue).toLocaleString(),
      }) || "";
    priceInCurrencyElem.innerHTML = priceInCurrencyValue || "";

    if (pointsOnlyPurchaseValue === "true") {
      separatorElem?.classList.add(D_NONE_CLASS);
      payCardElem?.classList.add(D_NONE_CLASS);

      if (pointsAfterPurchaseValue && Number(pointsAfterPurchaseValue) >= 0) {
        header.innerHTML = i18n.t("client.purchase.this_is_points_only");
      } else {
        header.innerHTML = i18n.t("client.purchase.not_enough_points");
        form?.classList.add(D_NONE_CLASS);
        earnPointsElem?.classList.remove(D_NONE_CLASS);
        if (pointsNeededElem && priceInPointsValue) {
          pointsNeededElem.innerHTML =
            i18n.t("client.purchase.points_needed", {
              amount: Number(priceInPointsValue).toLocaleString(),
            }) || "";
          pointsNeededElem.classList.remove(D_NONE_CLASS);
        }
      }
    }
  }

  private resetElements(
    header: HTMLElement | null,
    form: HTMLElement | null,
    earnPointsElem: HTMLElement | null,
    separatorElem: HTMLElement | null,
    payCardElem: HTMLElement | null,
    pointsNeededElem: HTMLElement | null,
  ) {
    if (header) header.innerHTML = i18n.t("client.purchase.you_have_points");
    form?.classList.remove(D_NONE_CLASS);
    earnPointsElem?.classList.add(D_NONE_CLASS);
    separatorElem?.classList.remove(D_NONE_CLASS);
    payCardElem?.classList.remove(D_NONE_CLASS);
    pointsNeededElem?.classList.add(D_NONE_CLASS);
  }
}
