import { Controller } from "@hotwired/stimulus";
import { Toaster } from "../utilities";
import i18n from "../utils/i18n";

const loadingSpinner = () =>
  `<span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"></span>`;

// Connects to data-controller="modal-waitlist"
export default class extends Controller {
  connect() {}

  waitlistUser(event: Event) {
    event.preventDefault();

    this.disableSubmitButton(
      "modal-waitlist-form-submit-btn",
      "modal-waitlist-form-submit-text",
    );

    let formElement = event.target as HTMLFormElement;
    let data = new FormData(formElement);

    if (
      !(
        data.get("email") &&
        data.get("name") &&
        data.get("followers") &&
        (data.get("instagram") ||
          data.get("tiktok") ||
          data.get("youtube") ||
          data.get("twitter") ||
          data.get("facebook"))
      )
    ) {
      Toaster.error(i18n.t("client.waitlist.required_fields"), {
        delay: 3000,
      });
      this.resetSubmitButton();
      return;
    }

    const message = i18n.t("client.waitlist.joined_message");

    const socialShares = [
      {
        name: "twitter",
        url: `https://twitter.com/intent/tweet?text=${message}%20`,
      },
      {
        name: "facebook",
        url: `https://www.facebook.com/sharer.php?quote=${message} REPLACE_ME&u=`,
      },
      {
        name: "linkedin",
        url: `https://www.linkedin.com/sharing/share-offsite/?summary=${message}&url=`,
      },
      {
        name: "email",
        url: `mailto:?subject=${i18n.t(
          "client.waitlist.joined_subject",
        )}&body=${message} `,
      },
      {
        name: "whatsapp",
        url: `https://api.whatsapp.com/send?text=${message} `,
      },
    ];

    fetch(formElement.action, {
      method: formElement.method,
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          socialShares.forEach((social) => {
            const shareButton = document.getElementById(
              `${social.name}-share`,
            ) as HTMLAnchorElement;
            shareButton.href =
              social.url.replace("REPLACE_ME", data.refLink) + data.refLink;
          });

          const referralInput = document.getElementById(
            "referral-link-input",
          ) as HTMLInputElement;
          referralInput.value = data.refLink;

          const referralLinkCopyButton = document.getElementById(
            "copy-referral-button",
          ) as HTMLButtonElement;
          referralLinkCopyButton.dataset.clipboardCopyTextParam = data.refLink;

          document
            .getElementById("waitlist-modal-form-container")
            ?.classList.add("d-none");
          document
            .getElementById("waitlist-modal-confirmation-container")
            ?.classList.remove("d-none");
        } else {
          this.resetSubmitButton();
        }
      })
      .catch(() => {
        Toaster.error(i18n.t("client.waitlist.problem_with_request"), {
          delay: 3000,
        });
        this.resetSubmitButton();
      });
  }

  requestDemo(event: Event) {
    event.preventDefault();

    this.disableSubmitButton(
      "modal-rewards-waitlist-form-submit-btn",
      "modal-rewards-waitlist-form-submit-text",
    );

    let formElement = event.target as HTMLFormElement;
    let data = new FormData(formElement);

    fetch(formElement.action, {
      method: formElement.method,
      body: data,
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          document
            .getElementById("rewards-waitlist-modal-form-container")
            ?.classList.add("d-none");
          document
            .getElementById("rewards-waitlist-modal-confirmation-container")
            ?.classList.remove("d-none");
        } else {
          this.resetRewardsSubmitButton();
        }
      })
      .catch(() => {
        Toaster.error(i18n.t("client.waitlist.problem_with_request"), {
          delay: 3000,
        });
        this.resetRewardsSubmitButton();
      });
  }

  focusSocialInput(event: Event) {
    const target = event.target as HTMLElement;
    const inputId = target.dataset.input || "";
    const input = document.querySelector(`#${inputId}`) as HTMLElement;
    input.focus();
  }

  resetForm() {
    setTimeout(() => {
      this.resetSubmitButton();

      const inputs = document.querySelectorAll(
        "#waitlist-modal-form-container input",
      ) as NodeListOf<HTMLInputElement>;
      for (const input of inputs) {
        input.value = "";
      }
      const selector = document.querySelector(
        "#waitlist-modal-form-container select",
      ) as HTMLSelectElement;
      selector.selectedIndex = 0;

      document
        .getElementById("waitlist-modal-form-container")
        ?.classList.remove("d-none");
      document
        .getElementById("waitlist-modal-confirmation-container")
        ?.classList.add("d-none");
      document
        .getElementById("modal-waitlist-invalid-feedback")
        ?.classList.add("d-none");
    }, 250);
  }

  resetRewardsForm() {
    setTimeout(() => {
      this.resetRewardsSubmitButton();

      const inputs = document.querySelectorAll(
        "#rewards-waitlist-modal-form input",
      ) as NodeListOf<HTMLInputElement>;
      for (const input of inputs) {
        input.value = "";
      }

      document
        .getElementById("rewards-waitlist-modal-form-container")
        ?.classList.remove("d-none");
      document
        .getElementById("rewards-waitlist-modal-confirmation-container")
        ?.classList.add("d-none");
      document
        .getElementById("rewards-modal-waitlist-invalid-feedback")
        ?.classList.add("d-none");
    }, 250);
  }

  resetSubmitButton() {
    this.resetButton(
      "modal-waitlist-form-submit-btn",
      "modal-waitlist-form-submit-text",
    );
  }

  resetRewardsSubmitButton() {
    this.resetButton(
      "modal-rewards-waitlist-form-submit-btn",
      "modal-rewards-waitlist-form-submit-text",
    );
  }

  resetButton(buttonId: string, buttonTextId: string) {
    const submitButton = document.getElementById(buttonId) as HTMLButtonElement;
    submitButton.disabled = false;

    const submitButtonText = document.getElementById(
      buttonTextId,
    ) as HTMLElement;
    submitButtonText.innerHTML = i18n.t("client.waitlist.submit");
  }

  disableSubmitButton(buttonId: string, buttonTextId: string) {
    const submitButton = document.getElementById(buttonId) as HTMLButtonElement;
    submitButton.disabled = true;

    const submitButtonText = document.getElementById(
      buttonTextId,
    ) as HTMLElement;
    submitButtonText.innerHTML = loadingSpinner();
  }
}
