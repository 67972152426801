import React from "react";
import i18n from "../../utils/i18n";

export default function CommentsEmptyState() {
  return (
    <div className="empty-message-container d-flex justify-content-center align-items-center">
      <div className="empty-message p-3 text-center">
        <div className="empty-message__text text-muted">
          {i18n.t("client.comments.no_comments")}
        </div>
      </div>
    </div>
  );
}
