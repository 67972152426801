import { Controller } from "@hotwired/stimulus";

type ToggleEvent = Event & {
  params: {
    active: Boolean;
  };
};

// Connects to data-controller="reaction"
export default class extends Controller {
  toggle(event: ToggleEvent) {
    const target = event.currentTarget as HTMLElement;

    if (target) {
      target.dataset.reactionActiveParam = (!event.params.active).toString();
      target.dataset.reactionLoading = "true";
    }
  }

  reloadPage() {
    window.location.reload();
  }
}
