import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static open = false;

  connect() {
    this.element.querySelectorAll(".dropdown-toggle").forEach((element) => {
      element.addEventListener("click", (e) => {
        this.open = !this.open;
        const menu = this.element.querySelector(".dropdown-menu");
        if (this.open) {
          menu.style.display = "block";
        } else {
          menu.style.display = "none";
        }
      });
    });

    addEventListener("scroll", (event) => {
      this.open = false;
      this.element.querySelector(".dropdown-menu").style.display = "none";
    });

    this.element
      .querySelector(".dropdown-toggle")
      .addEventListener("mouseover", () => {
        if (!this.open) {
          this.open = true;
          this.element.querySelector(".dropdown-menu").style.display = "block";
        }
      });

    this.element
      .querySelector(".dropdown-menu")
      .addEventListener("mouseleave", () => {
        if (this.open) {
          this.open = false;
          this.element.querySelector(".dropdown-menu").style.display = "none";
        }
      });
  }
}
