import { Controller } from "@hotwired/stimulus";
import JSConfetti from "js-confetti";

// Connects to data-controller="celebrate"
export default class extends Controller {
  connect() {
    this.celebrateSocial();
  }

  celebrateSocial() {
    const canvas = document.createElement("canvas") as HTMLCanvasElement;
    canvas.classList.add(
      "h-100",
      "w-100",
      "absolute-left-0",
      "radius-16",
      "z-index-1",
    );

    this.element.appendChild(canvas);

    const jsConfetti = new JSConfetti({ canvas: canvas });

    jsConfetti.addConfetti({
      confettiRadius: 4,
      confettiNumber: 500,
      emojiSize: 16,
      confettiColors: [
        "#FA3085",
        "#56E5A1",
        "#7877DF",
        "#DF6454",
        "#FFB37C",
        "#DF6454",
      ],
    });

    setTimeout(() => {
      jsConfetti.clearCanvas();
      canvas.remove();
    }, 1800);
  }
}
