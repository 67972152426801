import { Controller } from "@hotwired/stimulus";

/**
 * Adds and removes classes from elements based on another element
 * (`showTrigger`) reaching the top of the viewport.
 *
 * Connects to data-controller="scroll-top-trigger"
 */
export default class ScrollTopTriggerController extends Controller {
  static targets = [
    /**
     * The element that triggers adding the `shown` classes and removing
     * the `hidden` classes when it reaches the top of the page.
     */
    "showTrigger",

    /**
     * Elements which should have the `shown` and `hidden` classes toggled when
     * the showTrigger is reached.
     */
    "classToggle",
  ];

  static classes = [
    /**
     * Classes that should be added when the `showTrigger` is reached.
     */
    "shown",

    /**
     * Classes that should be removed when the `showTrigger` is reached.
     */
    "hidden",
  ];

  declare readonly shownClasses: string[];
  declare readonly hiddenClasses: string[];
  declare readonly showTriggerTarget: HTMLElement;
  declare readonly classToggleTargets: HTMLElement[];

  connect() {
    this.onScroll = this.onScroll.bind(this);
    this.showHeader = this.showHeader.bind(this);
    this.hideHeader = this.hideHeader.bind(this);
    window.addEventListener("scroll", this.onScroll);
    this.onScroll();
  }

  disconnect() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    const shouldShow = window.scrollY > this.showTriggerTarget.offsetTop;

    if (shouldShow) {
      requestAnimationFrame(this.showHeader);
    } else {
      requestAnimationFrame(this.hideHeader);
    }
  }

  showHeader() {
    if (this.hiddenClasses.length > 0) {
      this.classToggleTargets.forEach((target) =>
        target.classList.remove(...this.hiddenClasses),
      );
    }

    if (this.shownClasses.length > 0) {
      this.classToggleTargets.forEach((target) =>
        target.classList.add(...this.shownClasses),
      );
    }
  }

  hideHeader() {
    if (this.hiddenClasses.length > 0) {
      this.classToggleTargets.forEach((target) =>
        target.classList.add(...this.hiddenClasses),
      );
    }

    if (this.shownClasses.length > 0) {
      this.classToggleTargets.forEach((target) =>
        target.classList.remove(...this.shownClasses),
      );
    }
  }
}
