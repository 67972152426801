import React, { useEffect, useState } from "react";
import { SendBirdProvider } from "@sendbird/uikit-react";
import SendbirdChat from "@sendbird/chat";
import { GroupChannelModule } from "@sendbird/chat/groupChannel";
import Chat from "./Chat";
import { fetchJson } from "../../fetch";

const SendbirdChatApp = ({
  appId,
  profileId,
  profileTitle,
  profileImage,
  isOwner,
  startingChannelUrl,
  startingMessageId,
  startingMessageCreatedAt,
  parentMessageId,
}) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [sb, setSb] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [startingChannel, setStartingChannel] = useState(null);
  const [parentMessage, setParentMessage] = useState(null);

  const setUpChat = async (userId, accessToken) => {
    try {
      const sendbird = SendbirdChat.init({
        appId,
        localCacheEnabled: true,
        modules: [new GroupChannelModule()],
      });
      setSb(sendbird);
      sendbird.connect(userId, accessToken).then(() => {
        if (startingChannelUrl) {
          try {
            sendbird.groupChannel
              .getChannel(startingChannelUrl)
              .then((channel) => {
                setStartingChannel(channel);

                if (parentMessageId) {
                  getParentMessage(sendbird);
                }
              })
              .catch(() => {
                setStartingChannel(null);
              });
          } catch (error) {
            setStartingChannel(null);
          }
        }
      });
    } catch (error) {
      console.error("Sendbird initialization error:", error);
    }
  };

  const fetchUser = async () => {
    try {
      fetchJson("/sendbird_auth", { method: "POST" }).then((sbAuthResp) => {
        const { user_id, access_token } = sbAuthResp;
        setAccessToken(access_token);

        fetchJson(`/users/${user_id}`).then((usersResp) => {
          setUser(usersResp);
          setUpChat(user_id, access_token).then(() => {});
        });
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
    }
  };

  const getParentMessage = async (sendbird) => {
    if (!sendbird) return;

    try {
      const params = {
        messageId: parentMessageId,
        channelType: "group",
        channelUrl: startingChannelUrl,
      };

      sendbird.message.getMessage(params).then((message) => {
        setParentMessage(message);
      });
    } catch (error) {
      // To do: handle error
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    fetchUser();

    // on disconnect remove resize listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // #880808 red color
  const myColorSet = {
    "--sendbird-light-primary-500": "#880808", // Nothing
    "--sendbird-light-primary-400": "#33353a", // User Chat on hover
    "--sendbird-light-primary-300": "#FFFFFF", // Chat bubble, Channel names, Action Icons
    "--sendbird-light-primary-200": "#474a50", // Nothing
    "--sendbird-light-primary-100": "#FFFFFF1A", // Highlighted Channel Background
    "--sendbird-light-onlight-04": "rgba(255, 255, 255, 0.12)", // Chat border color
    "--sendbird-light-onlight-03": "#FFFFFF", // Chat message text input border
    "--sendbird-light-onlight-02": "#bdbdbd", // Notification count, date, time, chat username
    "--sendbird-light-onlight-01": "#FFFFFF", // Channel, Profile, Channel Title
    "--sendbird-light-ondark-01": "#000000", // Chat message text bubble
    "--sendbird-light-background-50": "#000000", // Main background color
    "--sendbird-light-background-100": "#000000", // Other user chat bubble, menu hover state
    "--sendbird-light-background-200": "#FFFFFF", //
    "--sendbird-font-family-default": "Inter, sans-serif",
  };

  return (
    <SendBirdProvider
      appId={appId}
      userId={user?.id}
      accessToken={accessToken}
      nickname={user?.username}
      colorSet={myColorSet}
      breakpoint={isMobile}
      isReactionEnabled={false}
      isMentionEnabled={true}
      replyType="THREAD"
      profileUrl={user?.avatar_url}
    >
      <Chat
        sb={sb}
        user={user || {}}
        setUser={setUser}
        isOwner={isOwner === "true"}
        profileId={profileId}
        profileTitle={profileTitle}
        profileImage={profileImage}
        isMobile={isMobile}
        startingChannel={startingChannel}
        startingMessageId={startingMessageId}
        startingMessageCreatedAt={startingMessageCreatedAt}
        parentMessage={parentMessage}
        disableChannelAutoSelect={startingChannelUrl || isMobile}
      />
    </SendBirdProvider>
  );
};

export default SendbirdChatApp;
