import {
  IconBrandApplePodcast,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandLinktree,
  IconBrandMessenger,
  IconBrandPatreon,
  IconBrandPinterest,
  IconBrandSnapchat,
  IconBrandSoundcloud,
  IconBrandSpotify,
  IconBrandTiktok,
  IconBrandVimeo,
  IconBrandWhatsapp,
  IconBrandX,
  IconBrandYoutube,
  IconLink,
  IconMailbox,
  IconStack3,
} from "@tabler/icons-react";
import React from "react";

export interface SocialLinkItem {
  name: string;
  platformId: number;
  icon?: React.ReactNode;
  url?: string;
  key?: string;
}

// Based on social_link.rb
// TODO: Merge to single source of truth
export const availableLinks: SocialLinkItem[] = [
  {
    name: "YouTube",
    platformId: 0,
    icon: <IconBrandYoutube size={16} />,
    url: "",
  },
  {
    name: "Instagram",
    platformId: 1,
    icon: <IconBrandInstagram size={16} />,
    url: "",
  },
  {
    name: "TikTok",
    platformId: 2,
    icon: <IconBrandTiktok size={16} />,
    url: "",
  },
  {
    name: "Pinterest",
    platformId: 3,
    icon: <IconBrandPinterest size={16} />,
    url: "",
  },
  {
    name: "Facebook",
    platformId: 4,
    icon: <IconBrandFacebook size={16} />,
    url: "",
  },
  {
    name: "Spotify",
    platformId: 5,
    icon: <IconBrandSpotify size={16} />,
    url: "",
  },
  {
    name: "LinkedIn",
    platformId: 6,
    icon: <IconBrandLinkedin size={16} />,
    url: "",
  },
  {
    name: "WhatsApp",
    platformId: 7,
    icon: <IconBrandWhatsapp size={16} />,
    url: "",
  },
  {
    name: "Vimeo",
    platformId: 8,
    icon: <IconBrandVimeo size={16} />,
    url: "",
  },
  {
    name: "Substack",
    platformId: 9,
    icon: <IconStack3 size={16} />,
    url: "",
  },
  {
    name: "Patreon",
    platformId: 10,
    icon: <IconBrandPatreon size={16} />,
    url: "",
  },
  {
    name: "Messenger",
    platformId: 11,
    icon: <IconBrandMessenger size={16} />,
    url: "",
  },
  {
    name: "Soundcloud",
    platformId: 12,
    icon: <IconBrandSoundcloud size={16} />,
    url: "",
  },
  {
    name: "Linktree",
    platformId: 13,
    icon: <IconBrandLinktree size={16} />,
    url: "",
  },
  {
    name: "Apple Podcasts",
    platformId: 14,
    icon: <IconBrandApplePodcast size={16} />,
    url: "",
  },
  {
    name: "Website",
    platformId: 15,
    icon: <IconLink size={16} />,
    url: "",
  },
  {
    name: "X",
    platformId: 16,
    icon: <IconBrandX size={16} />,
    url: "",
  },
  {
    name: "Email",
    platformId: 17,
    icon: <IconMailbox size={16} />,
    url: "",
  },
  {
    name: "Snapchat",
    platformId: 18,
    icon: <IconBrandSnapchat size={16} />,
    url: "",
  },
];
