import { Controller } from "@hotwired/stimulus";
import { GridStack } from "gridstack";
import Choices from "choices.js";

// Connects to data-controller="social-links-form"
// Wrap nested field inputs with class .nested-fields

export default class extends Controller {
  connect() {
    this.initGrid();
    this.initChangeListener();
    this.element.querySelector(".add-link").addEventListener("click", (e) => {
      this.handleAddLinkClick(this.element.querySelector(".add-link"), e);
    });

    this.element.querySelectorAll("select").forEach((element) => {
      this.initChoices(element);
    });
  }

  iconForPlatform = (platformName) => {
    const iconMap = JSON.parse(this.element.dataset.icons);
    const platform = iconMap.find((arr) => {
      return arr[0] === platformName;
    });
    return platform[1];
  };

  initGrid() {
    const grid = GridStack.init(
      {
        disableResize: true,
        column: 1,
        cellHeight: 124,
        margin: 6,
        handle: ".drag-handle",
      },
      "#nested-fields",
    );
  }

  getGridInstance() {
    return this.element.querySelector("#nested-fields").gridstack;
  }

  initChangeListener() {
    const grid = this.getGridInstance();
    grid.on("change", (event, items) => {
      if (items) {
        const itemsToUpdate = items.map?.((item) => {
          item.el.querySelector("#position-input").value = item.y;
        });
      }
    });
  }

  handleAddLinkClick(link, e) {
    e.preventDefault();
    if (!(link && e)) return;
    let time = new Date().getTime();
    let linkId = link.dataset.id;
    let regexp = linkId ? new RegExp(linkId, "g") : null;
    let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
    const newPosition = this.getGridInstance()
      .getGridItems()
      .filter((el) => !el.classList.contains("d-none")).length;
    const widget = this.getGridInstance().addWidget(newFields);
    widget.querySelector("#position-input").value = newPosition;
    this.initChoices(widget.querySelector("select"));
  }

  initChoices(element) {
    const getIcon = this.iconForPlatform;
    const displayPlatformName = (name) => {
      const capitalize = ([firstLetter, ...restOfWord]) =>
        firstLetter.toUpperCase() + restOfWord.join("");
      switch (name) {
        case "linkedin":
          return "LinkedIn";
        case "whatsapp":
          return "WhatsApp";
        case "applepodcasts":
          return "Apple Podcasts";
        case "soundcloud":
          return "SoundCloud";
        case "tiktok":
          return "TikTok";
        case "youtube":
          return "YouTube";
        default:
          return capitalize(name);
      }
    };

    new Choices(element, {
      itemSelectText: "",
      searchEnabled: false,
      position: "bottom",
      callbackOnCreateTemplates: function (template) {
        return {
          choice: ({ classNames }, data) => {
            return template(`
              <div
                id="choices--profile_category_ids-item-choice-${data.id}"
                class="text-nowrap choices__item choices__item--choice choices__item--selectable is-highlighted"
                role="option"
                data-choice=""
                data-id="${data.id}"
                data-value="${data.value}"
                data-select-text=""
                data-choice-selectable=""
                aria-selected="true"
              >
                <img src="${getIcon(data.label)}" class="icon">
                ${displayPlatformName(data.label)}
              </div>
            `);
          },
          item: ({ classNames }, data) => {
            return template(`
              <div
                id="choices--profile_category_ids-item-choice-${data.id}"
                class="text-nowrap choices__item choices__item--selectable "
                role="option"
                data-id="${data.id}"
                data-value="${data.value}"
                aria-selected="true"
              >
                <img src="${getIcon(data.label)}" class="icon">
                <span>${displayPlatformName(data.label)}</span>
                <svg style="margin-left: 8px;" width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L4 4L7 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            `);
          },
        };
      },
    });
  }

  handleRemoveClick(e) {
    e.preventDefault();

    let fieldContainer = e.currentTarget.closest(".grid-stack-item");

    let deleteField = fieldContainer
      ? fieldContainer.querySelector("#destroy-input")
      : null;

    if (deleteField) {
      deleteField.value = 1;
    }

    fieldContainer.classList.add("d-none");
    this.getGridInstance().removeWidget(fieldContainer, false);
  }
}
