import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import { clientTz, findTzByName } from "timezone-select-js/lib/tz.helper.js";
import timezones from "../utils/timezones";

export default class extends Controller {
  static targets = ["input", "selectValue"];

  choice: Choices | undefined;

  declare readonly inputTarget: HTMLSelectElement;
  declare readonly selectValueTarget: HTMLInputElement;

  connect() {
    const clientValue = findTzByName(clientTz())?.value;
    const initialValue = this.selectValueTarget?.value || clientValue;

    const selector = this.inputTarget;
    if (selector) {
      this.choice = new Choices(selector, {
        placeholder: true,
        searchPlaceholderValue: "Timezone",
        choices: timezones,
        position: "bottom",
        shouldSort: false,
        renderSelectedChoices: "always",
        itemSelectText: "",
        allowHTML: true,
        resetScrollPosition: false,
        searchFields: ["label", "value"],
        searchResultLimit: 10000, // show all results
        placeholderValue: "Type to search",
      }).setChoiceByValue(initialValue);
    }
  }
}
