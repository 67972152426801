import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="link-input-formatter"
export default class extends Controller {
  format(e) {
    const value = e.target ? e.target.value : e;

    if (value && value.length > 0 && !/^((http|https):\/\/)/.test(value)) {
      e.target.value = `https://${value}`;
    }
  }
}
