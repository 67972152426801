import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="deselect-radio-button"
export default class extends Controller {
  static targets = ["radioButton"];

  maybeDeselect(event) {
    if (this.radioButtonTarget.checked) {
      event.preventDefault();
      this.radioButtonTarget.checked = false;
    }
  }
}
