import { Controller } from "@hotwired/stimulus";

// Upon succesfull block creation / update, the server returns a view which
// requires this controller. When that view is rendered, this controller is
// initialized and the connect method is called.

// The connect method closes the offcanvas, and clicks a hidden button which reloads the
// edit page to reflect the new block changes
export default class extends Controller {
  connect() {
    document.getElementById("close-button").click();
    document.getElementById("reload-page").click();
  }
}
