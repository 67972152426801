import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  currentIndex = 0;
  static targets = ["currentIndex", "image"];
  static values = {
    imageCount: Number,
  };

  connect() {
    this.reload();
  }

  onNextImage() {
    this.currentIndex = this.currentIndex + 1;

    if (this.currentIndex >= this.imageCountValue) {
      this.currentIndex = 0;
    }

    this.reload();
  }

  onPreviousImage() {
    this.currentIndex = this.currentIndex - 1;

    if (this.currentIndex < 0) {
      this.currentIndex = this.imageCountValue - 1;
    }

    this.reload();
  }

  reload() {
    for (let imageTarget of this.imageTargets) {
      imageTarget.classList.add("d-none");
    }

    if (this.imageTargets.length > 0) {
      this.imageTargets[this.currentIndex].classList.remove("d-none");
      this.imageTargets[this.currentIndex].classList.add("d-flex");
    }
  }
}
