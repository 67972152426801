import { Controller } from "@hotwired/stimulus";
import { FrameElement } from "../types/turbo";
import invariant from "tiny-invariant";

// Connects to data-controller="constant-refresh-frame"
export default class ConstantRefreshFrameController extends Controller {
  static values = {
    frameId: String,
    url: String,
    interval: Number,
  };

  declare frameIdValue: string;
  declare urlValue: string;
  declare intervalValue: number;
  private refreshInterval: number | null = null;

  connect() {
    const frame = document.getElementById(this.frameIdValue) as FrameElement;
    invariant(frame, "Expected to find frame");
    const url = this.urlValue;
    const interval = this.intervalValue || 3000; // Set the interval to 3000 milliseconds (3 seconds)

    // Use setInterval to repeatedly refresh the frame every 3 seconds
    const refreshFrame = () => {
      frame.src = "";
      frame.src = url;
    };

    // Set up the interval to refresh the frame every 3 seconds
    this.refreshInterval = window.setInterval(refreshFrame, interval);
  }

  disconnect() {
    // Clear the interval when disconnecting to stop refreshing
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }
}
