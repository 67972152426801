import { Controller } from "@hotwired/stimulus";
import MultiImageSelectorController from "../controllers/multi_image_selector_controller.js";
import FormHelpersController from "../controllers/form_helpers_controller.js";

// Connects to data-controller="dropzone"
// USAGE:
// <div
//   data-controller="dropzone"
//   data-dropzone-max-file-size="2"
//   data-dropzone-max-files="1"
//   data-dropzone-accept-value=["image/png","image/gif","image/jpeg"]
//   data-dropzone-block-id-value="<%= @model.block.id %>"
//   data-dropzone-upload-type-value="multi | single"
//   data-dropzone-url-value="<%= upload_image_url(blockId: @block.id) %>"
// >
//   <input type="file" ... data-dropzone-target="input" />
//   <div data-dropzone-target="dragzone"
//        data-action="dragenter->dropzone#onDragEnter
//        dragover->dropzone#onDragOver
//        dragleave->dropzone#onDragLeave
//        drop->dropzone#dropUpload
//        (other on file change action)">
//     ...
//   </div>
// </div>
export default class extends Controller {
  static targets = [
    "cancelButton",
    "dragzone",
    "form",
    "input",
    "errorMessageWrap",
    "errorMessage",
  ];

  static values = {
    url: String,
    accept: Array,
    blockId: String,
    uploadType: String,
  };

  onDragEnter(e) {
    e.preventDefault();
    this.addUploadingStyles();
  }

  onDragOver(e) {
    e.preventDefault();
    this.addUploadingStyles();
  }

  onDragLeave(e) {
    e.preventDefault();
    this.removeUploadingStyles();
  }

  dropUpload(e) {
    e.preventDefault();
    e.target.files = e.dataTransfer.files;
    const file = e.dataTransfer.files[0];
    const fileInput =
      document.getElementById("link_image_file_input") ||
      document.getElementById("video_image_file_input");

    if (this.isValidFileType(file)) {
      setTimeout(() => {
        if (this.uploadTypeValue === "multi") {
          new MultiImageSelectorController({
            blockId: this.blockIdValue,
            url: this.urlValue,
          }).uploadedFileChanged(e);
        } else if (this.uploadTypeValue === "single") {
          new FormHelpersController().uploadedFileChanged(e);
          if (fileInput) {
            fileInput.files = e.target.files;
          }
        }
      }, 500);
    } else {
      this.showError(
        "Invalid file format. Please double check your file and re-upload.",
      );
    }
    this.removeUploadingStyles();
  }

  onError(error) {
    this.showError(error);
  }

  onSuccess() {
    this.formTarget.submit();
  }

  addUploadingStyles() {
    this.dragzoneTarget.classList.add("drag-active");
  }

  removeUploadingStyles() {
    this.dragzoneTarget.classList.remove("drag-active");
  }

  isValidFileType(file) {
    return this.acceptValue.includes(file.type);
  }

  showError(error) {
    this.errorMessageWrapTarget.classList.remove("d-none");
    this.errorMessageTarget.innerHTML = error;
  }

  hideError() {
    this.errorMessageWrapTarget.classList.add("d-none");
  }
}
