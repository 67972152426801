import React, { useEffect, useState } from "react";
import AddEditChannelDetailsModal from "./AddEditChannelDetailsModal";
import Toasts from "../Toasts";
import i18n from "../../utils/i18n";

export default function ChannelSettingsProfile({ sb, channel }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  }, [showToast]);

  return (
    <div className={"settings-edit-channel mt-4 pb-4"}>
      <div className={"content-left d-inline-block"}>
        <div className={"channel-name-container"}>{channel.name}</div>
      </div>
      <div className={"content-right d-inline-block"}>
        <button onClick={() => setShowEditModal(true)}>
          {i18n.t("client.community.edit")}
        </button>
      </div>
      <Toasts message={toastMessage} show={showToast} />
      <AddEditChannelDetailsModal
        sb={sb}
        channel={channel}
        onHide={() => setShowEditModal(false)}
        show={showEditModal}
        onSubmit={() => {
          setToastMessage(i18n.t("client.community.channel_saved"));
          setShowToast(true);
        }}
      />
    </div>
  );
}
