import React from "react";

export default function Menu() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.625 14.625C6.24632 14.625 6.75 14.1213 6.75 13.5C6.75 12.8787 6.24632 12.375 5.625 12.375C5.00368 12.375 4.5 12.8787 4.5 13.5C4.5 14.1213 5.00368 14.625 5.625 14.625Z"
        fill="currentcolor"
        stroke="currentcolor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.5 14.625C14.1213 14.625 14.625 14.1213 14.625 13.5C14.625 12.8787 14.1213 12.375 13.5 12.375C12.8787 12.375 12.375 12.8787 12.375 13.5C12.375 14.1213 12.8787 14.625 13.5 14.625Z"
        fill="currentcolor"
        stroke="currentcolor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.375 14.625C21.9963 14.625 22.5 14.1213 22.5 13.5C22.5 12.8787 21.9963 12.375 21.375 12.375C20.7537 12.375 20.25 12.8787 20.25 13.5C20.25 14.1213 20.7537 14.625 21.375 14.625Z"
        fill="currentcolor"
        stroke="currentcolor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
