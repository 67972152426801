import React from "react";
import ChatBubble from "../icons/ChatBubble";
import ChannelHeader from "./ChannelHeader";
import i18n from "../../utils/i18n";

export default function ChatPlaceholder({
  message,
  onBackClicked,
  isMobile,
  hideBack,
  isFrozen,
}) {
  return (
    <>
      {isMobile && !hideBack && (
        <ChannelHeader
          onBackClicked={onBackClicked}
          isPlaceholder={true}
          isMobile={isMobile}
          hideBack={hideBack}
        />
      )}
      <div className=" sendbird-place-holder">
        {isFrozen && (
          <div className="sendbird-notification sendbird-notification--frozen sendbird-conversation__messages__notification">
            <span className="sendbird-notification__text sendbird-label sendbird-label--caption-2 ">
              {i18n.t("client.community.frozen_channel")}
            </span>
          </div>
        )}
        <div className="sendbird-place-holder__body">
          <div
            className="sendbird-place-holder__body__icon sendbird-icon sendbird-icon-chat sendbird-icon-color--on-background-3"
            role="button"
            tabIndex="0"
            style={{ width: "64px", height: "64px" }}
          >
            <ChatBubble />
          </div>
          <span className="sendbird-place-holder__body__text sendbird-label sendbird-label--body-1 sendbird-label--color-onbackground-2">
            {message}
          </span>
        </div>
      </div>
    </>
  );
}
