import React, { useState, useEffect, useRef } from "react";
import { ThreadProvider } from "@sendbird/uikit-react/Thread/context";
import CommentsMessage from "./CommentsMessage";
import CommentsMessageInput from "./CommentsMessageInput";
import BackArrowCircle from "../icons/BackArrowCircle";
import i18n from "../../utils/i18n";

export default function CommentsThread({
  message,
  user,
  channel,
  bannedUsers,
  closeThread,
  startingMessageCreatedAt,
  isMobile,
  isChat,
}) {
  const CustomThreadUI = () => {
    const DIRECTION = {
      ASC: "ASC",
      DESC: "DESC",
    };

    const lastMessageTimestamp =
      message.threadInfo?.lastRepliedAt || message.createdAt;
    const startingPointTimestamp =
      startingMessageCreatedAt || lastMessageTimestamp;

    const [allThreadMessages, setAllThreadMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasLess, setHasLess] = useState(true);
    const [hasMore, setHasMore] = useState(
      startingMessageCreatedAt ? true : false,
    );
    const [firstTimestamp, setFirstTimestamp] = useState(
      startingPointTimestamp,
    );
    const [lastTimestamp, setLastTimestamp] = useState(startingPointTimestamp);
    const [direction, setDirection] = useState(DIRECTION.ASC);
    const containerRef = useRef(null);

    let currentDate = null;

    const getParamsForThreading = async (prevMessages = [], size = 15) => {
      setIsLoading(true);
      const paramsThreadedMessageListParams = {
        prevResultSize: size,
        nextResultSize: size,
        isInclusive: true,
        reverse: false,
        includeParentMessageInfo: false,
      };

      try {
        const { threadedMessages } =
          await message.getThreadedMessagesByTimestamp(
            direction === DIRECTION.ASC ? lastTimestamp : firstTimestamp,
            paramsThreadedMessageListParams,
          );

        if (threadedMessages.length > 0) {
          setAllThreadMessages(
            mergeArrays(prevMessages, threadedMessages, direction),
          );
        }

        setIsLoading(false);

        if (
          direction === DIRECTION.ASC &&
          prevMessages.length > 0 &&
          threadedMessages.length > 0 &&
          prevMessages[prevMessages.length - 1].createdAt ===
            threadedMessages[threadedMessages.length - 1].createdAt
        ) {
          setHasMore(false);
        } else if (
          direction === DIRECTION.DESC &&
          prevMessages.length > 0 &&
          threadedMessages.length > 0 &&
          prevMessages[0].createdAt === threadedMessages[0].createdAt
        ) {
          setHasLess(false);
        }
      } catch (e) {
        setIsLoading(false);
      }
    };

    const mergeArrays = (prevMessages, threadedMessages, direction) => {
      const prevMessageIds = new Set(
        prevMessages.map((message) => message.messageId),
      );

      const filteredThreadedMessages = threadedMessages.filter(
        (message) => !prevMessageIds.has(message.messageId),
      );

      let mergedMessages;
      if (direction === DIRECTION.ASC) {
        mergedMessages = [...prevMessages, ...filteredThreadedMessages];
      } else {
        mergedMessages = [...filteredThreadedMessages, ...prevMessages];
      }

      return mergedMessages;
    };

    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;

      if (
        scrollTop === 0 &&
        !isLoading &&
        allThreadMessages.length > 0 &&
        hasLess
      ) {
        // Scrolled to top
        setDirection(DIRECTION.DESC);
        getParamsForThreading(allThreadMessages);
      } else if (
        scrollTop + clientHeight >= scrollHeight &&
        !isLoading &&
        hasMore
      ) {
        // Scrolled to bottom
        setDirection(DIRECTION.ASC);
        getParamsForThreading(allThreadMessages);
      }
    };

    const scrollToMessage = () => {
      if (allThreadMessages.length < 5) return; // No need to scroll if there are less than 5 messages

      if (containerRef.current) {
        let scrollToTs = null;
        if (startingMessageCreatedAt) {
          scrollToTs = startingMessageCreatedAt;
        } else if (direction === DIRECTION.ASC) {
          scrollToTs = lastMessageTimestamp;
        } else {
          scrollToTs = firstTimestamp;
        }

        const element = containerRef.current.querySelector(
          `#message-ts-${scrollToTs}`,
        );
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }

        // We want to set the new first/last timestamp after the scrolling
        if (direction === DIRECTION.ASC) {
          setLastTimestamp(
            allThreadMessages[allThreadMessages.length - 1]?.createdAt,
          );
        } else {
          setFirstTimestamp(allThreadMessages[0].createdAt);
        }
      }
    };

    const getDateFormat = (timestamp) => {
      return new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "2-digit",
        year: "numeric",
      }).format(timestamp);
    };

    const renderReplyMessage = (threadMessage, isThreadParent) => {
      let renderSeparator =
        currentDate == null ||
        getDateFormat(currentDate) !== getDateFormat(threadMessage.createdAt);

      if (renderSeparator) {
        currentDate = threadMessage.createdAt;
      }

      return (
        <div
          className="comment-thread__message"
          key={threadMessage.messageId}
          id={`message-ts-${threadMessage.createdAt}`}
        >
          {renderSeparator && isChat && (
            <div className=" sendbird-separator">
              <div className="sendbird-separator__left sendbird-color--onbackground-4--background-color" />
              <div className="sendbird-separator__text">
                <span className="sendbird-label sendbird-label--caption-2 sendbird-label--color-onbackground-2">
                  {getDateFormat(currentDate)}
                </span>
              </div>
              <div className="sendbird-separator__right sendbird-color--onbackground-4--background-color" />
            </div>
          )}
          <CommentsMessage
            message={threadMessage}
            user={user}
            bannedUsers={bannedUsers}
            channel={channel}
            isMobile={isMobile}
            isThreadParent={isThreadParent}
            isChat={isChat}
            onSubmit={loadMoreMessages}
          />
        </div>
      );
    };

    const renderReplies = () => (
      <>
        <div className="comment-thread__body">
          <div
            className="comment-thread__messages"
            ref={containerRef}
            onScroll={handleScroll}
          >
            {isChat && renderReplyMessage(message, true)}
            {allThreadMessages.map((threadMessage) =>
              renderReplyMessage(threadMessage, false),
            )}
          </div>
          {!isChat && (
            <CommentsMessageInput
              channel={channel}
              user={user}
              parentMessage={message}
              onSubmit={loadMoreMessages}
              isChat={isChat}
              isMobile={isMobile}
            />
          )}
        </div>
        {isChat && (
          <div className="sendbird-conversation__footer">
            <CommentsMessageInput
              channel={channel}
              user={user}
              parentMessage={message}
              onSubmit={loadMoreMessages}
              isChat={isChat}
              isMobile={isMobile}
            />
          </div>
        )}
      </>
    );

    const renderHeader = () => (
      <>
        <div className="comment-thread__header">
          <div className="comment-thread__header-line-one d-flex align-items-center d-inline-block">
            <div className="comment-thread__back">
              <button
                className="comment-thread__back-button unstyled-btn"
                onClick={() => closeThread()}
              >
                <BackArrowCircle />
              </button>
            </div>
            <h4 className="ms-2 mb-0">
              {i18n.t("client.community.thread_header_title")}
            </h4>
          </div>
          {!isChat && (
            <CommentsMessage
              message={message}
              user={user}
              isThreadParent={true}
              isChat={isChat}
            />
          )}
        </div>
      </>
    );

    const renderSeparator = () => {
      if (allThreadMessages.length === 0) return null;
      return (
        <div className="comment-thread__separator">
          <div className="comment-thread__separator-count">
            {i18n.t("client.comments.replies", {
              count: allThreadMessages.length,
            })}
          </div>
          <div className="comment-thread__separator-line" />
        </div>
      );
    };

    const loadMoreMessages = () => {
      setDirection(DIRECTION.ASC);
      getParamsForThreading();
    };

    useEffect(() => {
      getParamsForThreading([], 50);
    }, []);

    useEffect(() => {
      scrollToMessage();
    }, [allThreadMessages, isLoading, direction]);

    return (
      <div
        className={`comment-thread w-100 p-4 absolute-left-0 ${
          isChat ? "" : "bg-white vh-100"
        }`}
      >
        {renderHeader()}
        {!isChat && renderSeparator()}
        {renderReplies()}
      </div>
    );
  };

  return (
    <ThreadProvider channelUrl={message.channelUrl} message={message}>
      <CustomThreadUI />
    </ThreadProvider>
  );
}
