import { Controller } from "@hotwired/stimulus";
// Connects to data-controller="image-selector"
export default class extends Controller {
  static targets = [
    "imagePreview",
    "uploadIcon",
    "submitButton",
    "placeholder",
    "emptyLabel",
    "nonEmptyLabel",
  ];

  triggerFileUpload() {
    document
      .getElementById(this.element.dataset.fileInputId)
      .click(this.element.dataset.fileInputId);
  }

  uploadedFileChanged(e) {
    const files = e.target.files;
  }

  displayImagePreview = (file) => {
    const imgURL = URL.createObjectURL(file);

    if (this.element.dataset.imageDisplayMode === "src") {
      this.imagePreviewTarget.src = imgURL;
      this.imagePreviewTarget.classList.remove("d-none");
      if (this.imagePreviewTarget.closest(".img-container")) {
        this.imagePreviewTarget
          .closest(".img-container")
          .classList.remove("d-none");
      }
    }

    if (this.hasUploadIconTarget) {
      this.uploadIconTarget.classList.add("d-none");
    }

    this.imagePreviewTarget.style.transform = "";
  };

  imageSelected = (e) => {
    const file = e.target.files[0];

    if (file) {
      this.displayImagePreview(file);

      if (this.hasPlaceholderTarget) {
        this.placeholderTarget.classList.add("d-none");
      }
    }
  };

  submitButtonClicked = (e) => {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add("d-none");
    }
  };
}
