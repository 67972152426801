import { Controller } from "@hotwired/stimulus";
import { Offcanvas, Tooltip } from "bootstrap";
import {
  cartLoadingTemplate,
  productDetailsLoadingTemplate,
} from "../templates/loading";

const DRAWER_OPEN_TIMEOUT = 200;

// Connects to data-controller="offcanvas"
export default class extends Controller {
  static targets = [
    "membershipStatus",
    "communityChat",
    "activityFeedConnections",
  ];

  connect() {
    this.openBlockDetailDrawerFromUrl();
    this.openDrawerFromUrl();
    this.closeEventListeners();
  }

  disconnect() {
    this.cleanupHideAllTooltipsOnDismiss();
  }

  /**
   * shows a loading state in the offcanvas
   */
  cartButtonClick(e) {
    const bsOffcanvas = Offcanvas.getOrCreateInstance("#right-tray");

    const turboFrame = document.getElementById("offcanvas-frame");
    turboFrame.innerHTML = cartLoadingTemplate;

    bsOffcanvas.show();

    this.hideAllTooltipsOnDismiss();
  }

  /**
   * shows a loading state in the offcanvas
   */
  blockDetailsClick(e) {
    const bsOffcanvas = Offcanvas.getOrCreateInstance("#right-tray");

    if (!bsOffcanvas._element.classList.contains("show")) {
      const turboFrame = document.getElementById("offcanvas-frame");
      turboFrame.innerHTML = productDetailsLoadingTemplate;

      bsOffcanvas.show();
      const { blockId, drawerContent } = e.currentTarget.dataset;

      // update url history to reflect the block detail drawer being open
      if (blockId) {
        const blockId = e.currentTarget.dataset.blockId;
        const url = new URL(window.location.href);
        url.searchParams.set("block_id", blockId);
        if (drawerContent === "comments") {
          url.searchParams.set("should_open_comments_drawer", "true");
        }
        window.history.pushState({}, "", url);
      }

      this.hideAllTooltipsOnDismiss();
    }
  }

  /**
   * a rudimentary way to open the block detail or comments drawer from a url by finding
   * a button and clicking it. room for improvement here.
   */
  openBlockDetailDrawerFromUrl() {
    const params = new URL(window.location.href).searchParams;
    const blockId = params.get("block_id");
    const shouldOpenCommentsDrawer =
      params.get("should_open_comments_drawer") === "true";

    if (blockId) {
      let element = document.getElementById(`profile_block_block_${blockId}`);
      let button;
      if (
        this.hasMembershipStatusTarget &&
        this.membershipStatusTarget.dataset.component === "profile_banner"
      ) {
        document.querySelector("#right-tray")?.classList.add("extra-wide");
      }

      if (shouldOpenCommentsDrawer) {
        button = element.querySelector(`#comment_block_${blockId}`);
      } else {
        button = element.querySelector(
          ".hide-button-for-stretched-link button",
        );
        button =
          button || element.querySelector(".hide-button-for-stretched-link a");

        // workaround for event lists
        button = button || element.querySelector('[aria-controls="rightTray"]');
      }

      if (element) {
        this.scrollToTargetAndClickButton(element, button);
      }
    }
  }

  /**
   * a rudimentary way to open a drawer from a url by finding
   * a button and clicking it. room for improvement here.
   */
  openDrawerFromUrl() {
    const params = new URL(window.location.href).searchParams;

    // chat drawer
    const shouldOpenChatDrawer =
      params.get("should_open_chat_drawer") === "true";
    if (shouldOpenChatDrawer && this.hasCommunityChatTarget) {
      const bsOffcanvas = Offcanvas.getOrCreateInstance(
        "#community-chat-offcanvas",
      );
      bsOffcanvas.show();
      return;
    }

    // activity feed connections drawer
    const shouldOpenSocialConnectionsDrawer =
      params.get("open_connections_drawer") === "true";
    if (
      shouldOpenSocialConnectionsDrawer &&
      this.hasActivityFeedConnectionsTarget
    ) {
      this.scrollToTargetAndClickButton(
        this.activityFeedConnectionsTarget,
        this.activityFeedConnectionsTarget,
      );
      return;
    }
  }

  scrollToTargetAndClickButton(target, button) {
    if (target) {
      setTimeout(() => {
        target.scrollIntoView();
        button?.click();
      }, DRAWER_OPEN_TIMEOUT);
    }
  }

  /**
   * workaround to address this bug:
   * https://www.pivotaltracker.com/story/show/185471602
   */
  hideAllTooltipsOnDismiss() {
    document
      .querySelector("#right-tray")
      ?.addEventListener("hide.bs.offcanvas", this.hideTooltips);
  }

  cleanupHideAllTooltipsOnDismiss() {
    document
      .querySelector("#right-tray")
      ?.removeEventListener("hide.bs.offcanvas", this.hideTooltips);
  }

  hideTooltips(ev) {
    for (let el of ev.currentTarget.querySelectorAll(
      '[data-bs-toggle="tooltip"],[data-controller*="tooltip"]',
    )) {
      let tooltip = Tooltip.getInstance(el);
      if (tooltip) {
        tooltip.hide();
      }
    }
  }

  closeEventListeners() {
    document
      .querySelector("#right-tray")
      ?.addEventListener("hide.bs.offcanvas", () => {
        const url = new URL(window.location.href);
        url.searchParams.delete("block_id");
        url.searchParams.delete("should_open_comments_drawer");
        window.history.pushState({}, "", url);
      });
  }
}
