import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

/**
 * Initializes a bootstrap tooltip for the given element using it's `title`
 * attribute as the tooltip content.
 *
 * @example
 * ```
 * <div data-controller="tooltip" title="This is the tooltip text" />
 * ```
 *
 * @remarks
 *
 * This is an alternative to using `data-bs-toggle="tooltip"`, and is more
 * useful in cases where we are dynamically rendering the tooltip element after
 * the page has been loaded. In those cases the tooltip initialization code in
 * `application.js` will have already been run before the DOM element is
 * created. This should be safe to use in any case, though, because if the
 * tooltip instance has been initialized already for the given element, we
 * don't recreate it.
 */
export default class TooltipController extends Controller<HTMLElement> {
  connect() {
    const tooltip = Tooltip.getInstance(this.element);
    if (!tooltip) {
      new Tooltip(this.element);
    }
  }

  disconnect() {
    const tooltip = Tooltip.getInstance(this.element);
    if (tooltip) {
      tooltip.dispose();
    }
  }

  hide() {
    const tooltip = Tooltip.getInstance(this.element);
    if (tooltip) {
      tooltip.hide();
    }
  }
}
