const getCsrfToken = () =>
  (document.getElementsByName("csrf-token")[0] as any)?.content;

const post = (path: string, body: any) => {
  return makeRequest(path, body, "post");
};

const deleteRequest = (path: string, body: any) => {
  return makeRequest(path, body, "delete");
};

const makeRequest = (path: string, body: any, method: string) => {
  return fetch(path, {
    method: method,
    body: JSON.stringify(body),
    headers: {
      "X-CSRF-Token": getCsrfToken(),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((json) => {
        throw new Error(json.error);
      });
    }

    return response;
  });
};

async function fetchJson(
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  data?: any,
) {
  let response = await fetch(input, {
    ...init,
    ...(data != null && { body: JSON.stringify(data) }),
    headers: {
      "X-CSRF-Token": getCsrfToken(),
      Accept: "application/json",
      ...(data != null && { "Content-Type": "application/json" }),
      ...init?.headers,
    },
  });

  if (!response.ok) {
    try {
      let errorData = response.json();
      throw new Error((errorData as any).error ?? "unknown error");
    } catch (er) {
      throw new Error(`bad response (${response.status})`);
    }
  }

  return await response.json();
}

export { getCsrfToken, post, fetchJson, deleteRequest };
