import React, { useEffect, useState } from "react";
import {
  FrigadeAnnouncement,
  FrigadeProgressBadge,
  FrigadeTour,
  useFlows,
} from "@frigade/react";
import { IconProgress } from "@tabler/icons-react";
import { FrigadeAppProps } from "../index";
import {
  announcementStyles,
  checklistStyles,
  tourStyles,
} from "./EditorStyles";

export function AnnouncementsAndTours(props: FrigadeAppProps) {
  const {
    getFlowStatus,
    markFlowSkipped,
    markFlowNotStarted,
    getNumberOfSteps,
    isLoading,
    isDegraded,
  } = useFlows();

  useEffect(() => {
    // @ts-ignore
    window.startCreatorTour = async () => {
      props?.reloadFrigade?.();
    };

    // @ts-ignore
    window.showCreatorAnnouncement = async () => {
      props?.reloadFrigade?.();
    };
  }, []);

  if (isLoading || isDegraded) {
    return null;
  }

  const startTour = async () => {
    if (
      props.flowIdCreatorTourMobile &&
      props.flowIdCreatorTourDesktop &&
      props.flowIdCreatorAnnouncement
    ) {
      await markFlowNotStarted(props.flowIdCreatorAnnouncement);
      await markFlowNotStarted(props.flowIdCreatorTourDesktop);
      await markFlowNotStarted(props.flowIdCreatorTourMobile).then(() => {
        // @ts-ignore
        window.startCreatorTour();
      });
    }
  };

  const isMobile = window.innerWidth < 768;

  return (
    <>
      {isMobile && props.flowIdCreatorTourMobile && props.isCreator && (
        <FrigadeTour
          flowId={props.flowIdCreatorTourMobile}
          appearance={tourStyles}
          dismissible={false}
          showHighlightOnly={true}
          showTooltipsSimultaneously={true}
          showStepCount={false}
          className="frigade-tour-mobile"
        />
      )}
      {!isMobile && props.flowIdCreatorTourDesktop && props.isCreator && (
        <FrigadeTour
          flowId={props.flowIdCreatorTourDesktop}
          appearance={tourStyles}
          dismissible={false}
          showHighlightOnly={true}
          showTooltipsSimultaneously={true}
          showStepCount={false}
          className="frigade-tour-desktop"
        />
      )}
      {props.flowIdCreatorAnnouncement && props.isCreator && (
        <FrigadeAnnouncement
          flowId={props.flowIdCreatorAnnouncement}
          appearance={announcementStyles}
          allowBackNavigation={true}
          onButtonClick={(step, index, cta, nextStep) => {
            if (
              props.flowIdCreatorAnnouncement &&
              index === getNumberOfSteps(props.flowIdCreatorAnnouncement) - 1
            ) {
              document?.getElementById("frigade-checklist-id")?.click();
            }
            return true;
          }}
        />
      )}
      {props.flowIdCreatorChecklist && props.isCreator && (
        <a
          onClick={startTour}
          id="frigade-checklist-id"
          className="d-flex btn btn-outline profile-nav-link-edit-btn h-44px h-lg-56px rounded-44px justify-content-center px-0 px-md-1 px-xl-2 align-items-center"
        >
          <IconProgress size={28} className="me-1" />
          <span className="d-none d-xl-inline">Access Tour</span>
        </a>
      )}
    </>
  );
}
