import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
import Cropper from "cropperjs";
import invariant from "tiny-invariant";
import { loadingSpinner } from "../utilities";

// Connects to data-controller="hero-image-picker"
export default class HeroImagePickerController extends Controller {
  static targets = [
    "fileInput",
    "changeImageButton",
    "cropModal",
    "cropImg",
    "cropInput",
    "cropCancelButton",
    "cropApplyButton",
    "zoomInput",
  ];

  declare readonly fileInputTarget: HTMLInputElement;
  declare readonly changeImageButtonTarget: HTMLButtonElement;
  declare readonly cropModalTarget: HTMLDivElement;
  declare readonly cropImgTarget: HTMLImageElement;
  declare readonly cropInputTarget: HTMLInputElement;
  declare readonly cropCancelButtonTarget: HTMLButtonElement;
  declare readonly cropApplyButtonTarget: HTMLButtonElement;
  declare readonly zoomInputTarget: HTMLInputElement;

  cropper: Cropper | undefined;

  connect() {}

  openFilePickerDialog() {
    this.fileInputTarget.click();
  }

  imageSelected(ev: Event) {
    let fileList = this.fileInputTarget.files;
    if (fileList == null || fileList.length === 0) return;
    invariant(fileList.length === 1, "expected 1 file to be chosen");

    let file = fileList[0];
    let modal = Modal.getOrCreateInstance(this.cropModalTarget, {
      backdrop: "static",
    });

    let objectUrl = URL.createObjectURL(file);
    this.cropImgTarget.src = objectUrl;
    modal.show();

    const handleModalShown = () => {
      this.zoomInputTarget.value = "0";
      this.cropper = new Cropper(this.cropImgTarget, {
        viewMode: 1,
        autoCropArea: 1,
        aspectRatio: 768 / 400,
        cropBoxResizable: false,
        cropBoxMovable: false,
        dragMode: "move",
        zoomOnTouch: false,
        zoomOnWheel: false,

        crop: (ev) => {
          let cropData = {
            x: ev.detail.x,
            y: ev.detail.y,
            width: ev.detail.width,
            height: ev.detail.height,
          };

          this.cropInputTarget.value = JSON.stringify(cropData);
        },
      });
      this.cropModalTarget.removeEventListener(
        "shown.bs.modal",
        handleModalShown,
      );
    };

    const handleModalHidden = () => {
      invariant(this.cropper, "expected cropper");
      this.cropper.destroy();
      URL.revokeObjectURL(objectUrl);
      this.cropImgTarget.removeAttribute("src");
      this.cropper = undefined;
      this.cropModalTarget.removeEventListener(
        "hidden.bs.modal",
        handleModalHidden,
      );
    };

    this.cropModalTarget.addEventListener("shown.bs.modal", handleModalShown);
    this.cropModalTarget.addEventListener("hidden.bs.modal", handleModalHidden);
  }

  showLoadingState() {
    this.cropCancelButtonTarget.disabled = true;
    this.cropApplyButtonTarget.disabled = true;
    this.cropApplyButtonTarget.innerHTML = loadingSpinner();
  }

  /**
   * @see https://github.com/fengyuanchen/cropperjs/issues/830
   */
  zoom(ev: any) {
    invariant(this.cropper, "expected a cropper");
    let imageNaturalWidth = this.cropper.getImageData().naturalWidth;
    let containerWidth = this.cropper.getContainerData().width;
    let value =
      (1 + (ev.target.value / 100) * 3) / (imageNaturalWidth / containerWidth);
    this.cropper.zoomTo(value);
  }
}
