import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  currentIndex = 0;
  static targets = ["item", "thumbnail"];
  declare readonly itemTargets: Array<HTMLElement>;
  declare readonly thumbnailTargets: Array<HTMLElement>;

  static values = {
    cycleMs: Number,
  };
  declare readonly cycleMsValue: number;

  intervalId: number | undefined;

  get itemCount() {
    return this.itemTargets.length;
  }

  connect() {
    this.render();

    if (this.cycleMsValue && this.itemCount > 1) {
      this.intervalId = window.setInterval(() => {
        this.onNextItem();
      }, this.cycleMsValue);
    }
  }

  disconnect(): void {
    if (this.intervalId != null) {
      clearInterval(this.intervalId);
    }
  }

  onNextItem = () => {
    this.currentIndex = this.currentIndex + 1;

    if (this.currentIndex >= this.itemCount) {
      this.currentIndex = 0;
    }

    this.render();
  };

  onPreviousItem() {
    this.currentIndex = this.currentIndex - 1;

    if (this.currentIndex < 0) {
      this.currentIndex = this.itemCount - 1;
    }

    this.render();
  }

  onSetCurrentIndex(e: any) {
    this.currentIndex = e.params.index;
    this.render();
  }

  render() {
    if (this.itemTargets.length > 0) {
      for (let itemTarget of this.itemTargets) {
        itemTarget.classList.add("d-none");
      }

      if (this.itemTargets[this.currentIndex]) {
        this.itemTargets[this.currentIndex].classList.remove("d-none");
      }

      if (this.thumbnailTargets.length > 0) {
        for (let thumbnailTarget of this.thumbnailTargets) {
          thumbnailTarget.classList.remove("active");
        }

        this.thumbnailTargets[this.currentIndex].classList.add("active");
      }
    }
  }
}
