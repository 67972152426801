import { Controller } from "@hotwired/stimulus";
import MuxPlayerElement from "@mux/mux-player/.";
import videojs from "video.js";
import Player from "video.js/dist/types/player";

export default class ExpandedViewController extends Controller {
  /**
   * Any parent elements that have the `scale-up-on-hover` class.
   */
  scaleUpOnHoverParents: Element[] = [];

  static targets = ["expandedView"];
  static values = {
    onEscPostMessage: String,
  };

  declare onEscPostMessageValue: string;
  declare readonly expandedViewTarget: HTMLElement;

  connect() {
    // The `scale-up-on-hover` class causes UI issues in expanded mode
    // in some cases, so we collect all parent nodes that have the
    // `scale-up-on-hover` class, and toggle those off when we are expanded,
    // and back on when we aren't.
    this.scaleUpOnHoverParents = [];

    let current: Element | null = this.element;
    let next;

    while (current?.parentElement) {
      next = current.parentElement.closest(".scale-up-on-hover");
      if (next) {
        this.scaleUpOnHoverParents.push(next);
      }
      current = next;
    }
  }

  onExpand() {
    this.pauseVideo();
    this.expandActionsForIframes();

    // Disable scrolling on the body element
    document.body.style["overflow"] = "hidden";
    // dismiss lightbox if a modal or drawer opens
    document.body.addEventListener("show.bs.modal", this.onEsc);
    document.body.addEventListener("show.bs.offcanvas", this.onEsc);

    const { blockId } = this.expandedViewTarget.dataset;

    if (blockId) {
      const url = new URL(window.location.href);
      url.searchParams.set("block_id", blockId);
      url.searchParams.delete("should_open_comments_drawer");
      window.history.replaceState({}, "", url.toString());
    }

    // Show the expanded view
    this.expandedViewTarget.classList.remove("d-none");
    this.element
      .querySelectorAll('[loading="lazy"]')
      .forEach((el) => el.attributes.removeNamedItem("loading"));

    this.scaleUpOnHoverParents.forEach((el) =>
      el.classList.remove("scale-up-on-hover"),
    );

    document.onkeydown = (e) => {
      if (
        e.key === "Escape" &&
        !this.expandedViewTarget.classList.contains("d-none")
      ) {
        this.onEsc();
      }
    };
  }

  onBackgroundClick(e: HTMLElementEventMap["click"]) {
    const target = e.target as HTMLElement;
    if (target?.classList.contains("expanded-content")) {
      this.onEsc();
    }
  }

  onEsc = () => {
    document.body.removeEventListener("show.bs.modal", this.onEsc);
    document.body.removeEventListener("show.bs.offcanvas", this.onEsc);
    // Re-enable scrolling on the body element
    document.getElementsByTagName("body")[0].style["overflow"] = "visible";

    this.pauseVideo();
    this.escActionsForIframes();
    const videoTarget =
      this.expandedViewTarget.querySelectorAll("[data-video-id]");
    const videoId = videoTarget?.[0]?.getAttribute("data-video-id");
    this.setVideoExpanded(videoId);
    // Hide the expanded view
    this.expandedViewTarget.classList.add("d-none");
    this.scaleUpOnHoverParents.forEach((el) =>
      el.classList.add("scale-up-on-hover"),
    );

    // if url has param block_id, remove it
    if (window.location.search.includes("block_id")) {
      const url = new URL(window.location.href);
      url.searchParams.delete("block_id");
      window.history.replaceState({}, "", url.toString());
    }
  };

  expandActionsForIframes() {
    this.iframes().forEach((iframe) => {
      if (iframe.dataset.srcUrl && iframe.src !== iframe.dataset.srcUrl) {
        iframe.src = iframe.dataset.srcUrl;
      }
    });
  }

  escActionsForIframes() {
    this.iframes().forEach((iframe) => {
      // Post any messages to the iframe (e.g. pause the youtube/vimeo video)
      if (this.onEscPostMessageValue) {
        iframe.contentWindow?.postMessage(this.onEscPostMessageValue, "*");
      }

      // Set the iframe to a blank page if it doesn't support parent post
      // actions
      if (iframe.dataset.srcUrl) {
        iframe.src = "about:blank";
      }
    });
  }

  iframes() {
    return [...this.expandedViewTarget.getElementsByTagName("iframe")];
  }

  getVideoPlayers() {
    const vjs: Record<string, Player> = videojs.getPlayers();
    const muxEls = document.getElementsByTagName(
      "mux-player",
    ) as HTMLCollectionOf<MuxPlayerElement>;
    return [...Object.values(vjs).filter(Boolean), ...muxEls];
  }

  pauseVideo() {
    // Pause every video, everywhere, all at once
    // This should really delegate to video controller
    const players = this.getVideoPlayers();
    players.forEach((vid) => vid.pause());
  }

  // I don't think this attribute is used anymore
  setVideoExpanded(videoId: string | null) {
    if (videoId) {
      this.getVideoPlayers().forEach((vj) => {
        const target = vj instanceof HTMLElement ? vj : (vj.el_ as HTMLElement);
        if (target && target.dataset.videoId === videoId) {
          target.setAttribute("data-video-expanded", "true");
        }
      });
    }
  }
}
