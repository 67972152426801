import { Controller } from "@hotwired/stimulus";
import {
  addRemovableButtonSpinner,
  loadingSpinner,
  Toaster,
} from "../utilities";

// This controller is used to connect and disconnect from oauth in activity feed
// Connects to data-controller="activity-feed-oauth"
export default class extends Controller {
  href: string | null | undefined;
  onConnect(event: Event): void {
    const element = event.target as HTMLAnchorElement;
    this.href = element.getAttribute("href") || element.dataset.href;
    this.handleConnect(element);
  }

  onDisconnect(event: Event) {
    const element = event.target as HTMLAnchorElement;
    this.href = element.getAttribute("href") || element.dataset.href;
    this.handleDisconnect(element);
  }

  // private
  handleConnect(eventTarget: HTMLAnchorElement) {
    if (this.href) {
      const removableSpinner = addRemovableButtonSpinner(eventTarget);

      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.href, true);
      xhr.setRequestHeader("Accept", "application/json");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            const responseData = JSON.parse(xhr.responseText);

            if (responseData.redirect_url) {
              window.location.href = responseData.redirect_url;

              return;
            } else {
              // Handle other JSON data as needed
            }
          } else {
            Toaster.error("An error occurred, please try again later", {
              delay: 3000,
            });
          }

          removableSpinner?.remove?.();
        }
      };
      xhr.send();
    }
  }

  handleDisconnect(eventTarget: HTMLAnchorElement) {
    if (this.href) {
      const spinner = document.getElementById("oauth-disconnect-spinner");
      if (spinner) {
        spinner.classList.remove("d-none");
      }

      const removableSpinner = addRemovableButtonSpinner(eventTarget);

      const xhr = new XMLHttpRequest();
      xhr.open("GET", this.href, true);
      xhr.setRequestHeader("Accept", "application/json");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          removableSpinner?.remove?.();

          if (xhr.status === 200) {
            spinner?.classList.add("d-none");
            window.location.reload();
          } else {
            Toaster.error("An error occured, please try again later", {
              delay: 3000,
            });
          }
        }
      };
      xhr.send();
    }
  }
}
