import { Controller } from "@hotwired/stimulus";
import { GridItemHTMLElement, GridStack, GridStackOptions } from "gridstack";

import invariant from "tiny-invariant";
import { emitResizeEvent } from "../controllers/block_image_controller";
import { post } from "../fetch";
import ProfilePublishFormController from "./profile_publish_form_controller";
import { loadingSpinner } from "../utilities";

type LocationAttributes = {
  "gs-x": number;
  "gs-y": number;
  "gs-w": number;
  "gs-h": number;
};

const indicators = {
  saving: loadingSpinner(),
  saved: `<span class="badge bg-green-light bg-opacity-20 text-green-dark align-middle d-inline-block"> Saved </span>`,
  error: `<span class="badge bg-danger align-middle d-inline-block"> Error </span>`,
};

// note: much of the code in here has been inlined from other files where it
// was originally written. moving towards using more stimulus apis (targets etc)
// but there are still dom lookups in here that should be refactored when
// bandwidth is available.

// These values should be kept in sync with the scss
const commonGridOptions = {
  cellHeight: 0, // We will handle positioning
  column: 3,
  oneColumnSize: 800, // size to move to one column, same as "grid" css breakpoint
  animate: false,
  float: true,
} satisfies GridStackOptions;

// These values should be kept in sync with the scss
const commonGridLayoutOptions = {
  cellHeight: 0, // We will handle positioning
  column: 3,
  oneColumnSize: 0, // Always 3 columns
  animate: false,
  float: true,
} satisfies GridStackOptions;

// Connects to data-controller="gridstack"
export default class extends Controller {
  static outlets = ["profile-publish-form"];
  declare readonly hasProfilePublishFormOutlet: boolean;
  declare readonly profilePublishFormOutlet: ProfilePublishFormController;

  static values = {
    editing: Boolean,
    editLayoutEnabled: Boolean,
    profileSubdomain: String,
    type: String,
  };
  declare readonly editingValue: boolean;
  declare readonly hasEditingValue: boolean;
  declare readonly profileSubdomainValue: string;
  declare readonly editLayoutEnabledValue: boolean;
  declare readonly typeValue: string;

  static targets = ["saveStatus"];

  declare readonly saveStatusTarget: HTMLElement;
  declare readonly hasSaveStatusTarget: boolean;

  grid: GridStack | null = null;
  previousGridColumns: null | number = null;

  connect() {
    let gridWrapper;
    if (this.typeValue === "edit_layout") {
      gridWrapper = document.getElementById("grid-layout-wrapper") as any;
    } else {
      gridWrapper = document.getElementById("grid-wrapper") as any;
    }

    let existingGrid = gridWrapper.gridstack as GridStack | undefined;

    if (existingGrid) {
      const previousScrollPosition = window.scrollY;

      existingGrid.destroy(false);

      window.scrollTo({
        top: previousScrollPosition,
        left: 0,
        behavior: "instant",
      });
    }

    this.grid = this.initGridStack();
    gridWrapper.classList.add("grid-dynamic");
  }

  disconnect() {
    invariant(this.grid, "expected grid");
    this.grid.off("change");
    this.grid.destroy(false);
  }

  reloadPublishForm = () => {
    if (this.hasProfilePublishFormOutlet) {
      this.profilePublishFormOutlet.reloadForm();
    }
  };

  initGridStack() {
    const options =
      this.typeValue === "edit_layout"
        ? commonGridLayoutOptions
        : commonGridOptions;

    const layout =
      this.typeValue === "edit_layout"
        ? "#grid-layout-wrapper"
        : "#grid-wrapper";

    let grid = GridStack.init(
      {
        ...options,
        resizable: { autoHide: true, handles: "s,e,se" },
      },
      layout,
    );

    this.setupResizing(grid);
    this.updateGridItemCoordinates(grid);

    if (this.editLayoutEnabledValue && this.typeValue === "edit_profile") {
      grid.disable();
    }
    return grid;
  }

  compactGrid() {
    if (this.grid) {
      this.grid.compact();
    }
  }

  updateGridItemCoordinates(grid: GridStack) {
    // If a block is added to the grid with no described coordinates, the gridstack
    // library will assign it a default set of coordinates.

    // The following looks through each of the block after initalization,
    // and updates the block's local data attributes with persisted published coordinates
    grid.getGridItems().forEach((gridItem) => {
      if (gridItem.dataset.stagingCoordinates === "{}") {
        this.updateGridItemLayout(gridItem);
      }
    });

    if (this.hasEditingValue && this.editingValue) {
      grid.on("change", (_event, _items) => {
        invariant(grid, "expected grid");
        const dirtyItems = grid.getGridItems().filter((item) => {
          const currentAttrs = {
            "gs-x": Number(item.getAttribute("gs-x")),
            "gs-y": Number(item.getAttribute("gs-y")),
            "gs-w": Number(item.getAttribute("gs-w")),
            "gs-h": Number(item.getAttribute("gs-h")),
          };

          const stagedCoords = item.dataset.stagingCoordinates
            ? JSON.parse(item.dataset.stagingCoordinates)
            : {};

          const itemIsClean = coordinatesMatch(stagedCoords, currentAttrs);

          return !itemIsClean;
        });

        let promises: Array<Promise<unknown>> = [];
        if (dirtyItems.length > 0) {
          if (this.typeValue === "edit_layout") {
            promises.push(this.bulkUpdateGridItemLayout(dirtyItems));
          } else {
            promises = dirtyItems.map((item) =>
              this.updateGridItemLayout(item),
            );
          }
        }

        Promise.allSettled(promises).then(this.reloadPublishForm);
      });
    }
  }

  setSaveStatus = (content: string) => {
    if (this.hasSaveStatusTarget) {
      this.saveStatusTarget.innerHTML = content;
    }
  };

  bulkUpdateGridItemLayout = async (gridItems: GridItemHTMLElement[]) => {
    this.setSaveStatus(indicators.saving);

    const values: Record<string, LocationAttributes> = {};
    gridItems.forEach((gridItem) => {
      values[gridItem.dataset.blockId!] = getCoordinatesFromGridItem(gridItem);
    });

    const response = await post(
      `/hub/${this.profileSubdomainValue}/edit/bulk_update_layout`,
      { values },
    );
    const result = await response.json();
    if (result.success) {
      this.setSaveStatus(indicators.saved);
      gridItems.forEach((gridItem) => {
        const blockId = gridItem.dataset.blockId;
        if (blockId) {
          gridItem.dataset.stagingCoordinates = JSON.stringify(values[blockId]);
          this.updateBlockLocation(blockId, values[blockId]);
        }
      });
    } else {
      this.setSaveStatus(indicators.error);
    }
  };

  updateBlockLocation = (blockId: string, attrs: LocationAttributes) => {
    const block = document.querySelector(
      `#blocks [data-block-id="${blockId}"]`,
    ) as HTMLElement;
    if (block) {
      block.dataset.stagingCoordinates = JSON.stringify(attrs);
      block.setAttribute("gs-x", String(attrs["gs-x"]));
      block.setAttribute("gs-y", String(attrs["gs-y"]));
      block.setAttribute("gs-w", String(attrs["gs-w"]));
      block.setAttribute("gs-h", String(attrs["gs-h"]));
    }
  };

  updateGridItemLayout = async (gridItem: any) => {
    // avoid updating the layout on small screens where only one column is shown
    const numberOfColumns = this.grid?.opts.column;
    if (numberOfColumns === 1) {
      return;
    }

    const blockId = gridItem.dataset.blockId;
    const newAttrs = getCoordinatesFromGridItem(gridItem);

    emitResizeEvent(blockId, newAttrs["gs-w"], newAttrs["gs-h"]);

    return post(`/hub/${this.profileSubdomainValue}/edit/update_layout`, {
      newAttrs,
      blockId,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // update the block's local data attributes with persisted staging coordinates
          gridItem.dataset.stagingCoordinates = JSON.stringify(newAttrs);
        }
      })
      .catch((err) => {
        console.info("Error updating layout", err);
      });
  };

  setupResizing = (grid: GridStack) => {
    this.resizeGrid(grid);

    // TODO: clear resize event listener upon grid destroy, for now we're just breaking out of the function if the grid is not present
    window.addEventListener("resize", () => {
      this.resizeGrid(grid);
    });

    grid.el.dataset.resizingState = "stopped";

    grid.on("resizestart", () => {
      grid.el.dataset.resizingState = "started";
    });

    grid.on("resizestop", () => {
      // wait for next tick
      setTimeout(() => {
        grid.el.dataset.resizingState = "stopped";
      }, 0);
    });
  };

  resizeGrid = (grid: GridStack) => {
    if (!grid.el) {
      return;
    }

    const width = document.body.clientWidth;
    const layout = "moveScale";
    const option =
      this.typeValue === "edit_layout"
        ? commonGridLayoutOptions
        : commonGridOptions;

    if (width < option.oneColumnSize) {
      grid.column(1, layout);
      grid.disable();
      for (let resizeHandleElem of document.getElementsByClassName(
        this.typeValue === "edit_layout"
          ? "layout-resize-handle"
          : "resize-handle",
      )) {
        resizeHandleElem.classList.add("d-none");
      }

      this.previousGridColumns = 1;
    } else {
      grid.column(option.column, layout);

      // this workaround handles an edge case where the user either
      // a) lands on the page at single column width, or
      // b) adds a block to the page when at a single column width
      // which causes the grid state to be initialized at a single column width
      // resizing up to three columns from here will cause the grid to use
      // a single column layout only occupying the first column
      if (this.previousGridColumns === 1) {
        window.location.reload();
        return;
      }

      if (grid.el.dataset?.editable === "true") {
        grid.enable();
        for (let resizeHandleElem of document.getElementsByClassName(
          this.typeValue === "edit_layout"
            ? "layout-resize-handle"
            : "resize-handle",
        )) {
          resizeHandleElem.classList.remove("d-none");
        }
      }

      this.previousGridColumns = option.column;
    }

    // Set the wrapping div minHeight to the same as the grid-wrapper element
    // to avoid issues with the screen jumping when scrolling on mobile
    const blocks = document.getElementById("blocks");

    if (!blocks) return;

    blocks.style.minHeight = grid.el.style.minHeight;
  };
}

const coordinatesMatch = (coords1: any, coords2: any) => {
  if (!(coords1 && coords2)) return false;

  return (
    coords1["gs-x"] === coords2["gs-x"] &&
    coords1["gs-y"] === coords2["gs-y"] &&
    coords1["gs-w"] === coords2["gs-w"] &&
    coords1["gs-h"] === coords2["gs-h"]
  );
};

const getCoordinatesFromGridItem = (item: any): LocationAttributes => ({
  "gs-x": Number(item.getAttribute("gs-x")),
  "gs-y": Number(item.getAttribute("gs-y")),
  "gs-w": Number(item.getAttribute("gs-w")),
  "gs-h": Number(item.getAttribute("gs-h")),
});
