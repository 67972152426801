import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
import Cropper from "cropperjs";
import invariant from "tiny-invariant";

// Connects to data-controller="hero-image-picker"
export default class AvatarImagePickerController extends Controller {
  static targets = [
    "fileInput",
    "changeImageButton",
    "cropImg",
    "cropInput",
    "cropCancelButton",
    "cropApplyButton",
    "imagePreviewContainer",
    "imagePreview",
    "cropZoomRangeInput",
    "placeholder",
  ];
  declare readonly fileInputTarget: HTMLInputElement;
  declare readonly changeImageButtonTarget: HTMLButtonElement;
  declare readonly cropImgTarget: HTMLImageElement;
  declare readonly cropInputTarget: HTMLInputElement;
  declare readonly cropCancelButtonTarget: HTMLButtonElement;
  declare readonly cropApplyButtonTarget: HTMLButtonElement;
  declare readonly imagePreviewContainerTarget: HTMLDivElement;
  declare readonly imagePreviewTarget: HTMLImageElement;
  declare readonly cropZoomRangeInputTarget: HTMLInputElement;
  declare readonly placeholderTarget: HTMLDivElement;
  declare readonly hasPlaceholderTarget: boolean;

  static values = {
    modalId: String,
  };
  declare readonly modalIdValue: string;

  cropper: Cropper | undefined;
  modal: Modal | undefined;

  openFilePickerDialog() {
    this.fileInputTarget.click();
  }

  imageSelected() {
    let fileList = this.fileInputTarget.files;
    if (fileList == null || fileList.length === 0) return;
    invariant(fileList.length === 1, "expected 1 file to be chosen");

    let file = fileList[0];

    const cropModalTarget = document.getElementById(this.modalIdValue);
    invariant(
      cropModalTarget,
      `expected to find crop modal target: ${this.modalIdValue}`,
    );

    // grabbing references to targets here since we move this modal to the
    // bottom of the document, which is outside the stimulus controller so
    // data-action attrs won't work
    const { cropApplyButtonTarget, cropZoomRangeInputTarget, cropImgTarget } =
      this;

    this.modal = Modal.getOrCreateInstance(cropModalTarget, {
      backdrop: "static",
    });

    let objectUrl = URL.createObjectURL(file);
    cropImgTarget.src = objectUrl;
    this.modal.show();

    let isCropConfirmed = false;
    let originalCropValue = this.cropInputTarget.value;

    const confirmCrop = () => {
      invariant(this.modal, "expected modal");
      invariant(this.cropper, "expected cropper");

      let croppedCanvas = this.cropper.getCroppedCanvas();
      // transform the cropped image to base64 and compress it
      this.imagePreviewTarget.src = croppedCanvas.toDataURL("image/jpeg", 0.3);
      this.imagePreviewTarget.removeAttribute("srcset");
      isCropConfirmed = true;

      if (this.hasPlaceholderTarget) {
        this.placeholderTarget.classList.add("d-none");
        this.imagePreviewContainerTarget.classList.remove("d-none");
      }

      // cropCancelButtonTarget.disabled = true

      this.modal.hide();
    };

    const handleModalShown = () => {
      // move this thing to end of body
      document.body.appendChild(cropModalTarget);
      cropApplyButtonTarget.addEventListener("click", confirmCrop);
      cropZoomRangeInputTarget.value = "0";
      cropZoomRangeInputTarget.addEventListener("input", this.zoom);

      this.cropper = new Cropper(cropImgTarget, {
        viewMode: 1,
        autoCropArea: 1,
        aspectRatio: 1,
        cropBoxResizable: false,
        cropBoxMovable: false,
        dragMode: "move",
        zoomOnTouch: false,
        zoomOnWheel: false,
        rotatable: false,
        guides: false,
        crop: (ev) => {
          let cropData = {
            x: ev.detail.x,
            y: ev.detail.y,
            width: ev.detail.width,
            height: ev.detail.height,
          };

          this.cropInputTarget.value = JSON.stringify(cropData);
        },
      });

      cropModalTarget.removeEventListener("shown.bs.modal", handleModalShown);
    };

    cropModalTarget.addEventListener("shown.bs.modal", handleModalShown);

    const handleModalHidden = () => {
      invariant(this.cropper, "expected cropper");
      this.cropper.destroy();
      URL.revokeObjectURL(objectUrl);
      cropImgTarget.removeAttribute("src");

      // move this thing back into the stimulus controller
      this.element.appendChild(cropModalTarget);

      if (!isCropConfirmed) {
        this.fileInputTarget.value = "";
        this.cropInputTarget.value = originalCropValue;
      }

      cropApplyButtonTarget.removeEventListener("click", confirmCrop);
      cropZoomRangeInputTarget.removeEventListener("input", this.zoom);

      cropModalTarget.removeEventListener("hidden.bs.modal", handleModalHidden);
    };
    cropModalTarget.addEventListener("hidden.bs.modal", handleModalHidden);
  }

  showLoadingState() {}

  /**
   * @see https://github.com/fengyuanchen/cropperjs/issues/830
   */
  zoom = (ev: any) => {
    invariant(this.cropper, "expected a cropper");
    let imageDims = this.cropper.getImageData();
    let containerDims = this.cropper.getContainerData();

    let imageAr = imageDims.naturalWidth / imageDims.naturalHeight;
    let containerAr = containerDims.width / containerDims.height;

    let isScaledByWidth = imageAr > containerAr;

    let scale = isScaledByWidth
      ? imageDims.naturalWidth / containerDims.width
      : imageDims.naturalHeight / containerDims.height;

    let value = (1 + (ev.target.value / 100) * 3) / scale;
    this.cropper.zoomTo(value);
  };
}
