import { Controller } from "@hotwired/stimulus";

// This controller runs when the user creates a block
// It finds the current position of the placeholder block and inserts it into a hidden input
// on the create form
export default class extends Controller {
  connect() {
    const placeholderBlock = document.getElementById("placeholder-block");
    const newAttrs = {
      "gs-x": placeholderBlock.attributes["gs-x"].value,
      "gs-y": placeholderBlock.attributes["gs-y"].value,
      "gs-w": placeholderBlock.attributes["gs-w"].value,
      "gs-h": placeholderBlock.attributes["gs-h"].value,
    };

    const gsConfigInput = document.querySelector("input#gs_config_input");

    if (gsConfigInput) {
      gsConfigInput.value = JSON.stringify(newAttrs);
    }
  }
}
