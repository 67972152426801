import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import invariant from "tiny-invariant";
import { fetchJson } from "../fetch";
import BlockFormController from "./block_form_controller";
import DrawerController from "./drawer_controller";
import ProgressBarController from "./progress_bar_controller";
import { fileUploadedEvent } from "../utilities";

// Connects to data-controller="file-upload"
export default class FileUploadController extends Controller {
  static values = {
    id: String,
    class: String,
    uploadPath: String,
    removePath: String,
    transcodePath: String,
    profileSubdomain: String,
  };

  static targets = ["errorMessage", "destroyFileInput"];
  declare readonly errorMessageTargets: HTMLDivElement[];
  declare readonly destroyFileInputTarget: HTMLInputElement;

  static outlets = ["drawer", "block-form", "progress-bar"];
  declare readonly drawerOutlet: DrawerController;
  declare readonly hasDrawerOutlet: boolean;
  declare readonly blockFormOutlet: BlockFormController;
  declare readonly hasBlockFormOutlet: boolean;
  declare readonly progressBarOutlet: ProgressBarController;
  declare readonly hasProgressBarOutlet: boolean;
  declare readonly transcodePathValue: string;
  declare readonly profileSubdomainValue: string;

  uploadXhrRequest: XMLHttpRequest | null = null;

  connect() {
    if (hiddenDataEl().name.startsWith("video")) {
      const signed_id = hiddenDataEl()?.value;
      const byte_size = hiddenDataEl()?.dataset.byteSize;

      // A connected controller with a signed_id value represents a state where
      // a user has already uploaded an attachment
      if (signed_id !== "" && signed_id !== undefined) {
        this.initiateTranscoding(signed_id, byte_size, true);
      }
    }
  }

  linkSelected() {
    downloadLinkInput().classList.remove("d-none");
    downloadFileInput().classList.add("d-none");
  }

  fileSelected() {
    downloadLinkInput().classList.add("d-none");
    downloadFileInput().classList.remove("d-none");
  }

  onClickUploadFile() {
    fileUploadFileInput().click();
  }

  clearErrors = () => {
    this.errorMessageTargets.forEach((target) => {
      target.remove();
    });
  };

  cancelUpload = () => {
    if (this.uploadXhrRequest) {
      this.uploadXhrRequest.abort();
    }
  };

  async uploadedVideoChanged(e: Event) {
    let blob = await this.uploadedFileChanged(e);

    if (this.hasBlockFormOutlet) {
      this.blockFormOutlet.setState("requesting-transcoding");
    }

    this.initiateTranscoding(
      blob.signed_id.toString(),
      blob.byte_size.toString(),
      false,
    );
  }

  // initiateTranscoding sets state on the DOM to signal that the transcoding
  // progress has begun.
  //
  // A hidden input is used to surface the following state:
  //  * The id of the transcoding job
  //  * The file size (in bytes) of uploaded file
  //  * Whether or not the transcoding is already in progress or if this is the
  //    initial kick-off.
  async initiateTranscoding(
    signed_id: string | Blob,
    file_size: string,
    inProgress: Boolean,
  ) {
    try {
      let formData = new FormData();
      formData.set("signed_id", signed_id);

      let data = await fetchJson(this.transcodePathValue, {
        method: "post",
        body: formData,
      });

      let input = this.element.querySelector(
        "#download_file_input",
      ) as HTMLElement;

      const transcodeJobIdInput = document.createElement("input");
      transcodeJobIdInput.setAttribute("type", "hidden");
      transcodeJobIdInput.setAttribute("value", data.transcode_job_id);
      transcodeJobIdInput.setAttribute(
        "data-video-form-target",
        "transcodeJobIdInput",
      );
      transcodeJobIdInput.setAttribute(
        "data-video-profile-subdomain",
        this.profileSubdomainValue,
      );
      transcodeJobIdInput.setAttribute("data-video-byte-size", file_size);

      transcodeJobIdInput.setAttribute(
        "data-video-transcoding-in-progress",
        inProgress.toString(),
      );

      invariant(
        input?.dataset.transcodeJobIdFieldName,
        "expected a data-transcode-job-id-field-name attribute",
      );
      transcodeJobIdInput.name = input.dataset.transcodeJobIdFieldName;

      this.element.appendChild(transcodeJobIdInput);
    } finally {
      if (this.hasBlockFormOutlet) {
        this.blockFormOutlet.setState("idle");
      }
    }
  }

  async uploadedFileChanged(e: Event) {
    if (this.hasDrawerOutlet) {
      this.drawerOutlet.onUploadStart();
    }
    if (this.hasBlockFormOutlet) {
      this.blockFormOutlet.setState("uploading");
    }

    let input = e.target as HTMLInputElement;

    const files = input.files;
    if (files == null || files.length !== 1)
      throw new Error("expected one file");
    const file = files[0];

    const url = input.dataset.directUploadUrl;
    invariant(url, "expected data-direct-upload-url to be specified");

    const directUploadDidProgress = (ev: ProgressEvent) => {
      const completionPercent =
        ev.total !== 0 ? Math.floor((ev.loaded / ev.total) * 100) : 0;
      updateProgressBar(completionPercent);
    };

    const updateProgressBar = (
      /** integer between 0 and 100 */
      completionPercent: number,
    ) => {
      if (this.hasProgressBarOutlet) {
        this.progressBarOutlet.update(completionPercent);
      }
    };

    const showProgressBar = () => {
      if (this.hasProgressBarOutlet) {
        this.progressBarOutlet.show();
      }
    };

    const hideProgressBar = () => {
      if (this.hasProgressBarOutlet) {
        this.progressBarOutlet.hide();
      }
    };

    let _this = this;

    return new Promise<{ signed_id: string; byte_size: number }>(
      (resolve, reject) => {
        // https://edgeguides.rubyonrails.org/active_storage_overview.html#integrating-with-libraries-or-frameworks
        const upload = new DirectUpload(file, url, {
          directUploadWillStoreFileWithXHR(request) {
            _this.uploadXhrRequest = request;
            request.upload.addEventListener("progress", (event) =>
              directUploadDidProgress(event),
            );
          },
        });

        showProgressBar();
        updateProgressBar(0);
        upload.create((error, blob) => {
          hideProgressBar();
          if (this.hasDrawerOutlet) {
            this.drawerOutlet.onUploadEnd();
          }
          if (this.hasBlockFormOutlet) {
            this.blockFormOutlet.setState("idle");
          }
          if (error) {
            window.alert(`Upload error: ${error}`);
            console.info("err!", error);
            resetFileUpload();
            reject(new Error("Upload error: ${error}"));
          } else {
            input.value = "";
            _this.destroyFileInputTarget.value = "";
            hiddenDataEl().setAttribute("value", blob.signed_id);
            invariant(input.form, "expected input to have a form");
            this.clearErrors();

            showDeleteButton();

            fileUploadNameInput().dispatchEvent(
              fileUploadedEvent(file.name, blob.byte_size, blob.signed_id),
            );
            resolve(blob);
          }
        });

        // hide add button
        chooseFileButton().classList.add("d-none");
        // update file name
        fileUploadNameInput().value = file.name;
        // show file display and loading spinner
        fileUploadDisplay().classList.remove("d-none");
        fileUploadSpinner().classList.remove("d-none");
      },
    );
  }

  removeUploadedFile() {
    fileUploadFileInput().value = "";
    resetFileUpload();
    this.destroyFileInputTarget.value = "t";
  }
}

const resetFileUpload = () => {
  fileUploadDisplay().classList.add("d-none");
  chooseFileButton().classList.remove("d-none");
  fileUploadFileInput().value = null;
  hiddenDataEl().value = null;
  hiddenDataEl().dataset.byteSize = "";

  // todo: flash error if present
};

const showDeleteButton = () => {
  fileUploadSpinner().classList.add("d-none");

  fileUploadDelete().classList.remove("d-none");
};

const showLoadingSpinner = () => {
  fileUploadSpinner().classList.remove("d-none");

  fileUploadDelete().classList.add("d-none");
};

const chooseFileButton = (): any =>
  document.getElementById("choose-file-button");
const fileUploadDisplay = (): any =>
  document.getElementById("file-upload-display");
const fileUploadSpinner = (): any =>
  document.getElementById("file-upload-spinner");
const fileUploadDelete = (): any =>
  document.getElementById("file-upload-delete");
const fileUploadFileInput = (): any =>
  document.getElementById("download_file_input");
const fileUploadNameInput = (): any =>
  document.getElementById("file-upload-name");
const downloadLinkInput = (): any =>
  document.getElementById("download-link-input");
const downloadFileInput = (): any =>
  document.getElementById("download-file-input");
const hiddenDataEl = (): any => document.getElementById("upload-signed-id");
