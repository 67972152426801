import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

// Connects to data-controller="event-duration"
export default class extends Controller {
  choice: Choices | undefined;
  static targets = ["input"];

  declare readonly inputTarget: HTMLSelectElement;

  connect() {
    const selector = this.inputTarget;
    if (selector) {
      this.choice = new Choices(selector, {
        position: "bottom",
        shouldSort: false,
        renderSelectedChoices: "always",
        itemSelectText: "",
        allowHTML: true,
        resetScrollPosition: false,
        searchFields: ["label"],
        searchResultLimit: 10000, // show all results
        placeholderValue: "Type to search",
      });
    }
  }
}
