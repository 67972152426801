import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

// Connects to data-controller="is-scrolled"
export default class extends Controller {
  static debounces = ["updateScrollState"];

  connect() {
    useDebounce(this, { wait: 50 });
    this.updateScrollState();
  }

  updateScrollState() {
    (this.element as HTMLElement).dataset.scrolled =
      window.scrollY > 0 ? "scrolled" : "top";
  }
}
