import React, { useState, useEffect } from "react";
import ChannelUI from "@sendbird/uikit-react/Channel/components/ChannelUI";
import { ChannelProvider } from "@sendbird/uikit-react/Channel/context";
import CommentsThread from "./CommentsThread";
import CommentsMessage from "./CommentsMessage";
import CommentsMessageInput from "./CommentsMessageInput";
import ChatPlaceholder from "../SendbirdChat/ChatPlaceholder";
import i18n from "../../utils/i18n";

export default function CommentsChannel({
  channel,
  user,
  bannedUsers,
  startingMessageCreatedAt,
  parentMessage,
  isMobile,
}) {
  const PANELS = {
    CHANNEL: "CHANNEL",
    MESSAGE_SEARCH: "THREAD",
  };

  const [threadMessage, setThreadMessage] = useState(parentMessage);
  const [panel, setPanel] = useState(PANELS.CHANNEL);

  useEffect(() => {
    if (threadMessage) {
      setPanel(PANELS.THREAD);
    } else {
      setPanel(PANELS.CHANNEL);
    }
  }, [threadMessage]);

  const renderMessage = (message) => {
    return (
      <CommentsMessage
        message={message.message}
        user={user}
        channel={channel}
        bannedUsers={bannedUsers}
        setThreadMessage={setThreadMessage}
        isMobile={isMobile}
        isChat={false}
      />
    );
  };

  return (
    <div
      className="comments-channel"
      key={user ? `comments${user.username}` : null}
    >
      {panel === PANELS.CHANNEL && (
        <ChannelProvider
          channelUrl={channel._url}
          disableUserProfile={true}
          startingPoint={startingMessageCreatedAt}
        >
          <ChannelUI
            renderChannelHeader={() => <></>}
            renderMessage={renderMessage}
            renderCustomSeparator={() => {
              return <></>;
            }}
            renderMessageInput={() => (
              <CommentsMessageInput
                channel={channel}
                user={user}
                isChat={false}
                isMobile={isMobile}
              />
            )}
            renderPlaceholderLoader={() => (
              <ChatPlaceholder
                message={i18n.t("client.comments.loading")}
                onBackClicked={() => setPanel(PANELS.CHANNEL_LIST)}
                isMobile={isMobile}
                hideBack={true}
                isFrozen={channel?.isFrozen}
              />
            )}
            renderPlaceholderEmpty={() => (
              <ChatPlaceholder
                message={i18n.t("client.comments.no_comments")}
                onBackClicked={() => setPanel(PANELS.CHANNEL_LIST)}
                isMobile={isMobile}
                hideBack={true}
                isFrozen={channel?.isFrozen}
              />
            )}
            renderPlaceholderInvalid={() => (
              <ChatPlaceholder
                message={i18n.t("client.comments.no_comments")}
                onBackClicked={() => setPanel(PANELS.CHANNEL_LIST)}
                isMobile={isMobile}
                isFrozen={channel?.isFrozen}
              />
            )}
          />
        </ChannelProvider>
      )}
      {panel === PANELS.THREAD && threadMessage && (
        <CommentsThread
          message={threadMessage}
          user={user}
          channel={channel}
          bannedUsers={bannedUsers}
          isMobile={isMobile}
          closeThread={() => setThreadMessage(null)}
          startingMessageCreatedAt={startingMessageCreatedAt}
          isChat={false}
        />
      )}
    </div>
  );
}
