import { Controller } from "@hotwired/stimulus";

import { post } from "../fetch";
import { createDownloadLink } from "../utilities";

export default class extends Controller {
  static values = {
    downloadPath: String,
    membershipPostId: String,
  };

  download() {
    const membershipPostId = this.membershipPostIdValue;
    const downloadPath = this.downloadPathValue;

    post(downloadPath, { membershipPostId })
      .then((res) => res.json())
      .then((json) => {
        createDownloadLink(json.url);
      })
      .catch((err) => {
        console.info("download error", err);
      });
  }
}
