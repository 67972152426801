import React, { useEffect, useState } from "react";
import { CustomFormTypeProps, useFlows } from "@frigade/react";

const Img = ({ src, rotation }: { src: string; rotation: string }) => (
  <img
    src={src}
    alt="Promo card"
    style={{
      borderRadius: "20px",
      height: "250px",
      marginRight: "-75px",
      transform: `rotate(${rotation})`,
      width: "auto",
    }}
  />
);

const P = ({ children }: { children: React.ReactNode }) => (
  <p
    style={{
      color: "#FFF",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0.16px",
      margin: "0 auto 24px",
      maxWidth: "719px",
      textAlign: "center",
    }}
  >
    {children}
  </p>
);

const scrollToButton = () => {
  const welcomeSplashButton = document.getElementById("welcomeSplashButton");
  if (welcomeSplashButton !== null) {
    welcomeSplashButton.scrollIntoView({ behavior: "smooth" });
  }
};

export function WelcomeSplash({ flowId, stepData }: CustomFormTypeProps) {
  const { markStepCompleted, markStepStarted } = useFlows();
  const [hasMarkedStepStarted, setHasMarkedStepStarted] = useState(false);

  useEffect(() => {
    if (hasMarkedStepStarted) return;
    markStepStarted(flowId, stepData.id);
    setHasMarkedStepStarted(true);
    scrollToButton();
  }, []);

  return (
    <>
      <style>
        {`html, body {
    background: black;
    color: white;
    margin: 0;
}`}
      </style>
      <div style={{ fontFamily: "Inter", textAlign: "center" }}>
        <div className="headerImages">
          <Img src="/onboarding/card_1.png" rotation="0deg" />
          <Img src="/onboarding/card_2.png" rotation="0deg" />
          <Img src="/onboarding/card_3.png" rotation="0deg" />
          <Img src="/onboarding/card_4.png" rotation="0deg" />
          <Img src="/onboarding/card_5.png" rotation="0deg" />
          <Img src="/onboarding/card_6.png" rotation="0deg" />
          <Img src="/onboarding/card_7.png" rotation="0deg" />
        </div>
        <h1
          style={{
            color: "#FFF",
            fontSize: "64px",
            fontWeight: "900",
            letterSpacing: "0.64",
            margin: "20px 0 16px",
            textAlign: "center",
          }}
        >
          {stepData.title}
        </h1>
        {stepData.subtitle
          ?.split("\n")
          .map((line: string) => <P key={line}>{line}</P>)}
        <button
          style={{
            border: 0,
            borderRadius: "22px",
            color: "black",
            padding: "10px 86px",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "24px",
            marginTop: "24px",
            cursor: "pointer",
          }}
          id="welcomeSplashButton"
          onClick={() => {
            markStepCompleted(flowId, stepData.id);
          }}
        >
          {stepData.primaryButtonTitle}
        </button>
      </div>
    </>
  );
}
