import React from "react";

export default function ChatAvatar({
  className,
  width,
  height,
  src,
  alt,
  onClick,
}) {
  return (
    <>
      {src && (
        <div
          className={`sendbird-avatar ${className}`}
          role="button"
          tabIndex="0"
          style={{ height: `${height}px`, width: `${width}px`, zIndex: 0 }}
          onClick={onClick}
          onKeyDown={onClick}
        >
          <div
            className="sendbird-avatar-img sendbird-image-renderer"
            style={{ height: `${height}px`, width: "100%", maxWidth: "400px" }}
          >
            <div
              className="sendbird-image-renderer__image"
              style={{
                height: `${height}px`,
                width: "100%",
                maxWidth: "400px",
                minWidth: `${width}px`,
                position: "absolute",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundImage: `url("${src}")`,
              }}
            />
            <img
              className="sendbird-image-renderer__hidden-image-loader"
              alt={alt}
              src={src}
            />
          </div>
        </div>
      )}
    </>
  );
}
