import React from "react";
import CloseCircle from "../icons/CloseCircle";
import ChannelSettingsProfile from "./ChannelSettingsProfile";
import ChannelSettingsModerationPanel from "./ChannelSettingsModerationPanel";

export default function ChannelSettings({ sb, channel, onCloseClick }) {
  return (
    <>
      <div className={"sendbird-channel-settings"}>
        <div className={"sendbird-channel-settings__header"}>
          <button onClick={onCloseClick} className={""}>
            <CloseCircle />
          </button>
        </div>
        <div className={"sendbird-channel-settings__scroll-area"}>
          <div className={"title mb-4"}>Channel Info</div>
          <ChannelSettingsProfile sb={sb} channel={channel} />
          <div className={"mt-4 mb-5"}>
            <ChannelSettingsModerationPanel sb={sb} channel={channel} />
          </div>
        </div>
      </div>
    </>
  );
}
