import { Controller } from "@hotwired/stimulus";
import { post, deleteRequest } from "../fetch";
import { Toaster } from "../utilities";

const REWARDS_ONBOARDING_CONTEXT = "rewards_onboarding";

// Connects to data-controller="follow-profile"
export default class extends Controller {
  static values = {
    followUrl: String,
    unfollowUrl: String,
    email: String,
    context: String,
    following: String,
    followingMemberId: String,
  };

  declare followUrlValue: string;
  declare unfollowUrlValue: string;
  declare emailValue: String;
  declare contextValue: String;
  declare followingValue: String;
  declare followingMemberIdValue: string;

  toggleSubscription() {
    if (this.contextValue === REWARDS_ONBOARDING_CONTEXT) {
      (this.element as HTMLButtonElement).disabled = true;
    }

    if (this.followingValue === "true") {
      if (this.followingMemberIdValue) {
        deleteRequest(
          this.unfollowUrlValue.replace(
            "REPLACEME",
            this.followingMemberIdValue,
          ),
          {},
        )
          .then((res) => res.json())
          .then((response) => {
            if (response.success && response.reload) {
              window.location.reload();
            } else {
              this.notifyUser(response.success, response.message);
              if (response.success) {
                this.followingValue = "false";
                this.updateFollowButton();
              }
            }
          });
      } else {
        this.notifyUser(
          true,
          "To unfollow, you need to cancel your membership first",
        );
      }
    } else if (this.emailValue) {
      //Don't need to check the follow param since email will be present
      post(this.followUrlValue, {
        email: this.emailValue,
        context: this.contextValue,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success && response.reload) {
            window.location.reload();
          } else {
            this.notifyUser(response.success, response.message);

            if (response.success) {
              const oldContextValue = this.contextValue;
              this.contextValue = "";
              this.followingValue = "true";
              this.followingMemberIdValue = response.membershipId;
              this.updateFollowButton(oldContextValue);
            }
          }
        });
    }
  }

  notifyUser(success: boolean, message: string) {
    if (success) {
      Toaster.success(message, { delay: 2500 });
    } else {
      Toaster.error(message, { delay: 2500 });
    }
  }

  updateFollowButton(oldContextValue?: String) {
    if (oldContextValue && oldContextValue === REWARDS_ONBOARDING_CONTEXT) {
      document
        .getElementById("rewards-onboarding-follow")
        ?.classList.add("d-none");
      document
        .getElementById("rewards-onboarding-following")
        ?.classList.remove("d-none");
      document
        .getElementById("rewards-onboarding-following-box")
        ?.setAttribute("data-controller", "celebrate");
    } else {
      this.element.classList.toggle("btn-i-profile-button");
      this.element.classList.toggle("btn-profile-button");
      let followValue =
        this.element.getAttribute("data-follow-profile-following-value") ===
        "true";
      this.element.setAttribute(
        "data-follow-profile-following-value",
        String(followValue),
      );

      document
        .getElementById("follow-button-following")
        ?.classList.toggle("d-none");
      document
        .getElementById("follow-button-follow")
        ?.classList.toggle("d-none");
    }
  }
}
