import { Controller } from "@hotwired/stimulus";
import * as tempusdominus from "@eonasdan/tempus-dominus";
import { ChangeEvent } from "@eonasdan/tempus-dominus/types/utilities/event-types";
import i18n from "../utils/i18n";

// Connects to data-controller="datetime-picker"
export default class extends Controller<HTMLElement> {
  static targets = ["datetimeDate", "datetimeTime", "datePicker"];
  declare datetimeDateTarget: HTMLInputElement;
  declare datetimeTimeTarget: HTMLInputElement;
  declare datePickerTarget: HTMLElement;
  subscription: null | ReturnType<tempusdominus.TempusDominus["subscribe"]> =
    null;

  connect() {
    let picker = new tempusdominus.TempusDominus(this.datePickerTarget, {
      allowInputToggle: true,
      display: {
        theme: "light",
        icons: {
          type: "icons",
          time: "bi bi-clock",
          date: "bi bi-calendar-date",
          up: "bi bi-arrow-up",
          down: "bi bi-arrow-down",
          previous: "bi bi-chevron-left",
          next: "bi bi-chevron-right",
          today: "bi bi-calendar-check",
          clear: "bi bi-trash",
          close: "bi bi-x",
        },
      },
      localization: {
        dayViewHeaderFormat: { month: "long", year: "numeric" },
        hourCycle: "h12",
        format: "yyyy-MM-dd hh:mm T",
        today: i18n.t("client.date_time_picker.today"),
        clear: i18n.t("client.date_time_picker.clear"),
        close: i18n.t("client.date_time_picker.close"),
        selectMonth: i18n.t("client.date_time_picker.selectMonth"),
        previousMonth: i18n.t("client.date_time_picker.previousMonth"),
        nextMonth: i18n.t("client.date_time_picker.nextMonth"),
        selectYear: i18n.t("client.date_time_picker.selectYear"),
        previousYear: i18n.t("client.date_time_picker.previousYear"),
        nextYear: i18n.t("client.date_time_picker.nextYear"),
        selectDecade: i18n.t("client.date_time_picker.selectDecade"),
        previousDecade: i18n.t("client.date_time_picker.previousDecade"),
        nextDecade: i18n.t("client.date_time_picker.nextDecade"),
        previousCentury: i18n.t("client.date_time_picker.previousCentury"),
        nextCentury: i18n.t("client.date_time_picker.nextCentury"),
        pickHour: i18n.t("client.date_time_picker.pickHour"),
        incrementHour: i18n.t("client.date_time_picker.incrementHour"),
        decrementHour: i18n.t("client.date_time_picker.decrementHour"),
        pickMinute: i18n.t("client.date_time_picker.pickMinute"),
        incrementMinute: i18n.t("client.date_time_picker.incrementMinute"),
        decrementMinute: i18n.t("client.date_time_picker.decrementMinute"),
        pickSecond: i18n.t("client.date_time_picker.pickSecond"),
        incrementSecond: i18n.t("client.date_time_picker.incrementSecond"),
        decrementSecond: i18n.t("client.date_time_picker.decrementSecond"),
        toggleMeridiem: i18n.t("client.date_time_picker.toggleMeridiem"),
        selectTime: i18n.t("client.date_time_picker.selectTime"),
        selectDate: i18n.t("client.date_time_picker.selectDate"),
        locale: i18n.locale,
      },
    });

    this.subscription = picker.subscribe(
      tempusdominus.Namespace.events.hide,
      (e) => this.dateChanged(e),
    );
  }

  disconnect() {
    super.disconnect();

    // @ts-ignore: ignoring because the library is returning either an array or a single object. We know it is a single object
    this.subscription?.unsubscribe();
  }

  dateChanged(e: ChangeEvent) {
    if (e.date) {
      this.datetimeDateTarget.value = `${e.date.getFullYear()}-${
        e.date.getMonth() + 1
      }-${e.date.getDate().toString()}`;
      this.datetimeTimeTarget.value = `${e.date
        .getHours()
        .toString()
        .padStart(2, "0")}:${e.date.getMinutes().toString().padStart(2, "0")}`;
    }
  }
}
