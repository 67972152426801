import React, { useEffect, useState } from "react";
import { SendBirdProvider } from "@sendbird/uikit-react";
import { GroupChannelModule } from "@sendbird/chat/groupChannel";
import SendbirdChat from "@sendbird/chat";
import LoadingState from "./LoadingState";
import CommentsChannel from "./CommentsChannel";
import EditProfileModal from "../SendbirdChat/EditProfileModal";
import ChatAvatar from "../SendbirdChat/ChatAvatar";
import Toasts from "../Toasts";
import i18n from "../../utils/i18n";

const Comments = ({
  appId,
  channelId,
  blockId,
  userData,
  accessToken,
  latestComments,
  startingMessageCreatedAt,
  parentMessageId,
}) => {
  const [user, setUser] = useState(userData);
  const [sb, setSb] = useState(null);
  const [channel, setChannel] = useState(null);
  const [bannedUsers, setBannedUsers] = useState([]);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [parentMessage, setParentMessage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  const initializeChat = async () => {
    try {
      const sendbird = SendbirdChat.init({
        appId,
        localCacheEnabled: true,
        modules: [new GroupChannelModule()],
      });
      setSb(sendbird);
      sendbird.connect(user.id, accessToken).then(() => {
        sendbird.groupChannel.getChannel(channelId).then((channel) => {
          setChannel(channel);

          if (parentMessageId) getParentMessage(sendbird);

          if (channel.myMemberState === "invited") channel.acceptInvitation();

          if (channel.myRole === "operator") {
            const query = channel.createBannedUserListQuery();
            const banList = query.next();
            setBannedUsers(banList);
          }
        });
      });
    } catch (error) {
      // To do: handle error
    }
  };

  const getParentMessage = async (sendbird) => {
    if (!(sendbird && parentMessageId)) return;

    try {
      const params = {
        messageId: parentMessageId,
        channelType: "group",
        channelUrl: channelId,
      };

      sendbird.message.getMessage(params).then((message) => {
        setParentMessage(message);
      });
    } catch (error) {
      // To do: handle error
    }
  };

  const handleDataLoaded = () => {
    const commentsContainer = document.querySelector(
      ".sendbird-conversation__messages-padding",
    );
    const lastIndex = latestComments.length - 1;
    const messageID = latestComments[lastIndex]?.message_id;
    let messageElement;
    let intervalCount = 0;
    const scrollToMessage = () => {
      if (messageElement) {
        messageElement.scrollIntoView();
        clearInterval(scrollInterval);
      } else if (commentsContainer && commentsContainer.childNodes.length > 0) {
        messageElement = document.getElementById(messageID);
      } else if (intervalCount > 10) {
        clearInterval(scrollInterval);
      }
      intervalCount++;
    };

    const scrollInterval = setInterval(scrollToMessage, 200);
  };

  const renderEditProfileModal = () => {
    if (!(user && sb && showEditProfileModal)) return null;
    return (
      <EditProfileModal
        show={showEditProfileModal}
        onHide={() => setShowEditProfileModal(false)}
        user={user}
        setUser={setUser}
        sb={sb}
        onSubmit={() => {
          setToastMessage(i18n.t("client.community.profile_saved"));
          setShowToast(true);
        }}
      />
    );
  };

  const renderEditProfile = () => {
    if (!user) return null;
    return (
      <div className="comments-edit-profile">
        <ChatAvatar
          width={50}
          height={50}
          src={avatarUrl}
          alt="Profile Image"
          onClick={() => setShowEditProfileModal(true)}
        />
      </div>
    );
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    initializeChat();
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      if (sb) {
        sb.disconnect();
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (channel) {
      handleDataLoaded();
    }
  }, [channel]);

  const avatarUrl = sb?.currentUser?.profileUrl;

  return (
    <>
      <Toasts message={toastMessage} show={showToast} />
      {renderEditProfileModal()}
      {renderEditProfile()}
      {!accessToken && (
        <LoadingState
          latestComments={latestComments}
          userLoggedOut={!user}
          blockId={blockId}
        />
      )}
      {channel && user && (
        <SendBirdProvider
          appId={appId}
          userId={user.id}
          accessToken={accessToken}
          nickname={user.username}
          profileUrl={user.avatar_url}
          isReactionEnabled={false}
          isMentionEnabled={true}
          replyType="THREAD"
        >
          <CommentsChannel
            channel={channel}
            user={user}
            bannedUsers={bannedUsers}
            startingMessageCreatedAt={startingMessageCreatedAt}
            parentMessage={parentMessage}
            isMobile={isMobile}
          />
        </SendBirdProvider>
      )}
    </>
  );
};

export default Comments;
