import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notification-center"
export default class extends Controller {
  handleCheckboxClick(event) {
    const el = event.target;
    const medium = el.getAttribute("data-category-medium");

    document.querySelector(
      `[type='checkbox'][name='[${medium}][all]']`,
    ).checked = false;
  }

  toggleUnsubscribe(event) {
    const el = event.target;
    const medium = el.getAttribute("data-medium");

    const checkboxes = document.querySelectorAll(
      `[data-category-medium='${medium}']`,
    );

    checkboxes.forEach((checkbox) => {
      checkbox.checked = !el.checked;
    });
  }
}
