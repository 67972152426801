import React, { useEffect, useState } from "react";
import { FormInputProps } from "@frigade/react";
import { unwatch, watch } from "../utils";

export function CategoryTypeahead({
  formInput,
  customFormTypeProps,
  onSaveInputData,
  inputData,
  setFormValidationErrors,
}: FormInputProps) {
  interface Categories {
    defaultCategories: Array<string>;
    customCategories: Array<string>;
  }

  const DEFAULT = "default";
  const CUSTOM = "custom";

  const [categories, setCategories] = useState<Categories>({
    defaultCategories: [],
    customCategories: [],
  });

  const categoriesDropDownId = "default-categories-selector";
  const customCategoriesDropDownId = "custom-categories-selector";
  const defaultCategories =
    document.querySelector("#default-categories")?.children || [];
  const customCategories =
    document.querySelector("#custom-categories")?.children || [];

  const scrollToBottom = () => {
    requestAnimationFrame(() => {
      const container = document.getElementsByClassName("fr-formContent")[0];
      if (!container) return;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    });
  };

  const updateCategories = (type: string) => {
    setCategories((prevState) => ({
      defaultCategories:
        type === DEFAULT
          ? Array.from(
              document.querySelectorAll(
                `#${categoriesDropDownId} option:checked`,
              ),
            )
              // @ts-ignore
              .map((el) => el.value)
          : prevState.defaultCategories,
      customCategories:
        type === CUSTOM
          ? Array.from(
              document.querySelectorAll(
                `#${customCategoriesDropDownId} option:checked`,
              ),
            )
              // @ts-ignore
              .map((el) => el.value)
          : prevState.customCategories,
    }));
  };

  useEffect(() => {
    const defaultCategoriesInputHandler = () => {
      updateCategories(DEFAULT);
    };
    const profileCustomCategoriesInputHandler = () => {
      updateCategories(CUSTOM);
    };
    watch(`#${categoriesDropDownId}`, defaultCategoriesInputHandler);
    watch(
      `#${customCategoriesDropDownId}`,
      profileCustomCategoriesInputHandler,
    );
    // Adds the default categories to the dropdown
    if (defaultCategories.length > 0) {
      document
        .querySelector(`#${categoriesDropDownId}`)
        ?.append(...defaultCategories);
    }

    // Adds the custom categories to the dropdown
    if (customCategories.length > 0) {
      document
        .querySelector(`#${customCategoriesDropDownId}`)
        ?.append(...customCategories);
    }

    return () => {
      unwatch(`#${categoriesDropDownId}`);
      unwatch(`#${customCategoriesDropDownId}`);
    };
  }, []);

  useEffect(() => {
    onSaveInputData({
      defaultCategories: categories.defaultCategories,
      customCategories: categories.customCategories,
    });
  }, [categories]);

  return (
    <span onClick={scrollToBottom} onKeyUp={scrollToBottom}>
      <div
        id="categories-container"
        className="my-3"
        data-controller="categories-dropdown"
      >
        <select
          name="profile[category_ids][]"
          id={categoriesDropDownId}
          multiple
          data-action="change->drawer#blockHide"
          className="choices__input"
        />
      </div>
      <div
        id="custom-categories-container"
        className="my-3"
        data-controller="custom-categories-dropdown"
      >
        <select
          name="profile[custom_categories][]"
          id={customCategoriesDropDownId}
          multiple
          className="choices__input"
        />
      </div>
    </span>
  );
}
