export function debounce(func: Function, delay: number) {
  let timeoutId: number | undefined;

  return (...args: any) => {
    // @ts-expect-error
    const _this = this;
    clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => func.apply(_this, args), delay);
  };
}
