import React from "react";

export default function Crown() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1864 2.41869C11.2789 2.28919 11.401 2.18364 11.5426 2.11081C11.6841 2.03799 11.841 2 12.0001 2C12.1593 2 12.3162 2.03799 12.4577 2.11081C12.5992 2.18364 12.7214 2.28919 12.8139 2.41869L17.2003 8.55894L21.3751 5.2192C22.0801 4.6552 23.09 5.23944 22.9951 6.10044L22.9775 6.2092L19.9775 20.2094C19.9296 20.4332 19.8064 20.6337 19.6285 20.7776C19.4506 20.9215 19.2287 20.9999 18.9999 20.9999H5.00002C4.7712 20.9999 4.54932 20.9215 4.37139 20.7776C4.19347 20.6337 4.07028 20.4332 4.02239 20.2094L1.02239 6.20957C0.825515 5.29007 1.89052 4.63195 2.62477 5.2192L6.79927 8.55894L11.1864 2.41869ZM12.0001 4.72007L7.81402 10.5813C7.735 10.6919 7.63427 10.7852 7.51799 10.8556C7.4017 10.9259 7.2723 10.9718 7.13768 10.9905C7.00306 11.0092 6.86604 11.0002 6.735 10.9642C6.60396 10.9281 6.48164 10.8657 6.37551 10.7808L3.56414 8.53195L5.80814 19.0001H18.191L20.435 8.53195L17.6251 10.7808C17.4271 10.9392 17.1762 11.0165 16.9233 10.9969C16.6705 10.9773 16.4345 10.8624 16.2631 10.6754L16.1866 10.5813L12.0001 4.72007ZM12.0001 11.9999C12.5306 11.9999 13.0394 12.2107 13.4145 12.5858C13.7896 12.9609 14.0004 13.4697 14.0004 14.0002C14.0004 14.5307 13.7896 15.0395 13.4145 15.4146C13.0394 15.7897 12.5306 16.0004 12.0001 16.0004C11.4696 16.0004 10.9609 15.7897 10.5858 15.4146C10.2106 15.0395 9.99989 14.5307 9.99989 14.0002C9.99989 13.4697 10.2106 12.9609 10.5858 12.5858C10.9609 12.2107 11.4696 11.9999 12.0001 11.9999Z"
        fill="currentcolor"
      />
    </svg>
  );
}
