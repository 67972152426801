import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="collapse"
export default class extends Controller {
  static targets: string[] = ["icon", "content"];
  static values = {
    showCollapseEvent: { type: String, default: "show.bs.collapse" },
    hideCollapseEvent: { type: String, default: "hide.bs.collapse" },
  };

  declare showCollapseEventValue: string;
  declare hideCollapseEventValue: string;

  declare readonly iconTarget: HTMLElement;
  declare readonly contentTarget: HTMLElement;

  private showHandler = () => {
    this.iconTarget?.classList.add("tw-rotate-90");
  };

  private hideHandler = () => {
    this.iconTarget?.classList.remove("tw-rotate-90");
  };

  connect() {
    this.contentTarget.addEventListener(
      this.showCollapseEventValue,
      this.showHandler,
    );
    this.contentTarget.addEventListener(
      this.hideCollapseEventValue,
      this.hideHandler,
    );
  }

  disconnect() {
    this.contentTarget?.removeEventListener(
      this.showCollapseEventValue,
      this.showHandler,
    );
    this.contentTarget?.removeEventListener(
      this.hideCollapseEventValue,
      this.hideHandler,
    );
  }
}
