import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

// Connects to data-controller="purchase-disabled-button"
export default class extends Controller {
  tooltip: Tooltip | null = null;

  static values = {
    message: String,
  };
  declare readonly messageValue: string;

  connect() {
    const titleElement = document.createElement("div");
    titleElement.innerHTML = this.messageValue;

    this.tooltip = new Tooltip(this.element, {
      title: titleElement,
      html: true,
      delay: {
        show: 0,
        // Using a delay so the user can click on the link in the tooltip.
        // If this ends up not being sufficient we can use an approach like this:
        // https://stackoverflow.com/a/55065536/985909
        hide: 500,
      },
    });
  }
}
