import React from "react";

export default function CloseCircle() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.0001 45.8337C36.4584 45.8337 45.8334 36.4587 45.8334 25.0003C45.8334 13.542 36.4584 4.16699 25.0001 4.16699C13.5417 4.16699 4.16675 13.542 4.16675 25.0003C4.16675 36.4587 13.5417 45.8337 25.0001 45.8337Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1042 30.8962L30.8959 19.1045"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8959 30.8962L19.1042 19.1045"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
