// Connects to data-controller="select-dropdown"
import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

export default class extends Controller {
  static values = {
    formLabel: String,
    subtitle: String,
    maxSelections: Number,
  };
  connect(e) {
    const formLabel = this.formLabelValue;
    const subtitle = this.subtitleValue;
    const maxSelectionsValue = this.maxSelectionsValue;

    this.choice = new Choices(this.element.querySelector("select"), {
      position: "bottom",
      shouldSort: true,
      renderSelectedChoices: "always",
      searchEnabled: false,

      itemSelectText: "",
      allowHTML: true,
      resetScrollPosition: false,

      sorter: function (a, b) {
        if (a.value === "custom-category-toggle") {
          return 1;
        } else if (b.value === "custom-category-toggle") {
          return -1;
        } else {
          return 0;
        }
      },
      callbackOnCreateTemplates: function (template) {
        return {
          item: ({ classNames }, data) => {
            return template(`
              <div class="${classNames.item} ${
                data.placeholder ? classNames.placeholder : ""
              }
                " data-item data-id="${data.id}" data-value="${data.id}" ${
                  data.active ? 'aria-selected="true"' : ""
                }
                ${data.disabled ? 'aria-disabled="true"' : ""}>
                  <span>${data.label}</span>
              </div>
            `);
          },
          containerInner: () => {
            return template(`
              <div class="parliament-input choices__inner">
                <div class="choices-label">${formLabel}</div>
              </div>`);
          },
          choice: (_, data) => {
            return template(`
                <div
                  id="choices--profile_category_ids-item-choice-${data.id}"
                  class="
                    choices__item
                    choices__item--choice
                    choices__item--selectable
                    pe-0
                    is-highlighted"
                  role="option"
                  data-choice=""
                  data-id="${data.id}"
                  data-value="${data.value}"
                  data-select-text=""
                  data-choice-selectable=""
                  aria-selected="true"
                >
                  ${data.label}
                </div>
              `);
          },
          dropdown: ({ classNames }) => {
            return template(`
              <div class="p-3 choices__list choices__list--dropdown" aria-expanded="false">
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <h5 class="m-0">${formLabel}</h5>
                    <small>${subtitle}</small>
                  </div>
                </div>
              </div>
            `);
          },
        };
      },
      fuseOptions: {
        threshold: 0.4,
        distance: 0,
      },
    });

    this.element.addEventListener("addItem", this.addCategory);
    this.element.addEventListener("choice", this.choiceFn);
  }

  hideDropdown = (e) => {
    this.choice.hideDropdown();
  };

  choiceFn = (e) => {
    if (this.choice.getValue(true).includes(e.detail.choice.value)) {
      this.choice.removeActiveItemsByValue(e.detail.choice.value);
    }
  };

  addCategory = (e) => {
    const value = e.detail.value;

    if (
      this.choice.getValue(true).includes(value) &&
      this.shouldToggleCategory
    ) {
      this.choice.removeActiveItemsByValue(value);
      this.shouldToggleCategory = false;
    }
  };

  removeCategory = (e) => {
    const value = e.currentTarget.closest(".choices__item").dataset.value;
    console.info(
      "removeCategory",
      value,
      e.currentTarget,
      e.currentTarget.closest(".choices__item"),
      e.currentTarget.closest(".choices__item").dataset,
      e.currentTarget.closest(".choices__item").dataset.value,
      this.choice,
    );

    this.choice.removeActiveItemsByValue(value);
  };
}
