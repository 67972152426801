import { Controller } from "@hotwired/stimulus";

import { fetchJson, post } from "../fetch";
import { checkmark, loadingSpinner, Toaster, xmark } from "../utilities";
import { Modal } from "bootstrap";
import ProfilePublishFormController from "./profile_publish_form_controller";
import invariant from "tiny-invariant";

// Connects to data-controller="publish-profile"
export default class extends Controller {
  static targets = ["publishButton"];
  declare readonly publishButtonTarget: any;
  static values = {
    profileSubdomain: String,
  };

  static outlets = ["profile-publish-form"];
  declare readonly profilePublishFormOutlet: ProfilePublishFormController;
  declare readonly profileSubdomainValue: string;

  confirmPublishChanges() {
    this.publishChanges({ confirmed: true });
  }

  async publishChanges({ confirmed = false }) {
    this.profilePublishFormOutlet.replaceButtonContents(loadingSpinner());

    const body = {
      ...(confirmed && { confirmed: true }),
    };

    let res;
    try {
      res = await fetchJson(
        `/hub/${this.profileSubdomainValue}/edit/publish_changes`,
        { method: "post" },
        body,
      );
    } catch (err) {
      console.info("Error publishing changes", err);
      this.profilePublishFormOutlet.reloadForm(xmark("black"));
      return;
    }

    invariant(res);
    if (res.success) {
      res.updated_coordinates.forEach((coords: any) => {
        const block = document.querySelector(
          `[data-block-id='${coords.id}']`,
        ) as HTMLElement | null;

        if (block) {
          // update the block's local data attributes with persisted published coordinates
          block.dataset.publishedCoordinates = JSON.stringify(
            coords["gs_config"],
          );

          block.dataset.isDirty = "false";
          block.classList.remove("dirty");
        } else {
          // noop, block is on a different page
        }
      });

      this.profilePublishFormOutlet.reloadForm(checkmark());
    } else {
      this.profilePublishFormOutlet.reloadForm(xmark("black"));

      res.messages
        .filter((m: any) => m.messageType === "toast")
        .forEach((message: any) => {
          Toaster.error(message.content);
        });

      res.messages
        .filter((m: any) => m.messageType === "openModal")
        .forEach((message: any) => {
          const modal = new Modal(message.content);
          modal.show();
        });
    }
  }
}
