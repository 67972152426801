import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="donate-form"
export default class extends Controller {
  static targets = ["amountTextInputWrapper"];

  onSelectChange(e) {
    if (!e.target.value) {
      this.amountTextInputWrapperTarget.classList.remove("d-none");
      this.amountTextInputWrapperTarget.querySelector("input").disabled = false;
    } else {
      this.amountTextInputWrapperTarget.classList.add("d-none");
      this.amountTextInputWrapperTarget.querySelector("input").disabled = true;
    }
  }
}
