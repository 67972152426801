import posthog from "posthog-js";
import Cookies from "js-cookie";
import { getEnv } from "./get-env";

const POSTHOG_API_KEY = getEnv("POSTHOG_API_KEY");

window.posthog = posthog.init(POSTHOG_API_KEY, {
  api_host: "https://app.posthog.com",
  autocapture: false,
});

const parts = window.location.hostname.split(".");
const subdomain = parts.length > 2 ? parts[0] : null;
const group_id = Cookies.get("ph_group_id");

if (subdomain && group_id !== subdomain) {
  posthog.group("company", subdomain, {});
  Cookies.set("ph_group_id", subdomain);
}
