import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="drafts"
export default class extends Controller {
  scrollToArchive() {
    document
      .getElementById("archive-section")
      .scrollIntoView({ behavior: "smooth" });
  }

  scrollToDrafts() {
    document
      .getElementById("right-tray")
      .scrollTo({ top: 0, behavior: "smooth" });
  }
}
