import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import { CHECKED, UNCHECKED } from "../icons/svg-icons";

const MAX_CATEGORIES_SELECTED = 3;

export default class extends Controller {
  static displayCustomCategoryInput = false;

  connect() {
    this.choice = new Choices(
      this.element.querySelector("#default-categories-selector"),
      {
        position: "bottom",
        shouldSort: true,
        renderSelectedChoices: "always",
        itemSelectText: "",
        allowHTML: true,
        resetScrollPosition: false,
        searchFields: ["label"],
        placeholderValue: "Type to search",
        choices: [],
        sorter: function (a, b) {
          if (a.value === "custom-category-toggle") {
            return 1;
          } else if (b.value === "custom-category-toggle") {
            return -1;
          } else {
            return 0;
          }
        },
        callbackOnCreateTemplates: function (template) {
          return {
            item: ({ classNames }, data) => {
              return template(`
              <div class="${classNames.item} ${
                data.placeholder ? classNames.placeholder : ""
              }
                " data-item data-id="${data.id}" data-value="${data.value}" ${
                  data.active ? 'aria-selected="true"' : ""
                }
                ${data.disabled ? 'aria-disabled="true"' : ""}>
                  <span style="margin-right:8px;">${data.label}</span>
                  <span data-action="click->drawer#blockHide click->categories-dropdown#removeCategory" style="cursor: pointer; padding: 5px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 1.63555C9.82322 1.34266 9.82322 0.867788 9.53033 0.574895C9.23744 0.282001 8.76256 0.282001 8.46967 0.574895L5 4.04456L1.53033 0.574895C1.23744 0.282001 0.762563 0.282001 0.46967 0.574895C0.176777 0.867788 0.176777 1.34266 0.46967 1.63555L3.93934 5.10522L0.46967 8.57489C0.176777 8.86779 0.176777 9.34266 0.46967 9.63555C0.762563 9.92845 1.23744 9.92845 1.53033 9.63555L5 6.16588L8.46967 9.63555C8.76256 9.92845 9.23744 9.92845 9.53033 9.63555C9.82322 9.34266 9.82322 8.86779 9.53033 8.57489L6.06066 5.10522L9.53033 1.63555Z" fill="black" fill-opacity="0.33"/>
                    </svg>
                  </span>
              </div>
            `);
            },
            containerInner: ({ classNames }, data) => {
              return template(`
              <div class="parliament-input choices__inner">
                <div class="choices-label">Categories</div>
              </div>`);
            },
            choice: ({ classNames }, data) => {
              const maxSelections =
                this.getValue(true).length >= MAX_CATEGORIES_SELECTED;
              const choiceSelected =
                this.getValue(true).indexOf(data.value) > -1;

              return template(`
                <div
                  id="choices--profile_category_ids-item-choice-${data.id}"
                  class="choices__item choices__item--choice choices__item--selectable ${
                    maxSelections &&
                    !choiceSelected &&
                    "choices__item--disabled"
                  } is-highlighted"
                  role="option"
                  data-choice=""
                  data-id="${data.id}"
                  data-value="${data.value}"
                  data-select-text=""
                  data-choice-selectable=""
                  aria-selected="true"
                >
                  ${data.selected ? CHECKED : UNCHECKED}
                  ${data.label}
                </div>
              `);
            },
            dropdown: ({ classNames }) => {
              return template(`
              <div class="p-3 choices__list choices__list--dropdown" aria-expanded="false">
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <h5 class="m-0">Category</h5>
                    <small>Choose up to ${MAX_CATEGORIES_SELECTED}</small>
                  </div>
                  <div class="btn btn-black" data-action="click->categories-dropdown#hideDropdown">
                    Close
                  </div>
                </div>
              </div>
            `);
            },
          };
        },
        fuseOptions: {
          threshold: 0.4,
          findAllMatches: true,
          isCaseSensitive: false,
        },
      },
    );

    this.element.addEventListener("addItem", this.addCategory);
    this.element.addEventListener("choice", this.choiceFn);
  }

  hideDropdown = (e) => {
    this.choice.hideDropdown();
  };

  toggleDisplayCustomCategoryInput = (e) => {
    this.displayCustomCategoryInput = !this.displayCustomCategoryInput;
    if (this.displayCustomCategoryInput) {
      document
        .querySelector("#custom-categories-container")
        .classList.remove("d-none");
      this.hideDropdown();
    } else {
      document
        .querySelector("#custom-categories-container")
        .classList.add("d-none");
    }
  };

  choiceFn = (e) => {
    if (this.choice.getValue(true).includes(e.detail.choice.value)) {
      this.choice.removeActiveItemsByValue(e.detail.choice.value);
      this.shouldToggleCategory = true;
    }
  };

  addCategory = (e) => {
    const value = e.detail.value;
    if (
      this.choice.getValue(true).length > MAX_CATEGORIES_SELECTED &&
      value !== "custom-category-toggle"
    ) {
      this.choice.removeActiveItemsByValue(value);
      this.choice.showDropdown(true);
      return;
    }

    if (
      this.choice.getValue(true).includes(value) &&
      this.shouldToggleCategory
    ) {
      this.choice.removeActiveItemsByValue(value);
      this.shouldToggleCategory = false;
    }

    if (value === "custom-category-toggle") {
      if (this.choice.getValue(true).length <= MAX_CATEGORIES_SELECTED) {
        this.toggleDisplayCustomCategoryInput();
      }
      this.choice.removeActiveItemsByValue(value);
    }
  };

  removeCategory = (e) => {
    const value = e.currentTarget.closest(".choices__item").dataset.value;
    this.choice.removeActiveItemsByValue(value);
    e.stopPropagation();
  };
}
