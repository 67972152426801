import { Controller } from "@hotwired/stimulus";
import { assert } from "../utils/asserts";

import { Appearance } from "@stripe/stripe-js";
// Using "pure" here loads stripe on-demand
import { loadStripe } from "@stripe/stripe-js/pure";

// Connects to data-controller="collect-payment-info-form"
export default class CollectPaymentInfoFormCollector extends Controller {
  static targets = [
    "stripePublishableKey",
    "setupIntentSecret",
    "paymentInput",
    "paymentErrors",
    "submit",
    "setupIntentId",
    "emailInput",
    "emailError",
    "nameInput",
    "nameError",
  ];

  declare readonly stripePublishableKeyTarget: HTMLElement;
  declare readonly setupIntentSecretTarget: HTMLElement;
  declare readonly paymentInputTarget: HTMLElement;
  declare readonly paymentErrorsTarget: HTMLElement;
  declare readonly submitTarget: HTMLElement;
  declare readonly setupIntentIdTarget: HTMLElement;
  declare readonly emailInputTarget: HTMLInputElement;
  declare readonly nameInputTarget: HTMLInputElement;
  declare readonly nameErrorTarget: HTMLElement;
  declare readonly emailErrorTarget: HTMLElement;

  EMAIL_REGEX = /(.+)@(.+){2,}\.(.+){2,}/;

  connect = async () => {
    const form = this.element as HTMLFormElement;
    const stripePublishableKey = this.stripePublishableKeyTarget.dataset.value;
    assert(stripePublishableKey);

    this.nameInputTarget.addEventListener("blur", this.validateName);
    this.nameInputTarget.addEventListener("keyup", this.hideNameError);
    this.emailInputTarget.addEventListener("blur", this.validateEmail);
    this.emailInputTarget.addEventListener("keyup", this.hideEmailError);

    const stripe = await loadStripe(stripePublishableKey);
    assert(stripe);

    const appearance: Appearance = {
      variables: {
        fontFamily: "Inter, system-ui, -apple-system",
        fontSizeBase: "16px",
        fontSizeSm: "14px",
        borderRadius: "11px",
        spacingGridColumn: "24px",
        colorDanger: "#DC3545",
      },
      rules: {
        ".Input": {
          border: "1px solid #e0e0e0",
          boxShadow: "none",
        },
        ".Label": {
          color: "#7F7F7F",
        },
      },
    };

    const elements = stripe.elements({
      clientSecret: this.setupIntentSecretTarget.dataset.value,
      appearance,
      fonts: [
        {
          family: "Inter",
          src: "url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiJ-Ek-_EeA.woff2)",
          weight: "400",
        },
      ],
    });

    const paymentElement = elements.create("payment", {
      terms: {
        card: "never",
      },
      fields: {
        billingDetails: {
          name: "never",
          email: "never",
        },
      },
    });
    paymentElement.mount(this.paymentInputTarget);

    form.addEventListener("submit", async (event) => {
      event.preventDefault();

      this.paymentErrorsTarget.classList.add("d-none");

      if (!this.validateName()) return;
      if (!this.validateEmail()) return;

      const name = this.nameInputTarget.value;
      const email = this.emailInputTarget.value;

      this.submitTarget.setAttribute("disabled", "");

      const results = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name,
              email,
            },
          },
        },
      });

      if (results.error) {
        console.error("stripe error", results.error);
        this.paymentErrorsTarget.classList.remove("d-none");
        this.paymentErrorsTarget.textContent = results.error.message || "";
        this.submitTarget.removeAttribute("disabled");
      } else {
        this.setupIntentIdTarget.setAttribute("value", results.setupIntent.id);
        form.submit();
      }
    });
  };

  validateName = (): boolean => {
    const nameValue = this.nameInputTarget.value;
    if (nameValue.length === 0) {
      this.nameErrorTarget.textContent = "Name cannot be blank.";
      this.nameInputTarget.classList.add("--has-error");
      return false;
    }

    return true;
  };

  hideNameError = () => {
    this.nameInputTarget.classList.remove("--has-error");
    this.nameErrorTarget.textContent = "";
  };

  validateEmail = (): boolean => {
    const emailValue = this.emailInputTarget.value;
    if (!this.EMAIL_REGEX.test(emailValue)) {
      this.emailInputTarget.classList.add("--has-error");
      this.emailErrorTarget.textContent = "Please enter a valid email address.";
      return false;
    }
    return true;
  };

  hideEmailError = () => {
    this.emailInputTarget.classList.remove("--has-error");
    this.emailErrorTarget.textContent = "";
  };
}
