import { Controller } from "@hotwired/stimulus";
import { post } from "../fetch";

// Connects to data-controller="membership-tier-editor-date-toggle"
export default class extends Controller {
  showDatepicker(e) {
    const td = e.target.closest("td");

    const datePickerWrapper = td.querySelector(".date-picker-wrapper");
    const dateWrapper = e.target.closest("div");

    const datePicker = datePickerWrapper.querySelector("duet-date-picker");

    datePicker.show();

    datePicker.addEventListener("duetChange", function (event) {
      post(`edit/membership_post/${td.dataset.postId}`, {
        membership_post: { available_on: event.detail.value },
      }).then(() => {
        datePickerWrapper.classList.add("d-none");
        dateWrapper.classList.remove("d-none");

        dateWrapper.querySelector("span.date-display-wrapper").innerText =
          event.detail.value;
      });
    });

    datePickerWrapper.classList.remove("d-none");
    dateWrapper.classList.add("d-none");
  }
}
