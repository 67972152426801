import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="click-elsewhere"
export default class extends Controller {
  static values = {
    target: String,
  };
  declare targetValue: string;

  connect() {}

  clickOn() {
    let target = document.querySelector(this.targetValue);
    if (target == null) {
      throw new Error(`target not found: ${this.targetValue}`);
    }
    (target as HTMLInputElement).click();
  }
}
