import { Controller } from "@hotwired/stimulus";
import posthog from "posthog-js";

export default class TrackEventsController extends Controller {
  trackBlockAction(event, action) {
    const { dataset } = event.currentTarget;

    posthog.capture(`Block ${action}`, {
      product_id: dataset.blockId,
      product_type: dataset.blockContentType,
      product_title: dataset.blockTitle,
    });
  }

  trackPurchaseClick(event, action) {
    const { dataset } = event.currentTarget;

    posthog.capture("Purchase Click", {
      action: action,
      cart_id: dataset.cartId,
      location: dataset.location,
      product_id: dataset.productId?.split(","),
      product_content_type: dataset.productType?.split(","),
      product_title: dataset.productTitle?.split(","),
    });
  }

  tipClicked(event, action) {
    const { dataset } = event.currentTarget;

    posthog.capture("Tip Click", {
      action: action,
      product_id: dataset.productId,
      product_title: dataset.productTitle,
      product_type: dataset.productType,
    });
  }

  trackContentAccess(event, action) {
    const { dataset } = event.currentTarget;

    posthog.capture("Content Accessed", {
      action: action,
      product_id: dataset.productId,
      product_type: dataset.productType,
      product_title: dataset.productTitle,
      location: dataset.location,
    });
  }

  // Block Clicks
  blockClicked(event) {
    this.trackBlockAction(event, "Clicked");
  }

  blockAdded(event) {
    this.trackBlockAction(event, "Added");
  }

  blockEdited(event) {
    this.trackBlockAction(event, "Edited");
  }

  // Purchase Clicks
  addToCartClicked(event) {
    this.trackPurchaseClick(event, "Add to Cart");
  }

  viewCartClicked(event) {
    this.trackPurchaseClick(event, "View Cart");
  }

  donationClicked(event) {
    this.trackPurchaseClick(event, "Donate");
  }

  downloadClicked(event) {
    this.trackPurchaseClick(event, "Download");
  }

  checkoutClicked(event) {
    this.trackPurchaseClick(event, "Checkout");
  }

  buyNowClicked(event) {
    this.trackPurchaseClick(event, "Buy Now");
  }

  // Access Content
  // Notes: Service Content is not a clickable button
  accessMembershipContent(event) {
    this.trackContentAccess(event, "Membership Content");
  }

  accessOnlineCourse(event) {
    this.trackContentAccess(event, "Online Course");
  }

  accessLivestream(event) {
    this.trackContentAccess(event, "Livestream");
  }

  accessLicense(event) {
    this.trackContentAccess(event, "License");
  }

  downloadContent(event) {
    this.trackContentAccess(event, "Downloaded");
  }

  shareClicked(event) {
    const { dataset } = event.currentTarget;

    posthog.capture("Share Clicked", {
      url: dataset.clipboardCopyTextParam,
      product_id: dataset.blockId,
      product_type: dataset.blockContentType,
      product_title: dataset.blockTitle,
      location: dataset.location,
    });
  }

  waitlistClicked() {
    posthog.capture("Waitlist Clicked");
  }

  livestreamBannerClicked() {
    posthog.capture("Livestream Banner Clicked", {
      product_id: dataset.blockId,
    });
  }

  tiktokOauthModalMobileInstructionsViewed() {
    if (window.Statsig) {
      window.Statsig.logEvent("Tiktok Oauth Modal Mobile Instructions Viewed");
    }
  }

  tiktokOauthModalDesktopInstructionsViewed() {
    if (window.Statsig) {
      window.Statsig.logEvent("Tiktok Oauth Modal Desktop Instructions Viewed");
    }
  }

  tiktokOauthModalGenerateQRCodeClicked() {
    if (window.Statsig) {
      window.Statsig.logEvent("Tiktok Oauth Modal Generate QR Code Clicked");
    }
  }
}
