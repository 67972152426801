import { Controller } from "@hotwired/stimulus";
import * as React from "react";
import SendbirdChat from "../components/SendbirdChat";
import { createRoot, Root } from "react-dom/client";
import { getEnv } from "../utils/get-env";

class SendbirdController extends Controller {
  root: Root | undefined = undefined;

  connect() {
    const sendbirdContainer = document.getElementById("sendbird-container");
    if (sendbirdContainer) {
      this.root = createRoot(sendbirdContainer);
      this.root.render(this.initSendbird());
    }

    document
      .getElementById("community-chat-offcanvas")
      ?.addEventListener("hide.bs.offcanvas", this.checkHasNotifications);
  }

  disconnect() {
    document
      .getElementById("community-chat-offcanvas")
      ?.removeEventListener("hide.bs.offcanvas", this.checkHasNotifications);

    if (this.root) {
      this.root.unmount();
    }
  }

  initSendbird() {
    const appId = getEnv("SENDBIRD_APP_ID");
    const urlParams = new URL(window.location.href).searchParams;
    const startingChannelUrl = urlParams.get("channel_url");
    const messageIdFromUrl = urlParams.get("message_id");
    const messageCreatedAtFromUrl = urlParams.get("message_created_at");
    const parentMessageIdFromUrl = urlParams.get("parent_message_id");
    const startingMessageId = messageIdFromUrl
      ? Number(messageIdFromUrl)
      : null;
    const startingMessageCreatedAt = messageCreatedAtFromUrl
      ? Number(messageCreatedAtFromUrl)
      : null;
    const parentMessageId = parentMessageIdFromUrl
      ? Number(parentMessageIdFromUrl)
      : null;

    const appProps = {
      appId: appId,
      isOwner: this.data.get("isOwner"),
      profileId: this.data.get("profileId"),
      profileImage: this.data.get("profileImage"),
      profileTitle: this.data.get("profileTitle"),
      startingChannelUrl: startingChannelUrl,
      startingMessageId: startingMessageId,
      startingMessageCreatedAt: startingMessageCreatedAt,
      parentMessageId: parentMessageId,
    };

    return React.createElement(SendbirdChat, appProps);
  }

  checkHasNotifications() {
    if (
      document.querySelectorAll(
        ".channel-preview-content-channel-name.has-new-message",
      ).length === 0
    ) {
      document.getElementById("chat-badge")?.classList.add("d-none");
    }
  }
}

export default SendbirdController;
