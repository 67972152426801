import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="places-search"
export default class extends Controller {
  static targets = ["hiddenInput", "visibleInput", "result"];

  connect() {
    this.visibleInputTarget.addEventListener("keydown", this.onKeydown);
  }

  onKeydown = (e) => {
    if (["ArrowDown", "ArrowUp"].includes(e.code)) {
      e.preventDefault();
      this.onArrowUpDown(e);
      return;
    }
    if (["Enter"].includes(e.code)) {
      e.preventDefault();
      this.populateFocusedEntry();
      return false;
    }

    var service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      {
        input: e.target.value,
        types: ["(cities)"],
      },
      this.createResultsNodes,
    );
    if (e.target.value === "") {
      document.querySelector("#profile-location-hidden").value = "";
    }
  };

  onKeyUp = (e) => {
    if (["Enter", "ArrowDown", "ArrowUp"].includes(e.code)) {
      e.preventDefault();
      return false;
    }
    document.querySelector("#profile-location-hidden").value = e.target.value;
  };

  onArrowUpDown = (e) => {
    if (!this.hasResultTarget) return;

    const focusedResult = this.getFocusedResult();
    if (focusedResult) {
      focusedResult.classList.remove("--is-focus");

      if (e.code === "ArrowUp") {
        focusedResult.previousElementSibling.classList.add("--is-focus");
      } else {
        focusedResult.nextElementSibling.classList.add("--is-focus");
      }
    } else {
      this.resultsContainer().firstChild.classList.add("--is-focus");
    }
  };

  populateFocusedEntry = () => {
    const focusedResult = this.getFocusedResult();

    if (focusedResult) {
      this.hiddenInputTarget.value = focusedResult.innerHTML;
      this.visibleInputTarget.value = focusedResult.innerHTML;
      this.resultsContainer().innerHTML = "";
      this.resultsContainer().classList.add("d-none");
    }
  };

  getFocusedResult = () => {
    return this.resultsContainer().querySelector(".--is-focus");
  };

  createResultsNodes = (results) => {
    this.resultsContainer().innerHTML = "";
    this.resultsContainer().classList.remove("d-none");
    if (results) {
      results.forEach((result) => {
        const resultNode = document.createElement("div");
        resultNode.classList.add("places-search-result", "p-1", "m-1");
        resultNode.setAttribute("tabindex", 0);
        resultNode.dataset.placesSearchTarget = "result";
        resultNode.innerHTML = result.description;
        resultNode.addEventListener("click", this.setLocation);
        this.resultsContainer().append(resultNode);
      });
    }
  };

  setLocation = (e) => {
    this.hiddenInputTarget.value = e.target.innerHTML;
    this.visibleInputTarget.value = e.target.innerHTML;
    this.resultsContainer().innerHTML = "";
    this.resultsContainer().classList.add("d-none");
  };

  resultsContainer = () => {
    return this.element.querySelector(".results");
  };

  resultsKeydownEvent = (e) => {
    if (!this.hasResultTarget) return;

    switch (e.code) {
      case "ArrowUp":
        e.preventDefault();
        e.target.previousElementSibling?.focus();
        break;
      case "ArrowDown":
        e.preventDefault();
        e.target.nextElementSibling?.focus();
        break;
    }
  };
}
