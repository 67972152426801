import { Controller } from "@hotwired/stimulus";
import { Offcanvas } from "bootstrap";
import { productDetailsLoadingTemplate } from "../templates/loading";

// Connects to data-controller="editable-block"
export default class extends Controller {
  static values = {
    blockEditPath: String,
    offcanvasFrameId: String,
  };

  /** @param {PointerEvent} ev */
  showEditOffcanvas(ev) {
    if (!(ev.target instanceof HTMLElement)) {
      console.error("unexpected element", ev.target);
      throw new Error("expected target to be an element");
    }

    let grid = ev.target.closest(".grid-stack");
    if (grid == null || !(grid instanceof HTMLElement))
      throw new Error("expected to find grid");

    if (grid.dataset.resizingState === "started") {
      return;
    }

    // avoid triggering edit view when clicking the resize handles
    if (ev.target.classList.contains("ui-resizable-handle")) {
      return;
    }

    let frame = document.getElementById(this.offcanvasFrameIdValue);
    if (frame == null) throw new Error("frame not found");

    frame.innerHTML = productDetailsLoadingTemplate;
    frame.src = this.blockEditPathValue;

    const bsOffcanvas = Offcanvas.getOrCreateInstance("#right-tray");
    bsOffcanvas.show();
  }
}
