import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="license-codes-form"
export default class extends Controller {
  static targets = [
    "autoRadioButton",
    "listRadioButton",
    "urlRadioButton",
    "autoFormSection",
    "listFormSection",
    "urlFormSection",
  ];

  connect() {
    this.autoRadioButtonTarget.addEventListener("change", () => {
      this.autoFormSectionTarget.classList.remove("d-none");
      this.urlFormSectionTarget.classList.add("d-none");
      this.listFormSectionTarget.classList.add("d-none");
    });

    this.urlRadioButtonTarget.addEventListener("change", () => {
      this.autoFormSectionTarget.classList.add("d-none");
      this.urlFormSectionTarget.classList.remove("d-none");
      this.listFormSectionTarget.classList.add("d-none");
    });

    this.listRadioButtonTarget.addEventListener("change", () => {
      this.autoFormSectionTarget.classList.add("d-none");
      this.urlFormSectionTarget.classList.add("d-none");
      this.listFormSectionTarget.classList.remove("d-none");
    });
  }
}
