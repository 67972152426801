import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import CommentsMessageInput from "./CommentsMessageInput";
import TimeAgo from "../utils/TimeAgo";
import i18n from "../../utils/i18n";
import Menu from "../icons/Menu";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import ChatAvatar from "../SendbirdChat/ChatAvatar";

export default function CommentsMessage({
  message,
  user,
  channel,
  bannedUsers,
  setThreadMessage,
  isThreadParent,
  onSubmit,
  isMobile,
  isChat,
}) {
  const [isMuted, setIsMuted] = useState(message?.sender.isMuted);
  const [isBanned, setIsBanned] = useState(
    bannedUsers?.length > 0 && bannedUsers.includes(message?.sender.userId),
  );
  const isByMe = message.sender?.userId === user.id;
  const isOperator = channel?.myRole === "operator";
  const [deleted, setDeleted] = useState(message.customType === "user-deleted");
  const [isEditing, setIsEditing] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    setDeleted(message.customType === "user-deleted");
  }, [message.customType]);

  function displayToast(message, isSuccess) {
    setToastMessage(message);
    setShowToast(true);
  }

  function handleSendbirdErrorResponse(e, message) {
    //TODO: log in posthog/sentry
    displayToast(
      message || i18n.t("client.community.something_went_wrong"),
      false,
    );
  }

  const deleteMessage = async () => {
    const params = {
      customType: "user-deleted",
    };

    await channel
      .updateUserMessage(message.messageId, params)
      .then((message) => {
        setDeleted(true);
        displayToast(i18n.t("client.comments.message_removed"), true);
      })
      .catch(handleSendbirdErrorResponse);
  };
  const reportMessage = async () => {
    try {
      // To do: handle alert
      await channel.reportMessage(
        message,
        "suspicious",
        "User reported message",
      );
      displayToast(
        i18n.t("client.comments.reported_toast", {
          username: message.sender?.nickname,
        }),
        true,
      );
    } catch (error) {
      handleSendbirdErrorResponse(error);
    }
  };

  const banUser = async () => {
    try {
      if (isBanned) {
        await channel.unbanUserWithUserId(message.sender.userId);
        setIsBanned(false);
        displayToast(
          i18n.t("client.comments.unbanned_toast", {
            username: message.sender?.nickname,
          }),
          true,
        );
      } else {
        await channel.banUser(message.sender, 60000000, "Banned by operator");
        setIsBanned(true);
        displayToast(
          i18n.t("client.comments.banned_toast", {
            username: message.sender?.nickname,
          }),
          true,
        );
      }
    } catch (error) {
      handleSendbirdErrorResponse(error);
    }
  };

  const muteUser = async () => {
    try {
      if (isMuted) {
        await channel.unmuteUser(message.sender);
        setIsMuted(false);
        displayToast(
          i18n.t("client.comments.unmuted_toast", {
            username: message.sender?.nickname,
          }),
          true,
        );
      } else {
        await channel.muteUser(message.sender, 60000000, "Muted by operator");
        setIsMuted(true);
        displayToast(
          i18n.t("client.comments.muted_toast", {
            username: message.sender?.nickname,
          }),
          true,
        );
      }
    } catch (error) {
      handleSendbirdErrorResponse(error);
    }
  };

  const editMessage = async () => {
    setIsEditing(true);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
    return (
      <Button
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className={`message-action-button border-0 p-0 bg-none ${
          isMobile ? "" : "d-none"
        }`}
      >
        {children}
      </Button>
    );
  });

  const renderDropdown = () => {
    if (deleted) return null;
    return (
      <div className="actions-menu position-absolute top-0 end-0">
        <Dropdown>
          {!isEditing && (
            <Dropdown.Toggle as={CustomToggle}>
              <Menu />
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu>
            {!message.parentMessage && (
              <Dropdown.Item
                onClick={() =>
                  message.parentMessage
                    ? setThreadMessage(parentMessage)
                    : setThreadMessage(message)
                }
              >
                {i18n.t("client.comments.reply")}
              </Dropdown.Item>
            )}
            {isByMe && (
              <Dropdown.Item onClick={() => editMessage()}>
                {i18n.t("client.comments.edit")}
              </Dropdown.Item>
            )}
            {(isByMe || isOperator) && (
              <Dropdown.Item onClick={() => deleteMessage()}>
                {i18n.t("client.comments.delete")}
              </Dropdown.Item>
            )}
            {isOperator && !isByMe && !isChat && (
              <Dropdown.Item onClick={() => banUser()}>
                {isBanned
                  ? i18n.t("client.comments.unban")
                  : i18n.t("client.comments.ban")}
              </Dropdown.Item>
            )}
            {isOperator && !isByMe && !isChat && (
              <Dropdown.Item onClick={() => muteUser()}>
                {isMuted
                  ? i18n.t("client.comments.unmute")
                  : i18n.t("client.comments.mute")}
              </Dropdown.Item>
            )}
            {!isByMe && (
              <Dropdown.Item onClick={() => reportMessage()}>
                {i18n.t("client.comments.report")}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const handleEditSubmit = (e) => {
    setIsEditing(false);
    if (onSubmit) onSubmit();
  };

  const focusEditInput = () => {
    const element = document.querySelector(".message__edit-input textarea");
    element.focus();
    element.setSelectionRange(element.value.length, element.value.length);
  };

  const renderEditInput = () => {
    setTimeout(() => {
      focusEditInput();
    }, 100);

    return (
      <div className="message__edit-input">
        <CommentsMessageInput
          channel={channel}
          messageToUpdate={message}
          onSubmit={handleEditSubmit}
          user={user}
          isChat={isChat}
          setIsEditing={setIsEditing}
          isMobile={isMobile}
        />
      </div>
    );
  };

  const renderToast = () => {
    return (
      <ToastContainer className="toast-container position-fixed top-0 end-0 p-3 pt-6">
        <Toast
          className="toast"
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={2000}
          autohide
        >
          <Toast.Body>
            <div className="d-flex">
              <div className="d-flex gap-2 w-100 align-items-center">
                <i className="bi-info-circle-fill fs-3 text-success" />
                <span className="align-super px-1 mb-0 text-black toast-message-container">
                  {toastMessage}
                </span>
              </div>
              <div className="d-flex gap-2 align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                />
              </div>
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    );
  };

  const displayChatTime = () => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(message.createdAt);
  };

  const displayMessageWithMentions = () => {
    const messageText = message.mentionedMessageTemplate;
    const regex = /@\{[^{}]*\}|\s+|\S+/g;
    const parts = messageText.match(regex);
    let mentionedUsersObject = {};
    for (let user of message.mentionedUsers) {
      mentionedUsersObject[`@{${user.userId}}`] = user.nickname;
    }

    return (
      <>
        {parts.map((part) => (
          <>
            {part.startsWith("@") ? (
              <span className={"user-mention"}>
                {mentionedUsersObject[part]}
              </span>
            ) : (
              <>{part}</>
            )}
          </>
        ))}
      </>
    );
  };

  return (
    <div
      className={`message-container position-relative row m-0 px-0 ${
        isChat ? "" : "py-3"
      }`}
      id={message.messageId}
    >
      <ChatAvatar
        className="message__avatar col-1"
        width={35}
        height={35}
        src={message.sender?.profileUrl}
        alt="Profile Image"
      />
      <div className="col-10">
        <div className="message__sender">
          {isChat && isByMe
            ? i18n.t("client.comments.you")
            : message.sender?.nickname}
          {isChat && (
            <span className="message__timestamp">{displayChatTime()}</span>
          )}
        </div>
        <p
          className={`message__text ${isByMe ? "my-message" : ""} ${
            message.threadInfo?.replyCount > 0 ? "mb-2" : ""
          }`}
        >
          {deleted && `${i18n.t("client.comments.message_removed")} `}
          {!deleted && (
            <>
              {!isEditing &&
                message.mentionedMessageTemplate &&
                displayMessageWithMentions()}
              {!(isEditing || message.mentionedMessageTemplate) &&
                message.message}{" "}
              {isEditing && renderEditInput()}
            </>
          )}
          {!(isChat || isEditing) && (
            <span className="message__timestamp">
              <TimeAgo timestamp={message.createdAt} />
            </span>
          )}
          {!(isEditing || deleted) && message.updatedAt !== 0 && (
            <span className="message__edited ps-1">
              ({i18n.t("client.comments.edited")})
            </span>
          )}
        </p>
        {message.threadInfo?.replyCount > 0 && !isThreadParent && (
          <>
            {isChat &&
              message.threadInfo.mostRepliedUsers.map((user) => (
                <ChatAvatar
                  className="message-thread-avatar"
                  width={24}
                  height={24}
                  src={user.profileUrl}
                  alt="Thread Profile Image"
                  onClick={() => setThreadMessage(message)}
                />
              ))}
            <button
              className="comment-thread__count btn unstyled-btn scale-up-on-hover"
              onClick={() => setThreadMessage(message)}
            >
              {!isChat &&
                i18n.t("client.comments.view_replies", {
                  count: message.threadInfo.replyCount,
                })}
              {isChat &&
                i18n.t("client.comments.replies", {
                  count: message.threadInfo.replyCount,
                })}
            </button>
          </>
        )}
        {!isThreadParent && renderDropdown()}
        {showToast && renderToast()}
      </div>
    </div>
  );
}
