import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="profile-banner"
export default class extends Controller {
  closeBanner() {
    const bannerId = this.element.getAttribute("data-banner-id");
    if (bannerId) {
      document.cookie = `_hide_banner_${bannerId}=true; path=/; max-age=172800`; // 2 days
    }

    this.element.remove();
  }
}
