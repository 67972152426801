import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="online-course-access"
export default class extends Controller {
  static targets = [
    "downloadRadioButton",
    "urlRadioButton",
    "usernameRadioButton",
    "downloadFormSection",
    "urlFormSection",
    "usernameFormSection",
  ];

  connect() {
    this.downloadRadioButtonTarget.addEventListener("change", () => {
      this.downloadFormSectionTarget.classList.remove("d-none");
      this.urlFormSectionTarget.classList.add("d-none");
      this.usernameFormSectionTarget.classList.add("d-none");
    });

    this.urlRadioButtonTarget.addEventListener("change", () => {
      this.downloadFormSectionTarget.classList.add("d-none");
      this.urlFormSectionTarget.classList.remove("d-none");
      this.usernameFormSectionTarget.classList.add("d-none");
    });

    this.usernameRadioButtonTarget.addEventListener("change", () => {
      this.downloadFormSectionTarget.classList.add("d-none");
      this.urlFormSectionTarget.classList.add("d-none");
      this.usernameFormSectionTarget.classList.remove("d-none");
    });
  }
}
