import React from "react";

export default function ThreeDots() {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.625 15.125C6.24632 15.125 6.75 14.6213 6.75 14C6.75 13.3787 6.24632 12.875 5.625 12.875C5.00368 12.875 4.5 13.3787 4.5 14C4.5 14.6213 5.00368 15.125 5.625 15.125Z"
        fill="currentcolor"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 15.125C14.1213 15.125 14.625 14.6213 14.625 14C14.625 13.3787 14.1213 12.875 13.5 12.875C12.8787 12.875 12.375 13.3787 12.375 14C12.375 14.6213 12.8787 15.125 13.5 15.125Z"
        fill="currentcolor"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.375 15.125C21.9963 15.125 22.5 14.6213 22.5 14C22.5 13.3787 21.9963 12.875 21.375 12.875C20.7537 12.875 20.25 13.3787 20.25 14C20.25 14.6213 20.7537 15.125 21.375 15.125Z"
        fill="currentcolor"
        stroke="currentcolor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
