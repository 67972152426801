import { Controller } from "@hotwired/stimulus";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Comments from "../components/Comments";
import { getEnv } from "../utils/get-env";

class SendbirdCommentsController extends Controller {
  connect() {
    this.initSendbird();
  }

  disconnect() {
    const sendbirdCommentsContainer = document.getElementById(
      "sendbird-comments-container",
    );

    if (sendbirdCommentsContainer) {
      ReactDOM.unmountComponentAtNode(sendbirdCommentsContainer);
    }
  }

  initSendbird() {
    const sendbirdCommentsContainer = document.getElementById(
      "sendbird-comments-container",
    );

    if (sendbirdCommentsContainer) {
      const appId = getEnv("SENDBIRD_APP_ID");
      const latestComments = JSON.parse(this.data.get("latestComments"));
      const urlParams = new URL(window.location.href).searchParams;
      const messageIdFromUrl = urlParams.get("message_id");
      const messageCreatedAtFromUrl = urlParams.get("message_created_at");
      const parentMessageIdFromUrl = urlParams.get("parent_message_id");
      const startingMessageId = messageIdFromUrl
        ? Number(messageIdFromUrl)
        : null;
      const parentMessageId = parentMessageIdFromUrl
        ? Number(parentMessageIdFromUrl)
        : null;
      const startingMessageCreatedAt = messageCreatedAtFromUrl
        ? Number(messageCreatedAtFromUrl)
        : null;
      let user = null;
      let accessToken = null;

      if (this.data.get("user")) {
        user = JSON.parse(this.data.get("user"));
        accessToken = this.data.get("accessToken");
      }

      const appProps = {
        appId,
        profileId: this.data.get("profileId"),
        channelId: this.data.get("channelId"),
        userData: user,
        accessToken,
        latestComments,
        startingMessageId,
        startingMessageCreatedAt,
        parentMessageId,
        blockId: this.data.get("blockId"),
      };

      const App = React.createElement(Comments, appProps);
      ReactDOM.render(App, sendbirdCommentsContainer);
    }
  }
}

export default SendbirdCommentsController;
