import { Controller } from "@hotwired/stimulus";
import { debounce } from "../debounce";
import { getCsrfToken } from "../fetch";

const PASSWORD_CHECK_DEBOUNCE_DELAY = 400;
const PASSWORD_CONFIRM_DEBOUNCE_DELAY = 700;
const CONFIRMATION_NO_MATCH_MESSAGE =
  "Password confirmation doesn't match Password";

interface PasswordValidationResponse {
  success: boolean;
  message?: string;
}

const VALIDATION_STATES = [
  "valid",
  "invalid",
  "validating",
  "unknown",
] as const;
type ValidationState = (typeof VALIDATION_STATES)[number];

/**
 * Typeguard for checking that `input` implements `PasswordValidationResponse`.
 */
const isPasswordValidationResponse = (
  input: any,
): input is PasswordValidationResponse => {
  return (
    typeof input === "object" &&
    input !== null &&
    "success" in input &&
    typeof input["success"] === "boolean" &&
    ("message" in input ? typeof input["message"] === "string" : true)
  );
};

// Connects to data-controller="password-validation"
export default class PasswordValidationController extends Controller<HTMLElement> {
  static targets = ["passwordInput", "confirmationInput"];

  declare readonly passwordInputTarget: HTMLInputElement;

  declare readonly confirmationInputTarget: HTMLInputElement;
  declare readonly hasConfirmationInputTarget: boolean;

  // @ts-expect-error
  onPasswordInputDebounced: (...args: any[]) => void;

  // @ts-expect-error
  onConfirmationInputDebounced: (...args: any[]) => void;

  connect() {
    this.onPasswordInput = this.onPasswordInput.bind(this);
    this.onPasswordInputDebounced = debounce(
      this.onPasswordInput,
      PASSWORD_CHECK_DEBOUNCE_DELAY,
    );

    this.onConfirmationInput = this.onConfirmationInput.bind(this);
    this.onConfirmationInputDebounced = debounce(
      this.onConfirmationInput,
      PASSWORD_CONFIRM_DEBOUNCE_DELAY,
    );

    this.passwordInputTarget.addEventListener(
      "input",
      this.onPasswordInputDebounced,
    );

    if (this.hasConfirmationInputTarget) {
      this.confirmationInputTarget.addEventListener(
        "input",
        this.onConfirmationInputDebounced,
      );
    }
  }

  disconnect() {
    this.passwordInputTarget.removeEventListener(
      "input",
      this.onPasswordInputDebounced,
    );

    if (this.hasConfirmationInputTarget) {
      this.confirmationInputTarget.removeEventListener(
        "input",
        this.onConfirmationInputDebounced,
      );
    }
  }

  onPasswordInput(event: Event) {
    const password = (event.target as HTMLInputElement).value;

    this.setPasswordValidationState("validating");
    fetch("/validate_password", {
      method: "POST",
      body: JSON.stringify({ password }),
      headers: {
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (this.passwordInputTarget.value !== password) {
          // The input has changed while the request was in-flight,
          // so this response is out-of-date
          return;
        }

        if (!isPasswordValidationResponse(json)) {
          return;
        }

        this.setPasswordValidationState(
          json.success ? "valid" : "invalid",
          json.message,
        );
      })
      .catch((err) => {
        if (this.passwordInputTarget.value !== password) {
          return;
        }

        // The validation itself failed (network request failed, server
        // returned unexpected response, etc...). Set state to "unknown" which
        // will allow the form to submit and regular server validation and
        // rendering to occur
        this.setPasswordValidationState("unknown");
      });
  }

  onConfirmationInput(event: Event) {
    const password = this.passwordInputTarget.value;
    const confirmation = (event.target as HTMLInputElement).value;

    if (password !== confirmation) {
      this.setIsConfirmationValid(false);
      return;
    }

    this.setIsConfirmationValid(true);
  }

  setPasswordValidationState(state: ValidationState, message?: string) {
    this.setValidationState(this.passwordInputTarget, state, message);
  }

  setIsConfirmationValid(valid: boolean) {
    if (valid) {
      this.setValidationState(this.confirmationInputTarget, "valid");
    } else {
      this.setValidationState(
        this.confirmationInputTarget,
        "invalid",
        CONFIRMATION_NO_MATCH_MESSAGE,
      );
    }
  }

  setValidationState(
    target: HTMLElement,
    state: ValidationState,
    message?: string,
  ) {
    VALIDATION_STATES.forEach((name) => {
      target!.classList[state === name ? "add" : "remove"](`is-${name}`);
    });

    // Try to find an element that has any validation message.
    let messageTarget = target.parentElement!.querySelector(
      VALIDATION_STATES.map((name) => `.${name}-feedback`).join(", "),
    );

    if (!messageTarget && message) {
      // We have a message, but no element to put it in, so create a new one.
      messageTarget = document.createElement("div");
      target.parentElement?.appendChild(messageTarget);
    }

    if (messageTarget) {
      // Add the appropriate class, removing all others.
      VALIDATION_STATES.forEach((name) => {
        messageTarget!.classList[state === name ? "add" : "remove"](
          `${name}-feedback`,
        );
      });
      messageTarget.textContent = message || "";
    }
  }
}
