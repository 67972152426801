{
  "en": {
    "client": {
      "cart": {
        "items": {
          "one": "1 item",
          "other": "%{count} items"
        }
      },
      "comments": {
        "ban": "Ban",
        "banned_toast": "%{username} has been banned from this channel",
        "cancel": "Cancel",
        "delete": "Delete",
        "edit": "Edit",
        "edited": "edited",
        "follow": "Follow to add/view",
        "frozen_placeholder": "This channel is frozen",
        "header": "Comment",
        "hide_replies": "Hide replies",
        "input_placeholder": "Write a comment",
        "input_placeholder_chat": "Write a message",
        "loading": "Loading comments",
        "login": "Add or view comments",
        "message_removed": "Message removed",
        "mute": "Mute",
        "muted_placeholder": "You are muted in this channel",
        "muted_toast": "%{username} has been muted in this channel",
        "no_comments": "Be the first to leave a comment",
        "replies": {
          "one": "1 reply",
          "other": "%{count} replies"
        },
        "reply": "Reply",
        "reply_placeholder": "Reply to thread",
        "report": "Report",
        "reported_toast": "%{username} has been reported",
        "save": "Save",
        "unban": "Unban",
        "unbanned_toast": "%{username} has been unbanned from this channel",
        "unmute": "Unmute",
        "unmuted_toast": "%{username} has been unmuted in this channel",
        "view_more": "View more",
        "view_replies": "View replies (%{count})",
        "you": "You"
      },
      "community": {
        "add_channel": "Create new channel",
        "added_operator": "%{name} was added as an operator",
        "ban": "Ban",
        "banned_member": "%{name} was banned from this channel",
        "banned_title": "Banned users",
        "channel_frozen": "This channel was frozen",
        "channel_img_alt": "Channel image",
        "channel_name": "Channel name",
        "channel_preview": {
          "days_ago": {
            "one": "Yesterday",
            "other": "%{count} days ago",
            "zero": ""
          },
          "months_ago": {
            "one": "1 month ago",
            "other": "%{count} months ago",
            "zero": ""
          },
          "years_ago": {
            "one": "1 year ago",
            "other": "%{count} years ago",
            "zero": ""
          }
        },
        "channel_saved": "Channel saved",
        "channel_unfrozen": "This channel was unfrozen",
        "create": "Create",
        "edit": "edit",
        "edit_channel_info": "Edit channel information",
        "freeze_channel": "Freeze Channel",
        "frozen_channel": "Channel frozen",
        "leave_channel": "Are you sure you want to leave?",
        "loading_chat_channel": "Hold please! Chat loading...",
        "members_title": "Members",
        "modal_header": "Profile",
        "mute": "Mute",
        "muted_member": "%{name} was muted in this channel",
        "muted_title": "Muted members",
        "new_channel": "New Channel",
        "nickname": "Nickname",
        "no_banned_members": "No banned members yet",
        "no_chat_messages": "No messages just yet. Kick off the conversation!",
        "no_chat_messages_frozen": "No messages.",
        "no_muted_members": "No muted members yet",
        "nothing_in_chat": "The creator has yet to set up chat channels, check again soon!",
        "operators_title": "Operators",
        "profile_image": "Profile image",
        "profile_saved": "Profile saved",
        "register_operator": "Register as operator",
        "removed_operator": "%{name} was removed as an operator",
        "save": "Save",
        "something_went_wrong": "Something went wrong. Please try again",
        "something_went_wrong_support": "Something went wrong. Please contact support to resolve the issue",
        "submit": "Save",
        "thread_header_title": "Thread",
        "unbanned_member": "%{name} was added back into the channel",
        "unmute": "Unmute",
        "unmuted_member": "%{name} was unmuted in this channel",
        "unregister_operator": "Unregister as operator",
        "upload": "Upload new picture",
        "username": "Username"
      },
      "date_time_picker": {
        "clear": "Clear selection",
        "close": "Close the picker",
        "decrementHour": "Decrement Hour",
        "decrementMinute": "Decrement Minute",
        "decrementSecond": "Decrement Second",
        "incrementHour": "Increment Hour",
        "incrementMinute": "Increment Minute",
        "incrementSecond": "Increment Second",
        "nextCentury": "Next Century",
        "nextDecade": "Next Decade",
        "nextMonth": "Next Month",
        "nextYear": "Next Year",
        "pickHour": "Pick Hour",
        "pickMinute": "Pick Minute",
        "pickSecond": "Pick Second",
        "previousCentury": "Previous Century",
        "previousDecade": "Previous Decade",
        "previousMonth": "Previous Month",
        "previousYear": "Previous Year",
        "selectDate": "Select Date",
        "selectDecade": "Select Decade",
        "selectMonth": "Select Month",
        "selectTime": "Select Time",
        "selectYear": "Select Year",
        "today": "Go to today",
        "toggleMeridiem": "Toggle Meridiem"
      },
      "login_modal": {
        "login": "Login",
        "next": "Next"
      },
      "purchase": {
        "not_enough_points": "You don't have enough points for this points-only product",
        "points_needed": "Needed points: %{amount}",
        "this_is_points_only": "This is a points-only product!",
        "use_points": {
          "one": "Use %{amount} point",
          "other": "Use %{amount} points"
        },
        "you_have_points": "You have points, do you want to use them?"
      },
      "rewards": {
        "creator": {
          "analytics": {
            "count": "Count",
            "display": "Display",
            "filters": "Filter by",
            "metric_options": {
              "awards": "Awards",
              "challenges": "Challenges",
              "comments": "Comments",
              "follows": "Follows",
              "grants": "All grants",
              "groupMessages": "Group Messages",
              "likes": "Likes",
              "livestreamViews": "Livestream Views",
              "mentions": "Mentions",
              "redemptions": "Redemptions",
              "reposts": "Reposts",
              "sales": "Sales",
              "subscriptions": "Subscriptions",
              "tips": "Tips"
            },
            "metrics": "Metrics",
            "metrics_select_help_text": "Choose 1 or 2 metrics to display",
            "platform_options": {
              "instagram": "Instagram",
              "shopify": "Shopify",
              "tiktok": "TikTok",
              "twitter": "Twitter",
              "webspot": "WebSpot",
              "youtube": "YouTube"
            },
            "platforms": "Platform",
            "points": "Points",
            "range_options": {
              "days": {
                "one": "Past day",
                "other": "Last %{count} days"
              },
              "hours": {
                "one": "Past hour",
                "other": "Last %{count} hours"
              },
              "months": {
                "one": "Past month",
                "other": "Last %{count} months"
              },
              "weeks": {
                "one": "Past week",
                "other": "Last %{count} weeks"
              },
              "years": {
                "one": "Past year",
                "other": "Last %{count} years"
              }
            },
            "summary": {
              "awards": "Total awards",
              "challenges": "Total challenges",
              "comments": "Total comments",
              "follows": "Total follows",
              "grants": "Total grants",
              "groupMessages": "Total group messages",
              "likes": "Total likes",
              "livestreamViews": "Total livestream views",
              "mentions": "Total mentions",
              "redemptions": "Total redemptions",
              "reposts": "Total reposts",
              "sales": "Total sales",
              "subscriptions": "Total subscriptions",
              "tips": "Total tips"
            },
            "timeframes": {
              "last_30_days": "Last 30 days",
              "last_7_days": "Last 7 days",
              "last_90_days": "Last 90 days",
              "today": "Last 24 hours"
            },
            "title": "Analytics"
          },
          "settings": {
            "accept_points_on_all": "Accept points on all products",
            "award_settings": "Awards settings",
            "can_set_up_later": "You can set this up later on product blocks.",
            "dollar_value": "$1 USD",
            "enter_valid_number": "Please enter a valid number between 1 and 1,000,000",
            "error_message": "There was a problem with your request. Please try again.",
            "point_value_note": "Default is 1,000 = $1, but you can update at your discretion.",
            "points_value": "Value of your points",
            "social_media_accounts": "Social media accounts",
            "why_we_need_info": "Why we need this information?"
          }
        },
        "fan": {
          "settings": {
            "reminder": "As a reminder, we never sell your personal information or access your accounts. Security and permissions  are set by the platforms themselves.",
            "social_connections": "Social connections",
            "title": "Settings",
            "update_profile": "Update profile"
          }
        }
      },
      "tiktok_oauth": {
        "logged_in": "Logged in",
        "phone": {
          "max_attempts": "You have reached the maximum number of attempts, please try again later",
          "resend_code": "Resend Code",
          "title": "Login with Phone Number"
        },
        "qr": {
          "error": "There was an error scanning the QR code, please try again",
          "exceeded_polling_attempts": "Login failed, please regenerate the QR code and try again",
          "expired": "QR code expired, please generate new QR code",
          "scanned": "QR Code Scanned",
          "title": "Login with QR Code"
        }
      },
      "waitlist": {
        "joined_message": "I joined the WebSpot waitlist.  Come build your direct to the community and make money while doing it.  Check out the website to lock in your spot.",
        "joined_subject": "Join me on the WebSpot waitlist!",
        "problem_with_request": "There was a problem with your request. Please try again.",
        "required_fields": "Name, email, number of followers, and at least one handle is required to continue.",
        "submit": "Submit"
      }
    }
  }
}