const cartLoadingListItem = `
  <a class="list-group-item d-flex gap-3 py-3 border-0 disable-hover-color">
    <div style="width:48px; height:48px;" class="text-muted placeholder-glow">
      <span class="placeholder col-12 h-100 "></span>
    </div>

    <div class="d-flex gap-2 w-100 justify-content-between align-items-center">
      <div class="w-50 w-s-60 placeholder-glow">
        <p class="mb-0 lh-1">
          <small class="fw-bold">
            <span class="placeholder col-10 rounded"></span>
          </small>
        </p>
        <small class="mb-0 opacity-75">
          <span class="placeholder col-7 rounded"></span>
        </small>
      </div>

      <div class="w-50 w-s-40 text-align-last-justify placeholder-glow">
        <div class="d-inline">
          <small class="text-muted"><span class="placeholder col-1 rounded"></span></small>
        </div>
        <div
          class="ps-1 d-inline"
          style="vertical-align:text-top;">
          <span class="placeholder col-2 rounded"></span>
        </div>
      </div>
    </div>
  </a>
`;

const cartLoadingTemplate = `
  <div >
    <section class="px-28 pt-4">
      <h3>Cart</h3>
    </section>

    <section class="p-4">
      <div>
        <ul class="list-group w-auto">
          ${cartLoadingListItem}
          ${cartLoadingListItem}
          ${cartLoadingListItem}
        </ul>
        <hr class="opacity-25">
        <div class="text-end placeholder-glow">
          <p class="lh-1 mb-0"><small>Total</small> <small class="fw-bold" id="cart-total">
            <span class="placeholder col-2 rounded"></span>
          </small></p>
          <p class="font-size-xs opacity-50"><span class="placeholder col-10 rounded"></span></p>
        </div>
      </div>
    </section>
  </div>
`;

const productDetailsLoadingTemplate = `
  <div class="card" aria-hidden="true">
    <div>
      <div style="width:100%; height:450px;" class="placeholder-glow text-muted">
        <span class="placeholder col-12 h-100 "></span>
      </div>
      <section class="p-4">
        <h5 class="card-title placeholder-glow text-muted">
          <span class="placeholder col-6 rounded"></span>
        </h5>
        <p class="text-small text-muted placeholder-glow text-muted">
          <span class="placeholder col-7 rounded"></span>
        </p>
      </section>
      <p class="card-text placeholder-glow pb-4 ps-4 pe-4 text-muted">
        <span class="placeholder col-7 rounded"></span>
        <span class="placeholder col-4 rounded"></span>
        <span class="placeholder col-4 rounded"></span>
        <span class="placeholder col-6 rounded"></span>
        <span class="placeholder col-8 rounded"></span>
      </p>
    </div>
  </div>
`;
export { cartLoadingTemplate, productDetailsLoadingTemplate };
