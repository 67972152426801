import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

const MAX_CATEGORIES_SELECTED = 1;

export default class extends Controller {
  connect() {
    this.choice = new Choices(
      this.element.querySelector("#category-selector"),
      {
        position: "bottom",
        shouldSort: true,
        renderSelectedChoices: "always",
        itemSelectText: "",
        allowHTML: true,
        resetScrollPosition: false,
        searchFields: ["label"],
        searchResultLimit: 10000, // show all results
        placeholderValue: "Type to search",
      },
    );
  }
}
