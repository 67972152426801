import { Controller } from "@hotwired/stimulus";
import { Toast } from "bootstrap";
import { post } from "../fetch";
import { addLoadingSpinnerToFormButton, Toaster } from "../utilities";
import i18n from "../utils/i18n";

export default class extends Controller {
  static values = {
    productType: String,
    productId: String,
    toastId: String,

    cartItemId: String,

    addItemPath: String,
    removeItemPath: String,
    allowUpdatePurchaseWithPoints: Boolean,
  };

  add(e) {
    const productType = this.productTypeValue;
    const productId = this.productIdValue;

    post(this.addItemPathValue, {
      productType,
      productId,
    })
      .then((res) => res.json())
      .then((res) => {
        const toastLiveExample = document.getElementById(
          `add-toast-${this.toastIdValue}`,
        );

        const toast = new Toast(toastLiveExample);

        updateCartCount(res.cart_count);
        handleCartDisplay(res.cart_count);

        toast.show();
      })
      .catch((err) => {
        Toaster.error(err.message, { delay: 3000 });
      });
  }

  remove() {
    const el = this.element;

    post(this.removeItemPathValue, {
      cartItemId: this.cartItemIdValue,
    })
      .then((res) => res.json())
      .then((res) => {
        el.nextElementSibling.remove();
        el.remove();

        const updatedTotal = res.updated_total;
        document.getElementById("cart-total").innerHTML = updatedTotal;
        document.getElementById("cart-total-mobile").innerHTML = updatedTotal;

        const cartPriceElement = document.getElementById("cart-price");
        if (cartPriceElement) {
          cartPriceElement.innerHTML = updatedTotal;
        }
        const remainingPriceElement =
          document.getElementById("remaining-price");
        if (remainingPriceElement) {
          remainingPriceElement.innerHTML = updatedTotal;
        }
        const purchaseAmountElement = document.getElementById(
          "purchase-with-points-amount",
        );
        if (purchaseAmountElement) {
          purchaseAmountElement.value = res.updated_points_total;
        }

        updateCartCount(res.cart_count);
        handleCartDisplay(res.cart_count);
        togglePurchaseWithPoints(
          res.enable_purchase_with_points,
          res.require_purchase_with_points_only,
        );
      });
  }

  clickPurchaseWithPointsCheckbox() {
    const el = this.element;

    updateCartTotal(el.checked);
  }

  onIdSelectChange(e) {
    this.productIdValue = e.target.value;
  }

  onClickCheckout(e) {
    if (this.allowUpdatePurchaseWithPointsValue)
      updatePurchaseWithPointsField();
    addLoadingSpinnerToFormButton(e);
  }
}

const updatePurchaseWithPointsField = () => {
  const purchaseWithPointsHidden = document.getElementById(
    "purchase-with-points-hidden",
  );
  const purchaseWithPointsCheckbox = document.getElementById(
    "purchase-with-points-checkbox",
  );
  if (!purchaseWithPointsHidden || !purchaseWithPointsCheckbox) return;

  purchaseWithPointsHidden.value = purchaseWithPointsCheckbox.checked
    ? "1"
    : "0";
};

const updateCartCount = (count) => {
  const cartBadgeElement = document.getElementById("cart-badge");
  const cartNavLink = document.getElementById("cart-link");

  if (count === 0) {
    cartBadgeElement.classList.add("d-none");
    cartNavLink.classList.add("d-none");
  } else {
    cartBadgeElement.classList.remove("d-none");
    cartBadgeElement.innerHTML = count;
    cartNavLink.classList.remove("d-none");
  }

  const cartItemNum = document.getElementById("cart-num-items");
  if (cartItemNum) {
    cartItemNum.innerHTML = i18n.t("client.cart.items", { count: count });
  }
};

const handleCartDisplay = (count) => {
  const cartEmptyMessageElement = document.getElementById("cart-empty-message");
  const cartItemsListElement = document.getElementById("cart-items-list");

  if (cartEmptyMessageElement) {
    if (count === 0) {
      // handle cart body state
      cartEmptyMessageElement.classList.remove("d-none");
    } else {
      // handle cart body state
      cartEmptyMessageElement.classList.add("d-none");
    }
  }

  if (cartItemsListElement) {
    if (count === 0) {
      // handle cart body state
      cartItemsListElement.classList.add("d-none");
    } else {
      // handle cart body state
      cartItemsListElement.classList.remove("d-none");
    }
  }
};

const togglePurchaseWithPoints = (
  enable_purchase_with_points,
  require_purchase_with_points_only,
) => {
  const purchasePointsSection = document.getElementById(
    "purchase-with-points-section",
  );
  const purchaseWithPointsCheckbox = document.getElementById(
    "purchase-with-points-checkbox",
  );
  if (!purchasePointsSection || !purchaseWithPointsCheckbox) return;

  if (enable_purchase_with_points === true) {
    if (require_purchase_with_points_only === true) {
      updateCartTotal(true);
      purchaseWithPointsCheckbox.checked = true;
      purchaseWithPointsCheckbox.disabled = true;
    } else {
      purchaseWithPointsCheckbox.checked = false;
      purchaseWithPointsCheckbox.disabled = false;
    }
    purchasePointsSection.classList.remove("d-none");
  } else {
    purchaseWithPointsCheckbox.checked = false;
    purchasePointsSection.classList.add("d-none");
  }
};

const updateCartTotal = (usePoints) => {
  const cartPriceElement = document.getElementById("cart-price");
  const cartTotalElement = document.getElementById("cart-total");
  const cartTotalMobileElement = document.getElementById("cart-total-mobile");
  const remainingPriceElement = document.getElementById("remaining-price");
  if (!cartPriceElement) return;

  const cartTotalPriceCents = cartPriceElement.innerHTML;
  if (usePoints === true) {
    if (remainingPriceElement) {
      remainingPriceElement.innerHTML = "$0.00";
    }
    if (cartTotalElement) {
      cartTotalElement.innerHTML = "$0.00";
    }
    if (cartTotalMobileElement) {
      cartTotalMobileElement.innerHTML = "$0.00";
    }
  } else {
    if (remainingPriceElement) {
      remainingPriceElement.innerHTML = cartTotalPriceCents;
    }
    if (cartTotalElement) {
      cartTotalElement.innerHTML = cartTotalPriceCents;
    }
    if (cartTotalMobileElement) {
      cartTotalMobileElement.innerHTML = cartTotalPriceCents;
    }
  }
};
