import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";
import { loadingSpinner } from "../utilities";

// Connects to data-controller="form-helpers"
export default class extends Controller {
  static debounces = ["fetchUrlDetails"];
  static targets = ["blockPrivacy"];
  static values = { profileSubdomain: String };

  connect() {
    useDebounce(this, { wait: 600 });
    this.changeFreeForMembershipVisibility();
  }

  onClickUpdate(e) {
    // Eject if button already has a spinner
    if (
      e.currentTarget.children.length > 0 &&
      e.currentTarget.children[0].classList.contains("spinner-border")
    )
      return;

    e.target.insertAdjacentHTML("afterbegin", loadingSpinner("me-2"));
  }

  fetchUrlDetails(e) {
    return this.getAndSetUrlDetails(e);
  }

  getAndSetUrlDetails(e) {
    if (!e.target.value || e.target.value === "") {
      return;
    }
    displayLoadingSpinner();
    fetch(
      `/hub/${this.profileSubdomainValue}/forms/url_details?${new URLSearchParams([["url", e.target.value]])}`,
    )
      .then((res) => res.json())
      .then((response) => {
        hideLoadingSpinner();

        if (response.error) {
          if (hasUploadedFile() || hasUploadedImage()) {
            showPreview();
          } else {
            showPlaceholder();
          }
          let fallback = { best_title: "No title found", host: "" };
          updateFormTitleElement(fallback.best_title);
          updatePreviewTitles(fallback);
        } else {
          if (response.image?.startsWith("https")) {
            setImagePreview(response.image);
            showPreview();
            updateImagePreviewFromUrl(response.image);
          } else {
            if (hasUploadedFile() || hasUploadedImage()) {
              showPreview();
            } else {
              showPlaceholder();
            }
          }

          updateFormElements(response);
          updatePreviewTitles(response);
        }
      });
  }

  updatePreviewTitle(e) {
    document.getElementById("title-placeholder").innerText = e.target.value;
  }

  triggerFileUpload() {
    document.getElementById("link_image_file_input").click();
  }

  uploadedFileChanged(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = function () {
      setImagePreview(reader.result);
      displayImagePreview();
      document.getElementById("link_image_url_input").value = null;
    };

    reader.readAsDataURL(file);
  }

  maybeResetPrice(e) {
    if (e.target.value === "0.0") e.target.value = "";
  }

  changeFreeForMembershipVisibility() {
    if (this.hasBlockPrivacyTarget) {
      const blockPrivacy = this.blockPrivacyTarget.value;
      const oneTimePurchaseElement = document.getElementById(
        "one-time-purchase-section",
      );
      oneTimePurchaseElement &&
        oneTimePurchaseElement.classList.toggle(
          "d-none",
          !blockPrivacy.startsWith("tier"),
        );
    }
  }
}

const hasUploadedFile = () => {
  const fileInput = document.getElementById("link_image_file_input");
  return fileInput.files.length > 0;
};

const hasUploadedImage = () => {
  let src = document
    .getElementById("image-preview-wrapper")
    .getAttribute("src");
  return src && src !== "";
};

/* Update Previews from response Helpers */
const updateImagePreviewFromUrl = (url) =>
  (document.getElementById("link_image_url_input").value = url);

const updateFormElements = (response) => {
  // set image url and title from response
  document.getElementById("link_url_input").value = response.url;
  updateFormTitleElement(response.best_title);

  // clear out uploaded file if there are any
  document.getElementById("link_image_file_input").value = null;
};
const updateFormTitleElement = (value) => {
  document.getElementById("link_title_input").value = value;
};

const updatePreviewTitles = (response) => {
  document.getElementById("title-placeholder").innerText = response.best_title;
  document.getElementById("subtitle-placeholder").innerText = response.host;
};

/* UI Element helpers */
const hidePlaceholder = () =>
  document.getElementById("image-preview-placeholder").classList.add("d-none");
const showPlaceholder = () =>
  document
    .getElementById("image-preview-placeholder")
    .classList.remove("d-none");

const hidePreview = () =>
  document.getElementById("image-preview-wrapper").classList.add("d-none");
const showPreview = () =>
  document.getElementById("image-preview-wrapper").classList.remove("d-none");

const hideLoadingSpinner = () => {
  document.getElementById("image-preview-spinner").classList.add("d-none");
  const inputLoadingSpinner = document.getElementById("input-hint-loader");
  const inputHint = document.getElementById("input-hint-icon");
  if (inputLoadingSpinner && inputHint) {
    inputLoadingSpinner.classList.add("d-none");
    inputHint.classList.remove("d-none");
  }
};

const showLoadingSpinner = () => {
  document.getElementById("image-preview-spinner").classList.remove("d-none");
  const inputLoadingSpinner = document.getElementById("input-hint-loader");
  const inputHint = document.getElementById("input-hint-icon");
  if (inputLoadingSpinner && inputHint) {
    inputLoadingSpinner.classList.remove("d-none");
    inputHint.classList.add("d-none");
  }
};

const setImagePreview = (imageUrl) => {
  document.getElementById("image-preview-wrapper").src = imageUrl;
  document.getElementById("image-preview").src = imageUrl;
};

/* Composed UI Element helpers */
const displayLoadingSpinner = () => {
  hidePlaceholder();
  hidePreview();
  showLoadingSpinner();
};

const displayImagePreview = () => {
  hideLoadingSpinner();
  hidePlaceholder();
  showPreview();
};
