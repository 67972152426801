import { Controller } from "@hotwired/stimulus";
import * as React from "react";
import * as ReactDOM from "react-dom";
import FrigadeApp, { FrigadeAppProps } from "../components/Frigade";
import { getEnv } from "../utils/get-env";
import { getConfig } from "../components/Frigade/constants";

class OnboardingController extends Controller {
  initialize() {
    this.initFrigade();
  }

  initFrigade() {
    const frigadeContainer = document.getElementById(
      this.data.get("containerId") ?? "frigade-container",
    );
    const apiKey = getEnv("FRIGADE_PUBLIC_API_KEY");
    const config = getConfig();

    const currentUserId = this.data.get("currentUserId");
    if (frigadeContainer && apiKey && currentUserId) {
      const appProps = {
        apiKey: apiKey,
        userId: currentUserId,
        profile: JSON.parse(this.data.get("profile") ?? "{}"),
        isCreator: this.data.get("isCreator") === "true",
        socialLinks: JSON.parse(this.data.get("socialLinks") ?? "[]"),
        blocks: JSON.parse(this.data.get("blocks") ?? "[]"),
        formAuthenticityToken: this.data.get("formAuthenticityToken"),
        flowIdCreatorOnboardingForm:
          config["FRIGADE_FLOW_ID_CREATOR_ONBOARDING_FORM"],
        flowIdCreatorAnnouncement:
          config["FRIGADE_FLOW_ID_CREATOR_ANNOUNCEMENT"],
        flowIdCreatorTourDesktop:
          config["FRIGADE_FLOW_ID_CREATOR_TOUR_DESKTOP"],
        flowIdCreatorTourMobile: config["FRIGADE_FLOW_ID_CREATOR_TOUR_MOBILE"],
        flowIdCreatorChecklist: config["FRIGADE_FLOW_ID_CREATOR_CHECKLIST"],
        showOnboardingForm: this.data.get("showOnboardingForm") === "true",
        showChecklist: this.data.get("showChecklist") === "true",
        showAnnouncementsAndTours:
          this.data.get("showAnnouncementsAndTours") === "true",
        enableSocialLogin: this.data.get("enableSocialLogin") === "true",
      } as FrigadeAppProps;

      const App = React.createElement(FrigadeApp, appProps);

      ReactDOM.render(App, frigadeContainer);
    }
  }
}

export default OnboardingController;
