import { Controller } from "@hotwired/stimulus";
import { Toast } from "bootstrap";

// Connects to data-controller="toast"
export default class ToastController extends Controller {
  connect() {
    const toast = Toast.getOrCreateInstance(this.element);
    if (!this.element.classList.contains("hide")) {
      toast.show();
    }
  }

  hide() {
    const toast = Toast.getInstance(this.element);
    if (toast) {
      toast.hide();
    }
  }
}
