// @ts-check
import Trix from "trix";

// @ts-expect-error
window.Trix = Trix;

const { lang } = Trix.config;
Trix.config.toolbar.getDefaultHTML = toolbarDefaultHTML;

const updateToolbars = () => {
  const toolbars = document.querySelectorAll("trix-toolbar");
  const html = Trix.config.toolbar.getDefaultHTML();
  toolbars.forEach((toolbar) => (toolbar.innerHTML = html));
};

document.addEventListener("trix-initialize", updateToolbars, { once: true });

window.addEventListener("trix-file-accept", (event) => {
  event.preventDefault();
  alert("File attachment not supported");
});

Trix.config.textAttributes.underline = {
  tagName: "u",
};

function toolbarDefaultHTML() {
  return `
    <div class="trix-button-row">
      <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
        <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${lang.heading1}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">#{lang.bold}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${lang.italic}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${lang.bullets}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${lang.numbers}</button>
        <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${lang.link}</button>
      </span>
    </div>
    <div class="trix-dialogs" data-trix-dialogs>
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
        <div class="trix-dialog__link-fields">
          <input type="url" name="href" class="trix-input trix-input--dialog parliament-input" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" data-trix-input>
          <div>
            <input type="button" class="btn btn-sm btn-dark" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="btn btn-sm btn-dark" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    </div>
  `;
}
