import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="signup-first-action"
export default class SignupFirstActionController extends Controller<HTMLElement> {
  static targets = ["submitButton"];

  declare readonly submitButtonTarget: HTMLInputElement;

  onDomainValidationStateChange(event: CustomEvent) {
    const { state } = event.detail;
    this.submitButtonTarget.disabled = state !== "valid" && state !== "unknown";
  }
}
