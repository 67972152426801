import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import i18n from "../../utils/i18n";
import { fetchJson } from "../../fetch";
import CloseCircle from "../icons/CloseCircle";
import ChatAvatar from "./ChatAvatar";

function EditProfileModal({ sb, show, onHide, user, setUser, onSubmit }) {
  if (!user) return null;

  const [username, setUsername] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("user[username]", username);

    if (profileImage) {
      formData.append("user[avatar]", profileImage);
    }

    try {
      await fetchJson(`/users/${user.id}`, {
        method: "PATCH",
        body: formData,
      }).then((response) => {
        if (response.status !== 200) {
          if (response.errors.username) {
            let errorHtml = "";
            response.errors.username.forEach((error) => {
              errorHtml += `<p>Username ${error}</p>`;
            });

            let errorDiv = document.getElementById("edit-profile-modal-errors");
            errorDiv.innerHTML = errorHtml;
            errorDiv.classList.remove("d-none");
          }

          throw new Error("Form errors");
        }
        setUser(response);
      });

      const params = {
        nickname: username,
        profileImage: profileImage,
      };
      sb.updateCurrentUserInfo(params).then(() => {
        setIsLoading(false);
        onSubmit();
        onHide();
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  //Bootstrap hijacking focus on modal so this will stop it
  function bypassFocus() {
    const modal = document.getElementById("community-edit-profile-modal");
    if (modal) {
      modal.addEventListener("focusin", (e) => e.stopPropagation());
    }
  }

  useEffect(() => {
    bypassFocus();
  }, [show]);

  useEffect(() => {
    setUsername(user.username);
  }, [user.username]);

  useEffect(() => {
    return () => {
      if (profileImage) {
        URL.revokeObjectURL(profileImage);
      }
    };
  }, [profileImage]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) setProfileImage(file);
  };

  const dummyFocus = () => {
    document.getElementById("dummy-focus-element").focus();
  };

  useEffect(() => {
    dummyFocus();

    // focus on dummy element to fix iOS issue with laggy file uploader
    fileInputRef.current.addEventListener("cancel", dummyFocus);
  }, []);

  const imageUrl = profileImage
    ? URL.createObjectURL(profileImage)
    : user.avatar_url;

  return (
    <Modal
      show={show}
      onHide={onHide}
      id={"community-edit-profile-modal-container"}
    >
      <div id="community-edit-profile-modal">
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => onHide()}
            aria-label="Close"
          >
            <CloseCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title>{i18n.t("client.community.modal_header")}</Modal.Title>
          <form onSubmit={handleSubmit}>
            <div
              id={"edit-profile-modal-errors"}
              className={"errors text-danger d-none"}
            />
            <div className="mb-4">
              <div className="d-flex align-items-center">
                <ChatAvatar
                  className="chat-header__avatar"
                  width={80}
                  height={80}
                  src={imageUrl}
                  alt="Profile Image"
                />
                <label
                  htmlFor="profile_image"
                  className="avatar-upload-button btn btn-lg bg-gray ms-3"
                >
                  {i18n.t("client.community.upload")}
                </label>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="form-control visually-hidden"
                  id="profile_image"
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </div>
            </div>
            {/* Dummy element for phantom focus */}
            <div
              tabIndex={-1}
              id="dummy-focus-element"
              style={{ position: "absolute", opacity: 0 }}
            />
            <div className="mb-4 parliament-input">
              <label htmlFor="username" className="form-label">
                {i18n.t("client.community.username")}
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                value={username}
                autoComplete="off"
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="d-flex">
              <button
                type="submit"
                disabled={isLoading}
                className="btn btn-lg btn-white w-50"
              >
                {!isLoading && i18n.t("client.community.submit")}
                {isLoading && <div className="spinner-border" role="status" />}
              </button>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default EditProfileModal;
