import { Controller } from "@hotwired/stimulus";
import { Offcanvas } from "bootstrap";
import { first } from "lodash";
import invariant from "tiny-invariant";
import { assert } from "../utils/asserts";
import BlockFormController from "./block_form_controller";
import FileUploadController from "./file_upload_controller";
import VideoFormController from "./video_form_controller";

type Status =
  | "hide-enabled"
  | "hide-blocked"
  | "hide-on-next-success"
  | "unblock-hide-on-next-success"
  | "overlay-shown"
  | "uploading";

// Connects to data-controller="drawer"
export default class DrawerController extends Controller {
  static targets = [
    "dirtyForm",
    "autoClosingForm",
    "discardPrompt",
    // backPrompt is an optional target for showing a confirmation modal
    // on a form that includes a back button
    "backPrompt",
    "fileUploadWarningDialog",
    "offcanvasFrame",
    "deletePageDialog",
    "deletePageForm",
  ];
  declare readonly discardPromptTarget: HTMLElement;
  declare readonly backPromptTarget?: HTMLElement;
  declare readonly autoClosingFormTarget: HTMLElement;
  declare readonly dirtyFormTarget: HTMLElement;
  declare readonly fileUploadWarningDialogTarget: HTMLDivElement;
  declare readonly hasOffcanvasFrameTarget: boolean;
  declare readonly offcanvasFrameTarget: HTMLDivElement;
  declare readonly deletePageDialogTarget: HTMLDivElement;
  declare readonly deletePageFormTarget: HTMLFormElement;

  static outlets = ["video-form", "file-upload"];

  declare readonly hasVideoFormOutlet: boolean;
  declare readonly videoFormOutlet: VideoFormController;

  declare readonly hasFileUploadOutlet: boolean;
  declare readonly fileUploadOutlet: FileUploadController;

  offcanvas: Offcanvas | undefined;

  status: Status = "hide-enabled";

  connect() {
    this.status = "hide-enabled";
    this.offcanvas = Offcanvas.getOrCreateInstance(this.element);

    this.element.addEventListener("hide.bs.offcanvas", (ev) => {
      if (this.status === "uploading") {
        ev.preventDefault();
        this.showFileUploadWarningDialog();

        this.status = "overlay-shown";
        return;
      }

      if (this.status === "hide-blocked" || this.status === "overlay-shown") {
        ev.preventDefault();
        this.showDiscardOverlay();
        this.status = "overlay-shown";
        return;
      }

      if (this.hasVideoFormOutlet) {
        this.videoFormOutlet.onDrawerHide();
      }
    });

    this.element.addEventListener("hidden.bs.offcanvas", () => {
      if (this.hasOffcanvasFrameTarget) {
        // removes all child nodes
        this.offcanvasFrameTarget.innerHTML = "";
      }
    });
  }

  cancelUpload() {
    this.fileUploadOutlet.cancelUpload();
    this.hide();
  }

  showFileUploadWarningDialog() {
    this.dirtyFormTarget.classList.add("pe-none", "opacity-50");
    this.fileUploadWarningDialogTarget.classList.add("show");
    this.fileUploadWarningDialogTarget.classList.remove("d-none");
    let firstButton =
      this.fileUploadWarningDialogTarget.querySelector("button");
    if (firstButton) {
      firstButton.focus();
    }
  }

  hideFileUploadWarningDialog() {
    this.dirtyFormTarget.classList.remove("pe-none", "opacity-50");
    this.fileUploadWarningDialogTarget.classList.remove("show");
    this.fileUploadWarningDialogTarget.classList.add("d-none");
  }

  onUploadStart() {
    this.status = "uploading";
  }

  onUploadEnd() {
    this.hideFileUploadWarningDialog();
    this.status = "hide-enabled";
  }

  onClickLogin(e: Event) {
    e.preventDefault();
    const targetElement = e.target as HTMLElement;
    const drawerLogin = document.getElementById("drawer-login");
    if (drawerLogin) {
      const loginParams = targetElement.getAttribute("data-login-params");
      if (loginParams) {
        drawerLogin.setAttribute("data-login-params", loginParams);
      }
      drawerLogin.click();
    }
  }

  dismissFileUploadWarningDialog() {
    this.hideFileUploadWarningDialog();
    this.status = "uploading";
  }

  showDiscardOverlay() {
    this.dirtyFormTarget.classList.add("pe-none", "opacity-50");
    this.discardPromptTarget.classList.remove("d-none");
    this.discardPromptTarget.classList.add("show");
  }

  closeDiscardPrompt() {
    this.dirtyFormTarget.classList.remove("pe-none", "opacity-50");
    this.discardPromptTarget.classList.add("d-none");
    this.discardPromptTarget.classList.remove("show");
    this.status = "hide-blocked";
  }

  showBackOverlay(e: Event) {
    if (this.status !== "hide-blocked") return;

    e.preventDefault();
    assert(this.backPromptTarget);
    this.showDialogue(this.backPromptTarget);
  }

  closeBackPrompt() {
    assert(this.backPromptTarget);
    this.hideDialogue(this.backPromptTarget);
    this.status = "hide-blocked";
  }

  blockHide() {
    this.status = "hide-blocked";
  }

  hideOnNextSuccess() {
    this.status = "hide-on-next-success";
  }

  unblockHideOnNextSuccess() {
    this.status = "unblock-hide-on-next-success";
  }

  hide() {
    invariant(this.offcanvas, "expected offcanvas");
    this.status = "hide-enabled";
    this.offcanvas.hide();
  }

  autoClosingFormTargetConnected(element: HTMLFormElement) {
    invariant(this.offcanvas, "expected offcanvas");
    element.addEventListener("turbo:submit-end", this.handleFormResponse);
  }

  autoClosingFormTargetDisconnected(element: HTMLFormElement) {
    invariant(this.offcanvas, "expected offcanvas");
    element.removeEventListener("turbo:submit-end", this.handleFormResponse);
  }

  handleFormResponse = (event: any) => {
    invariant(this.offcanvas, "expected offcanvas");

    let previousStatus = this.status;
    this.status = "hide-enabled";

    if (previousStatus === "unblock-hide-on-next-success") {
      // noop
      return;
    }

    if (previousStatus === "hide-on-next-success") {
      this.offcanvas.hide();
      return;
    }

    if (event.detail.success) {
      this.parseSuccessEvent(event);
    }
  };

  parseSuccessEvent(event: any) {
    const pathname = event.detail.formSubmission.location.pathname;
    const search = event.detail.formSubmission.location.search;
    const blockId = event.detail.formSubmission.formElement.querySelector(
      'input[name="block[id]"]',
    )?.value;

    // @ts-ignore
    const offcanvasElement = this.offcanvas._element;

    const currentOffcanvasContentPath =
      offcanvasElement.querySelector("turbo-frame")?.src;
    const currentContentIsEditForm =
      currentOffcanvasContentPath?.includes("/edit/block/");

    const isUpdateDraft =
      pathname === "/edit/update_status" && search === "?status=draft";
    const isUpdateGrid =
      pathname === "/edit/update_status" && search === "?status=grid";
    const isDelete = pathname.includes("delete");

    if (isUpdateDraft) {
      if (currentContentIsEditForm) {
        clickDraftsButton();
      }

      andThenScrollTo("#draft-section");
    } else if (isUpdateGrid) {
      this.offcanvas?.hide();

      andThenScrollTo(`.grid-stack-item[data-block-id="${blockId}"]`);
    } else if (isDelete) {
      if (currentContentIsEditForm) {
        clickDraftsButton();
        andThenScrollTo("#archive-section");
      } else {
        this.offcanvas?.hide();
      }
    } else {
      this.offcanvas?.hide();
    }
  }

  showPageDeleteDialog(ev: Event & { params: { action: string } }) {
    this.deletePageFormTarget.action = ev.params.action;
    this.showDialogue(this.deletePageDialogTarget);
  }

  dismissPageDeleteDialog() {
    this.deletePageFormTarget.action = "";
    this.hideDialogue(this.deletePageDialogTarget);
  }

  showDialogue(deletePageDialogTarget: HTMLElement) {
    this.dirtyFormTarget.classList.add("pe-none", "opacity-50");
    deletePageDialogTarget.classList.remove("d-none");
    deletePageDialogTarget.classList.add("show");
  }

  hideDialogue(dialogue: HTMLElement) {
    this.dirtyFormTarget.classList.remove("pe-none", "opacity-50");
    dialogue.classList.add("d-none");
    dialogue.classList.remove("show");
  }
}

const clickDraftsButton = () => {
  // @ts-ignore
  document.querySelector("#drafts-button")?.click();
};

const andThenScrollTo = (querySelector: string) => {
  console.info(`scrolling to ${querySelector}`);
  setTimeout(() => {
    document
      .querySelector(querySelector)
      ?.scrollIntoView({ behavior: "smooth" });
  }, 500);
};
