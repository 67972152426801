import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="synced-field"
export default class extends Controller {
  static values = {
    destination: String,
  };
  declare readonly destinationValue: string;

  connect() {
    this.syncField();
  }

  update() {
    this.syncField();
  }

  syncField() {
    let value = (this.element as HTMLInputElement).value;
    for (let input of document.querySelectorAll(this.destinationValue)) {
      (input as HTMLInputElement).value = value;
    }
  }
}
