import React, { useState, useEffect } from "react";
import { IconTrashX } from "@tabler/icons-react";
import { FormInputProps } from "@frigade/react";
import { blockWrapperStyle } from "./OnboardingFormStyles";
import { Plus } from "../icons/Plus";
import { getCsrfToken } from "../../../fetch";

interface Block {
  id: string;
  url: string;
  title: string;
}

const INITIAL_BLOCK = {
  id: "",
  url: "",
  title: "",
};

const BlockComponent = ({
  subdomain,
  initialBlock,
}: {
  subdomain: string;
  initialBlock: Block;
}) => {
  const [block, setBlock] = useState<Block>(initialBlock);
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(false);

  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  async function handleChange(value: string) {
    value = value.trim();
    if (value === "") {
      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(async () => {
      setLoading(true);
      const response = await fetch(
        `/hub/${subdomain}/edit/block/create_onboarding_link_block`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": getCsrfToken(),
          },
          body: JSON.stringify({
            url: value,
          }),
        },
      );

      if (response.ok) {
        const data = await response.json();

        if (!data.id || !data.url || !data.best_title) {
          setLoading(false);
          throw new Error("Not a valid URL");
        }

        setBlock({
          id: data.id,
          url: data.url,
          title: data.best_title,
        });
        setLoading(false);
      } else {
        throw new Error("Something went wrong");
      }
    }, 800);
  }

  async function handleDelete() {
    fetch(`/hub/${subdomain}/edit/block/${block.id}/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getCsrfToken(),
      },
    }).then((response) => {
      if (response.ok) {
        setBlock(INITIAL_BLOCK);
      } else {
        throw new Error("Something went wrong");
      }
    });
  }

  const renderBlock = () => {
    if (block.id && block.title && block.url) {
      return (
        <div className="w-100 mb-3 p-2 parliament-input rounded-8px position-relative">
          <div className="row">
            <div className="col-10">
              <span
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                {hovered ? block.url : block.title}
              </span>
            </div>
            <div className="col-2 d-flex justify-content-end">
              <IconTrashX
                size={26}
                className="cursor-pointer"
                onClick={handleDelete}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <span className="w-100 position-relative">
          <input
            className="w-100 mb-3 p-2 parliament-input rounded-8px"
            placeholder="Paste URL here"
            disabled={loading}
            onChange={(e) => handleChange(e.target.value)}
          />
          {loading && (
            <div
              className="spinner-border absolute-top-5-right-10"
              role="status"
            />
          )}
        </span>
      );
    }
  };

  return <>{renderBlock()}</>;
};

export function Blocks({
  formInput,
  customFormTypeProps,
  onSaveInputData,
  inputData,
  setFormValidationErrors,
}: FormInputProps) {
  const [blocks, setBlocks] = useState<Array<Block>>(
    Array(6).fill(INITIAL_BLOCK),
  );

  const profile = customFormTypeProps?.stepData?.props?.profile
    ? JSON.parse(customFormTypeProps.stepData.props.profile)
    : {};
  const subdomain = profile.subdomain;

  useEffect(() => {
    const profileBlocks = customFormTypeProps?.stepData?.props?.blocks
      ? JSON.parse(customFormTypeProps.stepData.props.blocks)
      : [];

    if (!profileBlocks.length) {
      return;
    }
    setBlocks([]);
    const addBlocks = profileBlocks.length < 6 ? 6 - profileBlocks.length : 0;
    const additionalBlocks = Array(addBlocks).fill(INITIAL_BLOCK);
    const newBlocks = [...profileBlocks, ...additionalBlocks];
    setBlocks(newBlocks);
  }, [customFormTypeProps?.stepData?.props?.blocks]);

  function addLink() {
    setBlocks([...blocks, INITIAL_BLOCK]);
    requestAnimationFrame(() => {
      const container = document.getElementsByClassName("fr-formContent")[0];
      if (!container) return;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    });
  }

  if (!subdomain) {
    return null;
  }

  return (
    <div style={blockWrapperStyle}>
      {blocks.map((block, index) => (
        <BlockComponent
          key={block.id + index}
          subdomain={subdomain}
          initialBlock={block}
        />
      ))}
      <div className="cursor-pointer" onClick={addLink} onKeyUp={addLink}>
        <Plus />
      </div>
    </div>
  );
}
