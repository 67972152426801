import React from "react";

export default function Emoji() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 10.5C8.32837 10.5 9 9.60449 9 8.5C9 7.39551 8.32837 6.5 7.5 6.5C6.67163 6.5 6 7.39551 6 8.5C6 9.60449 6.67163 10.5 7.5 10.5Z"
        fill="currentcolor"
      />
      <path
        d="M16 8.5C16 9.60449 15.3284 10.5 14.5 10.5C13.6716 10.5 13 9.60449 13 8.5C13 7.39551 13.6716 6.5 14.5 6.5C15.3284 6.5 16 7.39551 16 8.5Z"
        fill="currentcolor"
      />
      <path
        d="M11 15.2891C12.5 15.2891 14 14.2373 14 13.1328C14 12.0283 12 12 11 12C10 12 8 12.0283 8 13.1328C8 14.2373 9.5 15.2891 11 15.2891Z"
        fill="currentcolor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 11C22 17.0752 17.0752 22 11 22C4.9248 22 0 17.0752 0 11C0 4.9248 4.9248 0 11 0C17.0752 0 22 4.9248 22 11ZM20 11C20 15.9705 15.9705 20 11 20C6.02954 20 2 15.9705 2 11C2 6.02954 6.02954 2 11 2C15.9705 2 20 6.02954 20 11Z"
        fill="currentcolor"
      />
    </svg>
  );
}
