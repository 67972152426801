import React from "react";

export default function ChannelPreview({
  channel,
  currentChannel,
  updateUnread,
}) {
  if (channel.unreadMessageCount > 0) {
    document.getElementById("chat-badge")?.classList.remove("d-none");
  }

  return (
    <div className={"channel-preview"} data-sb-channel-url={channel.url}>
      <div
        className={`channel-preview-content ${
          channel.url === currentChannel?.url ? "channel-preview-active" : ""
        }`}
      >
        <span
          className={`channel-preview-content-channel-name ${
            channel.unreadMessageCount > 0 ? "has-new-message" : ""
          }`}
        >
          {channel.name}
        </span>
      </div>
    </div>
  );
}
