import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="impersonate-alert"
export default class extends Controller {
  connect() {}

  closeAlert() {
    this.element.classList.add("d-none");
  }
}
