import { Controller } from "@hotwired/stimulus";
import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import NotificationMenu from "../components/Notifications";
import { getEnv } from "../utils/get-env";

class InappNotificationsController extends Controller {
  root: Root | undefined = undefined;
  connect() {
    const apiKey = getEnv("KNOCK_PUBLIC_KEY");
    const feedId = getEnv("KNOCK_CHANNEL_ID");
    const user = getEnv("KNOCK_USER_ID");

    if (this.element) {
      const App = React.createElement(NotificationMenu, {
        apiKey: apiKey,
        feedId: feedId,
        user: user,
      });

      this.root = createRoot(this.element);
      this.root.render(App);
    }
  }

  disconnect() {
    this.root?.unmount();
  }
}

export default InappNotificationsController;
