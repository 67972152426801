import React, { CSSProperties } from "react";
import { Appearance } from "@frigade/react";

export const onboardingFormStyle = {
  styleOverrides: {
    formContainer: {
      overflow: "hidden",
      height: "100%",
    },
    formContent: {
      height: "100%",
      overflowX: "hidden",
    },
    mediumTitle: {
      marginTop: "24px",
      fontSize: "24px",
      marginBottom: "10px",
    },
    mediumSubtitle: {
      fontSize: "16px",
      color: "#00000080",
    },
    button: {
      backgroundColor: "#000000",
      borderColor: "#000000",
      borderRadius: "22px",
      width: "347px",
      height: "44px",
    },
    buttonSecondary: {
      borderRadius: "22px",
      height: "44px",
      width: "140px",
    },
    multipleChoiceListItem: {
      background: "transparent",
    },
    checkIconContainerChecked: {
      color: "#3AC0A0",
      backgroundColor: "#3AC0A0",
      borderRadius: "6px",
    },
    checkIconContainerUnchecked: {
      borderRadius: "6px",
    },
    multipleChoiceListItemSelected: {
      backgroundColor: "transparent",
    },
    ctaWrapper: {
      position: "fixed",
      width: "100%",
      right: 0,
      justifyContent: "center",
      bottom: 0,
      padding: "24px",
      backgroundColor: "#FFFFFF",
    },
    multiInput: {
      paddingBottom: "48px",
    },
  },
} as Appearance;

export const blockWrapperStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  width: "100%",
  flexWrap: "wrap",
} as CSSProperties;

export const headerWrapperStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 100,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: "40px",
  paddingRight: "40px",
  paddingTop: "35px",
  paddingBottom: "35px",
  height: "112px",
} as CSSProperties;

export const headerProgressStyle = {
  marginRight: "1rem",
  height: "10px",
  width: "100%",
  minWidth: "14px",
  borderRadius: "8px",
} as CSSProperties;

export const headerInitialsStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  border: "8px solid #000000",
  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
  backgroundColor: "#FFFFFF",
  color: "#000000",
  fontSize: "16px",
  textTransform: "uppercase",
} as CSSProperties;

// These colors are defined in color_themes.rb
// We eventually want to pull these in dynamically.

export const colors = [
  {
    name: "white",
    hexColor: "#ffffff",
  },
  {
    name: "green_spring",
    hexColor: "#bbbfb1",
  },
  {
    name: "madang",
    hexColor: "#bff6c8",
  },
  {
    name: "jagged_ice",
    hexColor: "#bfe1e7",
  },
  {
    name: "moon_raker",
    hexColor: "#c7daf5",
  },
  {
    name: "mauve",
    hexColor: "#d1b6ff",
  },
  {
    name: "lilac",
    hexColor: "#efd3f4",
  },
  {
    name: "sundown",
    hexColor: "#ffb1bf",
  },
  {
    name: "peach",
    hexColor: "#ffcd9a",
  },
  {
    name: "mine_shaft",
    hexColor: "#333333",
  },
  {
    name: "bitter",
    hexColor: "#828872",
  },
  {
    name: "turquoise",
    hexColor: "#56e5a1",
  },
  {
    name: "dodger_blue",
    hexColor: "#07b5fe",
  },
  {
    name: "chetwode",
    hexColor: "#7877df",
  },
  {
    name: "portage",
    hexColor: "#9863f3",
  },
  {
    name: "pink",
    hexColor: "#ff7aa5",
  },
  {
    name: "mandy",
    hexColor: "#e04460",
  },
  {
    name: "roman",
    hexColor: "#df6454",
  },
];

export const socialLinksStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  alignItems: "center",
  width: "100%",
  marginTop: "24px",
  gap: "24px",
} as CSSProperties;

export const socialLinkItemStyle = {
  width: "26px",
  height: "26px",
  borderRadius: "50%",
  backgroundColor: "#F2F2F2",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const socialLinkSelectBoxStyle = {
  width: "250px",
  backgroundColor: "#FFF",
};

export function OnboardingFormStyles() {
  return (
    <style>{`
/* Hides main nav */
.z-index-top-nav {
  display: none;
}

/* Hides toast container which gets in the way on some pages */
.toast-container {
  display: none;
}

button:hover, a:hover {
    opacity: 0.8;
}

.headerProgressBar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 556px;
}


@media (max-width: 510px) {
  .headerImages img:nth-child(n+3) {
    display: none;
  }
  .headerImages img {
    margin-left: -70px;
  }
  h1 {
    font-size: 24px !important;
  }

  p {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .fr-button, .fr-buttonSecondary {
    width: auto !important;
  }

  .headerProgressBar {
    width: auto !important;
  }

  .headerLogo {
    width: 60px;
    height: auto;
  }
}

@media (max-width: 670px) {
  .onboardingContainer {
    width: 100%;
    min-width: auto !important;
    max-width: auto !important;
  }

  .headerImages img:nth-child(n+4) {
      display: none;
  }
}

@media (max-width: 850px) {
  .headerImages img:nth-child(n+5) {
      display: none;
  }
}

@media (max-width: 1020px) {
  .headerImages img:nth-child(n+6) {
      display: none;
  }
}

@media (max-width: 1190px) {
  .headerImages img:nth-child(n+7) {
      display: none;
  }
}

.choices__list--dropdown {
  box-shadow: none !important;
  border: 1px solid #E0E0E0 !important;
}
.text-danger {
  display: none;
}

.react-select__value-container {
  display: flex;
}

.react-select__option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
}

.react-select__option:hover {
  background-color: #F2F2F2;
}

.react-select__menu {
  height: 250px;
  width: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: #FFF;
}

.react-select__menu-list {
  height: 250px;
  width: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.react-select__control {
 cursor: pointer;
}

.fr-multiInputContainer {
  padding-top: 0 !important;
}

.fr-multipleChoiceListItem b, .fr-multipleChoiceListItemSelected b {
  color: #3AC0A0;
}
  `}</style>
  );
}
