import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="activity-feed-loader"
export default class extends Controller {
  connect() {
    document.addEventListener(
      "turbo:before-fetch-request",
      this.show.bind(this),
    );
    document.addEventListener(
      "turbo:before-stream-render",
      this.hide.bind(this),
    );
  }

  disconnect() {
    document.removeEventListener(
      "turbo:before-fetch-request",
      this.show.bind(this),
    );
    document.removeEventListener(
      "turbo:before-stream-render",
      this.hide.bind(this),
    );
  }

  show() {
    this.element.classList.remove("d-none");
    this.element.classList.add("d-flex");
  }

  hide() {
    this.element.classList.remove("d-flex");
    this.element.classList.add("d-none");
  }
}
