import { Controller } from "@hotwired/stimulus";
import { getCsrfToken } from "../fetch";

// Connects to data-controller="stock-images"
export default class extends Controller {
  connect() {
    this.imageIdsAndUrls = [];
  }

  changeUploadType(e) {
    const uploadType = e.target.value;
    if (uploadType === "stock_image") {
      document.getElementById("stock-image").classList.remove("d-none");
      document.getElementById("add-image-button")?.classList?.add("d-none");
    } else if (uploadType === "upload") {
      document.getElementById("stock-image").classList.add("d-none");
      document.getElementById("add-image-button")?.classList?.remove("d-none");
    }
  }

  selectImage(e) {
    const imageContainer = e.target.closest(".search-result-container");
    const downloadUrl = imageContainer.dataset.imageUrl;
    const imageId = imageContainer.dataset.imageId;
    const allImageIds = this.imageIdsAndUrls.map((i) => i[0]);

    // Immediately import the image if it's a single-image component
    if (
      this.isSingleImageComponent() ||
      document.querySelector('[data-controller="audio-form"]')
    ) {
      this.imageIdsAndUrls.push([imageId, downloadUrl]);
      this.importImages(e);
      return;
    }

    if (allImageIds.includes(imageId)) {
      this.imageIdsAndUrls = this.imageIdsAndUrls.filter(
        (idAndUrl) => idAndUrl[0] !== imageId,
      );
      imageContainer.classList.remove("stock-image-selected");
    } else {
      this.imageIdsAndUrls.push([imageId, downloadUrl]);
      imageContainer.classList.add("stock-image-selected");
    }

    if (this.imageIdsAndUrls.length > 0) {
      document
        .getElementById("stock-image-add-button")
        .classList.remove("d-none");
      document
        .getElementById("stock-image-search-form")
        .classList.add("d-none");
    } else {
      document.getElementById("stock-image-add-button").classList.add("d-none");
      document
        .getElementById("stock-image-search-form")
        .classList.remove("d-none");
    }
  }

  // This is used for any multi-image components
  importImages(e) {
    e.preventDefault();
    this.imageIdsAndUrls.forEach((idAndUrl) => {
      const imageId = idAndUrl[0];
      const downloadUrl = idAndUrl[1];
      fetch(downloadUrl)
        .then((res) => res.blob())
        .then((blob) => {
          // Create a new file from the blob response from Unsplash URL
          const file = new File([blob], "attachment_name");
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          const fileList = dataTransfer.files;
          let fileInputEl;
          fileInputEl =
            document.getElementById("image_file_input") ||
            document.getElementById("link_image_file_input") ||
            document.getElementById("video_image_file_input");

          // Audio
          if (!fileInputEl) {
            fileInputEl = document.getElementById("image-file-field");
            const audioPreviewEl =
              document.getElementsByClassName("audio-image")[1];
            if (audioPreviewEl) {
              audioPreviewEl.src = downloadUrl;
            }
          }

          fileInputEl.files = fileList;
          if (this.isSingleImageComponent()) {
            // Manually change the image preview for single-image components
            document.getElementById("image-preview").src = downloadUrl;
            document
              .getElementById("image-preview-wrapper")
              .classList.remove("d-none");
            document
              .getElementById("image-preview-placeholder")
              .classList.add("d-none");
          } else {
            // Manually trigger the file added change event to load the image previews
            const event = new Event("change");
            fileInputEl.dispatchEvent(event);
          }
        });

      // Inform the Unsplash API that the image has been imported
      fetch(`/stock_images/${imageId}/confirm`, {
        method: "POST",
        headers: {
          "X-CSRF-Token": getCsrfToken(),
          Accept: "application/json",
        },
      });
    });

    document.getElementById("stock-image-add-button").classList.add("d-none");
    document
      .getElementById("stock-image-search-form")
      .classList.remove("d-none");
    document.getElementById("search-results-container").classList.add("d-none");
    this.imageIdsAndUrls = [];
  }

  // private
  isSingleImageComponent() {
    return !!document.getElementById("image-preview");
  }
}
