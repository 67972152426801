import React from "react";

export default function ThreeVerticalDots() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
      <path
        fill="currentcolor"
        fillRule="evenodd"
        d="M32 45.333a5.333 5.333 0 1 1 0 10.666 5.333 5.333 0 0 1 0-10.666zM32 28a5.333 5.333 0 1 1 0 10.668A5.333 5.333 0 0 1 32 28zm0-17.333c2.946 0 5.333 2.387 5.333 5.333S34.946 21.333 32 21.333 26.667 18.946 26.667 16s2.387-5.333 5.333-5.333z"
        className="icon-more_svg__fill"
      />
    </svg>
  );
}
