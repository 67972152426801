import React, { useState, useRef } from "react";
import {
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
} from "@knocklabs/react-notification-feed";

const NotificationMenu = ({ apiKey, feedId, user }) => {
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);

  const toggleFeedPopover = function () {
    setIsVisible(!isVisible);

    document
      .getElementById("inapp-notifications-mobile-close")
      ?.classList.toggle("open", !isVisible);
  };

  const replaceDropdown = function () {
    // document.querySelector(".rnf-dropdown select").classList.add("d-none")
    //TODO: create the dropdown for creator and fan if they are a creator
    // const node = document.createElement("li");
    // const textnode = document.createTextNode("Water");
    // node.appendChild(textnode);
    // document.getElementById(".rnf-dropdown").appendChild(select);
  };

  return (
    <KnockFeedProvider apiKey={apiKey} feedId={feedId} userId={user}>
      <>
        <NotificationIconButton
          ref={notifButtonRef}
          onClick={toggleFeedPopover}
          badgeCountType={"unread"}
          className={"nav-svg"}
        />
        <NotificationFeedPopover
          buttonRef={notifButtonRef}
          isVisible={isVisible}
          onOpen={replaceDropdown}
          onClose={toggleFeedPopover}
        />
      </>
    </KnockFeedProvider>
  );
};

export default NotificationMenu;
