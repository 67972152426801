import { Controller } from "@hotwired/stimulus";
import clipboardCopy from "clipboard-copy";
import { Toaster } from "../utilities";

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ["messageIdle", "messageCopied"];

  declare readonly messageIdleTarget: HTMLElement;
  declare readonly messageCopiedTarget: HTMLElement;
  timerId: number | null = null;

  connect() {}

  disconnect(): void {
    if (this.timerId != null) {
      clearTimeout(this.timerId);
    }
  }

  copy(ev: any) {
    const copyText = ev.params.copyText;
    clipboardCopy(copyText);

    if (ev.params.toastSuccess) {
      Toaster.success(ev.params.toastSuccess, { delay: 3000 });
    } else {
      this.messageIdleTarget.classList.add("d-none");
      this.messageCopiedTarget.classList.remove("d-none");

      this.timerId = window.setTimeout(() => {
        this.messageIdleTarget.classList.remove("d-none");
        this.messageCopiedTarget.classList.add("d-none");
      }, 3000);
    }
  }
}
