import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["stateSelect"];

  onCountryChange(e) {
    for (let stateSelect of this.stateSelectTargets) {
      if (stateSelect.dataset.country === e.target.value) {
        stateSelect.querySelector("select").disabled = false;
        stateSelect.classList.remove("d-none");
      } else {
        stateSelect.querySelector("select").disabled = true;
        stateSelect.classList.add("d-none");
      }
    }
  }
}
