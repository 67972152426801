import { Toast } from "bootstrap";

/**
 * Creates a Toast instance by cloning a template node with the ID `elementId`.
 * This node is expected to have a child node with the class
 * `toast-message-container` whose contents will be replaced with the
 * provided `message`.
 *
 * @returns the bootstrap Toast instance.
 */
const createToastFromTemplate = (
  elementId: string | number,
  message: string,
  options: Partial<Toast.Options> = { autohide: false },
) => {
  const toastTemplateEl = document.getElementById(
    elementId.toString(),
  ) as HTMLElement | null;

  if (!toastTemplateEl || !toastTemplateEl.parentNode) {
    throw new Error(`Could not find toast template with ID: ${elementId}`);
  }

  const errorToast = toastTemplateEl.cloneNode(true) as HTMLElement;
  const toastMessageContainer = errorToast.querySelector(
    ".toast-message-container",
  );

  if (!toastMessageContainer) {
    throw new Error("Could not find toast message container");
  }

  errorToast.id = `${elementId}-${Date.now()}`;
  errorToast.dataset.controller = "toast";
  toastMessageContainer.innerHTML = message;

  toastTemplateEl.parentNode.insertBefore(errorToast, null);

  const toast = new Toast(errorToast, options);

  // Remove the toast and the DOM node when the toast is fully hidden.
  errorToast.addEventListener("hidden.bs.toast", () => {
    toast.dispose();
    errorToast.remove();
  });

  return toast;
};

const Toaster = {
  error(message: string, options?: Partial<Toast.Options>) {
    const errorToast = createToastFromTemplate("error-toast", message, options);
    errorToast.show();
  },
  success(message: string, options?: Partial<Toast.Options>) {
    const successToast = createToastFromTemplate(
      "success-toast",
      message,
      options,
    );
    successToast.show();
  },
};

const createDownloadLink = (url: string, open_new = false) => {
  const link = document.createElement("a");
  if (open_new) {
    link.target = "_blank";
    link.rel = "noopener norefferer";
  }
  link.href = url;
  link.style.opacity = "0";
  link.style.position = "absolute";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const loadingSpinner = (additionalClasses?: string) =>
  `<span
        class="spinner-border spinner-border-sm ${additionalClasses}"
        role="status"
        aria-hidden="true"></span>`;

const checkmark = (color?: string) =>
  `<span
    class="
      d-inline-block
      align-middle
      icon-adjacent
      checkmark"
    role="status"
    aria-hidden="true">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-circle-check"
        width="1.5rem"
        height="1.5rem"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="${color ? color : "currentColor"}"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <circle cx="12" cy="12" r="9" />
        <path d="M9 12l2 2l4 -4" />
      </svg>
  </span>`;

const xmark = (color?: string) => `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-x"
    width="1.5rem"
    height="1.5rem"
    viewBox="0 0 24 24"
    stroke-width="2"
    stroke="${color ? color : "#fff"}"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </svg>
`;

// TODO: might want to set the loading indicator on a timeout to replace back
// in case of checkout failure
const addLoadingSpinnerToFormButton = (e: Event) => {
  const eventTarget = e.target && (e.target as HTMLElement);
  if (!eventTarget) {
    return;
  }

  const form = eventTarget.closest("form");

  if (!form || form.reportValidity()) {
    const cartCheckoutButton = eventTarget.closest("button") as
      | HTMLButtonElement
      | undefined;

    if (!cartCheckoutButton) {
      return;
    }

    cartCheckoutButton.disabled = true;

    if (cartCheckoutButton.dataset.loadingReplace === "true") {
      cartCheckoutButton.innerHTML = loadingSpinner();
    } else {
      cartCheckoutButton.insertAdjacentHTML(
        "afterbegin",
        loadingSpinner("me-2"),
      );
    }

    if (form) {
      form.submit();
    }
  }
};

const addRemovableButtonSpinner = (
  element: HTMLButtonElement | HTMLAnchorElement | null,
  options: {
    additionalSpinnerClasses?: string;
    spinnerPosition?: "before" | "after";
    replaceContent?: boolean;
    centerContent?: boolean;
  } = {
    additionalSpinnerClasses: "",
    spinnerPosition: "after",
    replaceContent: false,
    centerContent: true,
  },
) => {
  if (!element) {
    return;
  }

  const initialInnerHtml = element.innerHTML;
  const initialOnClick =
    element instanceof HTMLAnchorElement ? element.onclick : null;
  const initialDataAction = element.getAttribute("data-action");
  const initialHref =
    element instanceof HTMLAnchorElement ? element.getAttribute("href") : null;

  if (element instanceof HTMLButtonElement) {
    element.disabled = true;
  } else {
    element.classList.add("disabled");
  }

  if (options.centerContent) {
    element.classList.add("button-loading-spinner-centered");
  }

  if (options.replaceContent) {
    element.innerHTML = loadingSpinner(options.additionalSpinnerClasses);
  } else if (options.spinnerPosition === "before") {
    element.innerHTML =
      loadingSpinner(`tw-mr-2 ${options.additionalSpinnerClasses}`) +
      initialInnerHtml;
  } else {
    element.innerHTML =
      initialInnerHtml +
      loadingSpinner(`tw-ml-2 ${options.additionalSpinnerClasses}`);
  }

  if (initialOnClick) {
    element.onclick = () => false;
  }
  element.removeAttribute("data-action");
  if (initialHref) {
    element.removeAttribute("href");
  }

  return {
    remove: () => {
      if (!document.body.contains(element)) {
        return;
      }

      element.innerHTML = initialInnerHtml;
      if (element instanceof HTMLButtonElement) {
        element.disabled = false;
      } else {
        element.classList.remove("disabled");
      }
      if (options.centerContent) {
        element.classList.remove("button-loading-spinner-centered");
      }

      if (initialOnClick) {
        element.onclick = initialOnClick;
      }
      if (initialDataAction) {
        element.setAttribute("data-action", initialDataAction);
      }
      if (initialHref) {
        element.setAttribute("href", initialHref);
      }
    },
  };
};

const fileUploadedEvent = (
  name: string,
  byteSize: number,
  signedId: string,
) => {
  return new CustomEvent("fileUploaded", {
    detail: {
      name: name,
      byteSize: byteSize,
      signedId: signedId,
    },
  });
};

const getStatsigCookiesParams = (url: string) => {
  const hostname = new URL(url).hostname;
  const domainToSet = !hostname.includes("localtest")
    ? `.${hostname.replace("www.", "").replace("https://", "")}`
    : ".localtest.me";

  return {
    path: "/",
    domain: domainToSet,
    sameSite: "lax",
    httpOnly: false,
    ...(!domainToSet.includes("localtest")
      ? {
          secure: true,
        }
      : {}),
  };
};

export {
  addLoadingSpinnerToFormButton,
  addRemovableButtonSpinner,
  Toaster,
  createDownloadLink,
  loadingSpinner,
  checkmark,
  xmark,
  fileUploadedEvent,
  getStatsigCookiesParams,
};
