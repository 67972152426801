import { Controller } from "@hotwired/stimulus";

import { addLoadingSpinnerToFormButton, Toaster } from "../utilities";
// Connects to the load_after_click_controller
export default class extends Controller {
  onClickSubmit(e) {
    addLoadingSpinnerToFormButton(e);
    if (e.target.dataset.formId) {
      document.getElementById(e.target.dataset.formId).submit();
    }
  }
}
