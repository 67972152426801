import { ApplicationController, useDebounce } from "stimulus-use";
import invariant from "tiny-invariant";

// Connects to data-controller="text-form"
export default class extends ApplicationController {
  static debounces = ["renderPreview"];

  static values = {
    previewUrl: String,
  };

  declare previewUrlValue: string;

  static targets = ["form", "textPreviewFrame"];

  declare readonly formTarget: HTMLFormElement;
  declare readonly textPreviewFrameTarget: HTMLInputElement;

  connect() {
    useDebounce(this);

    document.addEventListener("trix-change", () => {
      this.renderPreview();
    });
  }

  renderPreview() {
    let form = this.formTarget;

    invariant(form, "expected a form");

    let formData = new FormData(form);
    let previewUrl = new URL(this.previewUrlValue);
    previewUrl.searchParams.append(
      "body",
      formData.get("text[body]") as string,
    );
    previewUrl.searchParams.append(
      "alignment",
      formData.get("text[alignment]") as string,
    );
    previewUrl.searchParams.append(
      "theme_color_preset",
      formData
        .getAll("text[theme_color_preset]")
        .slice(-1) as unknown as string,
    );
    (this.textPreviewFrameTarget as any).src = previewUrl.toString();
  }
}
