import { Controller } from "@hotwired/stimulus";
import { post } from "../fetch";
import { Toaster } from "../utilities";

// Connects to data-controller="phone-number-verification"
export default class extends Controller {
  private readonly GENERIC_ERROR_MESSAGE =
    "There was a problem with your request. Please try again later.";

  declare submitting: Boolean;

  connect() {}

  handlePhoneNumberSubmit = (event: Event) => {
    event.preventDefault();
    this.disableResubmit();

    const form = event.target as HTMLFormElement;
    const formDataJson = this.getFormData(form);

    post(form.action, formDataJson)
      .then((response) => {
        response.json().then((jsonResponse) => {
          const phoneNumberHolder = document.getElementById(
            "phone-number-holder",
          ) as HTMLSpanElement;
          phoneNumberHolder.innerText = jsonResponse.number;

          this.clearInput("phone-verification-number");
          this.toggleVisibility();
          this.focusInput("phone-verification-code");
        });
      })
      .catch((error) => {
        Toaster.error(error.message || this.GENERIC_ERROR_MESSAGE, {
          delay: 3000,
        });
      })
      .finally(() => {
        this.enableSubmit();
      });
  };

  handleCodeSubmit = (event: Event) => {
    event.preventDefault();
    this.disableResubmit();

    const form = event.target as HTMLFormElement;

    post(form.action, this.getFormData(form))
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        Toaster.error(error.message || this.GENERIC_ERROR_MESSAGE, {
          delay: 3000,
        });
        this.submitting = false;
        document.querySelectorAll(".verification-action").forEach((element) => {
          element.removeAttribute("disabled");
        });
      });
  };

  changeNumber = (event: Event) => {
    event.preventDefault();

    this.toggleVisibility();
    this.clearInput("phone-verification-code");
    this.focusInput("phone-verification-number");
  };

  resendVerification = (event: Event) => {
    event.preventDefault();
    this.disableResubmit();

    const link = event.target as HTMLAnchorElement;

    fetch(link.href as string)
      .then((response) => {})
      .catch((error) => {
        Toaster.error(error.message || this.GENERIC_ERROR_MESSAGE, {
          delay: 3000,
        });
      })
      .finally(() => {
        this.enableSubmit();
      });
  };

  getFormData(form: HTMLFormElement) {
    const formData = new FormData(form);
    const formDataJson: { [key: string]: FormDataEntryValue } = {};
    formData.forEach((value, key) => {
      formDataJson[key] = value;
    });
    return formDataJson;
  }

  disableResubmit = () => {
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    document.querySelectorAll(".verification-action").forEach((element) => {
      element.setAttribute("disabled", "true");
    });
  };

  enableSubmit = () => {
    this.submitting = false;
    document.querySelectorAll(".verification-action").forEach((element) => {
      element.removeAttribute("disabled");
    });
  };

  toggleVisibility = () => {
    const phoneDiv = document.getElementById(
      "phone-number-form-container",
    ) as HTMLDivElement;
    const codeDiv = document.getElementById(
      "phone-verification-code-form-container",
    ) as HTMLDivElement;

    phoneDiv.classList.toggle("d-none");
    codeDiv.classList.toggle("d-none");
  };

  clearInput = (id: string) => {
    const input = document.getElementById(id) as HTMLInputElement;
    input.value = "";
  };

  focusInput = (id: string) => {
    const input = document.getElementById(id) as HTMLInputElement;
    input.focus();
  };
}
