import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="change-payment-method"
export default class extends Controller {
  static targets = ["cardSelect"];

  declare readonly cardSelectTarget: HTMLSelectElement;

  connect() {}

  disconnect() {}

  async onSelectChange() {
    const selectedOption = this.cardSelectTarget.selectedOptions[0];
    const cardBrand = selectedOption?.dataset?.brand?.toLowerCase();
    const cardIconContainer = this.element.querySelector("#card_icon");
    if (cardIconContainer) {
      const response = await fetch(`/card_brand_icon/${cardBrand}`);
      cardIconContainer.innerHTML = await response.text();
    }
  }
}
