import React, { useEffect } from "react";
import { FormInputProps } from "@frigade/react";
import { unwatch, watch } from "../utils";

export function LocationTypeahead({
  formInput,
  customFormTypeProps,
  onSaveInputData,
  inputData,
  setFormValidationErrors,
}: FormInputProps) {
  const profileLocationHiddenId = "profile-location-hidden";

  useEffect(() => {
    const profileLocationInputHandler = () => {
      onSaveInputData({
        location: document
          .getElementById(profileLocationHiddenId)
          ?.getAttribute("value"),
      });
    };

    watch(`#${profileLocationHiddenId}`, profileLocationInputHandler);

    return () => {
      unwatch(`#${profileLocationHiddenId}`);
    };
  }, []);

  const scrollIntoView = () => {
    requestAnimationFrame(() => {
      const element = document.getElementById("profile-location");
      element?.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  };

  return (
    <>
      <div
        id="places-search"
        className="form-floating mt-3 col-12 places-search"
        data-controller="places-search"
      >
        <input
          type="text"
          id="profile-location"
          className="form-control parliament-input"
          data-action="keyup->places-search#onKeyUp"
          data-places-search-target="visibleInput"
          value={inputData?.location}
          autoComplete="off"
          onChange={(e) => {
            onSaveInputData({ location: e.target.value });
          }}
          onFocus={scrollIntoView}
          onKeyUp={scrollIntoView}
        />
        <input
          type="hidden"
          name="profile[location]"
          id={profileLocationHiddenId}
          data-places-search-target="hiddenInput"
          value={inputData?.location}
        />
        <label>Location</label>
        <div
          id="location-results"
          className="results parliament-input mt-2 d-none"
        />
      </div>
    </>
  );
}
