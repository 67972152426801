import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

type Status = "idle" | "uploading" | "requesting-transcoding";

// Connects to data-controller="block-form"
export default class BlockFormController extends Controller {
  static values = {
    disabledUploadingMessage: String,
  };
  declare readonly disabledUploadingMessageValue: string;

  static targets = ["disabledDuringUpload", "disabledDuringUploadTooltip"];
  declare readonly disabledDuringUploadTarget: HTMLButtonElement;
  declare readonly disabledDuringUploadTooltipTarget: HTMLButtonElement;

  state: Status = "idle";
  tooltip: Tooltip | null = null;

  connect() {}

  setState(status: Status) {
    this.state = status;
    this.render();
  }

  disconnect(): void {
    if (this.tooltip) {
      this.tooltip?.dispose();
    }
  }

  render() {
    switch (this.state) {
      case "uploading":
      case "requesting-transcoding":
        this.disabledDuringUploadTarget.disabled = true;
        this.disabledDuringUploadTooltipTarget.tabIndex = 0;

        this.tooltip = new Tooltip(this.disabledDuringUploadTooltipTarget, {
          title: this.disabledUploadingMessageValue,
        });
        return;
      case "idle":
        this.disabledDuringUploadTarget.disabled = false;
        this.tooltip?.dispose();
        this.tooltip = null;
        this.disabledDuringUploadTooltipTarget.removeAttribute("tabindex");
        return;
    }
  }
}
