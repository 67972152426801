import { Controller } from "@hotwired/stimulus";
import { PhoneValidResult, phone } from "phone";
import { fetchJson } from "../fetch";
import { getEnv } from "../utils/get-env";
import i18n from "../utils/i18n";
import { addRemovableButtonSpinner, loadingSpinner } from "../utilities";

// Connects to data-controller="tiktok-oauth"
class TikTokOauthController extends Controller {
  private pollingInterval: number | undefined;
  private countdownInterval: number | undefined;
  private timeout: number | undefined;
  private tiktokOauthModal: HTMLElement | null = null;
  private tiktokLoginContainer: HTMLElement | null = null;
  private expiresAt: number | undefined;
  private step: string | undefined;

  connect() {
    this.tiktokLoginContainer = document.getElementById(
      "tiktok_login_container",
    ) as HTMLElement;
    this.tiktokOauthModal = document.getElementById(
      "tiktok-oauth-modal",
    ) as HTMLFormElement;

    if (this.tiktokOauthModal) {
      this.tiktokOauthModal.addEventListener(
        "hidden.bs.modal",
        this.modalClosed.bind(this),
      );
      this.tiktokOauthModal.addEventListener(
        "shown.bs.modal",
        this.modalOpened.bind(this),
      );
      this.tiktokOauthModal.addEventListener(
        "turbo:frame-load",
        this.loadingNewContent.bind(this),
      );
    }
  }

  disconnect(): void {
    this.clearIntervalAndTimeout();

    if (this.tiktokOauthModal) {
      this.tiktokOauthModal.removeEventListener(
        "hidden.bs.modal",
        this.modalClosed.bind(this),
      );
      this.tiktokOauthModal.removeEventListener(
        "shown.bs.modal",
        this.modalOpened.bind(this),
      );
      this.tiktokOauthModal.removeEventListener(
        "turbo:frame-load",
        this.loadingNewContent.bind(this),
      );
    }
  }

  loadingNewContent() {
    this.step = document.getElementById(
      "tiktok-oauth-modal-step",
    )?.dataset.tiktokOauthStep;
    if (this.step === "qr-login") {
      this.startQRStatusPolling();
      this.startTimeout();
    } else {
      this.clearIntervalAndTimeout();
    }
  }

  clearIntervalAndTimeout() {
    this.clearPollingInterval();
    this.clearTimeout();
  }

  clearPollingInterval() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = undefined;
    }

    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
      this.countdownInterval = undefined;
    }
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  modalOpened() {
    this.step = document.getElementById(
      "tiktok-oauth-modal-step",
    )?.dataset.tiktokOauthStep;

    if (this.step === "qr-login") {
      this.startQRStatusPolling();
      this.startTimeout();
    }

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.append("tiktok_auth_modal", "true");
    history.pushState({}, "", `${window.location.pathname}?${urlParams}`);
  }

  modalClosed() {
    if (this.tiktokOauthModal) {
      // Hide modal
      this.tiktokOauthModal.classList.remove("show");
      this.tiktokOauthModal.style.display = "none";
      this.tiktokOauthModal.setAttribute("aria-hidden", "true");

      // Remove modal backdrop
      const backdrop = document.querySelector(".modal-backdrop");
      if (backdrop) {
        backdrop.remove();
      }

      this.clearIntervalAndTimeout();
    }

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("tiktok_auth_modal");
    history.pushState({}, "", `${window.location.pathname}?${urlParams}`);
  }

  handleSendSMSCode() {
    // Validate phone number
    const phoneNumberField = document.getElementById(
      "tiktok-phone-number",
    ) as HTMLFormElement;
    const countryCodeField = document.getElementById(
      "tiktok-country-code",
    ) as HTMLFormElement;
    const phoneErrorMessage = document.getElementById(
      "tiktok-sms-phone-error-message",
    ) as HTMLElement;
    const phoneNumber = phoneNumberField?.value;
    const countryCode = countryCodeField?.value;

    if (!phoneNumber && !countryCode) {
      phoneNumberField.classList.add("--has-error");
      phoneErrorMessage.classList.remove("d-none");
      return;
    }

    const sanitizedPhone = phone(countryCode + phoneNumber);

    if (!sanitizedPhone.isValid) {
      phoneNumberField.classList.add("--has-error");
      phoneErrorMessage.classList.remove("d-none");
      return;
    }

    phoneNumberField.classList.remove("--has-error");
    phoneErrorMessage.classList.add("d-none");

    // Disable SMS code button and start countdown once number has been validated
    const button = document.getElementById(
      "tiktok-send-sms-code",
    ) as HTMLButtonElement;
    button.disabled = true;
    button.classList.add("text-decoration-none");
    button.classList.remove("cursor-pointer");
    let timeLeft = 110;

    this.getSMSCode(sanitizedPhone);

    // Note: Let timer countdown to 0 to minimize calls to ScrapTik API
    const interval = setInterval(() => {
      timeLeft--;
      const timeLeftMinutes = Math.floor(timeLeft / 60);
      const timeLeftSeconds = timeLeft % 60;
      const timeLeftSecondsFormatted = timeLeftSeconds
        .toString()
        .padStart(2, "0");
      button.innerText = `${timeLeftMinutes} : ${timeLeftSecondsFormatted}`;
      if (timeLeft <= 0) {
        button.innerText = i18n.t("client.tiktok_oauth.phone.resend_code");
        button.classList.remove("text-decoration-none");
        button.classList.add("cursor-pointer");
        button.disabled = false;
        clearInterval(interval);
      }
    }, 1000);
  }

  async getSMSCode(sanitizedPhone: PhoneValidResult) {
    const scrapeTikApiKey = getEnv("RAPID_API_KEY");
    const scraperApiKey = getEnv("SCRAPERAPI_API_KEY");
    const encodedPhoneNumber = encodeURIComponent(sanitizedPhone.phoneNumber);
    const url = `https://scraptik.p.rapidapi.com/send-sms?mobile=${encodedPhoneNumber}`;
    const options = {
      method: "GET",
      headers: {
        "tok-proxy": `scraperapi:${scraperApiKey}@proxy-server.scraperapi.com:8001`,
        "X-RapidAPI-Key": scrapeTikApiKey,
        "X-RapidAPI-Host": "scraptik.p.rapidapi.com",
      },
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      const { data, message } = result;

      if (message === "success") {
        const tiktokMobileNumber = document.getElementById(
          "tiktok-mobile-number",
        ) as HTMLFormElement;
        if (tiktokMobileNumber) {
          tiktokMobileNumber.value = sanitizedPhone.phoneNumber;
        }
      } else {
        const errorMessageContainer = document.getElementById(
          "tiktok-sms-login-error",
        ) as HTMLElement;
        switch (data.error_code) {
          case 1107:
            const errorText = errorMessageContainer
              .children[1] as HTMLSpanElement;
            errorText.innerText = i18n.t(
              "client.tiktok_oauth.phone.max_attempts",
            );
            errorMessageContainer.classList.remove("d-none");
            break;
          default:
            const codeErrorMessage = document.getElementById(
              "tiktok-sms-code-error-message",
            ) as HTMLElement;
            codeErrorMessage.classList.remove("d-none");
            break;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async regenerateQRCode() {
    this.getQRCode();
    this.startTimeout();
  }

  async getQRCode() {
    const buttonElement = document.getElementById(
      "tiktok-qr-generate-button",
    ) as HTMLButtonElement;
    const removableSpinner = addRemovableButtonSpinner(buttonElement);

    try {
      const response = await fetch("/oauth/authorize/tiktok/qrconnect", {
        method: "GET",
      });
      const html = await response.text();
      if (this.tiktokLoginContainer) {
        removableSpinner?.remove?.();
        this.tiktokLoginContainer.innerHTML = html;
      }
      this.startQRStatusPolling();
    } catch (error) {
      this.clearIntervalAndTimeout();
      removableSpinner?.remove?.();
    }
  }

  async startQRStatusPolling() {
    const qrCodeData = document.getElementById("qr-code-container")?.dataset;
    const token = qrCodeData?.tiktokOauthQrToken;
    const userId = qrCodeData?.tiktokOauthUserId;
    this.expiresAt = parseInt(qrCodeData?.tiktokOauthQrExpires || "0");

    if (token && userId) {
      this.pollingInterval = window.setInterval(() => {
        this.pollForUserScannedQRCode(token, userId);
      }, 5000);
    }

    if (this.expiresAt > 0) {
      this.countdownInterval = window.setInterval(() => {
        this.updateCountdown();
      }, 1000);
    }
  }

  startTimeout() {
    // cancel polling after 2 minutes 50 seconds
    this.timeout = window.setTimeout(() => {
      this.cancelQRPolling(
        i18n.t("client.tiktok_oauth.qr.exceeded_polling_attempts"),
      );
      document.getElementById("tiktok-qr-timer")?.classList.add("d-none");
      fetchJson("/oauth/authorize/tiktok/qrconnect/expired", { method: "GET" });
    }, 170000);
  }

  async pollForUserScannedQRCode(token: string, userId: string) {
    try {
      const url = `/oauth/authorize/tiktok/qrconnect/status/?token=${token}&user_id=${userId}`;
      const response = await fetch(url, { method: "POST" }).then((response) =>
        response.json(),
      );
      if (response.status === "confirmed") {
        this.clearIntervalAndTimeout();
        this.renderPermissions();
      } else if (response.status === "expired") {
        this.clearPollingInterval();
        this.getQRCode();
      } else if (response.status === "scanned") {
        const qrScanImage = document.getElementById(
          "qr-scan-image",
        ) as HTMLElement;
        const qrScannedImage = document.getElementById(
          "qr-scanned-image",
        ) as HTMLElement;
        const qrImage = document.getElementById(
          "tiktok-qr-image",
        ) as HTMLElement;
        const qrTitle = document.getElementById(
          "tiktok-qr-title",
        ) as HTMLElement;
        const spinner = document.getElementById(
          "tiktok-qr-spinner",
        ) as HTMLElement;

        qrScanImage.classList.add("d-none");
        qrScannedImage.classList.remove("d-none");
        qrImage.classList.add("blur");
        spinner.classList.remove("d-none");

        qrTitle.innerText = i18n.t("client.tiktok_oauth.qr.scanned");
      }
    } catch (error) {
      this.cancelQRPolling();
    }
  }

  cancelQRPolling(errorMsg: string | undefined = undefined) {
    const regenerateQRButton = document.getElementById(
      "tiktok-qr-regenerate-button",
    ) as HTMLElement;
    regenerateQRButton.classList.remove("d-none");

    const spinner = document.getElementById("tiktok-qr-spinner") as HTMLElement;
    spinner.classList.add("d-none");

    const errorMessageContainer = document.getElementById(
      "tiktok-qr-login-error",
    ) as HTMLElement;
    const errorText = errorMessageContainer.children[1] as HTMLSpanElement;
    errorText.innerText = errorMsg
      ? errorMsg
      : i18n.t("client.tiktok_oauth.qr.error");
    errorMessageContainer.classList.remove("d-none");

    this.clearIntervalAndTimeout();
  }

  updateCountdown() {
    const countdown = document.getElementById(
      "tiktok-qr-timer-seconds",
    ) as HTMLElement;
    if (!countdown) {
      return;
    }

    if (this.expiresAt) {
      this.expiresAt -= 1;
      countdown.innerText = this.expiresAt.toString();
    }
  }

  cancelAuthorization() {
    try {
      this.modalClosed();
      fetchJson("/oauth/authorize/tiktok/destroy", { method: "POST" });
    } catch (error) {
      console.error(error);
    }
  }

  authorize = (event: Event) => {
    event.preventDefault();
    const formElement = event.target as HTMLFormElement;
    const formData = new FormData(formElement);

    const formButton = formElement.querySelector("button") as HTMLButtonElement;
    formButton.insertAdjacentHTML("afterbegin", loadingSpinner("me-2"));

    fetch(formElement.action, {
      method: formElement.method,
      body: formData,
      headers: {
        Accept: "application/json",
      },
    }).then(() => {
      window.location.reload();
    });
  };

  async renderPermissions() {
    const response = await fetch("/oauth/authorize/tiktok/permissions", {
      method: "GET",
    });
    const html = await response.text();
    if (this.tiktokLoginContainer) {
      this.tiktokLoginContainer.innerHTML = html;
    }
  }

  showDesktopQRInstructions() {
    const mobileInstructions = document.getElementById(
      "tiktok-mobile-instructions",
    ) as HTMLElement;
    const desktopInstructions = document.getElementById(
      "tiktok-desktop-instructions",
    ) as HTMLElement;
    mobileInstructions.classList.add("d-none");
    desktopInstructions.classList.remove("d-none");
  }

  showMobileQRInstructions() {
    const mobileInstructions = document.getElementById(
      "tiktok-mobile-instructions",
    ) as HTMLElement;
    const desktopInstructions = document.getElementById(
      "tiktok-desktop-instructions",
    ) as HTMLElement;
    mobileInstructions.classList.remove("d-none");
    desktopInstructions.classList.add("d-none");
  }
}

export default TikTokOauthController;
