let observers = new Map<string, MutationObserver>();
export function watch(inputSelector: string, handler: (value: string) => void) {
  // Use mutatation observer to watch for changes to the value/inner html of the input
  // When the value changes, call the handler with the new value

  const input = document.querySelector(inputSelector);
  if (!input) {
    return;
  }
  let observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      let oldValue = mutation.oldValue;
      let newValue = mutation.target.textContent;
      if (oldValue !== newValue) {
        handler(String(newValue));
      }
    });
  });

  observers.set(inputSelector, observer);

  observer.observe(input, {
    characterDataOldValue: true,
    subtree: true,
    childList: true,
    characterData: true,
    attributes: true,
  });
}

export function unwatch(inputSelector: string) {
  let observer = observers.get(inputSelector);
  if (observer) {
    observer.disconnect();
  }
}

export function getInitials(name: string) {
  if (!name) {
    return "";
  }
  const names = name.split(" ");
  let initials = "";
  names.forEach((n: string, index: number) => {
    if (index < 2) {
      initials += n[0];
    }
  });
  return initials;
}
