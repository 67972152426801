import { Controller } from "@hotwired/stimulus";
import invariant from "tiny-invariant";

// Connects to data-controller="sticky-stuck"
export default class extends Controller {
  observer: IntersectionObserver | null = null;

  static values = {
    placement: String,
  };

  declare readonly placementValue: string;
  declare readonly hasPlacementValue: boolean;

  connect() {
    this.observer = new IntersectionObserver(
      ([e]) => {
        const isStuck = e.intersectionRatio < 1;

        // prevent things being in "stuck" state before the person has scrolled
        // past them (https://www.pivotaltracker.com/story/show/185383864)
        if (
          this.hasPlacementValue &&
          this.placementValue === "top" &&
          isStuck &&
          e.intersectionRect.y !== 0
        ) {
          e.target.classList.toggle("stuck", false);
          return;
        }

        e.target.classList.toggle("stuck", isStuck);
      },
      { threshold: [1] },
    );

    this.observer.observe(this.element);
  }

  disconnect() {
    invariant(this.observer);
    this.observer.disconnect();
  }
}
