import React, { useEffect, useState } from "react";
import { FormInputProps } from "@frigade/react";
import { getInitials, unwatch, watch } from "../utils";

export function ProfilePicture({
  formInput,
  customFormTypeProps,
  onSaveInputData,
  inputData,
  setFormValidationErrors,
}: FormInputProps) {
  const [hasSelectedPicture, setHasSelectedPicture] = useState(false);

  const authenticityToken =
    customFormTypeProps?.stepData?.props?.formAuthenticityToken;
  const profile = customFormTypeProps?.stepData?.props?.profile
    ? JSON.parse(customFormTypeProps.stepData.props.profile)
    : {};

  const imagePreviewId = "imagePreview";

  useEffect(() => {
    const imageInputHandler = () => {
      const src = document.getElementById(imagePreviewId)?.getAttribute("src");
      if (!src) {
        return;
      }
      setHasSelectedPicture(true);
      onSaveInputData({
        image: src,
      });
    };

    watch(`#${imagePreviewId}`, imageInputHandler);

    return () => {
      unwatch(`#${imagePreviewId}`);
    };
  }, []);

  return (
    <>
      <form
        id="edit-profile-form"
        className="edit_profile form-floating w-100"
        encType="multipart/form-data"
        action={`/hub/${profile.subdomain}/profile`}
        accept-charset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value="patch" />
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        />
        <div
          className="mb-4 position-relative"
          data-controller="avatar-image-picker"
          data-avatar-image-picker-modal-id-value="avatar-crop-modal"
        >
          <div className="d-flex align-items-center">
            <div
              className="avatar d-flex justify-content-center align-items-center overflow-hidden"
              data-avatar-image-picker-target="imagePreviewContainer"
            >
              <img
                className="d-block mr-2 w-100"
                width="108"
                height="108"
                alt="Avatar"
                id={imagePreviewId}
                data-avatar-image-picker-target="imagePreview"
                sizes="100vw"
                src={inputData?.image}
              />
            </div>
            {!inputData?.image && !hasSelectedPicture && (
              <div
                className="no-avatar me-3"
                style={{ position: "absolute", left: "0" }}
              >
                <span>{getInitials(profile.title)}</span>
              </div>
            )}
            <button
              type="button"
              className="btn btn-light"
              data-action="click->avatar-image-picker#openFilePickerDialog"
            >
              Add profile photo
            </button>
            <button
              type="submit"
              id="upload-image-btn"
              style={{ opacity: 0 }}
            />
          </div>

          <input
            className="d-none"
            id="avatar-image-upload"
            accept="image/png, image/gif, image/jpeg"
            data-action="input->avatar-image-picker#imageSelected"
            data-avatar-image-picker-target="fileInput"
            data-direct-upload-url="/rails/active_storage/direct_uploads"
            type="file"
            name="profile[avatar]"
          />

          <input
            data-avatar-image-picker-target="cropInput"
            autoComplete="off"
            type="hidden"
            name="profile[avatar_crop]"
            id="profile_avatar_crop"
          />

          <div id="avatar-crop-modal" className="modal fade" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content modal-content-full-height">
                <div className="modal-header">
                  <h5 className="modal-title">Edit media</h5>
                  <div>
                    <button
                      type="button"
                      data-bs-dismiss="modal"
                      className="btn btn-light"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-black"
                      data-avatar-image-picker-target="cropApplyButton"
                    >
                      Apply
                    </button>
                  </div>
                </div>

                <div className="modal-body d-flex flex-column overflow-hidden h-100">
                  <div className="d-flex flex-column h-100 overflow-hidden">
                    <div className="d-flex flex-column h-100 overflow-hidden cropper-round position-relative">
                      <img
                        alt="Avatar"
                        className="d-block overflow-hidden"
                        data-avatar-image-picker-target="cropImg"
                      />
                    </div>
                    <div className="mt-3 mx-5">
                      <input
                        type="range"
                        className="w-full"
                        value="0"
                        data-avatar-image-picker-target="cropZoomRangeInput"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
