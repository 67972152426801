import { Controller } from "@hotwired/stimulus";
import { StatsigClient } from "@statsig/js-client";

/*
  Statsig controller in case we need to capture custom events
  Can be added to an element as follows:
  ```
    <a
      data-controller="statsig"
      data-statsig-value="top_tab_navlink"
      ...
    >
  ```
*/

export default class extends Controller {
  boundHandleClick!: (event: Event) => void;

  connect() {
    this.boundHandleClick = this.handleClick.bind(this);
    this.element.addEventListener("click", this.boundHandleClick);
  }

  disconnect() {
    this.element.removeEventListener("click", this.boundHandleClick);
  }

  handleClick(event: Event) {
    // @ts-ignore
    if ((Statsig as StatsigClient).logEvent) {
      const element = (this.element || event.target) as HTMLElement;

      const customEventName =
        element.dataset.statsigValue ||
        `${element.tagName} ${element.innerText}`;
      // @ts-ignore
      (Statsig as StatsigClient).logEvent("click", customEventName, {
        action: "Clicked",
        category: customEventName,
        element: element.tagName,
        ...((element as HTMLAnchorElement).href
          ? {
              href: (element as HTMLAnchorElement).href,
            }
          : {}),
        ...(element.innerText
          ? {
              text: element.innerText,
            }
          : {}),
        ...(element.id
          ? {
              elementId: element.id,
            }
          : {}),
        ...(element.className
          ? {
              elementClass: element.className,
            }
          : {}),
        ...(element.dataset
          ? {
              elementData: JSON.stringify(element.dataset),
            }
          : {}),
      });
    }
  }
}
