import React, { useRef, useEffect, useState } from "react";
import TimeAgo from "../utils/TimeAgo";
import CommentsEmptyState from "./CommentsEmptyState";
import i18n from "../../utils/i18n";
import ChatAvatar from "../SendbirdChat/ChatAvatar";

export default function LoadingState({
  latestComments,
  userLoggedOut,
  blockId,
}) {
  const containerRef = useRef(null);

  document.body.classList.add("sendbird-theme--light");

  const renderCommentsMessage = (message) => (
    <>
      <ChatAvatar
        className="message__avatar col-1"
        width={35}
        height={35}
        src={message.user.profile_url}
        alt="Profile Image"
      />
      <div className="col-10">
        <div className="message__sender">{message.user.nickname}</div>
        <p className="message__text">
          {message.message}{" "}
          <span className="message__timestamp">
            <TimeAgo timestamp={message.created_at} />
          </span>
        </p>
      </div>
    </>
  );

  const renderDummyInput = () => {
    return (
      <>
        {userLoggedOut && (
          <div className={"comment-login-container"}>
            <button
              className={"btn btn-lg btn-black comment-login"}
              onClick={openLoginModal}
            >
              <span className="d-block align-items-center w-100 h-100">
                {i18n.t("client.comments.login")}
              </span>
            </button>
          </div>
        )}
        {!userLoggedOut && (
          <div className="message-input-container">
            <div className="message-input d-flex d-inline btn-outline position-relative">
              <input
                id={`comments-message-input-${new Date().getTime()}`}
                className="comments-message-input unstyled-btn message-input-text w-100 h-100"
                placeholder={i18n.t("client.comments.loading")}
                disabled={true}
              />

              <div className="message-input-buttons">
                <div
                  className="text-muted mt-1 spinner-border"
                  role="status"
                  style={{ width: "22px", height: "22px" }}
                >
                  <span className="visually-hidden">
                    {i18n.t("client.comments.loading")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderEmptyState = () => (
    <>
      <CommentsEmptyState />
      {renderDummyInput()}
    </>
  );

  const renderComments = () => (
    <>
      <div
        style={{ overflowY: "scroll", height: "100%" }}
        ref={containerRef}
        className={"comments-scroll-container"}
      >
        {latestComments.map((message) => (
          <div className="row py-3 px-0 m-0" key={message.messageId}>
            {renderCommentsMessage(message)}
          </div>
        ))}
      </div>
      {renderDummyInput()}
    </>
  );

  const openLoginModal = () => {
    document.getElementById(`comment-login-${blockId}`).click();
  };

  const followAndRefresh = () => {
    const followNav = document.getElementById("follow-button-nav");
    followNav.setAttribute("data-follow-profile-context-value", "ctfw");
    followNav.click();
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [latestComments]);

  return (
    <div className="loading-message-container comments-channel">
      {latestComments.length === 0 ? renderEmptyState() : renderComments()}
    </div>
  );
}
