import { Controller } from "@hotwired/stimulus";
import { Toaster } from "../utilities";

// Connects to data-controller="toast-message"
export default class ToastMessageController extends Controller {
  success(ev: any) {
    const { toastMessageSuccess } = ev.currentTarget.dataset;
    if (toastMessageSuccess) {
      Toaster.success(toastMessageSuccess, { delay: 3000 });
    }
  }
}
