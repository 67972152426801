import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="wide-offcanvas"
export default class extends Controller {
  connect() {
    document.getElementById("right-tray").classList.add("wide");
  }
  disconnect() {
    document.getElementById("right-tray").classList.remove("wide");
  }
}
