import React from "react";

export default function BannedPeople() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 1.93994C18.0749 1.93994 22.9998 6.86482 22.9998 12.9398C22.9998 19.0148 18.0749 23.9397 11.9999 23.9397C5.92487 23.9397 1 19.0148 1 12.9398C1 6.86482 5.92487 1.93994 11.9999 1.93994ZM11.9999 3.93982C7.02925 3.93982 2.99988 7.96919 2.99988 12.9398C2.99988 17.9104 7.02925 21.9398 11.9999 21.9398C16.9705 21.9398 20.9999 17.9104 20.9999 12.9398C20.9999 7.96919 16.9705 3.93982 11.9999 3.93982ZM16.6248 11.9397C16.7242 11.9397 16.8196 11.9792 16.8899 12.0495C16.9602 12.1199 16.9998 12.2152 16.9998 12.3147V13.5649C16.9998 13.6644 16.9602 13.7598 16.8899 13.8301C16.8196 13.9004 16.7242 13.9399 16.6248 13.9399H7.375C7.27554 13.9399 7.18016 13.9004 7.10983 13.8301C7.03951 13.7598 7 13.6644 7 13.5649V12.3147C7 12.2152 7.03951 12.1199 7.10983 12.0495C7.18016 11.9792 7.27554 11.9397 7.375 11.9397H16.6248Z"
        fill="currentcolor"
      />
    </svg>
  );
}
