import { Controller } from "@hotwired/stimulus";

export default class ProgressBarController extends Controller {
  static targets = ["progressIndicator", "progressContainer"];

  declare readonly progressIndicatorTarget: HTMLDivElement;
  declare readonly progressContainerTarget: HTMLDivElement;

  connect() {}

  update(
    /** integer between 0 and 100 */
    completionPercent: number,
  ) {
    // peek a tiny bit of the progress bar so animation is visible
    let padding = 2;
    let padded = ((padding + completionPercent) / (100 + padding)) * 100;

    this.progressIndicatorTarget.style.width = `${padded}%`;
    this.progressIndicatorTarget.ariaValueNow = completionPercent.toString();
    this.progressIndicatorTarget.ariaValueMin = "0";
    this.progressIndicatorTarget.ariaValueMax = "100";
  }

  show() {
    this.progressContainerTarget.classList.remove("d-none");
  }

  hide() {
    this.progressContainerTarget.classList.add("d-none");
  }
}
