import { Controller } from "@hotwired/stimulus";

import { checkmark, xmark } from "../utilities";

// Connects to data-controller="request-indicator"
export default class extends Controller {
  static targets = ["saveButton"];
  static values = {
    success: Boolean,
  };

  connect() {
    if (this.hasSaveButtonTarget) {
      const originalInnerHTML = this.saveButtonTarget.innerHTML;

      if (this.successValue) {
        this.saveButtonTarget.innerHTML = "";
        this.saveButtonTarget.classList.add("success-button");
        this.saveButtonTarget.innerHTML = `${checkmark(
          "#539400",
        )} <span class="align-middle">Saved</span>`;
      } else {
        this.saveButtonTarget.innerHTML = xmark();
      }

      this.saveButtonTarget.disabled = true;
      // restore
      setTimeout(() => {
        this.saveButtonTarget.classList.remove("success-button");
        this.saveButtonTarget.innerHTML = originalInnerHTML;
        this.saveButtonTarget.disabled = false;
      }, 2000);
    }
  }
}
