import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shopify-form"
export default class extends Controller {
  static targets = ["saveButton"];

  static values = {
    type: String,
    profileSubdomain: String,
  };

  connect() {}

  formValidateUrl(e) {
    const value = e.target.value;

    this.fetchShopifyInfo(value);
  }

  setValidUrl() {
    document
      .getElementById(`shopify_${this.typeValue}_url_input`)
      .classList.remove("is-invalid");

    if (this.hasSaveButtonTarget) this.saveButtonTarget.disabled = false;

    document.getElementById("invalid-url-message").classList.add("d-none");
    document.getElementById("invalid-url-message").classList.remove("d-block");
  }

  setInvalidUrl() {
    document
      .getElementById(`shopify_${this.typeValue}_url_input`)
      .classList.add("is-invalid");

    if (this.hasSaveButtonTarget) this.saveButtonTarget.disabled = true;

    document.getElementById("invalid-url-message").classList.remove("d-none");
    document.getElementById("invalid-url-message").classList.add("d-block");
  }

  fetchShopifyInfo(url) {
    fetch(
      `/hub/${this.profileSubdomainValue}/forms/shopify_${this.typeValue}_url_details?url=${url}`,
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          this.setInvalidUrl();
        } else {
          this.setValidUrl();

          const urlInput = document.getElementById(
            `shopify_${this.typeValue}_url_input`,
          );
          const titleInput = document.getElementById(
            `shopify_${this.typeValue}_title_input`,
          );

          urlInput.value = response.url;

          if (titleInput.value === "") {
            titleInput.value = response.title.split(/\s[\-–\|]\s/)[0].trim();
          }
        }
      });
  }
}
