import React, { useEffect, useState } from "react";
import { FrigadeAppProps } from "../index";
import { useUser } from "@frigade/react";

export function DataSyncer(props: FrigadeAppProps) {
  const [hasSyncedData, setHasSyncedData] = useState(false);

  const { addPropertiesToUser } = useUser();

  useEffect(() => {
    if (!hasSyncedData && props.profile) {
      setHasSyncedData(true);
      addPropertiesToUser({
        ...props.profile,
        name: props.profile?.title,
        is_creator: props.isCreator,
        enable_social_login: props.enableSocialLogin,
      });
    }
  }, [props.profile]);

  useEffect(() => {
    setTimeout(() => {
      if (document) {
        document
          .querySelectorAll('[action="/edit/publish_changes"]')
          .forEach((el) => {
            const button = el.querySelector("button");
            button?.addEventListener("click", function (evt) {
              addPropertiesToUser({
                has_clicked_publish: true,
              });
            });
          });
      }
    }, 1000);
  }, []);

  return <></>;
}
