import { Controller } from "@hotwired/stimulus";
import { useThrottle } from "stimulus-use";

// Connects to data-controller="is-scrolling"
/// Dynamically toggle an element's visibility if the window is scrolling
export default class extends Controller {
  static targets = ["profileAvatar"];
  static values = {
    visibilityClass: { type: String, default: "invisible" },
  };
  static throttles = ["scroll"];

  declare readonly profileAvatarTarget: HTMLDivElement;
  declare readonly hasProfileAvatarTarget: boolean;
  declare visibilityClassValue: string;

  isScrolling = false;
  boundScroll = this.scroll.bind(this);
  endScrollId: number | undefined = undefined;

  connect() {
    useThrottle(this, { wait: 200 });
    window.addEventListener("scroll", this.boundScroll);
  }

  disconnect() {
    window.removeEventListener("scroll", this.boundScroll);
  }

  scroll() {
    if (!this.isScrolling) {
      this.isScrolling = true;
    }

    // Backstop to end scrolling if we didn't get an event (Safari)
    clearTimeout(this.endScrollId);
    this.endScrollId = window.setTimeout(this.scrollEnd.bind(this), 350);

    this.toggleProfileAvatarVisibility();
  }

  private scrollEnd() {
    this.isScrolling = false;
  }

  private toggleProfileAvatarVisibility() {
    if (this.hasProfileAvatarTarget) {
      const dynamicTriggerDistance = window.innerWidth > 1680 ? 210 : 130;

      this.profileAvatarTarget.classList.toggle(
        this.visibilityClassValue,
        window.scrollY <= dynamicTriggerDistance,
      );
    }
  }
}
