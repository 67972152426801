import { Tooltip } from "bootstrap";
import { Controller } from "@hotwired/stimulus";
import { debounce } from "../debounce";
import { getCsrfToken } from "../fetch";

/**
 * The debounced delay applied to the domain input element.
 */
const DEBOUNCE_DELAY = 300;

interface DomainValidationResponse {
  success: boolean;
  message: string;
}

type ValidationState = "valid" | "invalid" | "validating" | "unknown";

/**
 * Typeguard for checking that `input` implements `DomainValidationResponse`.
 */
const isDomainValidationResponse = (
  input: unknown,
): input is DomainValidationResponse => {
  return (
    typeof input === "object" &&
    input !== null &&
    "success" in input &&
    typeof (input as { success: unknown })["success"] === "boolean" &&
    "message" in input &&
    typeof (input as { message: unknown })["message"] === "string"
  );
};

// Connects to data-controller="domain-input"
export default class DomainInputController extends Controller<HTMLElement> {
  static badgeLabels: Partial<Record<ValidationState, string>> = {
    valid: "Available",
    invalid: "Unavailable",
  };

  static targets = ["container", "domainInput", "validationBadge"];

  declare readonly containerTarget: HTMLInputElement;
  declare readonly domainInputTarget: HTMLInputElement;
  declare readonly validationBadgeTarget: HTMLElement;

  // @ts-expect-error
  onDomainInputDebounced: (...args: any[]) => void;

  connect() {
    this.onDomainInput = this.onDomainInput.bind(this);
    this.onDomainInputDebounced = debounce(this.onDomainInput, DEBOUNCE_DELAY);

    this.domainInputTarget.addEventListener(
      "input",
      this.onDomainInputDebounced,
    );
  }

  disconnect() {
    this.domainInputTarget.removeEventListener(
      "input",
      this.onDomainInputDebounced,
    );
    const tooltip = Tooltip.getInstance(this.validationBadgeTarget);
    if (tooltip) {
      tooltip.dispose();
    }
  }

  onDomainInput(event: Event) {
    const subdomain = (event.target as HTMLInputElement).value;
    this.setState("validating", "Checking...");
    fetch("/verify_subdomain", {
      method: "POST",
      body: JSON.stringify({ subdomain }),
      headers: {
        "X-CSRF-Token": getCsrfToken(),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (this.domainInputTarget.value !== subdomain) {
          // The input has changed while the request was in-flight,
          // so this response is out-of-date
          return;
        }

        if (!isDomainValidationResponse(json)) {
          return;
        }

        this.setState(json.success ? "valid" : "invalid", json.message);
      })
      .catch((err) => {
        if (this.domainInputTarget.value !== subdomain) {
          return;
        }

        // The validation itself failed (network request failed, server
        // returned unexpected response, etc...). Set state to "unknown" which
        // will allow the form to submit and regular server validation and
        // rendering to occur
        this.setState("unknown");
      });
  }

  setState(state: ValidationState, message?: string) {
    this.dispatch("stateChange", { detail: { state, message } });

    this.containerTarget.classList[state === "valid" ? "add" : "remove"](
      "is-valid",
    );
    this.containerTarget.classList[state === "invalid" ? "add" : "remove"](
      "is-invalid",
    );
    this.containerTarget.classList[state === "validating" ? "add" : "remove"](
      "is-validating",
    );

    const label = DomainInputController.badgeLabels[state] || "";
    this.validationBadgeTarget.innerText = label;
    this.setTooltip(message);
  }

  setTooltip(title?: string) {
    const tooltip = Tooltip.getInstance(this.validationBadgeTarget);
    if (tooltip) {
      tooltip.dispose();
    }

    this.validationBadgeTarget.title = title || "";

    if (title) {
      new Tooltip(this.validationBadgeTarget, { title });
    }
  }
}
