import { Controller } from "@hotwired/stimulus";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "videojs-event-tracking";
import { trackEvent } from "../utils/ahoy";

// Connects to data-controller="video"
export default class extends Controller {
  static targets = ["video", "youtubeIframe", "extraControls"];

  declare readonly videoTargets: HTMLElement[];
  declare readonly extraControlsTarget: HTMLElement;
  declare readonly youtubeIframeTargets: HTMLElement[];
  declare readonly hasExtraControlsTarget: boolean;
  declare readonly hasVideoTarget: boolean;
  declare readonly hasYoutubeIframeTarget: boolean;

  declare intervalIds: number[];
  declare videoPlayers: Player[];

  connect() {
    this.videoPlayers = [];
    this.intervalIds = [];

    // Video could be played inline or in an the expanded view, which is currently configured by placing two (2) video tags in a block.
    if (this.hasVideoTarget) {
      this.videoTargets?.forEach((vp) => {
        let intervalId: number;
        const videoPlayer = videojs(vp, {
          // Disabling PiP because Chrome might choke on it (unable to reproduce)
          controlBar: { pictureInPictureToggle: false },
        }) as any;

        videoPlayer.eventTracking({
          performance: (data: Record<string, string | number>) => {
            // Only fire events for videos that have been watched
            if (parseInt(data.watchedDuration as string) > 0)
              trackEvent("Video disposed", {
                video_id: vp.dataset.videoId,
                profile_id: vp.dataset.profileId,
                watched_duration: data.watchedDuration,
              });
          },
        });

        // Start polling when video is playing
        videoPlayer.on("play", () => {
          intervalId = window.setInterval(() => {
            trackEvent("Video chunk played", {
              video_id: vp.dataset.videoId,
              profile_id: vp.dataset.profileId,
              duration: 15,
            });
          }, 15000);

          this.intervalIds.push(intervalId);

          if (this.hasExtraControlsTarget)
            this.extraControlsTarget.classList.add("d-none");
        });

        videoPlayer.on("pause", () => {
          const videoId = vp.dataset.videoId || null;
          if (videoId) {
            this.saveCurrentVideoTime(videoId, videoPlayer.currentTime());
          }
          if (this.hasExtraControlsTarget)
            this.extraControlsTarget.classList.remove("d-none");
        });

        videoPlayer.on(
          "tracking:firstplay",
          (_e: Event, data: Record<string, string | number>) => {
            trackEvent("Video first play", {
              video_id: vp.dataset.videoId,
              profile_id: vp.dataset.profileId,
              ...data,
            });
          },
        );

        videoPlayer.on(
          "tracking:pause",
          (_e: Event, data: Record<string, string | number>) => {
            clearInterval(intervalId);

            trackEvent("Video paused", {
              video_id: vp.dataset.videoId,
              profile_id: vp.dataset.profileId,
              ...data,
            });
          },
        );

        // Fires when the video ends
        videoPlayer.on(
          "tracking:fourth-quarter",
          (_e: Event, data: Record<string, string | number>) => {
            clearInterval(intervalId);

            trackEvent("Video ended", {
              video_id: vp.dataset.videoId,
              profile_id: vp.dataset.profileId,
              total_duration: data.duration,
              ...data,
            });
          },
        );

        videoPlayer.controlBar.volumePanel.muteToggle.on("click", () => {
          this.unmuteAutoplay(vp.dataset.videoId, videoPlayer.muted());
        });

        videoPlayer.controlBar.playToggle.on("click", () => {
          this.manuallyPauseVideoPlayer(
            vp.dataset.videoId,
            videoPlayer.paused(),
          );
        });

        if (vp.dataset.autoplay === "on" && !this.isIphone()) {
          videoPlayer.muted(true);
          const observer = new window.IntersectionObserver(
            ([entry]) => {
              if (entry.isIntersecting) {
                if (videoPlayer.paused() || videoPlayer.currentTime() === 0) {
                  const isManuallyPaused = vp.dataset.manuallyPaused;
                  if (!isManuallyPaused || isManuallyPaused === "false") {
                    this.pauseAllVideoPlayers();
                    videoPlayer.play();
                  }
                }
              } else {
                const videoTargetElement = videoPlayer.el_ as HTMLElement;
                if (
                  videoTargetElement &&
                  videoTargetElement.dataset.videoExpanded === "true"
                ) {
                  const player = this.videoPlayers.find(
                    (player) => player.id === videoPlayer.id,
                  );
                  const isManuallyPaused = vp.dataset.manuallyPaused;
                  if (!isManuallyPaused || isManuallyPaused === "false") {
                    player?.play();
                  }
                } else {
                  videoPlayer.pause();
                }
              }
            },
            {
              rootMargin: "-50% 0% -50% 0%",
              threshold: 0,
            },
          );
          observer.observe(vp);
        }

        this.videoPlayers.push(videoPlayer);
      });
    }

    if (this.hasYoutubeIframeTarget) {
      this.youtubeIframeTargets?.forEach((youtubeIframe) => {
        if (youtubeIframe) {
          const observer = new window.IntersectionObserver(
            ([entry]) => {
              if (entry.isIntersecting) {
                (youtubeIframe as HTMLIFrameElement).contentWindow?.postMessage(
                  '{"event":"command","func":"' + "playVideo" + '","args":""}',
                  "*",
                );
              } else {
                (youtubeIframe as HTMLIFrameElement).contentWindow?.postMessage(
                  '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
                  "*",
                );
              }
            },
            {
              rootMargin: "-50% 0% -50% 0%",
              threshold: 0,
            },
          );
          observer.observe(youtubeIframe);
        }
      });
    }
  }

  isIphone() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  pauseAllVideoPlayers() {
    if (this.videoPlayers.length > 0) {
      this.videoPlayers.forEach((vp) => vp.pause());
    }
  }

  unmuteAutoplay(videoId: string | undefined, isMuted: boolean) {
    const vps = videojs.getPlayers();
    Object.values(vps).forEach((vp: any) => {
      if (vp.tagAttributes["data-video-id"] === videoId) {
        vp.muted(false);
      }
    });
  }

  manuallyPauseVideoPlayer(videoId: string | undefined, isPaused: boolean) {
    const vts = this.videoTargets;
    Object.values(vts).forEach((vt: any) => {
      if (vt.dataset.videoId === videoId) {
        vt.dataset.manuallyPaused = isPaused.toString();
      }
    });
  }

  disconnect() {
    if (this.videoPlayers.length > 0) {
      this.intervalIds.forEach((id) => clearInterval(id));
      this.videoPlayers.forEach((vp) => vp.dispose());
    }
  }

  saveCurrentVideoTime(videoId: string, playedTime: number) {
    const vps = videojs.getPlayers();
    Object.values(vps).forEach((vp: any) => {
      if (vp.tagAttributes["data-video-id"] === videoId) {
        vp.currentTime(playedTime);
      }
    });
  }
}
