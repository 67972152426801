import { Components } from "@duetds/date-picker";
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="duet-date"
export default class DuetDateController extends Controller<
  HTMLElement & Components.DuetDatePicker
> {
  /**
   * `true` if the date picker is open; otherwise, `false`.
   */
  isPickerOpen = false;

  connect() {
    this.onDuetFocus = this.onDuetFocus.bind(this);
    this.onDuetOpen = this.onDuetOpen.bind(this);
    this.onDuetClose = this.onDuetClose.bind(this);

    const DuetDatePickerElement = customElements.get("duet-date-picker");

    if (!DuetDatePickerElement) {
      console.warn(
        "<duet-date-picker /> is not defined! The date picker will not work without it.",
      );
      return;
    }

    if (!(this.element instanceof DuetDatePickerElement)) {
      console.warn(
        "DuetDateController attached to a non-`duet-date-picker` element! Attach your controller to the <duet-date-picker />!",
      );
    }

    this.element.addEventListener("duetFocus", this.onDuetFocus);
    this.element.addEventListener("duetOpen", this.onDuetOpen);
    this.element.addEventListener("duetClose", this.onDuetClose);
  }

  disconnect() {
    this.element.removeEventListener("duetFocus", this.onDuetFocus);
    this.element.removeEventListener("duetOpen", this.onDuetOpen);
    this.element.removeEventListener("duetClose", this.onDuetClose);
  }

  onDuetFocus(event: Event) {
    // We need to keep track of the picker open state because we only want to
    // try to show it on focus if it isn't already open. This way, if
    // it _is_ open, users can still click into the input field to type
    // directly if they'd like, and the picker will close. Otherwise we'd just
    // keep reopening it on them.

    if (!this.isPickerOpen) {
      // If we try to open the modal right away it closes immediately. I think
      // this has to do with the open tranision that duet-date uses?, which is
      // currently set at 300ms.
      // See: https://github.com/duetds/date-picker/blob/a89499198d6e5555073bb0dec3a3dab9a5b3648b/src/components/duet-date-picker/duet-date-picker.tsx#L324
      setTimeout(() => this.element.show(), 300);
    }
  }

  onDuetOpen() {
    this.isPickerOpen = true;
  }

  onDuetClose() {
    this.isPickerOpen = false;
  }
}
