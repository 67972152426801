import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import invariant from "tiny-invariant";
import { CHECKED, UNCHECKED } from "../icons/svg-icons";

// based on categories_dropdown_controller.js
// Connects to data-controller="choices"
export default class extends Controller {
  static values = {
    label: String,
  };
  declare labelValue: string;

  static targets = ["input"];
  declare inputTarget: HTMLInputElement;

  choice: Choices | undefined;

  connect() {
    this.choice = new Choices(this.inputTarget, {
      callbackOnCreateTemplates: (template) => {
        return {
          item: ({ classNames }: any, data: any) => {
            return template(`
              <div class="${classNames.item} ${
                data.placeholder ? classNames.placeholder : ""
              }" data-item data-id="${data.id}" data-value="${data.value}" ${
                data.active ? 'aria-selected="true"' : ""
              }
                ${data.disabled ? 'aria-disabled="true"' : ""}>
                  <span style="margin-right:8px;">${data.label}</span>
                  <span data-action="click->drawer#blockHide click->choices#removeSelectedOption" style="cursor: pointer; padding: 5px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 1.63555C9.82322 1.34266 9.82322 0.867788 9.53033 0.574895C9.23744 0.282001 8.76256 0.282001 8.46967 0.574895L5 4.04456L1.53033 0.574895C1.23744 0.282001 0.762563 0.282001 0.46967 0.574895C0.176777 0.867788 0.176777 1.34266 0.46967 1.63555L3.93934 5.10522L0.46967 8.57489C0.176777 8.86779 0.176777 9.34266 0.46967 9.63555C0.762563 9.92845 1.23744 9.92845 1.53033 9.63555L5 6.16588L8.46967 9.63555C8.76256 9.92845 9.23744 9.92845 9.53033 9.63555C9.82322 9.34266 9.82322 8.86779 9.53033 8.57489L6.06066 5.10522L9.53033 1.63555Z" fill="black" fill-opacity="0.33"/>
                    </svg>
                  </span>
              </div>
            `);
          },
          containerInner: (_: unknown, data: any) => {
            return template(`
              <div class="parliament-input choices__inner">
                <div class="choices-custom-icon"></div>
              </div>`);
          },
          choice: (_: any, data: any) => {
            return template(`
                <div
                  id="choices--profile_category_ids-item-choice-${data.id}"
                  class="choices__item choices__item--choice choices__item--selectable is-highlighted"
                  role="option"
                  data-choice=""
                  data-id="${data.id}"
                  data-value="${data.value}"
                  data-select-text=""
                  data-choice-selectable=""
                  aria-selected="true"
                >
                  ${data.selected ? CHECKED : UNCHECKED}
                  ${data.label}
                </div>
              `);
          },
          dropdown: () => {
            return template(`
              <div class="p-3 choices__list choices__list--dropdown" aria-expanded="false">
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <h5 class="m-0">${this.labelValue}</h5>
                  </div>
                </div>
              </div>
            `);
          },
        };
      },
    });
  }

  removeSelectedOption(ev: any) {
    const value = ev.currentTarget.closest(".choices__item").dataset.value;
    invariant(this.choice);
    this.choice.removeActiveItemsByValue(value);
    ev.stopPropagation();
  }
}
