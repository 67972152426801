import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
import invariant from "tiny-invariant";

// Connects to data-controller="audio-embed"
export default class extends Controller {
  modal: Modal | null = null;

  connect() {
    this.modal = Modal.getOrCreateInstance(this.element);
    this.element.addEventListener("hidden.bs.modal", this.removeFromDom);

    this.element.addEventListener("show.bs.modal", () => {
      setTimeout(() => {
        document
          .querySelector(".modal-backdrop")
          ?.classList.add("modal-backdrop-black");
      }, 100);
    });

    this.modal.show();
  }

  removeFromDom = () => {
    invariant(this.modal, "expected modal");
    this.modal.dispose();
    this.element.remove();
  };

  disconnect(): void {
    this.element.removeEventListener("hidden.bs.modal", this.removeFromDom);
  }
}
