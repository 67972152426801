interface Timezone {
  label: string;
  value: string;
}

const timezones: Timezone[] = [
  { label: "Pacific Time - US & Canada", value: "America/Los_Angeles" },
  { label: "Mountain Time - US & Canada", value: "America/Denver" },
  { label: "Central Time - US & Canada", value: "America/Chicago" },
  { label: "Eastern Time - US & Canada", value: "America/New_York" },
  { label: "Alaska Time", value: "America/Anchorage" },
  { label: "Hawaii Time", value: "Pacific/Honolulu" },
  { label: "Arizona, Yukon Time", value: "America/Phoenix" },
  { label: "Newfoundland Time", value: "America/St_Johns" },
  { label: "Central America Time", value: "America/Guatemala" },
  { label: "Mountain Standard Time - Mexico", value: "America/Chihuahua" },
  { label: "Eastern Standard Time - Mexico", value: "America/Cancun" },
  { label: "Atlantic Time", value: "America/Halifax" },
  { label: "Greenland Time", value: "America/Godthab" },
  { label: "Mountain Time - Mexico", value: "America/Hermosillo" },
  { label: "Mexico City Time", value: "America/Mexico_City" },
  { label: "Montevideo Time", value: "America/Montevideo" },
  { label: "Brasilia Time", value: "America/Sao_Paulo" },
  { label: "Buenos Aires Time", value: "America/Argentina/Buenos_Aires" },
  { label: "Santiago Time", value: "America/Santiago" },
  { label: "Caracas Time", value: "America/Caracas" },
  { label: "Asuncion Time", value: "America/Asuncion" },
  { label: "Bogota, Jamaica, Lima Time", value: "America/Bogota" },
  { label: "Campo Grande Time", value: "America/Campo_Grande" },
  { label: "Saskatchewan Time", value: "America/Regina" },
  { label: "Havana Time", value: "America/Havana" },
  { label: "Mazatlan Time", value: "America/Mazatlan" },
  { label: "Godthab Time", value: "America/Godthab" },
  { label: "La Paz Time", value: "America/La_Paz" },
  { label: "Central European Time", value: "Europe/Berlin" },
  { label: "Eastern European Time", value: "Europe/Kiev" },
  { label: "UK, Ireland, Lisbon Time", value: "Europe/London" },
  { label: "Minsk Time", value: "Europe/Minsk" },
  { label: "Moscow Time", value: "Europe/Moscow" },
  { label: "Turkey Time", value: "Europe/Istanbul" },
  { label: "Korea Time", value: "Asia/Seoul" },
  { label: "Indian Time", value: "Asia/Kolkata" },
  { label: "Japan Time", value: "Asia/Tokyo" },
  { label: "Jordan Time", value: "Asia/Amman" },
  { label: "Baghdad Time", value: "Asia/Baghdad" },
  { label: "Baku Time", value: "Asia/Baku" },
  { label: "Lebanon Time", value: "Asia/Beirut" },
  { label: "Syria Time", value: "Asia/Damascus" },
  { label: "Asia/Dhaka", value: "Asia/Dhaka" },
  { label: "Dubai Time", value: "Asia/Dubai" },
  { label: "Asia/Irkutsk", value: "Asia/Irkutsk" },
  { label: "Indochina Time", value: "Asia/Jakarta" },
  { label: "Israel Time", value: "Asia/Jerusalem" },
  { label: "Kabul Time", value: "Asia/Kabul" },
  { label: "Kathmandu Time", value: "Asia/Kathmandu" },
  { label: "Sri Lanka Time", value: "Asia/Colombo" },
  { label: "Central Asia Time", value: "Asia/Almaty" },
  { label: "Pakistan Standard Time", value: "Asia/Karachi" },
  { label: "Arabian Standard Time", value: "Asia/Dubai" },
  { label: "West Asia Standard Time", value: "Asia/Tashkent" },
  { label: "Bangladesh Standard Time", value: "Asia/Dhaka" },
  { label: "Asia/Omsk", value: "Asia/Omsk" },
  { label: "Asia/Rangoon", value: "Asia/Rangoon" },
  { label: "China, Singapore, Perth", value: "Asia/Singapore" },
  { label: "Tehran Time", value: "Asia/Tehran" },
  { label: "Asia/Vladivostok", value: "Asia/Vladivostok" },
  { label: "Asia/Yakutsk", value: "Asia/Yakutsk" },
  { label: "Yekaterinburg", value: "Asia/Yekaterinburg" },
  { label: "Asia/Yerevan", value: "Asia/Yerevan" },
  { label: "Azores Time", value: "Atlantic/Azores" },
  { label: "Cape Verde Time", value: "Atlantic/Cape_Verde" },
  { label: "Adelaide Time", value: "Australia/Adelaide" },
  { label: "Brisbane Time", value: "Australia/Brisbane" },
  { label: "Darwin Time", value: "Australia/Darwin" },
  { label: "Eucla Time", value: "Australia/Eucla" },
  { label: "Lord Howe Time", value: "Australia/Lord_Howe" },
  { label: "Melbourne Time", value: "Australia/Melbourne" },
  { label: "Perth Time", value: "Australia/Perth" },
  { label: "Sydney Time", value: "Australia/Sydney" },
  { label: "UTC", value: "UTC" },
  { label: "Central Africa Time", value: "Africa/Lagos" },
  { label: "West Africa Time", value: "Africa/Lagos" },
  { label: "Africa/Cairo", value: "Africa/Cairo" },
  { label: "Africa/Windhoek", value: "Africa/Windhoek" },
  { label: "Apia Time", value: "Pacific/Apia" },
  { label: "Auckland Time", value: "Pacific/Auckland" },
  { label: "Chatham Islands Time", value: "Pacific/Chatham" },
  { label: "Easter Island Time", value: "Pacific/Easter" },
  { label: "Galapagos Time", value: "Pacific/Galapagos" },
  { label: "Gambier Time", value: "Pacific/Gambier" },
  { label: "Kritimatí Time", value: "Pacific/Kiritimati" },
  { label: "Majuro Time", value: "Pacific/Majuro" },
  { label: "Marquesas Time", value: "Pacific/Marquesas" },
  { label: "Norfolk Time", value: "Pacific/Norfolk" },
  { label: "Noumea Time", value: "Pacific/Noumea" },
  { label: "Pitcairn Time", value: "Pacific/Pitcairn" },
  { label: "Pago Pago Time", value: "Pacific/Pago_Pago" },
  { label: "Tarawa Time", value: "Pacific/Tarawa" },
  { label: "Tongatapu Time", value: "Pacific/Tongatapu" },
];

export default timezones;
