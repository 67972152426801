import { Controller } from "@hotwired/stimulus";
import "vanilla-colorful";
import type { HexColorPicker } from "vanilla-colorful/hex-color-picker";
import { Tooltip } from "bootstrap";

// Connects to data-controller="customize-theme-form"
export default class CustomizeThemeFormController extends Controller {
  static targets = [
    "hexInputField",
    "hexColorPicker",
    "hexColorPickerLabel",
    "customColorCheckBox",
    "submitButton",
    "disabledTooltip",
  ];

  declare readonly hexInputFieldTarget: HTMLInputElement;
  declare readonly hexColorPickerTarget: HexColorPicker;
  declare readonly hexColorPickerLabelTarget: HTMLElement;
  declare readonly customColorCheckBoxTarget: HTMLInputElement;
  declare readonly submitButtonTarget: HTMLButtonElement;
  declare readonly disabledTooltipTarget: HTMLButtonElement;

  declare boundHandleClickOutsideHexTargets: (e: Event) => void;
  tooltip: Tooltip | null = null;

  connect() {
    // Store a bound reference to the handler function so it can be used as a
    // event listener handler that can be removed.
    this.boundHandleClickOutsideHexTargets =
      this.handleClickOutsideHexTargets.bind(this);

    this.addEventListeners();
    this.setInitialState();
  }

  setInitialState() {
    const presetThemeHex = this.checkedPresetThemeOptionColor();
    const customHex = this.hexInputFieldTarget.value;

    if (customHex !== "" && customHex !== presetThemeHex) {
      this.checkCustomColorCheckBoxTarget();
    }
  }

  addEventListeners() {
    this.hexInputFieldTarget.addEventListener("focus", (event: Event) => {
      this.showColorPicker();
    });

    this.presetThemeOptions().forEach((option) => {
      option.addEventListener("click", this.handlePresetClick.bind(this));
    });

    this.hexColorPickerLabelTarget.addEventListener(
      "click",
      this.handleColorPickerLabelClick.bind(this),
    );
    this.hexInputFieldTarget.addEventListener(
      "input",
      this.handleHexInputFieldChange.bind(this),
    );
    this.hexInputFieldTarget.addEventListener(
      "focus",
      this.handleHexInputFieldChange.bind(this),
    );
    this.hexColorPickerTarget.addEventListener(
      "color-changed",
      this.handleHexColorPickerChange.bind(this),
    );

    const selector = document.getElementById(
      "profile_font",
    ) as HTMLSelectElement;
    selector.addEventListener("change", this.handleFontChange.bind(this));

    document.addEventListener("click", this.boundHandleClickOutsideHexTargets);
  }

  disconnect() {
    document.removeEventListener(
      "click",
      this.boundHandleClickOutsideHexTargets,
    );

    const selector = document.getElementById(
      "profile_font",
    ) as HTMLSelectElement;
    selector.removeEventListener("change", this.handleFontChange.bind(this));

    this.clearTooltip();
  }

  showColorPicker() {
    this.hexColorPickerTarget.classList.remove("d-none");
  }

  hideColorPicker() {
    this.hexColorPickerTarget.classList.add("d-none");
  }

  validateForm() {
    const presetChecked = this.checkedPresetThemeOption() !== null;
    const customChecked = this.customColorCheckBoxTarget.checked === true;

    if (!(presetChecked || customChecked)) {
      this.submitButtonTarget.disabled = true;

      this.tooltip = new Tooltip(this.disabledTooltipTarget, {
        title: "No color selected",
      });
    } else {
      this.submitButtonTarget.disabled = false;
      this.clearTooltip();
    }
  }

  clearTooltip() {
    if (this.tooltip) {
      this.tooltip?.dispose();
      this.tooltip = null;
    }
  }

  setColorOnPicker(hex: string) {
    this.hexColorPickerTarget.setAttribute("color", hex);
    this.setColorOnPickerLabelTarget(hex);
  }

  setColorOnInput(hex: string) {
    this.hexInputFieldTarget.value = hex;

    const form = document.getElementById(
      "customize-design-form",
    ) as HTMLFormElement;
    const url = form.action;

    fetch(`${url}/text_color?hex=${hex.slice(1)}`)
      .then((res) => res.json())
      .then((response) => {
        const previewContainer = document.getElementById(
          "profile-theme-preivew",
        ) as HTMLElement;
        previewContainer.style.backgroundColor = hex;
        previewContainer.style.color = response.textColor;
      })
      .catch(() => {});
  }

  setColorOnPickerLabelTarget(hex: string) {
    this.hexColorPickerLabelTarget.setAttribute("data-hex-color", hex);
    this.hexColorPickerLabelTarget.style.backgroundColor = hex;
  }

  handleClickOutsideHexTargets(event: Event) {
    if (
      !(event.target as HTMLElement)?.classList.contains(
        "hex-color-picker-element",
      )
    ) {
      this.hideColorPicker();
    }
  }

  // Clicking on a preset theme radio option visually indicates that a custom
  // theme is not used and opens the color picker set to the theme color.
  handlePresetClick(event: Event) {
    event.preventDefault();

    let presetOption = (event.target as HTMLInputElement)
      .previousElementSibling as HTMLInputElement;
    if (presetOption) {
      presetOption.checked = true;
    }
    this.uncheckCustomColorCheckBoxTarget();

    const hex = (event.target as HTMLElement)?.dataset.hexColor;

    if (hex) {
      this.setColorOnPicker(hex);
      this.setColorOnInput(hex);
    }

    this.showColorPicker();
  }

  checkCustomColorCheckBoxTarget() {
    this.customColorCheckBoxTarget.checked = true;

    let presetOption = this.checkedPresetThemeOption();
    if (presetOption) {
      presetOption.checked = false;
    }

    this.validateForm();
  }

  uncheckCustomColorCheckBoxTarget() {
    this.customColorCheckBoxTarget.checked = false;
    this.validateForm();
  }

  // Clicking the preview display for the custom hex code always toggles it  to
  // a "checked" state. An "unchecked" state would visually indicate a custom
  // value is not used, which is not accurate- custom values are always used if
  // present. It also opens the color picker.
  handleColorPickerLabelClick(event: Event) {
    event.preventDefault();
    this.checkCustomColorCheckBoxTarget();

    const hex = (event.target as HTMLElement)?.dataset.hexColor;

    if (hex) {
      this.setColorOnInput(hex);
    }

    this.showColorPicker();
  }

  handleHexInputFieldChange(event: Event) {
    let hex = (event.target as HTMLInputElement)?.value;

    if (hex === "") {
      this.uncheckCustomColorCheckBoxTarget();
    } else {
      this.checkCustomColorCheckBoxTarget();
      this.setColorOnPicker(hex);
    }
  }

  // Selecting a color on the hex picker always sets the preview display to
  // "checked" to indicate the custom color will be used. The preview display
  // and input are also updated to reflect the chosen color
  handleHexColorPickerChange(event: CustomEvent) {
    let hex = event.detail.value;

    this.checkCustomColorCheckBoxTarget();
    this.setColorOnInput(hex);
    this.setColorOnPickerLabelTarget(hex);
  }

  handleFontChange(event: Event) {
    const selector = document.getElementById(
      "profile_font",
    ) as HTMLSelectElement;
    const font = selector.value;

    const form = document.getElementById(
      "customize-design-form",
    ) as HTMLFormElement;
    const url = form.action;

    fetch(`${url}/font?font_id=${font}`)
      .then((res) => res.json())
      .then((response) => {
        const previewContainer = document.getElementById(
          "profile-theme-preivew",
        ) as HTMLElement;
        previewContainer.style.fontFamily = response.name;
      })
      .catch(() => {});
  }

  presetThemeOptions() {
    return this.element.querySelectorAll(".theme-preset");
  }

  checkedPresetThemeOption(): HTMLInputElement | null {
    return this.element.querySelector(".theme-preset-button:checked");
  }

  checkedPresetThemeOptionColor(): string {
    return (
      (this.checkedPresetThemeOption()?.nextElementSibling as HTMLInputElement)
        ?.dataset?.hexColor || ""
    );
  }
}
