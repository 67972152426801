import React, { useState, useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

export default function Toasts({ message, show }) {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(show);
  }, [show]);

  return (
    <ToastContainer className="toast-container position-fixed z-index-5000 top-0 end-0 p-3 pt-6">
      <Toast
        className="toast"
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={2000}
        autohide
      >
        <Toast.Body>
          <div className="d-flex">
            <div className="d-flex gap-2 w-100 align-items-center">
              <i className="bi-info-circle-fill fs-3 text-success" />
              <span className="align-super px-1 mb-0 text-black toast-message-container">
                {message}
              </span>
            </div>
            <div className="d-flex gap-2 align-items-center justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="toast"
                aria-label="Close"
              />
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
