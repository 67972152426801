import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="license-codes-counter"
export default class extends Controller {
  static targets = ["count"];

  onListChange(e) {
    this.countTarget.innerHTML = (e.target.value || "")
      .split("\n")
      .filter((code) => code).length;
  }
}
