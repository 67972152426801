import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="character-limit"
export default class extends Controller {
  static targets = ["input", "characters"];

  connect() {
    this.charactersTarget.innerHTML = `${this.remaningCharacterCount()} characters remaining`;
  }

  onChange() {
    this.charactersTarget.innerHTML = `${this.remaningCharacterCount()} characters remaining`;
  }

  characterLimit() {
    return parseInt(this.element.dataset.maxlength);
  }

  remaningCharacterCount() {
    return this.characterLimit() - this.inputTarget.value.length;
  }
}
