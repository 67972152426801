import { Controller } from "@hotwired/stimulus";
import { post } from "../fetch";
import { addLoadingSpinnerToFormButton } from "../utilities";

// Connects to data-controller="membership-management"
export default class extends Controller {
  static values = {
    cancelPath: String,
    updatePath: String,
    membershipStatusRecordId: String,
  };
  static targets = ["saveButton"];

  cancel(e) {
    addLoadingSpinnerToFormButton(e);
    post(this.cancelPathValue, {
      membershipStatusRecordId: this.membershipStatusRecordIdValue,
    })
      .then((res) => res.json())
      .then((json) => {
        location.reload();
      });
  }

  selectTier(e) {
    const targetItem = e.target.closest("li");

    if (targetItem.classList.contains("lower-tier")) return;

    e.target
      .closest("ul")
      .querySelectorAll("li")
      .forEach((li) => {
        li.classList.remove("tier-selected");
      });

    targetItem.classList.add("tier-selected");

    if (targetItem.classList.contains("current-tier")) {
      this.saveButtonTarget.classList.add("disabled");
    } else {
      this.saveButtonTarget.classList.remove("disabled");
    }
  }

  updateTier(e) {
    addLoadingSpinnerToFormButton(e);

    const { stripeCustomer } = e.target.dataset;

    if (stripeCustomer === "false") {
      window.location.href = "/payment_methods?notice=true";
      return;
    }

    const selectedTier = e.target
      .closest(".modal-content")
      .querySelector(".update-membership-list-item.tier-selected");

    post(this.updatePathValue, {
      membershipStatusRecordId: this.membershipStatusRecordIdValue,
      membershipTierId: selectedTier.dataset.membershipTierId,
    })
      .then((res) => res.json())
      .then((json) => {
        location.reload();
      });
  }
}
