import { Controller } from "@hotwired/stimulus";
import { assert } from "../utils/asserts";
import { loadStripe } from "@stripe/stripe-js/pure";

// Connects to data-controller="embedded-checkout"
export default class EmbeddedCheckout extends Controller {
  static targets = [
    "stripeConnectAccount",
    "stripeEmbeddedCheckout",
    "stripePublishableKey",
  ];

  connect = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const clientSecret = urlParams.get("client_secret");

    const stripeConnectAccount = this.stripeConnectAccountTarget.dataset.value;
    assert(stripeConnectAccount);

    const stripePublishableKey = this.stripePublishableKeyTarget.dataset.value;
    assert(stripePublishableKey);
    const stripe = await loadStripe(stripePublishableKey, {
      stripeAccount: stripeConnectAccount,
    });
    assert(stripe);
    const checkout = await stripe.initEmbeddedCheckout({
      clientSecret: clientSecret,
    });
    checkout.mount("#stripeEmbeddedCheckout");
  };
}
