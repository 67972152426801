import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="custom-gender"
export default class extends Controller {
  static targets = ["customGenderField"];
  static values = {
    hiddenClass: { type: String, default: "d-none" },
    customSelect: { type: String, default: "custom" },
  };

  declare readonly customGenderFieldTarget: HTMLElement;
  declare readonly hasCustomGenderFieldTarget: boolean;
  declare hiddenClassValue: string;
  declare customSelectValue: string;

  toggleCustomField(event: Event) {
    if (!this.hasCustomGenderFieldTarget) return;

    const target = event.target as HTMLSelectElement;
    if (target.value === this.customSelectValue) {
      this.customGenderFieldTarget.classList.remove(this.hiddenClassValue);
    } else {
      this.customGenderFieldTarget.classList.add(this.hiddenClassValue);
    }
  }
}
