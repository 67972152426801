import { Controller } from "@hotwired/stimulus";
import i18n from "../utils/i18n";
import { addRemovableButtonSpinner } from "../utilities";

// Connects to data-controller="modal-login"
export default class extends Controller {
  static targets = [
    "loginFormContainer",
    "redirectToInput",
    "contextInput",
    "googleForm",
    "instagramForm",
    "loginCustomContent",
    "loginCustomContentSignin",
    "checkEmailContainer",
    "nextButtonText",
    "loginButtonText",
    "emailInput",
    "passwordInput",
    "errorFeedback",
    "submittedEmail",
    "submittedAccountEmail",
    "loginForm",
    "submitBtn",
  ];

  declare loginFormContainerTarget: HTMLDivElement;
  declare redirectToInputTarget: HTMLInputElement;
  declare contextInputTarget: HTMLInputElement;
  declare googleFormTarget: HTMLFormElement;
  declare instagramFormTarget: HTMLFormElement;
  declare hasGoogleFormTarget: Boolean;
  declare hasInstagramFormTarget: Boolean;
  declare loginCustomContentTarget: HTMLDivElement;
  declare loginCustomContentSigninTarget: HTMLDivElement;
  declare checkEmailContainerTarget: HTMLDivElement;
  declare nextButtonTextTarget: HTMLSpanElement;
  declare loginButtonTextTarget: HTMLSpanElement;
  declare emailInputTarget: HTMLInputElement;
  declare passwordInputTarget: HTMLInputElement;
  declare errorFeedbackTarget: HTMLDivElement;
  declare submittedEmailTarget: HTMLElement;
  declare submittedAccountEmailTarget: HTMLElement;
  declare submitBtnTarget: HTMLButtonElement;

  declare defaultNextText: string;
  declare defaultLoginText: string;

  declare wc: Boolean;

  declare submitting: Boolean;

  connect() {
    document
      .querySelectorAll("[data-bs-target='#login-modal']")
      .forEach((element) => {
        element.addEventListener("click", this.setupLoginClicks);
      });
  }

  disconnect() {
    super.disconnect();
    document
      .querySelectorAll("[data-bs-target='#login-modal']")
      .forEach((element) => {
        element.removeEventListener("click", this.setupLoginClicks);
      });
  }

  resetModalTimeout = () => {
    setTimeout(this.resetModal, 100);
  };

  resetModal = () => {
    this.loginFormContainerTarget.classList.remove("d-none");

    this.checkEmailContainerTarget.classList.add("d-none");
    this.nextButtonTextTarget.classList.remove("d-none");
    this.loginButtonTextTarget.classList.add("d-none");
    document
      .getElementById("login-modal-form-password-container")
      ?.classList.add("d-none");
    document.getElementById("forgot-password-link")?.classList.add("d-none");
    this.emailInputTarget.classList.remove("is-invalid");
    this.passwordInputTarget.classList.remove("is-invalid");
    this.errorFeedbackTarget.classList.add("d-none");

    this.loginCustomContentTarget.classList.add("d-none");
    this.loginCustomContentSigninTarget.classList.add("d-none");
    let defaultContent = document.getElementById(
      "login-modal-default-content",
    ) as HTMLDivElement;
    defaultContent.classList.remove("d-none");
    document
      .getElementById("signin-account-link-container")
      ?.classList.add("d-none");

    this.submittedEmailTarget.innerText = "";
    this.submittedAccountEmailTarget.innerText = "";
    this.passwordInputTarget.value = "";
    this.redirectToInputTarget.value = "";
    this.contextInputTarget.value = "";
    this.loginCustomContentTarget.innerHTML = "";
    this.loginCustomContentSigninTarget.innerHTML = "";
    this.emailInputTarget.value = "";
    this.errorFeedbackTarget.innerText = "";

    let nextSpan = document.getElementById(
      "login-modal-form-next-text",
    ) as HTMLSpanElement;
    nextSpan.innerText = this.defaultNextText;
    let loginSpan = document.getElementById(
      "login-modal-form-login-text",
    ) as HTMLSpanElement;
    loginSpan.innerText = this.defaultLoginText;

    this.wc = false;
    this.submitting = false;
    document
      .getElementById("have-account-link-container")
      ?.classList.add("d-none");

    const additionalInputs = document.getElementById(
      "login-modal-additional-inputs",
    ) as HTMLElement;
    additionalInputs.innerHTML = "";
  };

  setupLoginClicks = (e: Event) => {
    let targetElement = e.currentTarget as HTMLElement;
    let redirectTo = targetElement.getAttribute("data-redirect");
    let context = targetElement.getAttribute("data-context");
    let contentDivId = targetElement.getAttribute("data-content");
    let contentDivIdSignin = targetElement.getAttribute("data-content-signin");
    let loginText = targetElement.getAttribute("data-logintext");
    let additionalInputsId = targetElement.getAttribute("data-login-params");

    if (targetElement.getAttribute("data-creator") === "true") {
      this.nextButtonTextTarget.classList.add("d-none");
      this.loginButtonTextTarget.classList.remove("d-none");
      document
        .getElementById("login-modal-form-password-container")
        ?.classList.remove("d-none");
      document
        .getElementById("forgot-password-link")
        ?.classList.remove("d-none");
    }

    this.wc = contentDivIdSignin !== null;
    if (this.wc) {
      document
        .getElementById("have-account-link-container")
        ?.classList.remove("d-none");
      document
        .getElementById("login-modal-singin-account-text")
        ?.classList.add("d-none");
      document
        .getElementById("login-modal-account-text")
        ?.classList.remove("d-none");
    }

    if (redirectTo === "current") {
      redirectTo = window.location.href;
    }

    redirectTo = redirectTo || "";
    this.redirectToInputTarget.value = redirectTo || "";
    this.contextInputTarget.value = context || "";

    if (this.hasGoogleFormTarget) {
      let googleFormNewAction =
        this.googleFormTarget
          .getAttribute("action")
          ?.replace("REPLACEME", redirectTo) || "";
      this.googleFormTarget.setAttribute("action", googleFormNewAction);
    }

    if (this.hasInstagramFormTarget) {
      let instagramFormNewAction =
        this.instagramFormTarget
          .getAttribute("action")
          ?.replace("REPLACEME", redirectTo) || "";
      this.instagramFormTarget.setAttribute("action", instagramFormNewAction);
    }

    if (contentDivId) {
      this.loginCustomContentTarget.innerHTML =
        document.getElementById(contentDivId)?.innerHTML || "";

      this.loginCustomContentTarget.classList.remove("d-none");
      let defaultContent = document.getElementById(
        "login-modal-default-content",
      ) as HTMLDivElement;
      defaultContent.classList.add("d-none");
    }

    if (contentDivIdSignin) {
      this.loginCustomContentSigninTarget.innerHTML =
        document.getElementById(contentDivIdSignin)?.innerHTML || "";
    }

    this.defaultNextText = i18n.t("client.login_modal.next");
    this.defaultLoginText = i18n.t("client.login_modal.login");

    if (loginText) {
      let nextSpan = document.getElementById(
        "login-modal-form-next-text",
      ) as HTMLSpanElement;
      nextSpan.innerText = loginText;
      let loginSpan = document.getElementById(
        "login-modal-form-login-text",
      ) as HTMLSpanElement;
      loginSpan.innerText = loginText;
    }

    if (additionalInputsId) {
      const additionalInputsDiv = document.getElementById(
        "login-modal-additional-inputs",
      ) as HTMLElement;
      additionalInputsDiv.innerHTML =
        document.getElementById(additionalInputsId)?.innerHTML || "";
    }
  };

  toggleLogin = (e: Event) => {
    e.preventDefault();
    this.wc = !this.wc;
    this.loginCustomContentTarget.classList.toggle("d-none");
    this.loginCustomContentSigninTarget.classList.toggle("d-none");
    document
      .getElementById("have-account-link-container")
      ?.classList.toggle("d-none");
    document
      .getElementById("signin-account-link-container")
      ?.classList.toggle("d-none");
    document
      .getElementById("login-modal-singin-account-text")
      ?.classList.toggle("d-none");
    document
      .getElementById("login-modal-account-text")
      ?.classList.toggle("d-none");
  };

  handleEmailFormSubmit = async (event: Event) => {
    event.preventDefault();

    if (this.submitting) {
      return;
    }

    const formElement = event.target as HTMLFormElement;

    const removableSpinner = addRemovableButtonSpinner(this.submitBtnTarget);

    try {
      this.submitting = true;

      let formData = new FormData(formElement);

      if (this.wc) {
        formData.set("context", `${formData.get("context")}wc`);
      }

      const response = await fetch(formElement.action, {
        method: formElement.method,
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      this.submitting = false;

      const data = await response.json();

      if (data.redirect_to) {
        window.location.href = data.redirect_to;
      }

      if (data.status === 200) {
        if (data.redirect_to) {
          window.location.href = data.redirect_to;
        }
        if (data.check_email) {
          this.loginFormContainerTarget.classList.add("d-none");
          this.submittedEmailTarget.innerText = this.emailInputTarget.value;
          this.submittedAccountEmailTarget.innerText =
            this.emailInputTarget.value;
          this.checkEmailContainerTarget.classList.remove("d-none");
        }

        if (data.require_password) {
          this.nextButtonTextTarget.classList.add("d-none");
          this.loginButtonTextTarget.classList.remove("d-none");
          document
            .getElementById("login-modal-form-password-container")
            ?.classList.remove("d-none");
          document
            .getElementById("forgot-password-link")
            ?.classList.remove("d-none");
        }
      } else if (data.status === 401) {
        this.emailInputTarget.classList.add("is-invalid");
        this.passwordInputTarget.classList.add("is-invalid");
        this.errorFeedbackTarget.innerText = data.error_message;
        this.errorFeedbackTarget.classList.remove("d-none");
      }
    } catch (err) {
      this.submitting = false;
    } finally {
      removableSpinner?.remove?.();
    }
  };
}
