import React from "react";
import { FormInputProps } from "@frigade/react";

export function TextareaWithCharacterCount({
  formInput,
  customFormTypeProps,
  onSaveInputData,
  inputData,
  setFormValidationErrors,
}: FormInputProps) {
  // @ts-ignore
  const maxChars = formInput?.props?.maxCharacters;

  return (
    <>
      <div
        className="form-floating edit_profile"
        data-controller="character-limit"
        data-maxlength={maxChars}
      >
        <textarea
          className="form-control parliament-input"
          maxLength={maxChars}
          data-character-limit-target="input"
          onChange={(e) => {
            onSaveInputData({ text: e.target.value });
          }}
          data-action="keyup->character-limit#onChange"
          style={{ height: 120 }}
          value={inputData?.text}
        />
        <label className="position-absolute">{formInput.title}</label>
        <small
          className="absolute-bottom-8-right-13"
          data-character-limit-target="characters"
        />
      </div>
    </>
  );
}
