import React, { useState, useRef, useEffect } from "react";
import EmojiPicker from "emoji-picker-react";
import i18n from "../../utils/i18n";
import Emoji from "../icons/Emoji";
import ChatAvatar from "../SendbirdChat/ChatAvatar";

export default function CommentsMessageInput({
  messageToUpdate,
  parentMessage,
  channel,
  onSubmit,
  user,
  isChat,
  setIsEditing,
  isMobile,
}) {
  const inputRef = useRef(null);
  const [inputText, setInputText] = useState(
    messageToUpdate ? messageToUpdate.message : "",
  );
  const [textareaStyle, setTextareaStyle] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const mentionContainerRef = useRef(null);
  const [showMentionList, setShowMentionList] = useState(false);
  const [mentionedUsers, setMentionedUsers] = useState(
    messageToUpdate ? messageToUpdate.mentionedUsers : [],
  );
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [startsWithMention, setStartsWithMention] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [refreshMentionList, setRefreshMentionList] = useState([]);
  const [emojiPickerPosition, setEmojiPickerPosition] = useState("top");
  const [sendPending, setSendPending] = useState(false);
  const isFrozen = channel.isFrozen;
  const currentDate = new Date().getTime();

  /**************************************************************************
   * Mention Code
   ***************************************************************************/

  // Filter out mentioned and current user from the list to show
  useEffect(() => {
    if (channel?.members) {
      const filtered = channel.members.filter(
        (member) =>
          member.userId !== user.id && !mentionedUsers.includes(member),
      );
      setFilteredMembers(filtered);
      setStartsWithMention(filtered);
    }
  }, [channel.members, user, mentionedUsers, refreshMentionList]);

  // Update the filtered members when the mentioned users change
  useEffect(() => {
    if (filteredMembers.length > 0) {
      setSelectedItem(filteredMembers[0]);
    } else {
      setSelectedItem(null);
    }
  }, [filteredMembers]);

  const detectMention = (text) => {
    if (text.endsWith("@") && !showMentionList) setShowMentionList(true);

    if (text.includes("@")) {
      const mentionedText = text.split("@").pop().toLowerCase();
      const filtered = filteredMembers.filter((member) =>
        member.nickname.toLowerCase().includes(mentionedText),
      );

      const exactMatch = filtered.find(
        (member) => member.nickname.toLowerCase() === mentionedText,
      );

      // Filter the filtered users to only users whose nickname starts with the typed mention
      const filteredStartsWith = filtered.filter((member) =>
        member.nickname.toLowerCase().startsWith(mentionedText),
      );
      setStartsWithMention(filteredStartsWith);

      if (exactMatch) {
        addToMentionedUsers(exactMatch);
        autoCompleteMention(exactMatch);
        setSelectedItem(null);
      } else if (filteredStartsWith.length > 0) {
        setSelectedItem(filteredStartsWith[0]);
      } else {
        setSelectedItem(null);
      }
    } else {
      setSelectedItem(null);
    }
  };

  const detectDeletingMention = () => {
    const updatedMentionedUsers = mentionedUsers.filter((mentionedUser) => {
      const mentionPattern = new RegExp(`@${mentionedUser.nickname}`, "g");
      return inputText.match(mentionPattern);
    });

    if (updatedMentionedUsers.length !== mentionedUsers.length) {
      setShowMentionList(true);
    }

    setMentionedUsers(updatedMentionedUsers);
  };

  const handleMentionClick = (mentionedUser) => {
    addToMentionedUsers(mentionedUser);
    autoCompleteMention(mentionedUser);
  };

  const addToMentionedUsers = (mentionedUser) => {
    setMentionedUsers((prevMentions) => [...prevMentions, mentionedUser]);
  };

  const handleArrowNavigation = (key) => {
    if (filteredMembers.length === 0) return;

    let currentIndex = filteredMembers.findIndex(
      (member) => member === selectedItem,
    );
    if (key === "ArrowDown") {
      currentIndex = (currentIndex + 1) % filteredMembers.length;
    } else if (key === "ArrowUp") {
      currentIndex =
        (currentIndex - 1 + filteredMembers.length) % filteredMembers.length;
    }

    setSelectedItem(filteredMembers[currentIndex]);
  };

  const autoCompleteMention = (selectedUser) => {
    if (!selectedUser) return;

    const mentionIndex = inputText.lastIndexOf("@");
    const replacedText = `${inputText.slice(0, mentionIndex)}@${
      selectedUser.nickname
    } `;
    setInputText(replacedText);
    setShowMentionList(false);
  };

  const handleMouse = (member) => {
    setSelectedItem(member);
  };

  const scrollToSelected = () => {
    const selectedElement = document.querySelector(".selected");
    if (selectedElement && mentionContainerRef.current) {
      const container = mentionContainerRef.current;
      const selectedElementTop = selectedElement.offsetTop;
      const containerScrollTop = container.scrollTop;
      const containerHeight = container.clientHeight;

      if (selectedElementTop < containerScrollTop) {
        container.scrollTo({
          top: selectedElementTop,
          behavior: "smooth",
        });
      } else if (selectedElementTop > containerScrollTop + containerHeight) {
        container.scrollTo({
          top:
            selectedElementTop - containerHeight + selectedElement.clientHeight,
          behavior: "smooth",
        });
      }
    }
  };

  const renderMentions = () => (
    <div tabIndex={0}>
      {showMentionList && startsWithMention.length > 0 && (
        <div className="comments-mention" ref={mentionContainerRef}>
          <ul>
            {startsWithMention.map((member) => (
              <li
                key={member.userId}
                onClick={() => handleMentionClick(member)}
                onKeyUp={() => handleMentionClick(member)}
                onMouseEnter={() => handleMouse(member)}
                onMouseLeave={() => handleMouse(member)}
                className={`d-flex align-items-center position-relative ${
                  selectedItem === member ? "selected" : ""
                }`}
              >
                <ChatAvatar
                  className="comments-mention__avatar mr-2 me-2 align-self-center"
                  width={24}
                  height={24}
                  src={member.profileUrl}
                  alt="Profile Image"
                />
                <span className="comments-mention__username">
                  {member.nickname}
                </span>

                <div
                  className="status-indicator position-absolute rounded-circle"
                  style={{
                    backgroundColor:
                      member.connectionStatus === "online" ? "green" : "red",
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );

  /**************************************************************************
   * Text Input Code
   ***************************************************************************/
  const handleSubmit = () => {
    setInputText("");
    setMentionedUsers([]);
    setTextareaStyle({ height: "28px" });
  };

  const getMentionedTemplate = (mentions) => {
    let messageText = inputText;
    for (let user of mentions) {
      messageText = messageText.replace(
        `@${user.nickname}`,
        `@{${user.userId}}`,
      );
    }

    return messageText;
  };

  const sendMessage = async () => {
    if (isMuted || inputText === "" || sendPending) return;
    setSendPending(true);

    const santiziedMenionedUsers = mentionedUsers.filter((user) => user);
    let messageParams = {
      mentionType: "users",
      mentionedUserIds: santiziedMenionedUsers.map((user) => user.userId),
      message: inputText,
      mentionedMessageTemplate:
        santiziedMenionedUsers.length > 0
          ? getMentionedTemplate(santiziedMenionedUsers)
          : "",
    };

    handleSubmit();

    if (messageToUpdate) {
      channel
        .updateUserMessage(messageToUpdate.messageId, messageParams)
        .then(() => {
          if (onSubmit) onSubmit();
          setSendPending(false);
        });
    } else {
      if (parentMessage) {
        messageParams.parentMessageId = parentMessage.messageId;
      }

      channel.sendUserMessage(messageParams).onSucceeded(() => {
        if (onSubmit) onSubmit();
        setSendPending(false);
      });
    }
  };

  /**************************************************************************
   * Emoji Picker Code
   ***************************************************************************/

  const onEmojiClick = (emojiData) => {
    setInputText((prevInput) => prevInput + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  const handleOutsideClick = (e) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(e.target)) {
      setShowEmojiPicker(false);
    }
  };

  const onShowEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState);
  };

  useEffect(() => {
    const handleEmojiPickerPosition = () => {
      const inputElement = inputRef.current;
      if (inputElement) {
        const { top: inputTop, bottom: inputBottom } =
          inputElement.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (inputTop > windowHeight / 2) {
          setEmojiPickerPosition("top");
        } else {
          setEmojiPickerPosition("bottom");
        }
      }
    };
    if (showEmojiPicker) {
      setTimeout(() => {
        document.addEventListener("click", handleOutsideClick);
      }, 100);
      handleEmojiPickerPosition();
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showEmojiPicker]);

  const renderEmojiPicker = () => {
    if (isMuted || isFrozen || isMobile) return null;

    const pickerClassName = `emoji-picker position-absolute z-index-top-nav ${
      showEmojiPicker ? "" : "d-none"
    } ${
      emojiPickerPosition === "top" ? "emoji-picker-top" : "emoji-picker-bottom"
    }`;

    return (
      <div className="message-input-buttons me-2">
        <button className="btn unstyled-btn" onClick={onShowEmojiPicker}>
          <Emoji />
        </button>
        <div ref={emojiPickerRef} className={pickerClassName}>
          {showEmojiPicker && (
            <EmojiPicker
              onEmojiClick={onEmojiClick}
              skinTonesDisabled={true}
              native={true}
              height={240}
              lazyLoadEmojis={true}
              previewConfig={{
                showPreview: false,
              }}
            />
          )}
        </div>
      </div>
    );
  };

  /**************************************************************************
   * Listeners
   ***************************************************************************/

  const onMessageInputChange = (e) => {
    autoAdjustTextarea(e);
    setInputText(e.currentTarget.value);
    detectMention(e.currentTarget.value);
  };

  const autoAdjustTextarea = (e) => {
    const lineHeight = 20;
    const maxRows = 7;
    const minRows = 1;

    e.target.rows = minRows;
    const currentRows = Math.floor(e.target.scrollHeight / lineHeight);
    e.target.style.height = "auto";

    if (currentRows <= maxRows) {
      e.target.rows = currentRows;
    } else {
      e.target.rows = maxRows;
    }

    setTextareaStyle({ height: e.target.style.height });
  };

  const handleKeyDown = (e) => {
    const isInputFocused = inputRef.current === document.activeElement;

    if (isInputFocused && e.key === "@" && e.shiftKey) {
      setShowMentionList(true);
    } else if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      handleArrowNavigation(e.key);
    } else if (e.key === "Tab" && selectedItem) {
      e.preventDefault();
      addToMentionedUsers(selectedItem);
      autoCompleteMention(selectedItem);
    } else if (e.key === "Delete" || e.key === "Backspace") {
      detectDeletingMention();

      if (e.key === "Backspace" && inputText.endsWith("@")) {
        setShowMentionList(false);
      }
    } else if (
      e.key === "Enter" &&
      inputText.trim().length > 0 &&
      !showMentionList
    ) {
      e.preventDefault();
      sendMessage();
    } else if (e.key === "Enter" && showMentionList) {
      e.preventDefault();
      addToMentionedUsers(selectedItem);
      autoCompleteMention(selectedItem);
    } else if (e.key === "Enter") {
      e.preventDefault();
      // Do nothing
    } else if (e.key === "Escape") {
      e.preventDefault();
      e.stopPropagation();
      setShowMentionList(false);
    } else if (e.code === "Space" && showMentionList) {
      setShowMentionList(false);
      setRefreshMentionList([]);
    }
  };

  const placeholderText = () => {
    if (isMuted) {
      return i18n.t("client.comments.muted_placeholder");
    } else if (parentMessage) {
      return i18n.t("client.comments.reply_placeholder");
    } else if (isFrozen) {
      return i18n.t("client.comments.frozen_placeholder");
    } else {
      return i18n.t(
        isChat
          ? "client.comments.input_placeholder_chat"
          : "client.comments.input_placeholder",
      );
    }
  };

  const focusMessageInput = () => {
    document.getElementById(`comments-message-input-${currentDate}`).focus();
  };

  useEffect(() => {
    if (!isMobile) focusMessageInput();

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.style.height = "auto";
      inputElement.style.height = `${inputElement.scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    scrollToSelected();
  }, [selectedItem]);

  useEffect(() => {
    channel?.getMyMutedInfo().then((res) => {
      setIsMuted(res.isMuted);
    });
    setInputText(messageToUpdate ? messageToUpdate.message : "");
    if (!isMobile) focusMessageInput();
  }, [channel]);

  return (
    <>
      <div className="message-input-container" onKeyDown={handleKeyDown}>
        {renderMentions()}
        <div className="message-input d-flex d-inline btn-outline position-relative">
          <textarea
            ref={inputRef}
            id={`comments-message-input-${currentDate}`}
            className="comments-message-input unstyled-btn message-input-text w-100"
            placeholder={placeholderText()}
            style={textareaStyle}
            rows={1}
            value={inputText}
            onChange={onMessageInputChange}
            autocomplete="off"
            disabled={isMuted || isFrozen}
          />
          {renderEmojiPicker()}
          {!(messageToUpdate || isMuted || isFrozen) && (
            <i
              class="bi bi-send-fill send-message-cta ms-2"
              onClick={() => sendMessage()}
              onKeyUp={() => sendMessage()}
            />
          )}
        </div>
      </div>
      {messageToUpdate && (
        <div className={"edit-actions"}>
          <button
            className="btn btn-primary mt-2 btn-cancel"
            onClick={() => setIsEditing(false)}
          >
            {i18n.t("client.comments.cancel")}
          </button>
          <button
            className="btn btn-primary mt-2 btn-save"
            onClick={() => {
              sendMessage();
              setIsEditing(false);
            }}
          >
            {i18n.t("client.comments.save")}
          </button>
        </div>
      )}
    </>
  );
}
