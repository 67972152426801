import { ApplicationController, useDebounce } from "stimulus-use";
import invariant from "tiny-invariant";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import { fetchJson } from "../fetch";
import ProgressBarController from "./progress_bar_controller";

// Connects to data-controller="video-form"
export default class VideoFormController extends ApplicationController {
  static values = {
    fallbackImage: String,
    profileSubdomain: String,
  };
  declare readonly hasFallbackImageValue: string;
  declare readonly fallbackImageValue: string;
  declare readonly profileSubdomainValue: string;

  static debounces = ["formValidateUrl"];
  static targets = [
    "serviceInput",
    "serviceIdInput",
    "imageUrlInput",
    "imageFileInput",
    "youtubeEmbed",
    "vimeoEmbed",
    "tiktokEmbed",
    "videoEmbed",
    "videoEmbedSource",
    "videoEmbedContainer",
    "videoPreviewsContainer",
    "removeImageBtn",
    "saveButton",
    "transcodeJobIdInput",
    "transcodeJobFrame",
  ];
  declare readonly serviceInputTarget: any;
  declare readonly serviceIdInputTarget: any;
  declare readonly imageUrlInputTarget: any;
  declare readonly imageFileInputTarget: any;
  declare readonly youtubeEmbedTarget: any;
  declare readonly vimeoEmbedTarget: any;
  declare readonly tiktokEmbedTarget: any;
  declare readonly videoEmbedTarget: any;
  declare readonly hasVideoEmbedTarget: boolean;
  declare readonly videoEmbedSourceTarget: any;
  declare readonly videoEmbedContainerTarget: any;
  declare readonly videoPreviewsContainerTarget: any;
  declare readonly removeImageBtnTarget: any;
  declare readonly saveButtonTarget: any;
  declare readonly hasSaveButtonTarget: boolean;
  declare readonly transcodeJobIdInputTarget: any;
  declare readonly transcodeJobFrameTarget: any;

  static outlets = ["progress-bar"];
  declare readonly progressBarOutlet: ProgressBarController;
  declare readonly hasProgressBarOutlet: boolean;

  player: Player | null = null;
  imagePoll: number | null = null;
  progressBarPoll: number | null = null;
  progressBarInitialized: boolean | null = null;
  progressBarPercentageComplete: number = 0;

  // The <has>Outlet callback is returning false when the outlet is connected.
  // This is a workaround which manually sets the value in the connect and
  // disconnect hooks.
  hasProgressBarOutletPatch: boolean | null = null;

  connect() {
    useDebounce(this);
    const initialUrlValue = (document.getElementById("video_url_input") as any)
      ?.value;

    if (initialUrlValue) {
      this.validateUrl(initialUrlValue);
    }
  }

  // The progress bar outlet is rendered in a refresh frame. This causes the
  // progress bar to be connected upon each refresh. This hook is used to either
  // initialize the values or set them to the appropriate state.
  progressBarOutletConnected(outlet: ProgressBarController, el: any) {
    if (this.progressBarInitialized) {
      // progress bar has already been initialized set default values
      this.progressBarOutlet.update(this.progressBarPercentageComplete);
    } else {
      // initializing the progress bar
      this.progressBarInitialized = true;
      this.progressBarPercentageComplete = 0;
    }

    this.hasProgressBarOutletPatch = true;
    this.progressBarOutlet.show();
  }

  progressBarOutletDisconnected(outlet: ProgressBarController, el: any) {
    this.hasProgressBarOutletPatch = false;
  }

  videoEmbedContainerTargetConnected(el: any) {
    let videoEl = el.querySelector(".video-js");
    invariant(
      videoEl,
      "expected to find child video el in videoEmbedContainer",
    );
    this.player = videojs(videoEl, {
      controlBar: { pictureInPictureToggle: false },
    });
  }

  videoEmbedContainerTargetDisconnected() {
    if (this.player) {
      this.player.dispose();
    }
  }

  transcodeJobIdInputTargetConnected(el: HTMLInputElement) {
    this.startPollingForPreviewImage(el);
    this.startTranscodingProgressBar(el);
  }

  startPollingForPreviewImage(el: HTMLInputElement) {
    if (this.imagePoll != null) {
      clearTimeout(this.imagePoll);
    }

    let profileSubdomain = el.dataset.videoProfileSubdomain;
    let previewUrl = `/hub/${profileSubdomain}/transcode_jobs/${el.value}/preview`;
    this.transcodeJobFrameTarget.src = previewUrl;
    this.serviceInputTarget.value = null;
    this.serviceIdInputTarget.value = null;
    this.hideAllEmbeds();
    this.transcodeJobFrameTarget.classList.remove("d-none");

    const pollForImage = async () => {
      let data = await fetchJson(previewUrl);

      if (data.status === "error") {
        return;
      }

      if (data.image_url == null) {
        this.imagePoll = window.setTimeout(pollForImage, 500);
      } else {
        this.imageUrlInputTarget.value = data.image_url;
        setImagePreview(data.image_url);
        displayImagePreview();
      }
    };
    pollForImage();
  }

  startTranscodingProgressBar(el: HTMLInputElement) {
    if (this.progressBarPoll != null) {
      this.clearProgressBarState();
    }

    let startTimeMs: number | null;

    // The following values are all estimates for a better user experience. A
    // transcoding job is in a state of : Not started | in progress | complete.
    //
    // These values serve to give some feedback for the in-progress state.
    const expectedTranscodingTimeMs = this.calculateExpectedTranscodingTimeMs(
      Number(el.dataset.videoByteSize),
    );
    const progressBarPollIntervalMs = 10;
    const initialState =
      el.dataset.videoTranscodingInProgress === "true" ? 50 : 0;

    const updateProgressBar = async () => {
      if (this.hasProgressBarOutletPatch) {
        startTimeMs = startTimeMs || Date.now();

        // Transcoding is an approximation. The progress bar will only ever get
        // to 99% full to prevent it appearing complete when it is still in
        // progress
        this.progressBarPercentageComplete = Math.min(
          ((Date.now() - startTimeMs) / expectedTranscodingTimeMs) * 100 +
            initialState,
          99,
        );

        this.progressBarOutlet.update(this.progressBarPercentageComplete);
      }

      if (!(this.progressBarPercentageComplete === 99)) {
        this.progressBarPoll = window.setTimeout(
          updateProgressBar,
          progressBarPollIntervalMs,
        );
      }
    };
    updateProgressBar();
  }

  // Expected time for transcoding is static estimation is based off existing
  // transcoding jobs. There isn't a lot of data so this can become more acurate
  // over time.
  //
  // Specifically, tanscoding doesn't appear to take longer than 20secs for any
  // video size and the average size is 40600856 bytes.
  calculateExpectedTranscodingTimeMs(videoByteSize: number) {
    const avgByteSize = 40600856;
    const maxEstimatedTime = 20000;

    return Math.min(
      this.lineOfBestFitApproximation(videoByteSize || avgByteSize),
      maxEstimatedTime,
    );
  }

  // Line of best fit was used to take existing (byte, ms) points and convert
  // them to a function that can be used for an approximation.
  lineOfBestFitApproximation(bytes: number) {
    return 0.000242527 * bytes + 8836;
  }

  disconnect() {
    if (this.imagePoll != null) {
      clearTimeout(this.imagePoll);
    }

    if (this.progressBarPoll != null) {
      this.clearProgressBarState();
    }
    this.progressBarInitialized = false;
  }

  clearProgressBarState() {
    if (this.progressBarPoll != null) {
      clearTimeout(this.progressBarPoll);
    }
    this.progressBarInitialized = false;
    this.progressBarPercentageComplete = 0;
  }

  formValidateUrl(e: any) {
    const value = e.target.value;

    if (this.validateUrl(value)) {
      this.fetchVideoDetails(value);
    }
  }

  validateUrl(value: string) {
    this.hideAllEmbeds();
    this.sizePreviewTargetsToDefault();

    const youtubeId = this.parseServiceUrl(
      value,
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?(?<id>[^#&?]{11}).*/,
    );

    if (youtubeId) {
      this.setValidUrl();
      this.serviceInputTarget.value = "youtube";
      this.serviceIdInputTarget.value = youtubeId;

      this.youtubeEmbedTarget.src = `https://www.youtube.com/embed/${youtubeId}`;
      this.youtubeEmbedTarget.classList.remove("d-none");

      return true;
    }

    const vimeoId = this.parseServiceUrl(
      value,
      /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?(?<id>[0-9]+)/,
    );

    if (vimeoId) {
      this.setValidUrl();
      this.serviceInputTarget.value = "vimeo";
      this.serviceIdInputTarget.value = vimeoId;

      this.vimeoEmbedTarget.src = `https://player.vimeo.com/video/${vimeoId}`;
      this.vimeoEmbedTarget.classList.remove("d-none");

      return true;
    }

    const tiktokId = this.parseServiceUrl(
      value,
      /^.*(tiktok\.com\/)@(?<creator>([A-z]|\.|_|[0-9])+)\/video\/(?<id>[0-9]{19})/,
    );

    if (tiktokId) {
      this.setValidUrl();
      this.serviceInputTarget.value = "tiktok";
      this.serviceIdInputTarget.value = tiktokId;

      this.sizePreviewTargetsForTikTok();
      this.tiktokEmbedTarget.src = `https://www.tiktok.com/embed/${tiktokId}`;
      this.tiktokEmbedTarget.classList.remove("d-none");

      return true;
    }

    this.serviceInputTarget.value = null;
    this.serviceIdInputTarget.value = null;
    this.setInvalidUrl();

    return false;
  }

  setValidUrl() {
    (document.getElementById("video_url_input") as any).classList.remove(
      "is-invalid",
    );

    if (this.hasSaveButtonTarget) {
      this.saveButtonTarget.disabled = false;
    }
    (document.getElementById("invalid-url-message") as any).classList.add(
      "d-none",
    );
    (document.getElementById("invalid-url-message") as any).classList.remove(
      "d-block",
    );
  }

  setInvalidUrl() {
    (document.getElementById("video_url_input") as any).classList.add(
      "is-invalid",
    );

    if (this.hasSaveButtonTarget) {
      this.saveButtonTarget.disabled = true;
    }
    (document.getElementById("invalid-url-message") as any).classList.remove(
      "d-none",
    );
    (document.getElementById("invalid-url-message") as any).classList.add(
      "d-block",
    );
  }

  hideAllEmbeds() {
    this.youtubeEmbedTarget.classList.add("d-none");
    this.vimeoEmbedTarget.classList.add("d-none");
    this.tiktokEmbedTarget.classList.add("d-none");
    this.transcodeJobFrameTarget.classList.add("d-none");
  }

  sizePreviewTargetsToDefault() {
    this.videoPreviewsContainerTarget.classList.remove("tiktok");
  }

  sizePreviewTargetsForTikTok() {
    this.videoPreviewsContainerTarget.classList.add("tiktok");
  }

  parseServiceUrl(url: string, regex: RegExp) {
    const match = url.match(regex);

    return match?.groups?.id;
  }

  fetchVideoDetails(url: string) {
    hidePlaceholder();
    showLoadingSpinner();

    this.fetchUrlDetails(url).then((response) => {
      if (response.error) {
      } else {
        (document.getElementById("video_url_input") as any).value =
          response.url;

        // Update video title
        const videoTitleInput = document.getElementById(
          "video_title_input",
        ) as any;

        if (videoTitleInput.value === "") {
          videoTitleInput.value = response.title
            .replace(" - YouTube", "")
            .replace(" on Vimeo", "")
            .replace(" | TikTok", "");
        }

        // Update thumbnail preview
        if (response.image) {
          this.imageUrlInputTarget.value = response.image;
          setImagePreview(response.image);
          displayImagePreview();
        } else {
          hidePreview();
          showPlaceholder();
        }
      }
    });
  }

  triggerFileUpload() {
    this.imageFileInputTarget.click();
  }

  uploadedFileChanged(e: any) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result);
      displayImagePreview();
    };

    reader.readAsDataURL(file);
  }

  async removeImage() {
    hidePreview();

    this.removeImageBtnTarget.style.display = "none";
    this.imageFileInputTarget.value = "";

    const url = (document.getElementById("video_url_input") as any).value;
    if (!url) {
      if (this.hasFallbackImageValue) {
        this.maybeShowImageRemoveBtn(this.fallbackImageValue);
        this.imageUrlInputTarget.value = this.fallbackImageValue;
        setImagePreview(this.fallbackImageValue);
        displayImagePreview();
      } else {
        showPlaceholder();
      }
      return;
    }

    showLoadingSpinner();

    let response = await this.fetchUrlDetails(url);

    if (response.error) {
      console.warn("error fetching url details", response);
      return;
    }

    // Update thumbnail preview
    if (response.image) {
      this.maybeShowImageRemoveBtn(response.image);
      this.imageUrlInputTarget.value = response.image;
      setImagePreview(response.image);
      displayImagePreview();
    } else {
      hidePreview();
      showPlaceholder();
    }
  }

  async fetchUrlDetails(url: string) {
    return fetch(
      `/hub/${this.profileSubdomainValue}/forms/url_details?url=${url}`,
    ).then((res) => res.json());
  }

  maybeShowImageRemoveBtn(imgUrl: string) {
    if (!(imgUrl.includes("ytimg.com") || imgUrl.includes("vimeocdn.com")))
      this.removeImageBtnTarget.style.display = "inline-block";
  }

  onDrawerHide() {
    this.player?.pause();
  }
}

const setImagePreview = (imageUrl: any) => {
  (document.getElementById("image-preview") as any).src = imageUrl;
};

/* Composed UI Element helpers */
const hideLoadingSpinner = () =>
  (document.getElementById("image-preview-spinner") as any).classList.add(
    "d-none",
  );
const showLoadingSpinner = () =>
  (document.getElementById("image-preview-spinner") as any).classList.remove(
    "d-none",
  );

const hidePlaceholder = () =>
  (document.getElementById("image-preview-placeholder") as any).classList.add(
    "d-none",
  );

const showPlaceholder = () =>
  (
    document.getElementById("image-preview-placeholder") as any
  ).classList.remove("d-none");

const hidePreview = () =>
  (document.getElementById("image-preview-wrapper") as any).classList.add(
    "d-none",
  );
const showPreview = () =>
  (document.getElementById("image-preview-wrapper") as any).classList.remove(
    "d-none",
  );
const showPreviewContainer = () =>
  (document.getElementById("image-preview-container") as any).classList.remove(
    "d-none",
  );

const displayImagePreview = () => {
  hideLoadingSpinner();
  hidePlaceholder();
  showPreviewContainer();
  showPreview();
};
