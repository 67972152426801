import React from "react";
import { Appearance } from "@frigade/react";

export const announcementStyles = {
  styleOverrides: {
    announcementButton: {
      backgroundColor: "#000000",
      borderColor: "#000000",
      borderRadius: "22px",
      height: "44px",
    },
    announcementButtonSecondary: {
      borderRadius: "22px",
      height: "44px",
      backgroundColor: "#F1F2F4",
      border: 0,
    },
    announcementBackButtonSecondary: {
      borderRadius: "22px",
      height: "44px",
      backgroundColor: "#F1F2F4",
      border: 0,
    },
    modalContainer: {
      maxWidth: "580px",
      padding: "32px",
      height: "auto !important",
    },
    announcementContainer: {
      maxWidth: "fit-content",
    },
    announcementTitle: {
      textAlign: "left",
      justifyContent: "flex-start",
      fontSize: "24px",
      lineHeight: "32px",
    },
    announcementSubtitle: {
      textAlign: "left",
      fontSize: "14px",
      color: "#32363E",
    },
  },
  theme: {
    colorPrimary: "#000000",
  },
} as Appearance;

export const tourStyles = {
  styleOverrides: {
    tourHighlightInnerCircle: {
      width: "24px",
      height: "24px",
    },
    tourHighlightOuterCircle: {
      width: "140%",
      height: "140%",
      backgroundColor: "rgb(26 93 77)",
    },
    smallTitle: {
      fontSize: "18px",
      marginBottom: "8px",
    },
    smallSubtitle: {
      marginBottom: "0px",
    },
    // This controls the z-index of the tooltip itself
    tooltipContainerWrapper: {
      zIndex: "1032 !important",
    },
    button: {
      backgroundColor: "#000000",
      border: 0,
      borderRadius: "22px",
      paddingLeft: "18px",
      paddingRight: "18px",
    },
    buttonSecondary: {
      borderRadius: "22px",
      paddingLeft: "18px",
      paddingRight: "18px",
    },
    tooltipCTAContainer: {
      flexDirection: "row-reverse",
    },
    tooltipContentContainer: {
      padding: "18px 18px 0px",
    },
    tooltipContainer: {
      borderRadius: "16px",
      boxShadow: "0px 4px 60px 0px rgba(0, 0, 0, 0.15)",
    },
  },
} as Appearance;

export const checklistStyles = {
  styleOverrides: {
    progressBadgeContainer: {
      border: 0,
      backgroundColor: "transparent",
      padding: 0,
    },
  },
} as Appearance;

export const embeddedTipStyles = {
  styleOverrides: {
    embeddedTipContainer: {
      marginLeft: "50px",
      marginRight: "50px",
      marginBottom: "40px",
      width: "100%",
      maxWidth: "fit-content",
      borderRadius: "24px",
      border: "1px solid #3AC0A0",
      background: "#E7F4E8",
      padding: "16px",
    },
    embeddedTipButton: {
      backgroundColor: "#000000",
      borderColor: "#000000",
      borderRadius: "22px",
      height: "44px",
    },
    embeddedTipTitle: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
    },
    embeddedTipSubtitle: {
      color: "#32363E",
    },
  },
} as Appearance;

export function EditorStyles() {
  return (
    <style>{`
.drawer-content {
  padding-bottom: 0px;
}

.publish-step .publish-step-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  max-height: 260px;
  margin-bottom: 20px;
}

.publish-step {
  font-size: 14px;
  line-height: 22px;
}

.publish-step button {
  color: #FFFFFF;
  background-color: #000000;
  border-radius: 22px;
  border: 0;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.publish-step button:hover {
  opacity: 0.8;
}


.publish-step .publish-step-text {
  margin-bottom: 16px;
}

.publish-step button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.publish-step .publish-step-publish-button {
  background-color: #3AC0A0;
}

.publish-step .publish-step-ctas {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px
}


  `}</style>
  );
}
