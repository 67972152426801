import React, { useState, useEffect } from "react";

const TimeAgo = ({ timestamp }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const updateTimeAgo = () => {
      const now = new Date().getTime();
      const difference = now - timestamp;

      const minute = 60 * 1000;
      const hour = minute * 60;
      const day = hour * 24;

      if (difference < minute) {
        setTimeAgo("1 m");
      } else if (difference < hour) {
        const minutes = Math.floor(difference / minute);
        setTimeAgo(`${minutes} m`);
      } else if (difference < day) {
        const hours = Math.floor(difference / hour);
        setTimeAgo(`${hours} hr`);
      } else {
        const days = Math.floor(difference / day);
        setTimeAgo(`${days} day${days > 1 ? "s" : ""}`);
      }
    };

    updateTimeAgo();

    // Update time every minute for dynamic time display
    const interval = setInterval(updateTimeAgo, 60 * 1000);

    return () => clearInterval(interval);
  }, [timestamp]);

  return <span>{timeAgo}</span>;
};

export default TimeAgo;
