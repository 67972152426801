import React, { useEffect } from "react";
import { FrigadeForm, useFlows } from "@frigade/react";
import { LocationTypeahead } from "./LocationTypeahead";
import { CategoryTypeahead } from "./CategoryTypeahead";
import { ProfilePicture } from "./ProfilePicture";
import { TextareaWithCharacterCount } from "./TextareaWithCharacterCount";
import { WelcomeSplash } from "./WelcomeSplash";
import { FrigadeAppProps } from "../index";
import { Header } from "./Header";
import { Blocks } from "./Blocks";
import { Customize } from "./Customize";
import { SocialLinks } from "./SocialLinks";
import { SocialConnections } from "./SocialConnections";
import { onboardingFormStyle } from "./OnboardingFormStyles";

export function OnboardingForm(props: FrigadeAppProps) {
  const {
    getCurrentStepIndex,
    markStepCompleted,
    markStepStarted,
    getFlowStatus,
  } = useFlows();

  const [bioText, setBioText] = React.useState(props.profile?.bio ?? "");
  const flowId = props.flowIdCreatorOnboardingForm ?? "";
  const currentStepIndex = getCurrentStepIndex(flowId);
  const isFlowCompleted = getFlowStatus(flowId) === "COMPLETED_FLOW";

  const flowStepIds = [
    "welcome",
    "interests",
    "profile-info",
    "content",
    "customize",
    "social-connections",
  ];

  useEffect(() => {
    if (currentStepIndex > 1) {
      attachBackButton();
    }
  }, [currentStepIndex]);

  useEffect(() => {
    if (isFlowCompleted) {
      window.location.href = `/hub/${props.profile.subdomain}/edit?onboarding_completed=true`;
    }
  }, [isFlowCompleted]);

  if (isFlowCompleted || !flowId) {
    return null;
  }

  const attachBackButton = () => {
    const backButton: HTMLButtonElement = document.createElement("button");
    backButton.id = "backButton";
    backButton.className = "btn btn-lg btn-outline h-44px";
    backButton.innerHTML = "Back";
    backButton.onclick = () => {
      const onboardingContainer = document.querySelector(
        ".onboardingContainer",
      );
      const loadingContainer = document.querySelector(".loadingContainer");
      markStepStarted(flowId, flowStepIds[currentStepIndex - 1]);
      onboardingContainer?.setAttribute("style", "display: none");
      loadingContainer?.setAttribute("style", "display: block");
      // Form states get weird if we don't reload the page after going back
      // This is a temporary solution until we can find a better way to handle this
      setTimeout(() => {
        window.location.reload();
      }, 600);
    };

    const ctaWrapper = document.querySelector(
      ".fr-ctaWrapper, .cfr-ctaWrapper",
    );
    ctaWrapper?.prepend(backButton);
  };

  return (
    <div
      style={{
        marginTop: "112px",
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        height: `calc(100vh - ${window.innerWidth < 400 ? "190px" : "112px"})`,
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingBottom: "40px",
        overflow: "hidden",
      }}
    >
      <Header {...props} />

      <div
        className="onboardingContainer"
        style={{
          maxWidth: currentStepIndex === 0 ? "inherit" : "500px",
          width: currentStepIndex === 0 ? "100%" : "500px",
        }}
      >
        <FrigadeForm
          flowId={flowId}
          showFooter={currentStepIndex !== 0}
          customFormElements={{
            locationTypeahead: LocationTypeahead,
            categoryTypeahead: CategoryTypeahead,
            profilePicture: ProfilePicture,
            textareaWithCharacterCount: TextareaWithCharacterCount,
            blocks: Blocks,
            customize: (p) => (
              <Customize
                title={
                  props.profile.title ||
                  props.profile.brand_name ||
                  props.profile.subdomain
                }
                subdomain={props.profile.subdomain}
                onSaveInputData={p.onSaveInputData}
              />
            ),
            socialLinks: SocialLinks,
            socialConnections: () => (
              <SocialConnections subdomain={props.profile.subdomain} />
            ),
          }}
          onButtonClick={(step, index, cta) => {
            // Mark step completed if secondary button (skip) is clicked.
            if (cta === "secondary") {
              markStepCompleted(flowId, step.id);
            }

            return true;
          }}
          validationHandler={async (
            step,
            index,
            nextStep,
            allFormData,
            stepSpecificFormData,
          ) => {
            const data = new FormData();
            data.append("_method", "patch");
            data.append(
              "authenticity_token",
              props.formAuthenticityToken ?? "",
            );

            if (stepSpecificFormData.stepId === "profile-info") {
              const bio = stepSpecificFormData?.data?.bio?.text ?? "";
              const location =
                stepSpecificFormData?.data?.location?.location ?? "";
              const categoryIds =
                stepSpecificFormData?.data?.categories?.defaultCategories ?? [];
              const customCategories =
                stepSpecificFormData?.data?.categories?.customCategories ?? [];
              const image =
                stepSpecificFormData?.data["profile-picture"]?.image ?? "";

              if (
                !bio &&
                !categoryIds.length &&
                !customCategories.length &&
                !location
              ) {
                return true;
              }

              if (bio && bio !== props.profile.bio) {
                data.append("profile[bio]", bio);
                setBioText(bio);
              }
              if (categoryIds) {
                if (categoryIds.length > 0) {
                  categoryIds.forEach((categoryId: string) => {
                    data.append("profile[category_ids][]", categoryId);
                  });
                } else {
                  data.append("profile[category_ids][]", "");
                }
              }
              if (customCategories) {
                if (customCategories.length > 0) {
                  customCategories.forEach((category: string) => {
                    data.append("profile[custom_categories][]", category);
                  });
                } else {
                  data.append("profile[custom_categories][]", "");
                }
              }
              if (location) {
                data.append("profile[location]", location);
              }
              if (image) {
                document.getElementById("upload-image-btn")?.click();
                await new Promise((resolve) => setTimeout(resolve, 1000));
              }
              try {
                await fetch(`/hub/${props.profile.subdomain}/profile`, {
                  body: data,
                  method: "POST",
                  credentials: "include",
                });
              } catch (e) {
                console.error(e);
                return false;
              }
            } else if (stepSpecificFormData.stepId === "customize") {
              const themeColor =
                stepSpecificFormData?.data?.customize?.themeColor;
              const font = stepSpecificFormData?.data?.customize?.font;

              if (themeColor) {
                data.append("profile[theme_color_hex]", themeColor);
                data.append("profile[custom_theme]", "0");
                data.append("profile[custom_theme]", "1");
              }
              if (font) {
                data.append("profile[font]", font);
              }

              try {
                await fetch(
                  `/hub/${props.profile.subdomain}/editor/customize_designs`,
                  {
                    body: data,
                    method: "POST",
                    mode: "cors",
                    credentials: "include",
                  },
                );
              } catch (e) {
                console.error(e);
                return false;
              }
            }

            // Returning false will stop the step from submitting.
            return true;
          }}
          customVariables={{
            formAuthenticityToken: props.formAuthenticityToken ?? "",
            profile: JSON.stringify(props.profile),
            subdomain: props.profile.subdomain,
            blocks: JSON.stringify(props.blocks),
          }}
          customStepTypes={{
            welcomeSplash: WelcomeSplash,
          }}
          appearance={onboardingFormStyle}
          onComplete={() => {
            // Clears any temporary form data saved during onboarding
            Object.keys(localStorage).forEach((key) => {
              if (key.startsWith("frigade-")) {
                localStorage.removeItem(key);
              }
            });
          }}
        />
      </div>
      <div className="loadingContainer" style={{ display: "none" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "4rem", height: "4rem" }}
          />
        </div>
      </div>
    </div>
  );
}
