import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import i18n from "../../utils/i18n";
import CloseCircle from "../icons/CloseCircle";

export default function AddEditChannelDetailsModal({
  sb,
  channel,
  show,
  onHide,
  onSubmit,
  profileId,
  user,
}) {
  const [channelName, setChannelName] = useState(channel?.name);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (channel) {
      const params = {
        name: channelName,
      };

      await channel.updateChannel(params);
    } else {
      channel = await sb.groupChannel.createChannel({
        name: channelName,
        customType: profileId,
        isPublic: true,
        operatorUserIds: [user.id],
        userIds: [user.id],
      });
    }

    setIsLoading(false);
    onHide(channel);
    setChannelName("");
    if (onSubmit) onSubmit();
  };

  //Bootstrap hijacking focus on modal so this will stop it
  function bypassFocus() {
    const modal = document.getElementById(
      "community-edit-channel-settings-modal",
    );
    if (modal) {
      modal.addEventListener("focusin", (e) => e.stopPropagation());
    }
  }

  useEffect(() => {
    bypassFocus();
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      id={"community-edit-channel-settings-modal-container"}
    >
      <div id="community-edit-channel-settings-modal">
        <Modal.Header>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={() => onHide()}
            aria-label="Close"
          >
            <CloseCircle />
          </button>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title>
            {i18n.t(
              channel
                ? "client.community.edit_channel_info"
                : "client.community.add_channel",
            )}
          </Modal.Title>
          <form onSubmit={handleSubmit}>
            <div
              id={"edit-profile-modal-errors"}
              className={"errors text-danger d-none"}
            />
            <div className="mb-4 parliament-input">
              <label htmlFor="username" className="form-label">
                {i18n.t("client.community.channel_name")}
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={channelName}
                onChange={(e) => setChannelName(e.target.value)}
              />
            </div>
            <div className="d-flex">
              <button
                type="submit"
                disabled={isLoading}
                className="btn btn-lg btn-white w-50"
              >
                {!isLoading &&
                  i18n.t(
                    channel
                      ? "client.community.save"
                      : "client.community.create",
                  )}
                {isLoading && <div className="spinner-border" role="status" />}
              </button>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
}
