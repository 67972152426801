import { Controller } from "@hotwired/stimulus";
import { FrameElement } from "../types/turbo";

// Connects to data-controller="profile-publish-form"
export default class ProfilePublishFormController extends Controller {
  static targets = ["frame", "button"];
  declare readonly frameTarget: FrameElement;
  declare readonly buttonTarget: HTMLButtonElement;

  static values = {
    frameSrc: String,
  };
  declare readonly frameSrcValue: string;

  timeoutId: number | undefined;
  queuedButtonContents: string | undefined;

  connect(): void {}

  buttonTargetConnected() {
    if (this.queuedButtonContents != null) {
      this.replaceButtonContentsTemporarily(this.queuedButtonContents);
      this.queuedButtonContents = undefined;
    }
  }

  disconnect(): void {
    if (this.timeoutId != null) clearTimeout(this.timeoutId);
  }

  reloadForm(buttonContents?: string) {
    this.queuedButtonContents = buttonContents;
    this.frameTarget.src = "";
    this.frameTarget.src = this.frameSrcValue;
  }

  replaceButtonContents(contents: string) {
    this.buttonTarget.innerHTML = contents;
  }

  replaceButtonContentsTemporarily(contents: string) {
    let originalContents = this.buttonTarget.innerHTML;
    this.replaceButtonContents(contents);
    this.timeoutId = window.setTimeout(() => {
      this.replaceButtonContents(originalContents);
    }, 1000);
  }
}
