import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="checkbox-reveal"
export default class extends Controller {
  static targets = ["checkbox", "reveal"];

  declare readonly checkboxTarget: HTMLInputElement;
  declare readonly revealTarget: HTMLFieldSetElement | HTMLDivElement;

  connect() {
    this.render();
  }

  render() {
    let isChecked = this.checkboxTarget.checked;
    this.revealTarget.classList.toggle("d-none", !isChecked);
    if (this.revealTarget instanceof HTMLFieldSetElement) {
      this.revealTarget.disabled = !isChecked;
    }

    const textInputFields = document.querySelectorAll("input[type='text']");

    textInputFields.forEach((inputField: Element) => {
      inputField.addEventListener("click", () => {
        if (inputField instanceof HTMLInputElement) {
          inputField.value = "";
        }
      });
    });
  }
}
