import { Controller } from "@hotwired/stimulus";
import { post } from "../fetch";

import { FrameElement } from "../types/turbo";
import type { ChartConfiguration, Tick } from "chart.js";
import {
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-luxon";
import shortNumber from "short-number";
import invariant from "tiny-invariant";

Chart.register(TimeScale);
Chart.register(LineController);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(Filler);
Chart.register(Tooltip);
Chart.register(Legend);

// Connects to data-controller="stats"
export default class extends Controller {
  static values = {
    chart: Object,
    chartQuery: String,
  };
  declare chartValue: any;
  declare chartQueryValue: string;

  static targets = ["activitySummary", "timeframeInput", "chartCanvas"];
  declare readonly activitySummaryTarget: FrameElement;
  declare readonly timeframeInputTarget: HTMLInputElement;
  declare readonly chartCanvasTarget: HTMLCanvasElement;

  statsChart: AppChart | undefined;

  connect() {
    this.statsChart = initChart(this.chartCanvasTarget);
    this.updateChart();
  }

  /*
    To use stimulus to render the table, we need to simulate a link click.
    Here we add relevant query params to the query link and click it.
  */
  updateActivity(e: any) {
    const activityQueryLink: any = document.querySelector(
      "#activity-query-link",
    );
    const url = new URL(activityQueryLink.href);

    url.searchParams.set("months", e.target.value);

    activityQueryLink.href = url.toString();
    activityQueryLink.click();
  }

  updateSearchQuery(e: any) {
    const searchQueryLink: any = document.querySelector("#activity-query-link");
    const url = new URL(searchQueryLink.href);
    url.searchParams.set("query", e.target.value);
    searchQueryLink.href = url.toString();
    searchQueryLink.click();
  }

  updateChart() {
    const timeframe = this.timeframeInputTarget.value;

    let url = new URL(window.location.href);
    url.searchParams.set("months", timeframe);

    this.activitySummaryTarget.src = url.toString();

    const metricSelector = document.querySelector("#metrics-selector");
    const metrics = Array.from(
      metricSelector!.querySelectorAll("option:checked"),
    ).map((option: any) => option.value);

    let statsChart = this.statsChart;

    post(this.chartQueryValue, { metrics, timeframe })
      .then((res) => res.json())
      .then((json) => {
        invariant(statsChart, "expected stats chart");
        statsChart.data.datasets = json.datasets;
        statsChart.update();
      });
  }
}

const initChart = (canvasEl: HTMLCanvasElement) => {
  const data = {
    datasets: [],
  };

  return new Chart(canvasEl, {
    type: "line",
    data: data,
    options: {
      plugins: {
        filler: {
          propagate: false,
        },

        legend: {
          display: true,
          position: "bottom",
          labels: {
            boxWidth: 2,
            color: "#000000",
            padding: 32,
          },
        },
      },

      scales: {
        x: {
          type: "time",
          time: {
            unit: "week",
          },
          grid: {
            drawOnChartArea: false,
          },
        },
        ...generateYScales(),
      },
      interaction: {
        intersect: false,
      },
    },
  });
};

type AppChart = ReturnType<typeof initChart>;

let currencyCallback = (
  tickValue: string | number,
  _index: number,
  _ticks: Tick[],
) => {
  let tickNum =
    typeof tickValue === "number" ? tickValue : parseInt(tickValue, 10);

  if (tickNum < 1000) {
    return `$${Math.floor(tickNum)}`;
  } else {
    return `$${shortNumber(tickNum)}`;
  }
};

let numberCallback = (
  tickValue: string | number,
  _index: number,
  _ticks: Tick[],
) => {
  return shortNumber(tickValue);
};

let positions = ["left", "right"] as const;
type Position = (typeof positions)[number];

let colors: Record<Position, string> = {
  left: "#000000",
  right: "#000000",
} as const;

let metrics = {
  Revenue: { type: "currency", positive: false },
  Orders: { type: "number", positive: true },
  Downloads: { type: "number", positive: true },
  Views: { type: "number", positive: true },
} as const;

type Scales = Required<ChartConfiguration>["options"]["scales"];

function generateYScales(): Scales {
  let scales: Scales = {};

  for (let position of positions) {
    for (let [key, value] of Object.entries(metrics)) {
      let yAxisId = `y-${key}-${position}`;
      scales[yAxisId] = {
        type: "linear",
        display: "auto",
        position: position,
        ...(value.positive && { min: 0 }),
        beginAtZero: true,
        ticks: {
          color: colors[position],
          precision: 0,
          ...(value.type === "currency" && { callback: currencyCallback }),
          ...(value.type === "number" && { callback: numberCallback }),
        },
        ...(position === "right" && {
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        }),
        border: {
          display: false,
        },
        title: {
          display: false,
          text: key,
          align: "center",
          color: colors[position],
        },
      };
    }
  }
  return scales;
}
