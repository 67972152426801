import React from "react";

export default function GreaterThan() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2926 17.2926C8.11252 17.4817 8.01349 17.7337 8.01668 17.9948C8.01986 18.2559 8.125 18.5054 8.30964 18.6901C8.49428 18.8747 8.7438 18.9798 9.0049 18.983C9.26601 18.9862 9.51801 18.8872 9.7071 18.7071L15.7071 12.7071C15.8946 12.5195 15.9999 12.2651 15.9999 11.9999C15.9999 11.7346 15.8946 11.4802 15.7071 11.2926L9.7071 5.2926C9.51801 5.11252 9.26601 5.01349 9.0049 5.01668C8.7438 5.01986 8.49428 5.125 8.30964 5.30964C8.125 5.49428 8.01986 5.7438 8.01668 6.0049C8.01349 6.26601 8.11252 6.51801 8.2926 6.7071L13.5861 11.9999L8.2926 17.2926Z"
        fill="currentcolor"
        fillOpacity="0.75"
      />
    </svg>
  );
}
