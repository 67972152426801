import React, { useEffect, useState } from "react";
import { colors } from "./OnboardingFormStyles";

function Color({
  hexColor,
  name,
  selectedColor,
  updateColors,
}: {
  hexColor: string;
  name: string;
  selectedColor: string;
  updateColors: (color: string) => void;
}) {
  const id = `profile_theme_color_preset_${name}`;

  return (
    <div>
      <input
        className="theme-preset-button visually-hidden hex-color-picker-element"
        type="radio"
        value="white"
        name="profile[theme_color_preset]"
        checked={selectedColor === hexColor}
        id={id}
        onClick={() => {
          updateColors(hexColor);
        }}
      />
      <label
        style={
          {
            "--theme-picker-background": hexColor,
          } as React.CSSProperties
        }
        data-hex-color="#ffffff"
        className="theme-picker-label theme-preset hex-color-picker-element"
        // @ts-ignore
        for={id}
        onClick={() => {
          updateColors(hexColor);
        }}
        onKeyUp={() => {
          updateColors(hexColor);
        }}
      >
        <div className="visually-hidden">{name}</div>
      </label>
    </div>
  );
}

export interface CustomizeProps {
  title: string;
  subdomain: string;
  onSaveInputData: (data: any) => void;
}

export function Customize({
  title,
  subdomain,
  onSaveInputData,
}: CustomizeProps) {
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [font, setFont] = useState("inter");
  const [fontFamily, setFontFamily] = useState("Inter");
  const [textColor, setTextColor] = useState("#000000");

  const updateColors = (color: string) => {
    setSelectedColor(color);
    fetch(
      `/hub/${subdomain}/editor/customize_designs/text_color?hex=${color.slice(1)}`,
    )
      .then((res) => res.json())
      .then((data) => {
        setTextColor(data.textColor);
      });
  };

  const fontMap: { [key: string]: string } = {
    inter: "Inter",
    ubuntu: "Ubuntu",
    oswald: "Oswald",
    roboto: "Roboto",
    lora: "Lora",
    libre_baskerville: "Libre Baskerville",
    nunito: "Nunito",
  };

  useEffect(() => {
    onSaveInputData({
      themeColor: selectedColor,
      font: font,
    });
  }, [selectedColor, font]);

  // import font family from google fonts
  useEffect(() => {
    const fontName = font
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("+");
    const link = document.createElement("link");
    link.href = `https://fonts.googleapis.com/css2?family=${fontName}:wght@400;500;700&display=swap`;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  }, [font]);

  return (
    <div>
      <div className="mb-4 container">
        <div className="mb-3">
          <div className="block-show-section-heading mt-0">Color</div>
          <div className="mt-3">
            <div
              className="d-flex gap-2 mb-2"
              style={{
                flexWrap: "wrap",
              }}
            >
              {colors.map((color) => (
                <Color
                  name={color.name}
                  hexColor={color.hexColor}
                  updateColors={updateColors}
                  selectedColor={selectedColor}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="d-flex flex-row gap-2 align-items-center">
          <div>
            <input
              name="profile[custom_theme]"
              type="hidden"
              autoComplete="off"
            />
            <input
              className="visually-hidden theme-custom hex-color-picker-element"
              id="profile_custom_theme_custom"
              data-customize-theme-form-target="customColorCheckBox"
              type="color"
              value={selectedColor}
              onChange={(e) => {
                updateColors(e.target.value);
              }}
              name="profile[custom_theme]"
            />
            <label
              style={
                {
                  "--theme-picker-background": selectedColor,
                } as React.CSSProperties
              }
              data-hex-color="#ffffff"
              data-customize-theme-form-target="hexColorPickerLabel"
              className="theme-picker-label hex-color-picker-element"
              // @ts-ignore
              for="profile_custom_theme_custom"
            >
              <div className="visually-hidden" />
            </label>
          </div>
          <input
            className="hex-color-picker-element parliament-input p2 form-control h-44 "
            // @ts-ignore
            maxLength="7"
            value={selectedColor.toUpperCase()}
            data-customize-theme-form-target="hexInputField"
            type="text"
            onChange={(e) => {
              updateColors(e.target.value);
            }}
            name="profile[theme_color_hex]"
            id="profile_theme_color_hex"
          />
        </div>
      </div>

      <div className="block-show-section-heading">Fonts</div>
      <div className="form-floating">
        <select
          className="form-control parliament-input"
          placeholder="Font"
          name="profile[font]"
          id="profile_font"
          value={font}
          onChange={(e) => {
            setFont(e.target.value);
            setFontFamily(fontMap[e.target.value]);
          }}
        >
          <option value="inter">Inter</option>
          <option value="ubuntu">Ubuntu</option>
          <option value="oswald">Oswald</option>
          <option value="roboto">Roboto</option>
          <option value="lora">Lora</option>
          <option value="libre_baskerville">Libre Baskerville</option>
          <option value="nunito">Nunito</option>
        </select>

        <label>Font</label>
      </div>

      <label className="block-show-section-heading">Preview</label>
      <div
        className="theme-profile-preview rounded-16px p-2 pt-3 profile-text text-center"
        style={{
          backgroundColor: selectedColor,
          fontFamily: fontFamily,
          color: textColor,
          width: "100%",
          height: "100%",
          outline: "1px solid #adb5bd",
        }}
      >
        <h4>{title}</h4>
      </div>
    </div>
  );
}
