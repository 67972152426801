import { Controller } from "@hotwired/stimulus";
import { fetchJson } from "../fetch";
import { loadingSpinner } from "../utilities";

// Connects to data-controller="page-access-level"
export default class extends Controller {
  static targets = [
    "accessLevelCriteriaField",
    "accessLevelCustomCriteriaField",
  ];
  static values = {
    hiddenClass: { type: String, default: "d-none" },
    selectPublic: { type: String, default: "public" },
    selectProduct: { type: String, default: "block_purchase" },
    selectMembershipTier: { type: String, default: "membership_tier" },
    selectRewardsEnrollment: { type: String, default: "rewards_enrollment" },
    selectRewardsPoints: { type: String, default: "rewards_points" },
    selectRewardsTopPercentile: {
      type: String,
      default: "rewards_top_percentile",
    },
    profileSubdomain: { type: String },
  };

  declare readonly accessLevelCriteriaFieldTarget: HTMLElement;
  declare readonly accessLevelCustomCriteriaFieldTarget: HTMLInputElement;
  declare readonly hasAccessLevelCriteriaFieldTarget: boolean;
  declare readonly hasAccessLevelCustomCriteriaFieldTarget: boolean;
  declare hiddenClassValue: string;
  declare selectPublicValue: string;
  declare selectProductValue: string;
  declare selectMembershipTierValue: string;
  declare selectRewardsEnrollmentValue: string;
  declare selectRewardsPointsValue: string;
  declare selectRewardsTopPercentileValue: string;
  declare readonly profileSubdomainValue: string;

  saveChanges(event: Event) {
    const form = document.getElementById("page-access-level-form");
    if (form instanceof HTMLFormElement) {
      form.submit();
    }
  }

  async handleChangedAccessLevel(event: Event) {
    if (
      !this.hasAccessLevelCriteriaFieldTarget ||
      !this.hasAccessLevelCustomCriteriaFieldTarget
    ) {
      return;
    }

    const target = event.target as HTMLSelectElement;
    const criteriaNotRequiredOptions = [
      this.selectPublicValue,
      this.selectRewardsEnrollmentValue,
    ];
    const customCriteriaAllowedOptions = [
      this.selectRewardsPointsValue,
      this.selectRewardsTopPercentileValue,
    ];

    if (criteriaNotRequiredOptions.includes(target.value)) {
      this.hideCriteriaFields(true, true);
    } else if (customCriteriaAllowedOptions.includes(target.value)) {
      if (target.value === this.selectRewardsPointsValue) {
        this.accessLevelCustomCriteriaFieldTarget.value = "10000";
      } else {
        this.accessLevelCustomCriteriaFieldTarget.value = "10";
      }
      this.hideCriteriaFields(true, false);
    } else {
      try {
        const accessLevelPageIdElement = document.getElementById(
          "access-level-page-id",
        );
        const pageId = accessLevelPageIdElement?.innerText;
        const queryParams = new URLSearchParams({
          access_level: target.value,
        }).toString();
        const data = await fetchJson(
          `/hub/${this.profileSubdomainValue}/p/${pageId}/access_level_criteria_options?${queryParams}`,
          { method: "GET" },
        );
        this.updateCriteriaDropdown(data.options);
      } catch (error) {
        console.error("Error updating criteria dropdown", error);
      }
    }
    this.saveChanges(event);
  }

  handleChangedCriteria(event: Event) {
    this.saveChanges(event);
  }

  updateCriteriaDropdown(options: string[]) {
    this.accessLevelCriteriaFieldTarget.innerHTML = "";

    if (options.length === 0) {
      const defaultOption = document.createElement("option");
      defaultOption.innerHTML = "";
      defaultOption.value = "";
      this.accessLevelCriteriaFieldTarget.appendChild(defaultOption);
    } else {
      options.forEach((option) => {
        const optionElement = document.createElement("option");
        optionElement.innerHTML = option[0];
        optionElement.value = option[1];
        this.accessLevelCriteriaFieldTarget.appendChild(optionElement);
      });
    }
    this.hideCriteriaFields(false, true);
  }

  hideCriteriaFields(
    hideDropdownCriteria: boolean,
    hideCustomCriteria: boolean,
  ) {
    this.accessLevelCriteriaFieldTarget.parentElement?.classList.toggle(
      this.hiddenClassValue,
      hideDropdownCriteria,
    );

    this.accessLevelCustomCriteriaFieldTarget.parentElement?.classList.toggle(
      this.hiddenClassValue,
      hideCustomCriteria,
    );
  }
}
