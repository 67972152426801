import { Controller } from "@hotwired/stimulus";
import { FrameElement } from "../types/turbo";
import invariant from "tiny-invariant";

export default class ConstantRefreshFrameController extends Controller {
  static values = {
    frameId: String,
    url: String,
    interval: Number,
  };

  declare frameIdValue: string;
  declare urlValue: string;
  declare intervalValue: number;
  private refreshInterval: number | null = null;
  private isLoading: boolean = false;

  connect() {
    const frame = document.getElementById(this.frameIdValue) as FrameElement;
    invariant(frame, "Expected to find frame");
    const url = this.urlValue;
    const interval = this.intervalValue || 3000; // Default interval is 3 seconds

    const refreshFrame = async () => {
      if (!this.isLoading) {
        try {
          this.isLoading = true;
          frame.src = url;
        } catch (error) {
          // Handle errors here, e.g., show an error message
          console.error("Error refreshing frame:", error);
        } finally {
          this.isLoading = false;
        }
      }
    };

    refreshFrame();

    this.refreshInterval = window.setInterval(refreshFrame, interval);
  }

  disconnect() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }
}
