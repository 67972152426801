import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

type ButtonState = "pending" | "loading" | "success" | "error";

/**
 * Meant to silently submit forms on a debounce
 * will not display validation errors. Meant to replace auto_save_controller
 *
 * Connects to data-controller="silent-submit"
 */
export default class extends Controller {
  static debounces = ["submitLater"];
  static classes = ["hidden"];

  static targets = [
    "submitButtonPending",
    "submitButtonLoading",
    "submitButtonSuccess",
    "submitButtonError",
  ];

  declare readonly submitButtonPendingTarget: HTMLElement;
  declare readonly submitButtonLoadingTarget: HTMLElement;
  declare readonly submitButtonSuccessTarget: HTMLElement;
  declare readonly submitButtonErrorTarget: HTMLElement;

  declare readonly hiddenClasses: Array<string>;

  timeoutId: number | undefined;

  connect() {
    useDebounce(this, { wait: 1000 });
  }

  setButtonState(state: ButtonState) {
    let buttonMap: Record<ButtonState, HTMLElement> = {
      pending: this.submitButtonPendingTarget,
      error: this.submitButtonErrorTarget,
      success: this.submitButtonSuccessTarget,
      loading: this.submitButtonLoadingTarget,
    };

    for (let [buttonState, buttonEl] of Object.entries(buttonMap)) {
      if (buttonState === state) {
        buttonEl.classList.remove(...this.hiddenClasses);
      } else {
        buttonEl.classList.add(...this.hiddenClasses);
      }
    }
  }

  disconnect(): void {
    if (this.timeoutId != null) clearTimeout(this.timeoutId);
  }

  async submitLater() {
    let form = this.element as HTMLFormElement;
    let formData = new FormData(form);

    formData.set("_source", "silent_submit");

    if (this.timeoutId != null) clearTimeout(this.timeoutId);
    this.setButtonState("loading");

    try {
      let response = await fetch(form.action, {
        method: "post",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Silent submit: bad response");
      }

      this.setButtonState("success");
    } catch (er) {
      this.setButtonState("error");
    } finally {
      this.timeoutId = window.setTimeout(() => {
        this.setButtonState("pending");
      }, 2000);
    }
  }
}
