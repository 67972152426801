import { Controller } from "@hotwired/stimulus";
import { DateTime } from "luxon";

// Connects to data-controller="datetime"
export default class extends Controller {
  static targets = ["date", "time"];
  static values = {
    iso: String,
  };

  declare readonly isoValue: string;
  declare readonly dateTarget: HTMLElement;
  declare readonly timeTarget: HTMLElement;
  declare readonly hasDateTarget: boolean;
  declare readonly hasTimeTarget: boolean;

  connect() {
    const datetime = DateTime.fromISO(this.isoValue);
    console.dir(datetime);

    if (this.hasDateTarget) {
      this.dateTarget.innerText = datetime.toLocaleString(DateTime.DATE_FULL);
    }

    if (this.hasTimeTarget) {
      this.timeTarget.innerText = datetime.toLocaleString({
        hour: "numeric",
        minute: "2-digit",
        timeZoneName: "short",
      });
    }
  }
}
