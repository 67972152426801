import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="autoscroll"
export default class extends Controller {
  static targets: string[] = ["active"];
  declare readonly activeTarget: HTMLElement;
  private leftMargin = 20;

  connect() {
    let activeLeft = this.activeTarget.getBoundingClientRect().left;
    let parentLeft = this.element.getBoundingClientRect().left;
    let left = activeLeft - parentLeft - this.leftMargin;
    this.element.scrollTo({ left });
  }
}
