import ahoy, { EventProperties } from "ahoy.js";
import { getEnv } from "../utils/get-env";

ahoy.configure({
  withCredentials: true,
  visitsUrl: "/web/visits",
  eventsUrl: "/web/events",
  cookieDomain: getEnv("BASE_URL").replace(/^https?:\/\//, ""),
});

export const trackEvent = (
  name: string,
  properties: EventProperties | undefined,
) => {
  ahoy.track(name, { ...properties, source: "javascript" });
};
