import { Controller } from "@hotwired/stimulus";

// This controller is used to change the image preview on the image gallery
// Connects to data-controller="image-fit"
export default class extends Controller {
  onChange(event) {
    const target = event.target;
    const multiImagePreviewEl = document.getElementById(
      "multi-image-gridstack",
    );
    const drawerEl = document.getElementById("right-tray");
    let previewAudioImage;
    if (drawerEl) {
      previewAudioImage = drawerEl.getElementsByClassName("audio-image")[0];
    }

    let previewImages;
    if (multiImagePreviewEl) {
      const previewImagesEls =
        multiImagePreviewEl.getElementsByClassName("gallery-image") || [];
      previewImages = Array.from(previewImagesEls);
    } else if (previewAudioImage) {
      previewImages = [previewAudioImage];
    } else {
      previewImages = [document.getElementById("image-preview")];
    }

    if (target.id.includes("_fill_true")) {
      this.refreshPreviewImages(previewImages, "fill");
    } else if (target.id.includes("_fill_false")) {
      this.refreshPreviewImages(previewImages, "fit");
    }
  }

  // private
  refreshPreviewImages(previewImages, fitOrFill) {
    if (fitOrFill === "fit") {
      previewImages.map((image) => {
        image.classList.add("fit");
        image.classList.remove("fill");
      });
    } else {
      previewImages.map((image) => {
        image.classList.add("fill");
        image.classList.remove("fit");
      });
    }
  }
}
