import { Controller } from "@hotwired/stimulus";
import { createDownloadLink } from "../utilities";

// Connects to data-controller="license-code-reveal"
export default class extends Controller {
  static values = {
    url: String,
  };

  reveal() {
    if (this.urlValue) {
      createDownloadLink(this.urlValue, true);
    }
  }
}
