import React, { useState, useMemo, useEffect } from "react";
import { ChannelList } from "@sendbird/uikit-react";
import ChannelListHeader from "./ChannelListHeader";
import ChannelPreview from "./ChannelPreview";
import EditProfileModal from "./EditProfileModal";
import ChannelHeader from "./ChannelHeader";
import PlusCircle from "../icons/PlusCircle";
import ChannelSettings from "./ChannelSettings";
import { ChannelSettingsProvider } from "@sendbird/uikit-react/ChannelSettings/context";
import AddEditChannelDetailsModal from "./AddEditChannelDetailsModal";
import { ChannelProvider } from "@sendbird/uikit-react/Channel/context";
import ChannelUI from "@sendbird/uikit-react/Channel/components/ChannelUI";
import CommentsMessage from "../Comments/CommentsMessage";
import CommentsThread from "../Comments/CommentsThread";
import CommentsMessageInput from "../Comments/CommentsMessageInput";
import ChatPlaceholder from "./ChatPlaceholder";
import i18n from "../../utils/i18n";
import ChatAvatar from "./ChatAvatar";
import Toasts from "../Toasts";

export default function Chat({
  sb,
  user,
  setUser,
  profileId,
  profileTitle,
  profileImage,
  isOwner,
  isMobile,
  startingChannel,
  startingMessageId, // currently unused but keeping in case we need it in the future
  startingMessageCreatedAt,
  parentMessage,
  disableChannelAutoSelect,
}) {
  const PANELS = {
    CHANNEL_LIST: "CHANNEL_LIST",
    CHANNEL: "CHANNEL",
    CHANNEL_SETTINGS: "CHANNEL_SETTINGS",
    MESSAGE_SEARCH: "MESSAGE_SEARCH",
    THREAD: "THREAD",
  };

  const [panel, setPanel] = useState(PANELS.CHANNEL_LIST);
  const [showSettings, setShowSettings] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showAddChannelModal, setShowAddChannelModal] = useState(false);
  const [currentChannel, setCurrentChannel] = useState(startingChannel);
  const [threadMessage, setThreadMessage] = useState(parentMessage);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);

  useEffect(() => {
    bypassFocus();
  }, []);

  //Bootstrap hijacking focus on modal so this will stop it
  function bypassFocus() {
    const modal = document.getElementById("sendbird-modal-root");

    if (modal) {
      modal.addEventListener("focusin", (e) => e.stopPropagation());
    }
  }

  const queries = useMemo(
    () => ({
      channelListQuery: {
        // Should be an instance of GroupChannelListQueryParams
        // https://sendbird.com/docs/chat/v4/javascript/ref/interfaces/_sendbird_chat_groupChannel.GroupChannelListQueryParams.html
        includeEmpty: true,
        customTypesFilter: [profileId],
      },
      applicationUserListQuery: {
        // Should be an instance of ApplicationUserListQuery
        // https://sendbird.com/docs/chat/v4/javascript/ref/interfaces/_sendbird_chat.ApplicationUserListQueryParams.html
        // metaDataKeyFilter: 'profile_id',
        // metaDataValuesFilter: [profileId],
      },
    }),
    [profileId],
  );

  function setCorrectChannel(channel2) {
    if (channel2 && sb) {
      sb.groupChannel.getChannel(channel2.url).then((channel) => {
        setCurrentChannel(channel);
        setPanel(PANELS.CHANNEL);
      });
    }
  }

  useEffect(() => {
    if (startingChannel) {
      setCurrentChannel(startingChannel);
      setPanel(PANELS.CHANNEL);
    }
  }, [startingChannel]);

  useEffect(() => {
    if (parentMessage) {
      setThreadMessage(parentMessage);
      setPanel(PANELS.THREAD);
    }
  }, [parentMessage]);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  }, [showToast]);

  const isSidePanelOpen = panel === PANELS.CHANNEL_SETTINGS;

  const renderMessage = (message) => {
    if (message.message.parentMessageId) return <></>;

    return (
      <CommentsMessage
        message={message.message}
        user={user}
        channel={currentChannel}
        bannedUsers={[]}
        setThreadMessage={(message) => {
          setThreadMessage(message);
          setPanel(PANELS.THREAD);
        }}
        isChat={true}
        isMobile={isMobile}
      />
    );
  };

  return (
    <>
      <Toasts message={toastMessage} show={showToast} />
      {showEditProfile && (
        <EditProfileModal
          sb={sb}
          show={showEditProfile}
          onHide={() => {
            setShowEditProfile(false);
          }}
          onSubmit={() => {
            setToastMessage(i18n.t("client.community.profile_saved"));
            setShowToast(true);
          }}
          user={user}
          setUser={setUser}
          profile={profileId}
        />
      )}
      {showAddChannelModal && (
        <AddEditChannelDetailsModal
          sb={sb}
          onHide={(channel) => {
            setShowAddChannelModal(false);

            if (channel) {
              setCurrentChannel(channel);
              setPanel(PANELS.CHANNEL);
            }
          }}
          onSubmit={() => {
            setToastMessage(i18n.t("client.community.channel_saved"));
            setShowToast(true);
          }}
          show={showAddChannelModal}
          profileId={profileId}
          user={user}
        />
      )}
      {(panel === PANELS.CHANNEL_LIST || !isMobile) && (
        <div className="chat-nav">
          <div className="d-flex justify-content-end chat-nav__profile">
            <ChatAvatar
              className="chat-nav__avatar"
              width={50}
              height={50}
              src={user.avatar_url || ""}
              alt="Profile Image"
              onClick={() => setShowEditProfile(true)}
            />
          </div>
        </div>
      )}
      <div
        className={`chat-container row p-0 m-0 bg-black ${
          isMobile ? "mobile-height" : "desktop-height"
        } ${
          isMobile && panel === PANELS.CHANNEL_LIST
            ? "mobile-channel-list-height"
            : ""
        }`}
      >
        <div className="channel-wrap position-relative col-12 p-0 m-0 d-flex d-inline-block text-black">
          {(panel === PANELS.CHANNEL_LIST || !isMobile) && (
            <div
              className={`channel-list ${isMobile ? "w-100 h-100" : ""} ${
                isSidePanelOpen ? "sendbird-channel-fade" : ""
              }`}
            >
              <ChannelList
                allowProfileEdit
                onChannelSelect={(channel) => {
                  setCorrectChannel(channel);
                }}
                // Custom ChannelListHeader
                renderHeader={() => (
                  <ChannelListHeader
                    profileTitle={profileTitle}
                    profileImage={profileImage}
                  />
                )}
                disableAutoSelect={disableChannelAutoSelect}
                queries={queries}
                renderChannelPreview={(props) => (
                  <ChannelPreview
                    channel={props.channel}
                    currentChannel={currentChannel}
                  />
                )}
                className={
                  (isOwner ? "has-add" : "") + (isMobile ? " w-100" : "")
                }
              />
              {isOwner && (
                <div id={"add-channel-contaier"}>
                  <button
                    onClick={() => setShowAddChannelModal(true)}
                    className={"text-decoration-none"}
                  >
                    <span>
                      <PlusCircle />
                    </span>{" "}
                    Add Channel
                  </button>
                </div>
              )}
            </div>
          )}

          {(panel === PANELS.CHANNEL || !isMobile) && (
            <div
              className={`channel-chat ${isMobile ? "w-100 h-100" : ""} ${
                isSidePanelOpen ? "sendbird-channel-fade" : ""
              }`}
            >
              <ChannelProvider
                channelUrl={currentChannel?._url}
                startingPoint={startingMessageCreatedAt}
              >
                <ChannelUI
                  renderChannelHeader={() => (
                    <ChannelHeader
                      channel={currentChannel}
                      isOperator={
                        isOwner || currentChannel?.myRole === "operator"
                      }
                      sb={sb}
                      isMobile={isMobile}
                      onBackClicked={() => setPanel(PANELS.CHANNEL_LIST)}
                      onHeaderActionClick={() =>
                        setPanel(PANELS.CHANNEL_SETTINGS)
                      }
                    />
                  )}
                  renderMessage={renderMessage}
                  renderMessageInput={() => (
                    <CommentsMessageInput
                      channel={currentChannel}
                      user={user}
                      isChat={true}
                      isMobile={isMobile}
                    />
                  )}
                  renderPlaceholderLoader={() => (
                    <ChatPlaceholder
                      message={i18n.t("client.community.loading_chat_channel")}
                      onBackClicked={() => setPanel(PANELS.CHANNEL_LIST)}
                      isMobile={isMobile}
                      hideBack={true}
                      isFrozen={currentChannel?.isFrozen}
                    />
                  )}
                  renderPlaceholderEmpty={() => (
                    <ChatPlaceholder
                      message={
                        currentChannel?.isFrozen
                          ? i18n.t("client.community.no_chat_messages_frozen")
                          : i18n.t("client.community.no_chat_messages")
                      }
                      onBackClicked={() => setPanel(PANELS.CHANNEL_LIST)}
                      isMobile={isMobile}
                      hideBack={true}
                      isFrozen={currentChannel?.isFrozen}
                    />
                  )}
                  renderPlaceholderInvalid={() => (
                    <ChatPlaceholder
                      message={i18n.t("client.community.nothing_in_chat")}
                      onBackClicked={() => setPanel(PANELS.CHANNEL_LIST)}
                      isMobile={isMobile}
                      isFrozen={currentChannel?.isFrozen}
                    />
                  )}
                />
              </ChannelProvider>
            </div>
          )}

          {(isOwner || currentChannel?.myRole === "operator") &&
            (panel === PANELS.CHANNEL_SETTINGS || showSettings) && (
              <div
                className={`channel-settings end-0 z-index-top-nav ${
                  isMobile ? "w-100 top-0" : ""
                }`}
              >
                <ChannelSettingsProvider channelUrl={currentChannel.url}>
                  <ChannelSettings
                    sb={sb}
                    channel={currentChannel}
                    onCloseClick={() => {
                      setPanel(PANELS.CHANNEL);
                      setShowSettings(false);
                    }}
                  />
                </ChannelSettingsProvider>
              </div>
            )}

          {panel === PANELS.THREAD && (
            <div
              className={`channel-thread end-0 z-index-top-nav ${
                isMobile ? "w-100 mobile-thread" : ""
              }`}
            >
              <CommentsThread
                message={threadMessage}
                user={user}
                channel={currentChannel}
                bannedUsers={[]}
                closeThread={() => {
                  setThreadMessage(null);
                  setPanel(PANELS.CHANNEL);
                }}
                startingMessageCreatedAt={startingMessageCreatedAt}
                isChat={true}
                isMobile={isMobile}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
