import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

const CHECKED =
  '<svg style="pointer-events:none; margin-right:5px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="black" stroke="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7803 6.21967C16.0732 6.51256 16.0732 6.98744 15.7803 7.28033L8.78034 14.2803C8.48745 14.5732 8.01257 14.5732 7.71968 14.2803L4.21967 10.7197C3.92677 10.4268 3.92678 10.0126 4.21967 9.71967C4.51257 9.42677 4.92678 9.42677 5.21967 9.71967L8.25001 12.6893L14.7197 6.21967C15.0126 5.92678 15.4874 5.92678 15.7803 6.21967Z" fill="white"/></svg>';

const UNCHECKED =
  '<svg style="pointer-events:none; margin-right:5px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="18" height="18" rx="3" fill="white"/><rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="black" stroke-opacity="0.12"/></svg>';

const MAX_CATEGORIES_SELECTED = 2;

const allowCustomCategories = (choicesElement) => {
  choicesElement.input.element.addEventListener("keydown", (event) => {
    if (event.keyCode === 13 && event.target.value) {
      let queryHits = 0;
      [...choicesElement.choiceList.element.children].forEach((element) => {
        // exclude this element because it displays the search query in
        // the prompt for them to create a new category
        if (element.classList.contains("has-no-results")) return;
        if (element.innerText.includes(event.target.value)) {
          queryHits += 1;
        }
      });
      if (!queryHits) {
        event.stopPropagation();
        choicesElement.setChoices(
          [
            {
              value: event.target.value,
              label: event.target.value,
              selected: true,
            },
          ],
          "value",
          "label",
          false,
        );
        const linterEvent = event;
        linterEvent.target.value = "";
      }
    }
  });
};

export default class extends Controller {
  static displayCustomCategoryInput = false;

  connect() {
    this.choice = new Choices(
      this.element.querySelector("#custom-categories-selector"),
      {
        position: "bottom",
        shouldSort: false,
        renderSelectedChoices: "always",
        itemSelectText: "",
        noChoicesText: "",
        noResultsText: () => {
          return `<div class="choices__item choices__item--choice has-no-results">Press ENTER to create your own category: ${this.choice._currentValue}</div>`;
        },
        allowHTML: true,
        resetScrollPosition: false,
        searchFields: ["label"],
        searchFloor: 1,
        callbackOnCreateTemplates: function (template) {
          return {
            item: ({ classNames }, data) => {
              return template(`
              <div class="${classNames.item} ${
                data.placeholder ? classNames.placeholder : ""
              }
                " data-item data-id="${data.id}" data-value="${data.value}" ${
                  data.active ? 'aria-selected="true"' : ""
                }
                ${data.disabled ? 'aria-disabled="true"' : ""}>
                  <span style="margin-right:8px;">${data.label}</span>
                  <span data-action="click->drawer#blockHide click->custom-categories-dropdown#removeCategory" style="cursor: pointer; padding: 5px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53033 1.63555C9.82322 1.34266 9.82322 0.867788 9.53033 0.574895C9.23744 0.282001 8.76256 0.282001 8.46967 0.574895L5 4.04456L1.53033 0.574895C1.23744 0.282001 0.762563 0.282001 0.46967 0.574895C0.176777 0.867788 0.176777 1.34266 0.46967 1.63555L3.93934 5.10522L0.46967 8.57489C0.176777 8.86779 0.176777 9.34266 0.46967 9.63555C0.762563 9.92845 1.23744 9.92845 1.53033 9.63555L5 6.16588L8.46967 9.63555C8.76256 9.92845 9.23744 9.92845 9.53033 9.63555C9.82322 9.34266 9.82322 8.86779 9.53033 8.57489L6.06066 5.10522L9.53033 1.63555Z" fill="black" fill-opacity="0.33"/>
                    </svg>
                  </span>
              </div>
            `);
            },
            containerInner: ({ classNames }, data) => {
              return template(`
              <div class="parliament-input choices__inner">
                <div class="choices-label">Add Your Own Categories</div>
              </div>`);
            },
            choice: ({ classNames }, data) => {
              const maxSelections =
                this.getValue(true).length >= MAX_CATEGORIES_SELECTED;
              const choiceSelected =
                this.getValue(true).indexOf(data.value) > -1;
              return template(`
              <div
                id="choices--profile_category_ids-item-choice-${data.id}"
                class="choices__item choices__item--choice choices__item--selectable ${
                  maxSelections && !choiceSelected && "choices__item--disabled"
                } is-highlighted"
                role="option"
                data-choice=""
                data-id="${data.id}"
                data-value="${data.value}"
                data-select-text=""
                data-choice-selectable=""
                aria-selected="true"
              >
                ${data.selected ? CHECKED : UNCHECKED}
                ${data.label}
              </div>
            `);
            },
            dropdown: ({ classNames }) => {
              return template(`
              <div class="p-3 choices__list choices__list--dropdown" aria-expanded="false">
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <h5 class="m-0">Category</h5>
                    <small>Write in 1 or 2</small>
                  </div>
                  <div class="btn btn-black" data-action="click->custom-categories-dropdown#hideDropdown">
                    Close
                  </div>
                </div>
              </div>
            `);
            },
          };
        },
        fuseOptions: {
          threshold: 0,
          distance: 0,
        },
      },
    );

    this.element.addEventListener("addItem", this.addCategory);
    this.element.addEventListener("choice", this.choiceFn);
    allowCustomCategories(this.choice);
  }

  hideDropdown = (e) => {
    this.choice.hideDropdown();
  };

  toggleDisplayCustomCategoryInput = (e) => {
    this.displayCustomCategoryInput = !this.displayCustomCategoryInput;
    e.stopPropagation();
  };

  choiceFn = (e) => {
    if (this.choice.getValue(true).includes(e.detail.choice.value)) {
      this.choice.removeActiveItemsByValue(e.detail.choice.value);
      this.shouldToggleCategory = true;
    }
  };

  addCategory = (e) => {
    const value = e.detail.value;
    if (
      this.choice.getValue(true).length > MAX_CATEGORIES_SELECTED &&
      value !== "custom-category-toggle"
    ) {
      this.choice.removeActiveItemsByValue(value);
      this.choice.showDropdown(true);
      return;
    }

    if (
      this.choice.getValue(true).includes(value) &&
      this.shouldToggleCategory
    ) {
      this.choice.removeActiveItemsByValue(value);
      this.shouldToggleCategory = false;
    }
  };

  removeCategory = (e) => {
    const value = e.currentTarget.closest(".choices__item").dataset.value;
    this.choice.removeActiveItemsByValue(value);
    e.stopPropagation();
  };
}
