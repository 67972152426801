import { Controller } from "@hotwired/stimulus";
import { checkmark, loadingSpinner, xmark } from "../utilities";
import { fetchJson, getCsrfToken } from "../fetch";

// Connects to data-controller="auto-save"
export default class extends Controller {
  static targets = ["form", "saveIndicator"];
  static values = {
    published: Boolean,
  };

  connect() {
    this.setupAutosaveListener();
  }

  async saveBlock() {
    const formTarget = this.formTarget;

    let data = new FormData(this.formTarget);

    try {
      await fetchJson(formTarget.dataset.autoSaveUrl, {
        method: "post",
        body: data,
      });
    } catch (err) {
      // noop, might be validation errors etc
      console.info("Autosaving error occured", err);
    }
  }

  setupAutosaveListener() {
    /** @type {Element} */

    if (this.publishedValue) {
      // block already published, disabling autosave
      return;
    }

    const formTarget = this.formTarget;
    const inputs = formTarget.querySelectorAll("input, trix-editor, select");

    const originalInnerHTML = this.hasSaveButtonTarget
      ? this.saveButtonTarget.innerText.trim()
      : null;

    const maybeAutosave = (e) => {
      if (e.relatedTarget && e.relatedTarget === this.saveButtonTarget) {
        // don't autosave if user clicked on the create button
        return;
      }

      let data = new FormData(this.formTarget);

      this.buttonSaving();
      this.hideIndicator();

      fetch(formTarget.dataset.draftSaveUrl, {
        method: "post",
        body: data,
        headers: {
          "X-CSRF-Token": getCsrfToken(),
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((json) => {
              throw new Error(json.error);
            });
          }
        })
        .then(() => {
          this.buttonSaved();
          this.showIndicator();
        })
        .catch((e) => {
          console.info("Error autosaving changes", e);
          this.buttonError();
          this.hideIndicator();
        })
        .finally(() => {
          // restore
          setTimeout(() => {
            this.buttonReset(originalInnerHTML);
          }, 2000);
        });
    };

    inputs.forEach((input) => {
      input.addEventListener("blur", (e) => maybeAutosave(e));
      input.addEventListener("fileUploaded", (e) => maybeAutosave(e));
    });
  }

  buttonSaving() {
    if (!this.hasSaveButtonTarget) return;

    this.saveButtonTarget.innerHTML = loadingSpinner();
  }

  buttonSaved() {
    if (!this.hasSaveButtonTarget) return;

    this.saveButtonTarget.classList.add("success-button");
    this.saveButtonTarget.disabled = true;

    setTimeout(() => {
      this.saveButtonTarget.innerHTML = `${checkmark(
        "#539400",
      )} <span class="align-middle">Saved</span>`;
    }, 200);
  }

  buttonError() {
    if (!this.hasSaveButtonTarget) return;

    this.saveButtonTarget.innerHTML = xmark();
  }

  buttonReset(label) {
    if (!this.hasSaveButtonTarget) return;

    this.saveButtonTarget.classList.remove("success-button");
    this.saveButtonTarget.innerHTML = label;
    this.saveButtonTarget.disabled = false;
  }

  hideIndicator() {
    if (!this.hasSaveIndicatorTarget) return;
    this.saveIndicatorTarget.classList.add("d-none");
  }

  showIndicator() {
    if (!this.hasSaveIndicatorTarget) return;
    this.saveIndicatorTarget.classList.remove("d-none");
  }
}
