// Connects to data-controller="multi-select-dropdown"
import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

const MAX_CATEGORIES_SELECTED = 2;

export const CHECKED =
  '<svg style="pointer-events:none; margin-right:5px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="black" stroke="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7803 6.21967C16.0732 6.51256 16.0732 6.98744 15.7803 7.28033L8.78034 14.2803C8.48745 14.5732 8.01257 14.5732 7.71968 14.2803L4.21967 10.7197C3.92677 10.4268 3.92678 10.0126 4.21967 9.71967C4.51257 9.42677 4.92678 9.42677 5.21967 9.71967L8.25001 12.6893L14.7197 6.21967C15.0126 5.92678 15.4874 5.92678 15.7803 6.21967Z" fill="white"/></svg>';

export const UNCHECKED =
  '<svg style="pointer-events:none; margin-right:5px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="1" width="18" height="18" rx="3" fill="white"/><rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="black" stroke-opacity="0.12"/></svg>';

export default class extends Controller {
  static values = {
    formLabel: String,
    subtitle: String,
    maxSelections: Number,
    hideSearch: Boolean,
  };

  connect(e) {
    const formLabel = this.formLabelValue;
    const subtitle = this.subtitleValue;
    const maxSelectionsValue = this.maxSelectionsValue;

    this.choice = new Choices(this.element.querySelector("select"), {
      position: "bottom",
      shouldSort: true,
      renderSelectedChoices: "always",
      removeItems: true,
      removeItemButton: true,

      itemSelectText: "",

      resetScrollPosition: false,

      sorter: function (a, b) {
        if (a.value === "custom-category-toggle") {
          return 1;
        } else if (b.value === "custom-category-toggle") {
          return -1;
        } else {
          return 0;
        }
      },
      callbackOnCreateTemplates: function (template) {
        return {
          item: ({ classNames }, data) => {
            return template(`
              <div
                class="
                  ${classNames.item}
                  ${data.placeholder ? classNames.placeholder : ""}
                  padding-6-10
                  removable-item
                "
                data-item
                data-deletable
                data-id="${data.id}"
                data-value="${data.id}"
                ${data.active ? 'aria-selected="true"' : ""}
                ${data.disabled ? 'aria-disabled="true"' : ""}>
                  <span>${data.label}</span>
                  <button type="button" class="remove-button" aria-label="Remove item" data-button="">✕</button>
              </div>
            `);
          },
          containerInner: ({ classNames }, data) => {
            return template(`
              <div class="parliament-input choices__inner">
                <div class="choices-label">${formLabel}?</div>
              </div>`);
          },
          choice: ({ classNames }, data) => {
            let maxSelections;
            if (maxSelectionsValue > 1) {
              maxSelections = this.getValue(true).length >= maxSelectionsValue;
            } else {
              maxSelections = false;
            }

            const choiceSelected = this.getValue(true).indexOf(data.value) > -1;

            return template(`
                <div
                  id="choices--profile_category_ids-item-choice-${data.id}"
                  class="
                    choices__item
                    choices__item--choice
                    choices__item--selectable
                    ${
                      maxSelections &&
                      !choiceSelected &&
                      "choices__item--disabled"
                    }
                    pe-0
                    is-highlighted"
                  role="option"
                  data-choice=""
                  data-id="${data.id}"
                  data-value="${data.value}"
                  data-select-text=""
                  data-choice-selectable=""
                  aria-selected="true"
                >
                  ${data.selected ? CHECKED : UNCHECKED}
                  ${data.label}
                </div>
              `);
          },
          dropdown: ({ classNames }) => {
            return template(`
              <div class="p-3 choices__list choices__list--dropdown" aria-expanded="false">
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <h5 class="m-0">${formLabel}</h5>
                    <small style="word-break: normal;">${subtitle}</small>
                  </div>
                </div>
              </div>
            `);
          },
        };
      },
      fuseOptions: {
        threshold: 0.4,
        distance: 0,
      },
    });

    this.element.addEventListener("addItem", this.addCategory);
    this.element.addEventListener("choice", this.choiceFn);
    this.element.addEventListener("change", this.hideLabelFn);

    this.hideLabelFn();
    if (this.hideSearchValue) {
      this.element.querySelector("[name=search_terms]").classList.add("d-none");
    }
  }

  hideDropdown = (e) => {
    this.choice.hideDropdown();
  };

  choiceFn = (e) => {
    if (this.choice.getValue(true).includes(e.detail.choice.value)) {
      this.choice.removeActiveItemsByValue(e.detail.choice.value);
      this.shouldToggleCategory = true;
    }
  };

  hideLabelFn = () => {
    const selectedElements = this.choice.getValue(true).length;
    if (selectedElements === 0) {
      this.element.querySelector(".choices-label").classList.remove("d-none");
    } else {
      this.element.querySelector(".choices-label").classList.add("d-none");
    }
  };

  addCategory = (e) => {
    const value = e.detail.value;
    if (this.choice.getValue(true).length > MAX_CATEGORIES_SELECTED) {
      this.choice.removeActiveItemsByValue(value);
      this.choice.showDropdown(true);
      return;
    }

    if (
      this.choice.getValue(true).includes(value) &&
      this.shouldToggleCategory
    ) {
      this.choice.removeActiveItemsByValue(value);
      this.shouldToggleCategory = false;
    }
  };

  removeCategory = (e) => {
    const value = e.currentTarget.closest(".choices__item").dataset.value;

    this.choice.removeActiveItemsByValue(value);
  };
}
