import { useResize } from "stimulus-use";
import { Controller } from "@hotwired/stimulus";
import { debounce } from "../debounce";
import { getCsrfToken } from "../fetch";

/**
 * Adds add a class to an element when it is overflowing (scrollable) and
 * removes it when it is not.
 *
 * Connects to data-controller="overflow-class-toggle"
 */
export default class OverflowClassToggleController extends Controller {
  static classes = ["overflowing"];

  declare readonly overflowingClass?: string;
  declare readonly hasOverflowingClass: boolean;

  connect() {
    useResize(this, {});
  }

  resize(contentRect: DOMRectReadOnly) {
    const isOverflowing = contentRect.width < this.element.scrollWidth;
    const className = this.hasOverflowingClass
      ? this.overflowingClass!
      : "overflowing";
    this.element.classList[isOverflowing ? "add" : "remove"](className);
  }
}
