import React, { useEffect, useState } from "react";

export function SocialConnections({ subdomain }: { subdomain: string }) {
  const [html, setHtml] = useState<string>("");

  useEffect(() => {
    const fetchSocialConnections = async () => {
      try {
        const response = await fetch(
          `/hub/${subdomain}/onboarding/social_connections`,
        );
        const html = await response.text();
        setHtml(html);
      } catch (error) {
        console.error("Error fetching social connections:", error);
      }
    };

    if (subdomain) {
      fetchSocialConnections();
    }
  }, [subdomain]);

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
