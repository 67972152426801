import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="persist-event-list-title"
export default class extends Controller {
  connect() {
    this.element
      .querySelector("#event_list_title_input")
      .addEventListener("input", (e) => {
        this.setCookie(e.target.value);
      });
    if (
      this.element.querySelector("#event_list_title_input").value === "" &&
      this.element.dataset.shouldPersist === "true"
    ) {
      this.element.querySelector("#event_list_title_input").value =
        this.getCookie() || "";
    }
  }

  setCookie(value) {
    var expires = "";
    var date = new Date();
    date.setTime(date.getTime() + 1000 * 60 * 10);
    expires = `; expires=${date.toUTCString()}`;
    document.cookie = `event-list-title=${value || ""}${expires}; path=/`;
  }

  getCookie = () => {
    const value = `; ${document.cookie}`;
    const parts = value.split("; event-list-title=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  };
}
