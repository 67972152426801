import React, { useState } from "react";
import { FrigadeProvider } from "@frigade/react";
import { OnboardingForm } from "./components/OnboardingForm";
import { OnboardingFormStyles } from "./components/OnboardingFormStyles";
import { AnnouncementsAndTours } from "./components/AnnouncementsAndTours";
import { DataSyncer } from "./components/DataSyncer";

export interface FrigadeAppProps {
  apiKey: string;
  userId: string;
  profile: any;
  isCreator: boolean;
  socialLinks: any[];
  blocks: any[];
  showOnboardingForm: boolean;
  showAnnouncementsAndTours: boolean;
  showChecklist: boolean;
  formAuthenticityToken?: string;
  enableSocialLogin?: boolean;
  flowIdCreatorOnboardingForm?: string;
  flowIdCreatorAnnouncement?: string;
  flowIdCreatorTourMobile?: string;
  flowIdCreatorTourDesktop?: string;
  flowIdCreatorChecklist?: string;
  reloadFrigade?: () => void;
}

const FrigadeApp = (props: FrigadeAppProps) => {
  const [isReloading, setIsReloading] = useState(false);
  props = {
    ...props,
    reloadFrigade: () => {
      setIsReloading(true);
      setTimeout(() => {
        setIsReloading(false);
      }, 1);
    },
  };

  if (isReloading) {
    return null;
  }

  return (
    <FrigadeProvider
      publicApiKey={props.apiKey}
      userId={props.userId}
      config={{
        defaultAppearance: {
          theme: {
            colorPrimary: "#3AC0A0",
          },
        },
      }}
    >
      {props.showOnboardingForm && (
        <>
          <OnboardingFormStyles />
          <OnboardingForm {...props} />
        </>
      )}
      {props.showAnnouncementsAndTours && <AnnouncementsAndTours {...props} />}
      <DataSyncer {...props} />
    </FrigadeProvider>
  );
};

export default FrigadeApp;
