import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="truncated-text"
export default class extends Controller {
  connect() {
    // Hide the show more button unless the content has overflowed
    const trixContentElem = this.element.querySelector(".trix-content");
    if (trixContentElem.scrollHeight <= trixContentElem.clientHeight) {
      this.element.querySelector(".cursor-pointer").classList.add("d-none");
    }
  }
  readMore(e) {
    e.target
      .closest(".truncated-description")
      .classList.remove("truncated-description");

    e.target.classList.add("d-none");
  }
}
