import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="delete-confirmation"
export default class extends Controller {
  static targets = ["deleteConfirmationDialog", "offcanvas"];

  openDialog(e) {
    e.preventDefault();
    this.deleteConfirmationDialogTarget.classList.remove("d-none");
    this.offcanvasTarget.classList.add("opacity-50");
    this.offcanvasTarget.classList.add("pe-none");
  }

  cancel() {
    this.deleteConfirmationDialogTarget.classList.add("d-none");
    this.offcanvasTarget.classList.remove("opacity-50");
    this.offcanvasTarget.classList.remove("pe-none");
  }
}
