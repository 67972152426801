import React from "react";
import { useFlows } from "@frigade/react";
import { FrigadeAppProps } from "../index";
import { getInitials } from "../utils";
import {
  headerInitialsStyle,
  headerProgressStyle,
  headerWrapperStyle,
} from "./OnboardingFormStyles";

export function Header({
  flowIdCreatorOnboardingForm,
  profile,
  formAuthenticityToken,
}: FrigadeAppProps) {
  const { getNumberOfSteps, getCurrentStepIndex } = useFlows();

  if (!flowIdCreatorOnboardingForm) {
    return null;
  }

  const totalSteps = getNumberOfSteps(flowIdCreatorOnboardingForm);
  const currentStep = getCurrentStepIndex(flowIdCreatorOnboardingForm);
  return (
    <div style={headerWrapperStyle}>
      <div>
        <img
          src="/onboarding/logo.svg"
          alt="logo"
          className="headerLogo"
          style={{
            filter: currentStep === 0 ? "invert(1)" : "invert(0)",
          }}
        />
      </div>

      <div>
        <div className="headerProgressBar">
          {currentStep !== 0 &&
            Array.from(Array(totalSteps).keys()).map((step, index) => {
              if (index === 0) return null;
              return (
                <div
                  key={step}
                  style={{
                    ...headerProgressStyle,
                    backgroundColor:
                      step <= currentStep ? "#3AC0A0" : "#0000000D",
                  }}
                />
              );
            })}
        </div>
      </div>
      <div>
        <div
          style={{
            ...headerInitialsStyle,
            filter: currentStep === 0 ? "invert(1)" : "invert(0)",
          }}
        >
          {getInitials(profile.title)}
        </div>
      </div>
    </div>
  );
}
