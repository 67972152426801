import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="settings-nav"
export default class extends Controller {
  connect() {}

  showNavSettings(event: Event) {
    event.preventDefault();

    const mainDiv = document.getElementById("main") as HTMLDivElement;
    const navDiv = document.getElementById("sidebar") as HTMLDivElement;

    mainDiv.classList.remove("show-mobile-nav-block");
    mainDiv.classList.add("hide-mobile-nav-block");

    navDiv.classList.remove("hide-mobile-nav-block");
    navDiv.classList.add("show-mobile-nav-block");
  }

  hideNavSettings(event: Event) {
    event.preventDefault();

    const mainDiv = document.getElementById("main") as HTMLDivElement;
    const navDiv = document.getElementById("sidebar") as HTMLDivElement;

    mainDiv.classList.remove("hide-mobile-nav-block");
    mainDiv.classList.add("show-mobile-nav-block");

    navDiv.classList.remove("show-mobile-nav-block");
    navDiv.classList.add("hide-mobile-nav-block");
  }
}
