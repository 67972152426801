import { Controller } from "@hotwired/stimulus";
import ImgixClient from "@imgix/js-core";
import { assert } from "../utils/asserts";
import { getEnv } from "../utils/get-env";

const RESIZE_EVENT = "resizeImage";

export default class BlockImageController extends Controller {
  static targets = ["expandedView"];

  declare readonly expandedViewTarget: HTMLElement;

  connect() {
    const height = this.element.getAttribute("data-height");
    const width = this.element.getAttribute("data-width");

    assert(height);
    assert(width);

    this.setImageBackground(parseInt(height), parseInt(width));

    this.element.addEventListener(RESIZE_EVENT, (_e) => {
      const e = _e as CustomEvent;

      this.setImageBackground(e.detail.height, e.detail.width);
    });
  }

  setImageBackground = (heightBlocks: number, widthBlocks: number) => {
    const element = this.element as HTMLImageElement;
    const token = element.getAttribute("data-image-key");
    const fitting = element.dataset.fitting as "fit" | "fill";

    assert(token);

    const client = new ImgixClient({
      domain: getEnv("IMGIX_DOMAIN"),
    });

    const heightPx = heightBlocks * 500;
    const widthPx = widthBlocks * 500;
    const max = Math.max(heightPx, widthPx);
    const rounding = 200;

    const fit = {
      fill: "crop",
      fit: "clip",
    }[fitting];

    if (fit == null) {
      throw new Error(`unexpected fitting: ${JSON.stringify(fitting)}`);
    }

    const url = client.buildURL(token, {
      w: roundUpToNearest(max, rounding),
      h: roundUpToNearest(max, rounding),
      fit,
      auto: "format",
    });

    const srcSet = client.buildSrcSet(token, {
      w: roundUpToNearest(this.element.clientWidth, rounding),
      h: roundUpToNearest(this.element.clientHeight, rounding),
      fit,
      auto: "format",
    });

    element.src = url;
    element.srcset = srcSet;
  };
}

export const emitResizeEvent = (
  blockId: string,
  height: number,
  width: number,
) => {
  const blockImage = document.querySelector(
    `[data-controller='block-image'][data-block-id='${blockId}']`,
  );
  if (!blockImage) return;

  blockImage.dispatchEvent(
    new CustomEvent(RESIZE_EVENT, { detail: { height, width } }),
  );
};

function roundUpToNearest(n: number, nearest: number) {
  return Math.ceil(n / nearest) * nearest;
}
