import React from "react";
import ThreeDots from "../icons/ThreeDots";
import BackArrowCircle from "../icons/BackArrowCircle";

export default function ChannelHeader({
  channel,
  isOperator,
  isMobile,
  onBackClicked,
  onHeaderActionClick,
  isPlaceholder,
  hideBack,
}) {
  return (
    <>
      <div
        className={"sendbird-chat-header sendbird-conversation__channel-header"}
      >
        {isMobile && !hideBack && (
          <button
            onClick={onBackClicked}
            className={"sendbird-chat-header-content-back"}
          >
            <BackArrowCircle />
          </button>
        )}
        {!isPlaceholder && (
          <>
            <div className={"sendbird-chat-header-content"}>
              <div className={"sendbird-chat-header-content-title"}>
                {channel.name}
              </div>
            </div>
            <div className={"sendbird-chat-header-actions"}>
              {isOperator && (
                <button
                  className="sendbird-chat-header__right__info sendbird-iconbutton"
                  type="button"
                  onClick={onHeaderActionClick}
                >
                  <div
                    className={
                      "sendbird-icon sendbird-icon-info sendbird-icon-color--primary"
                    }
                  >
                    <ThreeDots />
                  </div>
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
